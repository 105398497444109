import { RootState } from "..";
import { createSelector } from "./createSelector";
import { WeatherType, isTodayWeatherType } from "../../types/weatherType";
import { HourlyForecast } from "../../api/weatherApi";
import {
  createLocationByIdSelector,
  createNearbyIdSelector,
} from "./locations";
import { getRegionById } from "./regions";
import { getFavoriteLocationIds } from "./favorite";

const weather = (state: RootState) => state.weather;
const currentWeather = (state: RootState) => state.weather.current;
const dailyForecasts = (state: RootState) => state.weather.daily;
const hourlyForecasts = (state: RootState) => state.weather.hourly;
const monthlyStats = (state: RootState) => state.weather.stats;
const imagery = (state: RootState) => state.weather.imagery;
const tides = (state: RootState) => state.weather.tides;
const weatherAlerts = (state: RootState) => state.weather.alerts;

export const getWeatherLocationId = (state: RootState) =>
  state.weather.selected.locationId;

export const getWeatherSelectorError = (state: RootState) =>
  state.weather.selected.error;

export const getWeatherLocation = createLocationByIdSelector(
  getWeatherLocationId
);

export const getWeatherLocationFavorite = createSelector(
  getWeatherLocationId,
  getFavoriteLocationIds,
  (locationId, favorites) => !!locationId && favorites.includes(locationId)
);

export const getWeatherLocationNameWithShortRegion = (state: RootState) => {
  const location = getWeatherLocation(state);
  if (!location) return "";
  const region = getRegionById(state, location.regionId);
  if (region && region.code.match(/^[A-Za-z]+$/))
    return `${location.name}, ${region.code.toUpperCase()}`;
  return location.name;
};

export const getWeatherLocationNearbyCityIds = createNearbyIdSelector(
  "c",
  getWeatherLocationId
);

export const getWeatherLocationNearbyPlaceIds = createNearbyIdSelector(
  "p",
  getWeatherLocationId
);

export const getWeatherType = createSelector(
  weather,
  (weather) => weather.selected.weatherType
);

export const getSelectedCurrentWeather = createSelector(
  currentWeather,
  (currentWeather) => currentWeather.data.now
);

export const getSelectedDailyForecasts = createSelector(
  dailyForecasts,
  (dailyForecasts) => dailyForecasts.data.daily
);

export const getSelectedDailyForecast = createSelector(
  getSelectedDailyForecasts,
  getWeatherType,
  (days, weatherType) => {
    if (!days || !days.length) return undefined;
    return isTodayWeatherType(weatherType)
      ? days[0]
      : weatherType === WeatherType.Tomorrow
      ? days[1]
      : undefined;
  }
);

export const getDefaultDailyForecast = createSelector(
  getWeatherType,
  getSelectedDailyForecast,
  getSelectedCurrentWeather,
  (weatherType, dailyForecast, currentWeather) => {
    return isTodayWeatherType(weatherType) && currentWeather
      ? currentWeather
      : dailyForecast;
  }
);

export const getSelectedHourlyForecasts = createSelector(
  hourlyForecasts,
  (hourlyForecasts) => hourlyForecasts.data.hourly
);

export const getSelectedTideStations = createSelector(
  tides,
  (tides) => tides.data.stations
);

export function getEveryNthHour(hours: HourlyForecast[], n: number) {
  if (!hours || !hours.length) return [];
  if (n <= 1) return hours;
  const timeZero = hours[0].time.getTime();
  return hours.filter(
    (x) => (x.time.getTime() - timeZero) % (n * 3600 * 1000) === 0
  );
}

export function getNextHours(
  hours: HourlyForecast[],
  count: number
): HourlyForecast[] {
  // TODO
  if (!hours || !hours.length) return [];

  const firstNotPast = hours.findIndex((x) => !x.past);
  if (firstNotPast < 0) return [];

  const endTime = new Date(
    hours[firstNotPast].time.getTime() + (count - 1) * 3600 * 1000
  );

  return hours.filter((x, i) => i >= firstNotPast && x.time <= endTime);
}

// const getSelectedNextHours = (
//   hours: HourlyForecast[],
//   count: number,
//   every: number = 1
// ) => {
//   const nextHours = getNextHours(hours, count);
//   return getEveryNthHour(nextHours, every);
// };

// export const getSelectedNext12HoursBy3 = createSelector(
//   getSelectedHourlyForecasts,
//   hourly => getSelectedNextHours(hourly, 13, 3)
// );

function getHoursByDate(hours: HourlyForecast[], date: Date) {
  const utc = date.getUTCDate();
  return hours.filter(({ time }) => time.getUTCDate() === utc);
}

export const getSelectedHours = createSelector(
  getSelectedHourlyForecasts,
  getWeatherType,
  (hours, weatherType) => {
    if (!hours.length) return hours;
    if (isTodayWeatherType(weatherType))
      return getHoursByDate(hours, hours[0].time);
    if (weatherType !== WeatherType.Tomorrow) return [];

    const tomorrowDate = new Date(hours[0].time.getTime() + 24 * 3600 * 1000);
    return getHoursByDate(hours, tomorrowDate);
  }
);

export const getSelectedHoursOrNext12 = createSelector(
  getSelectedHourlyForecasts,
  getWeatherType,
  getSelectedHours,
  (hours, weatherType, selectedHours) => {
    if (isTodayWeatherType(weatherType)) {
      const result = selectedHours.filter((x) => !x.past);
      return result.length < 12 ? getNextHours(hours, 12) : result;
    }
    return selectedHours;
  }
);

export const getSelected5HoursOrNext12 = createSelector(
  getSelectedHourlyForecasts,
  getWeatherType,
  getSelectedHours,
  (hours, weatherType, selectedHours) => {
    if (isTodayWeatherType(weatherType)) {
      return getEveryNthHour(getNextHours(hours, 13), 3);
      // const result = selectedHours.filter(x => !x.past);
      // return result.length < 12 ? getNextHours(hours, 12) : result;
    }
    return getEveryNthHour(selectedHours, 5).slice(0, 5);
  }
);

export const getSelectedHoursOrNext24By3 = createSelector(
  getSelectedHourlyForecasts,
  getWeatherType,
  getSelectedHours,
  (hours, weatherType, selectedHours) => {
    if (isTodayWeatherType(weatherType)) {
      return getEveryNthHour(getNextHours(hours, 24), 3);
      // const result = selectedHours.filter(x => !x.past);
      // return result.length < 12 ? getNextHours(hours, 12) : result;
    }
    return getEveryNthHour(selectedHours, 3);
  }
);

// export const getSelectedDayHoursBy3 = createSelector(getSelectedHours, hours =>
//   getEveryNthHour(hours, 3)
// );

export const getSelectedMonthlyStats = createSelector(
  monthlyStats,
  (monthlyStats) => monthlyStats.data.stats
);

export const getSelectedImagery = createSelector(
  imagery,
  (imagery) => imagery.data
);

export const getSelectedRadarImages = createSelector(getSelectedImagery, (x) =>
  x ? x.radar : []
);

export const getSelectedSatelliteImages = createSelector(
  getSelectedImagery,
  (x) => (x ? x.satellite : [])
);

export const getSelectedWeatherAlerts = createSelector(
  weatherAlerts,
  (x) => x.data.alerts
);

import { RootState } from "..";
import { createSelector } from "./createSelector";
import { getRegionByCode, getRegionById, getRegionCountry } from "./regions";
import { Location, Region, Country, LocationType } from "../../api/placesApi";
import { getCountryContinent, getCountryById } from "./countries";
import { distinct } from "../../utils/array";

const byId = (state: RootState) => state.locations.byId;
const byRegion = (state: RootState) => state.locations.byRegion;
const nearby = (state: RootState) => state.locations.nearby;

export const getHomeLocationId = (state: RootState) => state.locations.home.id;
export const getGeoipLocationId = (state: RootState) =>
  state.locations.geoip.id;
export const getGeoipCountryId = (state: RootState) =>
  state.locations.geoip.countryId;

export const getLocationIdsByRegion = (
  state: RootState,
  locationType: LocationType,
  regionId: string
) => {
  if (!regionId) return [];
  const { ids = [] } = byRegion(state)[locationType][regionId] || {};
  return ids;
};

export const getLocationsByRegion = createSelector(
  byRegion,
  byId,
  (_: RootState, locationType: LocationType) => locationType,
  (_: RootState, __: LocationType, regionid: string) => regionid,
  (byRegion, byId, locationType, regionId) => {
    if (!regionId) return [];
    const { ids = [] } = byRegion[locationType][regionId] || {};
    return ids.map((x) => byId[x]).filter((x) => x);
  }
);

export const createLocationByIdSelector = (
  getLocationId: (state: RootState) => string
) =>
  createSelector(byId, getLocationId, (byId, locationId) =>
    locationId ? byId[locationId] : undefined
  );

export const getLocationById = (state: RootState, locationId: string) =>
  locationId ? byId(state)[locationId] : undefined;

export const createLocationListSelect = (
  getIds: (state: RootState) => string[]
) =>
  createSelector(byId, getIds, (byId, ids) => {
    return ids.map((x) => byId[x]).filter((x) => x);
  });

export const createNearbySelector = (
  locationType: LocationType,
  getLocationId: (state: RootState) => string
) =>
  createLocationListSelect(
    (state) => nearby(state)[locationType][getLocationId(state)] || []
  );

export const createNearbyIdSelector = (
  locationType: LocationType,
  getLocationId: (state: RootState) => string
) => {
  return (state: RootState) =>
    nearby(state)[locationType][getLocationId(state)] || [];
};

export const getHomeLocation = createLocationByIdSelector(getHomeLocationId);
export const getHomeGeolocated = (state: RootState) => state.locations.home.geo;

export function areLocationsByRegionLoaded(
  state: RootState,
  type: LocationType,
  regionId: string
) {
  const region = byRegion(state)[type][regionId];
  return region && !region.loading;
}

export const getGeoipLocation = createLocationByIdSelector(getGeoipLocationId);

const getGeoipNearbyCities = createNearbySelector("c", getGeoipLocationId);
export const getGeoipNearestCities = createSelector(
  getGeoipLocation,
  getGeoipNearbyCities,
  (location, nearby) => (location ? [location, ...nearby] : nearby)
);

export const getLocationRegion = (
  state: RootState,
  location: Location | undefined
) => (location ? getRegionById(state, location.regionId) : undefined);

export const getLocationsRegions = (state: RootState, cities: Location[]) =>
  distinct(cities.map((x) => x.regionId))
    .map((x) => getRegionById(state, x))
    .filter((x) => x) as Region[];

export const getLocationCountry = (
  state: RootState,
  location: Location | undefined
) => getRegionCountry(state, getLocationRegion(state, location));

export const getLocationsCountries = (state: RootState, cities: Location[]) =>
  distinct(getLocationsRegions(state, cities).map((x) => x.countryId))
    .map((x) => getCountryById(state, x))
    .filter((x) => x) as Country[];

export const getLocationContinent = (
  state: RootState,
  location: Location | undefined
) => getCountryContinent(state, getLocationCountry(state, location));

export function getLocationByCode(
  state: RootState,
  locationType: LocationType,
  countryId: string,
  regionCode: string,
  locationCode: string
) {
  if (!locationCode) return undefined;
  const region = getRegionByCode(state, countryId, regionCode);
  if (!region) return undefined;

  const cities = Object.values(state.locations.byId);
  return cities.find(
    (x) =>
      x.type === locationType &&
      x.code === locationCode &&
      x.regionId === region._id
  );
}

export const isGeoipLoaded = (state: RootState) => state.locations.geoip.loaded;
export const isNearbyLoaded = (
  state: RootState,
  locationId: string,
  locationType: LocationType
) => (locationId ? !!nearby(state)[locationType][locationId] : true);

import React from "react";
import { TideHourly } from "../../../api/weatherApi";
import { LocaleMessage, LocaleTime } from "../../../components/Locale";
import styles from "./TideStationHourlyPopup.module.scss";
import { formatMeter } from "../../../types/units";
import { useFormatMessage } from "../../../i18n";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";

type TideStationHourlyPopupProps = {
  hourly: TideHourly[];
};

const TideStationHourlyPopup: React.FC<TideStationHourlyPopupProps> = ({
  hourly,
}) => {
  const formatMessage = useFormatMessage();
  const units = useSelector(getUnits);

  return (
    <div className={styles.popup}>
      <h3 className={styles.title}>
        <LocaleMessage id="TideHourlyPredictions" />
      </h3>

      <div className={styles.wrap}>
        <div className={`${styles.row} ${styles.header}`}>
          <div className={styles.col}>
            <LocaleMessage id="TideTime" />
          </div>
          <div className={styles.col}>
            <LocaleMessage id="TideLevel" />
          </div>
        </div>

        {hourly.map(({ time, level }) => (
          <div key={time.getTime()} className={styles.row}>
            <div className={styles.col}>
              <LocaleTime value={time} />
            </div>
            <div className={styles.col}>
              {formatMeter(formatMessage, level, units)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TideStationHourlyPopup;

import React from "react";
import { MessageKey } from "../../i18n";
import { FormattedMessage } from "react-intl";

type LocaleMessageProps = {
  id: MessageKey;
  values?: Record<string, React.ReactNode>;
  children?(...nodes: React.ReactNodeArray): React.ReactNode;
};

export const LocaleMessage: React.FC<LocaleMessageProps> = props => {
  return <FormattedMessage {...props} />;
};

import React, { useEffect } from "react";
import { LocaleMessage } from "../../../components/Locale";
import { popularCityIds } from "../../../types/popularCities";
import styles from "./PopularCities.module.scss";
import { useDispatch } from "../../../store/hooks";
import { tryLoadDailyShort } from "../../../store/actions/dailyShort";
import { useSelector } from "../../../store/hooks";
import {
  getLocationById,
  getLocationRegion,
} from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";
import { getCountryContinent } from "../../../store/selectors/countries";
import { getLocationWeatherUrl } from "../../../utils/url";
import { getLocale } from "../../../store/selectors/locale";
import { Link } from "react-router-dom";
import { getCurrentWeatherShort } from "../../../store/selectors/dailyShort";
import { getWeatherDescription } from "../../../types/weatherCodes";
import { useFormatMessage } from "../../../i18n";
import { formatTempShort } from "../../../types/units";
import { getUnits } from "../../../store/selectors/units";
import { autoRefreshDailyShort } from "../../../utils/cache";

type PopularCityUI = {
  name: string;
  dark?: true;
};

const popularCitiesUi: {
  [key: string]: PopularCityUI;
} = {
  "1609350": { name: "Bangkok" },
  "2643743": { name: "London" },
  "2988507": { name: "Paris", dark: true },
  "292223": { name: "Dubai", dark: true },
  "1880252": { name: "Singapore" },
  "5128581": { name: "New York" },
  "1735161": { name: "Kuala Lumpur" },
  "1850147": { name: "Tokyo" },
};

const PopularCity: React.FC<{ id: string }> = ({ id }) => {
  const ui = popularCitiesUi[id];
  const locale = useSelector(getLocale);
  const location = useSelector((x) => getLocationById(x, id));
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));
  const weather = useSelector((x) => getCurrentWeatherShort(x, id));
  const formatMessage = useFormatMessage();
  const units = useSelector(getUnits);

  return (
    <Link
      className={`${styles.card} ${ui.dark ? styles.dark : ""}`}
      to={getLocationWeatherUrl(locale, continent, country, region, location)}
    >
      <div className={styles.cardImg}>
        <div className={`${styles.cardBg} ${styles["city-" + id]}`} />
        {weather && (
          <div className={styles.weather}>
            <div className={styles.temp}>
              {formatTempShort(weather.temp, units)}
              {/* <span className={styles.tempMax}>
                {formatTempShort(weather.temp, units)}
              </span>
              <span className={styles.tempMin}>
                {" "}
                / {formatTempShort(weather.tempMin, units)}
              </span> */}
            </div>
            <div className={styles.weatherDesc}>
              {getWeatherDescription(formatMessage, weather.weatherCode, "")}
            </div>
          </div>
        )}
      </div>
      <div className={styles.name}>{location ? location.name : ui.name}</div>
      <div className={styles.country}>
        {country ? country.name : <>&nbsp;</>}
      </div>
    </Link>
  );
};

const PopularCities: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return autoRefreshDailyShort(() =>
      dispatch(tryLoadDailyShort(popularCityIds))
    );
  }, [dispatch]);

  return (
    <div className="container section">
      <h3 className="title title-solo">
        <LocaleMessage id="PopularCities" />
      </h3>
      <div className={styles.list}>
        {popularCityIds.map((id) => (
          <PopularCity id={id} key={id} />
        ))}
      </div>
    </div>
  );
};

export default PopularCities;

import React from "react";
import styles from "./PlaceSelector.module.scss";

export type LettersMask = { [key: string]: boolean };

export type LetterSelectorProps = {
  mask: LettersMask;
  letters: string[];
  selected: string;
  select: (x: string) => void;
};

const LetterSelector: React.FC<LetterSelectorProps> = ({
  letters,
  mask,
  selected,
  select,
}) => {
  return (
    <div
      className={`${styles.letterSelector} ${
        letters.length >= 20 && letters.length <= 26
          ? styles.stretch
          : styles.left
      } ${letters.length <= 26 ? styles.bullets : ""}`}
    >
      {letters.map((x) => {
        const disabled = !mask[x];
        const active = x === selected;
        return (
          <button
            key={x}
            disabled={disabled}
            className={active ? styles.active : undefined}
            onClick={(e) => {
              e.preventDefault();
              select(x);
            }}
          >
            {x}
          </button>
        );
      })}
    </div>
  );
};

export default LetterSelector;

import { UnitKey } from "../../types/units";

export const UNITS_SET = "UNITS_SELECTED";

type UnitSetAction = {
  type: typeof UNITS_SET;
  user: boolean;
  units: UnitKey;
};

export type UnitActionTypes = UnitSetAction;

import React, { useCallback, useRef } from "react";
import styles from "./LoginDialog.module.scss";
import { LocaleMessage } from "../../Locale";
import { useSelector, useDispatch } from "../../../store/hooks";
import { getLoginDialogVisible } from "../../../store/selectors/auth";
import {
  authShowDialog,
  loginGoogle,
  loginFacebook,
} from "../../../store/actions/auth";
import { useClickOutsideClose } from "../../../utils/hooks";
import googleImg from "./google.svg";
import facebookImg from "./facebook.svg";
import logo from "../assets/Logo_horizontal.svg";
import { RemoveScroll } from "react-remove-scroll";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../../config";
import { useFormatMessage } from "../../../i18n";
import { useHistory } from "react-router-dom";
import { getAccountSettingsUrl } from "../../../utils/url";

const LoginDialog: React.FC = () => {
  const history = useHistory();

  const visible = useSelector(getLoginDialogVisible);
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const close = useCallback(() => dispatch(authShowDialog(false)), [dispatch]);

  useClickOutsideClose(wrapperRef, visible, close);

  if (!visible) return null;

  return (
    <RemoveScroll enabled={visible} noIsolation>
      <div className="container" style={{ position: "relative" }}>
        <div ref={wrapperRef} className={styles.popup}>
          <div className={styles.mobHead}>
            <img alt="Weatherman" src={logo} />
            <button className={styles.closeBtn} onClick={close} />
          </div>
          <h3 className={styles.title}>
            <LocaleMessage id="LoginWelcome" />
          </h3>
          <div className={styles.continue}>
            <LocaleMessage id="LoginContinue" />
          </div>
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Login"
            onSuccess={(r) =>
              dispatch(loginGoogle(r, history, getAccountSettingsUrl()))
            }
            onFailure={(e) => console.error(e)}
            render={(props) => (
              <button {...props} className={styles.loginBtn}>
                <img alt="Google" src={googleImg} />
                <LocaleMessage id="LoginGoogle" />
              </button>
            )}
          >
            <img alt="Google" src={googleImg} />
          </GoogleLogin>
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            disableMobileRedirect={true}
            //redirectUri={FACEBOOK_REDIRECT_URL}
            fields="name,email,picture"
            callback={(r) =>
              dispatch(loginFacebook(r, history, getAccountSettingsUrl()))
            }
            cssClass={styles.loginBtn}
            icon={<img alt="Facebook" src={facebookImg} />}
            textButton={formatMessage("LoginFacebook")}
          />
          {/* <button className={styles.loginBtn}>
            <img alt="Google" src={googleImg} />
            <LocaleMessage id="LoginGoogle" />
          </button>
          <button className={styles.loginBtn}>
            <img alt="Facebook" src={facebookImg} />
            <LocaleMessage id="LoginFacebook" />
          </button> */}
        </div>
      </div>
    </RemoveScroll>
  );
};

export default LoginDialog;

import React from "react";
import styles from "./ContinentSelector.module.scss";
import { Link } from "react-router-dom";
import AfricaIcon from "./icons/Africa.svg";
import AntarcticaIcon from "./icons/Antarctica.svg";
import AsiaIcon from "./icons/Asia.svg";
import EuropeIcon from "./icons/Europe.svg";
import NorthAmericaIcon from "./icons/NorthAmerica.svg";
import SouthAmericaIcon from "./icons/SouthAmerica.svg";
import OceaniaIcon from "./icons/Oceania.svg";

import { getContinents } from "../../../types/continents";
import { getContinentUrl } from "../../../utils/url";
import { useSelector } from "react-redux";
import {
  getSelectedContinent,
  getSelectedLocationType,
} from "../../../store/selectors/locationSelector";

import { ContinentKey } from "../../../api/placesApi";
import { useLocale } from "../../../i18n";
import { LocaleMessage } from "../../../components/Locale";

const continentIcons: { [key in ContinentKey]: string } = {
  af: AfricaIcon,
  an: AntarcticaIcon,
  as: AsiaIcon,
  eu: EuropeIcon,
  na: NorthAmericaIcon,
  sa: SouthAmericaIcon,
  oc: OceaniaIcon,
};

const ContinentSelector: React.FC = () => {
  const locale = useLocale();
  const continents = getContinents(locale);
  const selected = useSelector(getSelectedContinent);
  const locationType = useSelector(getSelectedLocationType);

  return (
    <div className={styles.wrap}>
      <div
        className={`${styles.background} ${
          selected ? styles["background-" + selected._id] : ""
        }`}
      />
      <div className={`container ${styles.container}`}>
        <h1 className={styles.header}>
          <LocaleMessage id="WeatherSelectionHeader" />
        </h1>
        <p className={styles.header2}>
          <LocaleMessage
            id={
              locationType === "c"
                ? "WeatherSelectionHeader2"
                : "WeatherSelectionPlacesHeader2"
            }
          />
        </p>
      </div>
      <div className={`container ${styles.selector}`}>
        {continents.map((continent) => {
          const { _id, name } = continent;
          const active = continent === selected;
          const img = continentIcons[_id] || "";
          return (
            <Link
              key={_id}
              to={{
                pathname: getContinentUrl(locale, locationType, continent),
                state: {
                  softRedirect: true,
                },
              }}
              className={`${styles.continent} ${active ? styles.active : ""}`}
            >
              <img src={img} alt={name} className={styles.continentImg} />
              {name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ContinentSelector;

import React from "react";
import { getWeatherTypeDaysCount } from "../../../types/weatherType";
import styles from "./DaysPageButtons.module.scss";
import { useSelector } from "../../../store/hooks";
import { getWeatherType } from "../../../store/selectors/weather";
import { getSelectedDailyForecasts } from "../../../store/selectors/weather";
import { LocaleMessage } from "../../../components/Locale";

type DaysPageButtonsProps = {
  offset: number;
  setOffset: (x: number) => void;
};

const DaysPageButtons: React.FC<DaysPageButtonsProps> = ({
  offset,
  setOffset,
}) => {
  const weatherType = useSelector(getWeatherType);
  const count = getWeatherTypeDaysCount(weatherType);

  const total = useSelector(getSelectedDailyForecasts).length;

  if (total <= count) return null;

  const available = Math.min(total - offset - count, count);
  const next = () => {
    if (available > 0) setOffset(offset + count);
  };
  const prev = () => {
    if (offset - count >= 0) setOffset(offset - count);
  };

  return (
    <div
      className={styles.wrap}
      style={!total ? { visibility: "hidden" } : undefined}
    >
      <button
        aria-label="Previous"
        disabled={offset <= 0}
        className={styles.prev}
        onClick={prev}
      />
      <button disabled={available <= 0} className={styles.next} onClick={next}>
        <LocaleMessage
          id="NextXDays"
          values={{ days: available <= 0 ? count : available }}
        />
      </button>
    </div>
  );
};

export default DaysPageButtons;

import React from "react";
import styles from "./Controls.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneField.css";

type PhoneFieldProps = {
  label: string;
  value?: string;
  onChange: (value: string) => void;
};

export const PhoneField: React.FC<PhoneFieldProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <div className={styles.phoneField}>
      <label className={styles.label}>
        {label}
        <PhoneInput
          country="us"
          value={value}
          onChange={(value) => {
            if (onChange) onChange(value);
          }}
          containerClass={styles.phoneInput}
        />
      </label>
    </div>
  );
};

import React from "react";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
//@ts-ignore
import emojiSupport from "detect-emoji-support";

const svg = !emojiSupport();

type CountryFlagProps = {
  countryCode: string;
};

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode }) => {
  return <ReactCountryFlag svg={svg} countryCode={countryCode} />;
};

export default CountryFlag;

const monthAvgDays: number[] = [
  31,
  28.25,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

export function getMonthAvgDays(month: number) {
  return monthAvgDays[month];
}

export function timeMinutesDuration(end: Date, start: Date) {
  return Math.trunc((end.getTime() - start.getTime()) / 60000);
}

export function getStartOfUTCDay(date: Date): Date {
  const oneDay = 24 * 3600 * 1000;
  return new Date(Math.trunc(date.getTime() / oneDay) * oneDay);
}

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import iconLocation from "../../../assets/icons/i-location.svg";
import { useSelector } from "../../../store/hooks";
import {
  getLocationById,
  getLocationRegion,
} from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";

type LocationRadioOption = {
  name: string;
  onChange: (selected: string) => void;
  locationId: string;
  selected: string | undefined;
};

const LocationRadioOption: React.FC<LocationRadioOption> = ({
  name,
  locationId,
  selected,
  onChange,
}) => {
  const location = useSelector((x) => getLocationById(x, locationId));
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));

  return (
    <label className={styles.radioLabel}>
      <span
        className={`${styles.radioView} ${
          locationId === selected ? styles.radioActive : ""
        }`}
      >
        {locationId === selected && (
          <span className={styles.radionIconCheck}></span>
        )}
      </span>
      <input
        name={name}
        className={styles.input}
        type="radio"
        value={locationId}
        checked={locationId === selected}
        onChange={() => onChange(locationId)}
      />
      <img
        className={styles.iconLocation}
        src={iconLocation}
        alt="location icon"
      />
      <div className={styles.chip}>
        <h3 className={styles.chipTitle}>{location?.name}</h3>
        <h6 className={styles.chipSubtitle}>
          {region?.name || "?"}, {country?.name || "?"}
        </h6>
      </div>
    </label>
  );
};

type LocationRadioSelectorProps = {
  name: string;
  locations: string[];
  selected: string | undefined;
  onChange: (selected: string) => void;
};

const LocationRadioSelector: React.FC<LocationRadioSelectorProps> = ({
  name,
  locations,
  selected,
  onChange,
}) => {
  return (
    <div className={styles.radioGroup}>
      {locations.map((location) => (
        <LocationRadioOption
          key={location}
          name={name}
          onChange={onChange}
          locationId={location}
          selected={selected}
        />
      ))}
    </div>
  );
};

export default LocationRadioSelector;

import {
  AuthActionTypes,
  AUTH_DIALOG,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "../types/auth";
import { User } from "../../api/authApi";

export type AuthState = {
  dialog: boolean;
  user: User | undefined;
  token: string;
};

const initialState: AuthState = { dialog: false, user: undefined, token: "" };

export default function reducer(
  state: AuthState = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case AUTH_DIALOG:
      return { ...state, dialog: action.dialog };
    case AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
        dialog: false,
      };
    case AUTH_LOGOUT:
      return { ...state, user: undefined, token: "" };

    default:
      return state;
  }
}

import React, { useEffect } from "react";
import PlaceList from "../PlaceList";
import { useSelector, useDispatch } from "../../store/hooks";
import {
  getWeatherType,
  getWeatherLocationNearbyPlaceIds,
} from "../../store/selectors/weather";
import { tryLoadDailyShort } from "../../store/actions/dailyShort";
import { LocaleMessage } from "../Locale";
import { autoRefreshDailyShort } from "../../utils/cache";

const NearbyPlaces: React.FC = () => {
  const weatherType = useSelector(getWeatherType);
  const nearbyIds = useSelector(getWeatherLocationNearbyPlaceIds);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!nearbyIds.length) return;
    //console.log("NearbyPlaces: ", nearbyIds.length);
    return autoRefreshDailyShort(() => dispatch(tryLoadDailyShort(nearbyIds)));
  }, [dispatch, nearbyIds]);

  if (!nearbyIds.length) return null;

  return (
    <div className="container section">
      <h3 className="title title-solo">
        <LocaleMessage id="NearbyPlaces" />
      </h3>
      <PlaceList locationIds={nearbyIds} weatherType={weatherType} />
    </div>
  );
};

export default NearbyPlaces;

import HomePage from "./scenes/HomePage";
import { RouteConfig } from "react-router-config";
//import PlacesPage from "./scenes/PlacesPage";
import WeatherSelectionPage from "./scenes/WeatherSelectionPage";
import WeatherPage from "./scenes/WeatherPage";
import MobileSearchPage from "./scenes/MobileSearchPage";
import { getContinents } from "./types/continents";
import NotFound from "./scenes/NotFound";
import { defaultLocale, langPrefix } from "./i18n";
import ContactUsPage from "./scenes/ContactUsPage";
import PrivacyPolicyPage from "./scenes/PrivacyPolicyPage";
import TermsOfUsePage from "./scenes/TermsOfUsePage";
import { StaticRouterContext } from "react-router";
import AccountSettings from "./scenes/AccountSettings";

const continents = getContinents(defaultLocale);
const continentIdRegex = continents.map((x) => x._id).join("|");
const continentLongCodeRegex = continents.map((x) => x.longCode).join("|");

const enRoutes: RouteConfig[] = [
  {
    path: "/",
    exact: true,
    component: HomePage,
  },
  {
    path: "/contact",
    exact: true,
    component: ContactUsPage,
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsOfUsePage,
  },
  {
    path: "/search",
    exact: true,
    component: MobileSearchPage,
  },
  {
    path: "/account-settings",
    component: AccountSettings,
  },
  {
    path: `/:continentId(${continentIdRegex})/:countryId/:regionCode/:cityType`,
    component: WeatherPage,
  },
  {
    path: `/:locationType(p)/:continentId(${continentIdRegex})/:countryId/:regionCode/:cityType`,
    component: WeatherPage,
  },
  {
    path: `/:locationType(p)/:continentCode(${continentLongCodeRegex})`,
    component: WeatherSelectionPage,
  },
  {
    path: `/:locationType(p)/:continentCode(${continentIdRegex})/:countryCode/:regionCode?`,
    component: WeatherSelectionPage,
  },
  {
    path: `/:continentCode(${continentLongCodeRegex})`,
    component: WeatherSelectionPage,
  },
  {
    path: `/:continentCode(${continentIdRegex})/:countryCode/:regionCode?`,
    component: WeatherSelectionPage,
  },
  {
    component: NotFound,
  },
];

const routes: RouteConfig[] = [
  ...enRoutes.map((x) => ({
    ...x,
    path: langPrefix + (x.path || ""),
  })),
  ...enRoutes,
];

export default routes;

export interface RouteStaticContext extends StaticRouterContext {
  cacheControl?: string;
}

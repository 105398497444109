import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import { Switch, Radio, NumberField } from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import {
  ClassificationInfo,
  ClassificationPopupBody,
  classificationVisibility,
} from "./ClassificationInfo";
import {
  convertDist,
  convertDistToKm,
  formatDist,
  getDistUnit,
  UnitKey,
} from "../../../types/units";
import { CardSection } from "../Card/Card";

type AlertsVisibilityProps = {
  alerts: UserWeatherAlerts;
  units: UnitKey;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsVisibility: React.FC<AlertsVisibilityProps> = ({
  alerts,
  units,
  updateAlerts,
}) => {
  const handleVisibilityChange = (visibility: boolean) => {
    if (visibility) {
      updateAlerts({
        visibility,
        visibilityValue: 4,
        visibilitySelector: "poor",
        desktop: true,
      });
    } else {
      updateAlerts({ visibility, desktop: true });
    }
  };

  const handleVisibilityValueTypeChange = (label: string) => {
    if (label.includes("Custom parameters")) {
      updateAlerts({ visibilitySelector: "custom" });
      return;
    }
    if (label.includes("Notify me about thick fog and worse")) {
      updateAlerts({ visibilityValue: 0.2, visibilitySelector: "fog" });
      return;
    }
    updateAlerts({ visibilityValue: 4, visibilitySelector: "poor" });
  };

  const handleVisibilityValueChange = (visibilityValue: number) => {
    updateAlerts({ visibilityValue: convertDistToKm(visibilityValue, units) });
  };

  return (
    <>
      <CardSection className={styles.alertsSection}>
        <Switch
          label="Visibility"
          checked={!!alerts.visibility}
          onChange={handleVisibilityChange}
        />
        <ClassificationInfo
          popupBody={<ClassificationPopupBody {...classificationVisibility} />}
        />
      </CardSection>
      {!!alerts.visibility && (
        <>
          <CardSection>
            <div className={styles.radioVisibility}>
              <Radio
                checked={alerts.visibilitySelector === "poor"}
                name="visibilityAlerts"
                label={`Notify me about poor visibility and worse (< ${formatDist(
                  4,
                  units
                )}).`}
                onChange={handleVisibilityValueTypeChange}
              />
              <Radio
                checked={alerts.visibilitySelector === "fog"}
                name="visibilityAlerts"
                label={`Notify me about thick fog and worse (< ${formatDist(
                  0.2,
                  units
                )}).`}
                onChange={handleVisibilityValueTypeChange}
              />
              <Radio
                checked={alerts.visibilitySelector === "custom"}
                name="visibilityAlerts"
                label={`Custom parameters (${getDistUnit(units)}):`}
                onChange={handleVisibilityValueTypeChange}
              />
            </div>
          </CardSection>

          <CardSection>
            <NumberField
              label="Notify me when the visibility is less than"
              value={convertDist(alerts.visibilityValue || 0, units)}
              units={getDistUnit(units)}
              min={0}
              onChange={handleVisibilityValueChange}
              disabled={alerts.visibilitySelector !== "custom"}
            />
          </CardSection>
        </>
      )}
      <HSplit />
    </>
  );
};

export default AlertsVisibility;

import React, { useState, useEffect, Suspense } from "react";
import { LocaleMessage } from "../../../components/Locale";
import { isServer } from "../../../utils/browser";
const Map = isServer() ? null : React.lazy(() => import("./Map"));

const RadarMap: React.FC = () => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    setShowMap(true);
  }, []);

  const loading = <LocaleMessage id="Loading" />;

  return showMap && Map ? (
    <div className="container section">
      <Suspense fallback={loading}>
        <Map />
      </Suspense>
    </div>
  ) : (
    loading
  );
};

export default RadarMap;

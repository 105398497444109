import {
  WeatherActionTypes,
  WEATHER_SELECT,
  WEATHER_DAILY_LOADING,
  WEATHER_DAILY_LOADED,
  WEATHER_DAILY_FAILED,
  WEATHER_HOURLY_LOADING,
  WEATHER_HOURLY_LOADED,
  WEATHER_HOURLY_FAILED,
  WEATHER_CURRENT_LOADING,
  WEATHER_CURRENT_LOADED,
  WEATHER_CURRENT_FAILED,
  WEATHER_STATS_LOADING,
  WEATHER_STATS_LOADED,
  WEATHER_STATS_FAILED,
  WEATHER_IMAGERY_LOADING,
  WEATHER_IMAGERY_LOADED,
  WEATHER_IMAGERY_FAILED,
  WEATHER_SELECT_ERROR,
  WEATHER_ALERTS_LOADING,
  WEATHER_ALERTS_LOADED,
  WEATHER_ALERTS_FAILED,
  WEATHER_TIDES_LOADING,
  WEATHER_TIDES_LOADED,
  WEATHER_TIDES_FAILED,
} from "../types/weather";
import { WeatherType } from "../../types/weatherType";
import {
  ImageryResult,
  DailyForecastResult,
  HourlyForecastResult,
  CurrentWeatherResult,
  MonthlyStatsResult,
  WeatherAlertsResult,
  TidesResult,
} from "../../api/weatherApi";
import { combineReducers } from "redux";

type WeatherSelectedState = {
  weatherType: WeatherType;
  locationId: string;
  error: string;
};

const initialSelectedState: WeatherSelectedState = {
  weatherType: WeatherType.Today,
  locationId: "",
  error: "",
};

function weatherSelectedReducer(
  state: WeatherSelectedState = initialSelectedState,
  action: WeatherActionTypes
): WeatherSelectedState {
  switch (action.type) {
    case WEATHER_SELECT:
      return {
        weatherType: action.weatherType,
        locationId: action.locationId,
        error: "",
      };
    case WEATHER_SELECT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export type WeatherDetailState<T> = {
  loading: boolean;
  data: T;
};

function createDetailedReducer<T>(
  loading: string,
  loaded: string,
  failed: string,
  initialData: T
) {
  const initialState: WeatherDetailState<T> = {
    loading: false,
    data: initialData,
  };
  return (
    state: WeatherDetailState<T> = initialState,
    action: WeatherActionTypes
  ): WeatherDetailState<T> => {
    switch (action.type) {
      case loading:
        return { ...state, loading: true };
      case loaded:
        return {
          data: (action as any).data,
          loading: false,
        };
      case failed:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
}

const noDate = new Date(0);

const dailyReducer = createDetailedReducer<DailyForecastResult>(
  WEATHER_DAILY_LOADING,
  WEATHER_DAILY_LOADED,
  WEATHER_DAILY_FAILED,
  { daily: [], expires: noDate }
);

const hourlyReducer = createDetailedReducer<HourlyForecastResult>(
  WEATHER_HOURLY_LOADING,
  WEATHER_HOURLY_LOADED,
  WEATHER_HOURLY_FAILED,
  { hourly: [], expires: noDate }
);

const currentReducer = createDetailedReducer<CurrentWeatherResult>(
  WEATHER_CURRENT_LOADING,
  WEATHER_CURRENT_LOADED,
  WEATHER_CURRENT_FAILED,
  { now: undefined, expires: noDate }
);

const statsReducer = createDetailedReducer<MonthlyStatsResult>(
  WEATHER_STATS_LOADING,
  WEATHER_STATS_LOADED,
  WEATHER_STATS_FAILED,
  { stats: [], expires: noDate }
);

const imageryReducer = createDetailedReducer<ImageryResult>(
  WEATHER_IMAGERY_LOADING,
  WEATHER_IMAGERY_LOADED,
  WEATHER_IMAGERY_FAILED,
  { radar: [], satellite: [], expires: noDate }
);

const alertsReducer = createDetailedReducer<WeatherAlertsResult>(
  WEATHER_ALERTS_LOADING,
  WEATHER_ALERTS_LOADED,
  WEATHER_ALERTS_FAILED,
  { alerts: [], expires: noDate }
);

const tidesReducer = createDetailedReducer<TidesResult>(
  WEATHER_TIDES_LOADING,
  WEATHER_TIDES_LOADED,
  WEATHER_TIDES_FAILED,
  { stations: [], expires: noDate }
);

const combinedReducer = combineReducers({
  selected: weatherSelectedReducer,
  daily: dailyReducer,
  hourly: hourlyReducer,
  current: currentReducer,
  stats: statsReducer,
  imagery: imageryReducer,
  alerts: alertsReducer,
  tides: tidesReducer,
});

const reducer: typeof combinedReducer = (state, action) => {
  if (!state) return combinedReducer(state, action);

  if (action.type === WEATHER_SELECT)
    return combinedReducer(
      action.locationId === state.selected.locationId ? state : undefined,
      action
    );

  const locationId = (action as any).locationId;
  if (
    typeof locationId !== "undefined" &&
    locationId !== state.selected.locationId
  )
    return state;

  return combinedReducer(state, action);
};

export default reducer;

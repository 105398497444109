import {
  LocationsActionTypes,
  LOCATIONS_LOADED,
  LOCATIONS_GEOIP_SET,
  LOCATIONS_HOME_SET,
  LOCATIONS_NEARBY_LOADED,
  LOCATIONS_HOME_GEOLOCATED,
  LOCATIONS_BY_REGION_LOADING,
  LOCATIONS_BY_REGION_LOADED,
} from "../types/locations";
import { Location, LocationType } from "../../api/placesApi";
import { toMap } from "../../utils/array";
import { combineReducers } from "redux";
import { LOCALE_SET, LocaleActionTypes } from "../types/locale";

type ByIdState = { [_id: string]: Location };

function byIdReducer(
  state: ByIdState = {},
  action: LocationsActionTypes | LocaleActionTypes
): ByIdState {
  switch (action.type) {
    case LOCATIONS_LOADED:
    case LOCATIONS_NEARBY_LOADED:
      return { ...state, ...toMap(action.locations, (x) => x._id) };
    case LOCALE_SET:
      return {};
    default:
      return state;
  }
}

type ByRegionState = {
  [regionId: string]: {
    loading: boolean;
    ids: string[];
  };
};

function createByRegionReducer(locationType: LocationType) {
  return function (
    state: ByRegionState = {},
    action: LocationsActionTypes | LocaleActionTypes
  ): ByRegionState {
    switch (action.type) {
      case LOCATIONS_BY_REGION_LOADING:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.regionId]: { ...state[action.regionId], loading: true },
        };
      case LOCATIONS_BY_REGION_LOADED:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.regionId]: {
            loading: false,
            ids: action.locationIds,
          },
        };
      case LOCALE_SET:
        return {};
      default:
        return state;
    }
  };
}

const byRegionReducer = combineReducers({
  p: createByRegionReducer("p"),
  c: createByRegionReducer("c"),
});

type HomeState = {
  id: string;
  geo: boolean;
};

const initialHomeState: HomeState = {
  id: "",
  geo: false,
};

function homeReducer(
  state: HomeState = initialHomeState,
  action: LocationsActionTypes | LocaleActionTypes
): HomeState {
  switch (action.type) {
    case LOCATIONS_HOME_SET:
      return { ...state, geo: false, id: action.locationId };
    case LOCATIONS_HOME_GEOLOCATED:
      return { ...state, geo: true, id: action.locationId || state.id };
    case LOCALE_SET:
      return initialHomeState;
    default:
      return state;
  }
}

type GeoIpState = {
  id: string;
  countryId: string;
  loaded: boolean;
};

const initialGeoIpState: GeoIpState = {
  loaded: false,
  id: "",
  countryId: "",
};

function geoipReducer(
  state: GeoIpState = initialGeoIpState,
  action: LocationsActionTypes | LocaleActionTypes
): GeoIpState {
  switch (action.type) {
    case LOCATIONS_GEOIP_SET:
      return {
        loaded: true,
        id: action.locationId,
        countryId: action.countryId,
      };
    case LOCALE_SET:
      return initialGeoIpState;
    default:
      return state;
  }
}

type NearbyState = { [_id: string]: string[] };

function createNearbyReducer(locationType: LocationType) {
  return function (
    state: NearbyState = {},
    action: LocationsActionTypes | LocaleActionTypes
  ): NearbyState {
    switch (action.type) {
      case LOCATIONS_NEARBY_LOADED:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.locationId]: action.locations.map((x) => x._id),
        };
      case LOCALE_SET:
        return {};
      default:
        return state;
    }
  };
}

const nearbyReducer = combineReducers({
  p: createNearbyReducer("p"),
  c: createNearbyReducer("c"),
});

export default combineReducers({
  byRegion: byRegionReducer,
  byId: byIdReducer,
  home: homeReducer,
  geoip: geoipReducer,
  nearby: nearbyReducer,
});

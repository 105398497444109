import React, { useEffect, useState, useCallback } from "react";
import PlaceList from "../../../components/PlaceList";
import { useSelector, useDispatch } from "../../../store/hooks";
import { WeatherType } from "../../../types/weatherType";
import { tryLoadDailyShort } from "../../../store/actions/dailyShort";
import {
  getSelectedRegionLocationIds,
  getSelectedRegionLocations,
} from "../../../store/selectors/locationSelector";
import { autoRefreshDailyShort } from "../../../utils/cache";
import styles from "./PlaceSelector.module.scss";
import { Location } from "../../../api/placesApi";
import LetterSelector, { LettersMask } from "./LetterSelector";
import topImg from "./top.svg";
import { debounce } from "throttle-debounce";

function getFirstLettersMask(names: string[]): LettersMask {
  if (!names || !names.length) return {};

  return names.reduce<LettersMask>((p, x) => {
    if (!x) return p;
    p[x[0]] = true;
    return p;
  }, {});
}

let latinLetters: string[] = [];
let latinMask: LettersMask | undefined = undefined;

function getAllLetters(mask: LettersMask): string[] {
  if (!latinLetters.length)
    latinLetters = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
  const latinMaskLocal =
    latinMask || (latinMask = getFirstLettersMask(latinLetters));

  const keys = Object.keys(mask);

  if (keys.every((x) => latinMaskLocal[x])) return latinLetters;

  keys.sort((a, b) => a.localeCompare(b));
  return keys;
}

type PlaceSelectorInnerProps = {
  letters: string[];
  mask: LettersMask;
  locations: Location[];
  locationIds: string[];
};

const PlaceSelectorInner: React.FC<PlaceSelectorInnerProps> = ({
  locations,
  locationIds,
  letters,
  mask,
}) => {
  const [selectedLetter, setSelectedLetter] = useState("");
  const [scrolled, setScrolled] = useState(false);

  const showLocationIds = selectedLetter
    ? locations
        .filter((x) => x.name && x.name[0] === selectedLetter)
        .map((x) => x._id)
    : locationIds;

  const checkScrolled = useCallback(
    debounce(200, () => {
      const newScrolled = window.scrollY > window.innerHeight;
      setScrolled(newScrolled);
    }),
    [setScrolled]
  );

  useEffect(() => {
    document.addEventListener("scroll", checkScrolled, { passive: true });
    return () => document.removeEventListener("scroll", checkScrolled);
  }, [checkScrolled]);

  return (
    <div className={styles.wrap}>
      <LetterSelector
        mask={mask}
        letters={letters}
        selected={selectedLetter}
        //select={(x) => setSelectedLetter(x === selectedLetter ? "" : x)}
        select={setSelectedLetter}
      />
      <PlaceList
        locationIds={showLocationIds}
        weatherType={WeatherType.Today}
      />
      <button
        className={`${styles.topButton} ${scrolled ? styles.visible : ""}`}
        onClick={() => window.scroll(0, 0)}
      >
        <img alt="Top" src={topImg} />
        TOP
      </button>
    </div>
  );
};

const PlaceSelector: React.FC = () => {
  const locationIds = useSelector(getSelectedRegionLocationIds);

  const dispatch = useDispatch();
  useEffect(() => {
    return autoRefreshDailyShort(() =>
      dispatch(tryLoadDailyShort(locationIds))
    );
  }, [dispatch, locationIds]);

  const locations = useSelector(getSelectedRegionLocations);
  if (!locations.length) return null;

  const firstLetters = getFirstLettersMask(locations.map((x) => x.name));
  const allLetters = getAllLetters(firstLetters);

  return (
    <PlaceSelectorInner
      locationIds={locationIds}
      locations={locations}
      mask={firstLetters}
      letters={allLetters}
    />
  );
};

export default PlaceSelector;

import React, { useState, useEffect } from "react";
import styles from "./SidebarMenu.module.scss";
import { RemoveScroll } from "react-remove-scroll";
import { Link } from "react-router-dom";
import logoImg from "../assets/Logo_horizontal.svg";
import UnitSelector from "./UnitSelector";
import locationsImg from "./assets/locations.svg";
import todayImg from "./assets/weather.svg";
//import widgetImg from "./assets/widget.svg";
// import LanguageSelector from "./LanguageSelector";
import AuthState from "./AuthState";
import { useLocale } from "../../../i18n";
import {
  getHomeUrl,
  getWeatherUrl,
  //getWidgetUrl,
} from "../../../utils/url";
import { LocaleMessage } from "../../Locale";
import { useDispatch } from "../../../store/hooks";
import { weatherNavigateToHomeLocation } from "../../../store/actions/weather";

type SidebarMenuProps = {
  open: boolean;
  close: () => void;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ open, close }) => {
  const locale = useLocale();
  const dispatch = useDispatch();

  const [firstOpen, setFirstOpen] = useState(false);
  useEffect(() => {
    if (open && !firstOpen) setFirstOpen(true);
  }, [open, firstOpen]);

  // console.log(open, firstOpen);
  if (!firstOpen && !open) return null;
  // console.log("render", open, firstOpen);

  return (
    <RemoveScroll
      enabled={open && firstOpen}
      noIsolation
      className={`${styles.sidebar} ${open && firstOpen ? styles.open : ""}`}
    >
      <div className={styles.overlay} onClick={close} />

      <div className={styles.wrap}>
        <div className={styles.menu}>
          <Link
            onClick={close}
            className={styles.brandLink}
            to={getHomeUrl(locale)}
          >
            <img src={logoImg} alt="WeatherMan" className={styles.brand} />
          </Link>
          <Link
            onClick={(e) => {
              e.preventDefault();
              dispatch(weatherNavigateToHomeLocation());
              close();
            }}
            className={styles.navLink}
            to="#"
          >
            <img className={styles.icon} alt="Today" src={todayImg} />{" "}
            <LocaleMessage id="TodaysWeather" />
          </Link>

          <Link
            onClick={close}
            className={styles.navLink}
            to={getWeatherUrl(locale)}
          >
            <img className={styles.icon} alt="Locations" src={locationsImg} />{" "}
            <LocaleMessage id="WorldWeather" />
          </Link>

          {/* <Link
            onClick={close}
            className={`${styles.navLinkSplit}`}
            to={getWidgetUrl(locale)}
          >
            <img className={styles.icon} alt="Weather Widget" src={widgetImg} />{" "}
            <LocaleMessage id="WeatherWidget" />
          </Link> */}
          <UnitSelector close={close} />

          {/* <LanguageSelector close={close} /> */}
          <div className={styles.splitter} />

          <AuthState close={close} />
        </div>
      </div>
    </RemoveScroll>
  );
};

export default SidebarMenu;

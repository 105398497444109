import { RouteComponentProps } from "react-router-dom";
import { RouteStaticContext } from "../routes";

function setCacheControl(
  props: RouteComponentProps<any, RouteStaticContext>,
  value: string
) {
  if (!props.staticContext) return;
  // don't override
  if (props.staticContext.cacheControl) return;
  props.staticContext.cacheControl = value;
}

export function setCacheControlWeather(
  props: RouteComponentProps<any, RouteStaticContext>
) {
  setCacheControl(props, "public, max-age=600");
}

export function setCacheControlPlaces(
  props: RouteComponentProps<any, RouteStaticContext>
) {
  setCacheControl(props, "public, max-age=86400");
}

export function setCacheControlStatic(
  props: RouteComponentProps<any, RouteStaticContext>
) {
  setCacheControl(props, "public, max-age=86400");
}

export function setCacheControlHome(
  props: RouteComponentProps<any, RouteStaticContext>
) {
  setCacheControl(props, "private, max-age=600");
}

type RunningFn = (running: () => boolean) => Promise<any>;

function autoRefresh(min: number, rnd: number, func: RunningFn) {
  let running = true;
  let timeoutId: number | undefined = undefined;
  const doRefresh: TimerHandler = async () => {
    try {
      await func(() => running);
    } catch {
    } finally {
      if (running) timeoutId = setTimeout(doRefresh, min + Math.random() * rnd);
    }
  };

  doRefresh();

  return () => {
    running = false;
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };
}

export function autoRefreshDailyShort(func: RunningFn) {
  return autoRefresh(30 * 60 * 1000, 10 * 60 * 1000, func);
}

export function autoRefreshWeather(func: RunningFn) {
  return autoRefresh(15 * 60 * 1000, 1 * 60 * 1000, func);
  //return autoRefresh(1000, 1000, func);
}

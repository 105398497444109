import React from "react";
import styles from "./CurrentConditionsHead.module.scss";
import DayBackground from "../../../components/DayBackground";
import DayHeadSummary from "../DayHeadSummary";
import WeatherDescription from "../WeatherDescription";
import { useSelector } from "../../../store/hooks";
import { getDefaultDailyForecast } from "../../../store/selectors/weather";
import WeatherHead from "../WeatherHead";

const CurrentConditionsHead: React.FC = () => {
  const forecast = useSelector(getDefaultDailyForecast);

  return (
    <div className={styles.wrap}>
      <DayBackground className={styles.background} />
      <div className={styles.content}>
        <WeatherHead forecast={forecast} />
        <div className="container">
          <DayHeadSummary className={styles.summary} weather={forecast} />
          <WeatherDescription weather={forecast} />
        </div>
      </div>
    </div>
  );
};

export default CurrentConditionsHead;

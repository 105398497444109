import React from "react";
import { components, IndicatorProps } from "react-select";
import down from "./down.svg";
import up from "./up.svg";

const DropdownIndicator = (props: IndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      {<img src={props.isFocused ? up : down} alt="Indicator" />}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

import newMoon from "./assets/new_moon.svg";
import waxingCrescent from "./assets/waxing_crescent.svg";
import waxingCrescent1 from "./assets/waxing_crescent_1.svg";
import firstQuarter from "./assets/first_quarter.svg";
import firstQuarter1 from "./assets/first_quarter_1.svg";
import fullMoon from "./assets/full_moon.svg";
import waxingGibbous from "./assets/waxing_gibbous.svg";
import waxingGibbous1 from "./assets/waxing_gibbous_1.svg";

const northernImages = [
  newMoon,
  waxingCrescent,
  firstQuarter,
  waxingGibbous,
  fullMoon,
  waxingGibbous1,
  firstQuarter1,
  waxingCrescent1
];

const southernImages = [
  newMoon,
  waxingCrescent1,
  firstQuarter1,
  waxingGibbous1,
  fullMoon,
  waxingGibbous,
  firstQuarter,
  waxingCrescent
];

export function getMoonPhaseImg(moonPhase: number, lat: number) {
  const images = lat >= 0 ? northernImages : southernImages;
  return images[moonPhase];
}

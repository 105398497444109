import React, { useCallback } from "react";
import Dropdown, { Option } from "../../Dropdown";
import { units, UnitKey } from "../../../types/units";
import { useSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import { unitSelect } from "../../../store/actions/units";
import { getUnits } from "../../../store/selectors/units";

// const unitOptions: Option[] = Object.entries(units).map(([value, label]) => ({
//   value,
//   label
// }));

const C_Option: Option = {
  value: "C_ms",
  label: units.C_ms
};

const F_Option: Option = {
  value: "F_mph",
  label: units.F_mph
};

type UnitSelectorProps = {
  className?: string;
};

const UnitSelector: React.FC<UnitSelectorProps> = ({ className = "" }) => {
  const units = useSelector(getUnits);
  const dispatch = useDispatch();

  const setOption = useCallback(
    ({ value }: Option) => {
      dispatch(unitSelect(value as UnitKey));
    },
    [dispatch]
  );

  //console.log("units", units);
  const options =
    units === "C_ms" ? [C_Option, F_Option] : [F_Option, C_Option];

  return (
    <Dropdown
      className={`w-120 ${className}`}
      options={options}
      value={units}
      onChange={setOption}
    />
  );
};

export default UnitSelector;

import React, { useEffect, useState } from "react";
import styles from "./AccountSettings.module.scss";
import { LocaleMessage } from "../../components/Locale";
import { useDispatch, useSelector } from "../../store/hooks";
import { RootDispatch, Store } from "../../store";
import { Request } from "express";
import { tryInit } from "../../store/actions/init";
import ContactInformation from "./ContactInformation";
import CurrentLocation from "./CurrentLocation";
import Favorites from "./Favorites";
import AlertsNotifications from "./AlertsNotifications";
import { getUserProfile } from "../../store/selectors/userProfile";
import { userProfileUpdate } from "../../store/actions/userProfile";
import {
  UserWeatherAlerts,
  UserProfile,
  UserWeatherAlertsStatistic,
} from "../../api/authApi";
import { getUserWeatherAlerts } from "../../store/selectors/userWeatherAlerts";
import {
  getUsersWeatherAlertsStatistic,
  userWeatherAlertsUpdate,
} from "../../store/actions/userWeatherAlerts";
import { DataPage } from "../DataPage";
import { match, Redirect, useLocation } from "react-router-dom";
import { getAuthorized } from "../../store/selectors/auth";
import { getHomeUrl } from "../../utils/url";
import { useLocale } from "../../i18n";
import { requestFirebaseNotificationPermission } from "../../firebaseInit";
import { tryScrollIntoView } from "../../utils/hooks";
import AlertsStatistic from "./AlertsNotifications/AlertsStatistic";

async function fetchData(dispatch: RootDispatch, req?: Request) {
  await dispatch(tryInit(req));
}

const AccountSettings: React.FC & DataPage = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const userProfile = useSelector(getUserProfile);
  const alerts = useSelector(getUserWeatherAlerts);
  const loggedIn = useSelector(getAuthorized);
  const locale = useLocale();
  const [dataFetched, setDataFetched] = useState(false);
  const [tokenReceived, setTokenReceived] = useState(false);
  const [statistic, setStatistic] = useState<
    UserWeatherAlertsStatistic | undefined
  >();
  const [statLoading, setStatLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await fetchData(dispatch);
      setDataFetched(true);
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const statistic = await dispatch(getUsersWeatherAlertsStatistic());
      if (statistic) setStatistic(statistic);
    })();
  }, [dispatch, userProfile.email]);

  const handleRefreshStatistic = async () => {
    setStatLoading(true);
    const statistic = await dispatch(getUsersWeatherAlertsStatistic());
    if (statistic) setStatistic(statistic);
    setStatLoading(false);
  };

  const handleUserUpdate = (userProfile: Partial<UserProfile>) => {
    dispatch(userProfileUpdate(userProfile));
  };

  const handleAlertsUpdate = (alerts: Partial<UserWeatherAlerts>) => {
    dispatch(userWeatherAlertsUpdate(alerts));
  };

  useEffect(() => {
    if (hash === "#notifications") {
      const notificationsSection = document.getElementById("notifications");
      if (notificationsSection)
        setTimeout(() => tryScrollIntoView(notificationsSection), 200);
    }
  }, [hash]);

  useEffect(() => {
    (async () => {
      if (!tokenReceived) {
        await requestFirebaseNotificationPermission()
          .then((firebaseToken) => {
            if (
              !alerts.tokens?.includes(firebaseToken as string) &&
              !tokenReceived
            ) {
              setTokenReceived(true);
              dispatch(
                userWeatherAlertsUpdate({
                  tokens: [firebaseToken as string],
                })
              );
            }
          })
          .catch((err) => {
            console.log("Error while saving push notification token", err);
            return err;
          });
      }
    })();
  }, [dispatch, alerts.tokens, tokenReceived]);

  if (!userProfile) return <></>;

  const account = userProfile.pictureUrl?.includes("google")
    ? "Google"
    : "Facebook";

  if (dataFetched && !loggedIn)
    return (
      <Redirect
        to={{
          pathname: getHomeUrl(locale),
          state: { softRedirect: true },
        }}
      />
    );

  return (
    <div className={styles.accountSettings}>
      <div className="container">
        <h1 className={`title huge-title ${styles.pageTitle}`}>
          <LocaleMessage id="AccountSettings" />
        </h1>

        <ContactInformation
          account={account}
          userProfile={userProfile}
          updateUser={handleUserUpdate}
        />

        <CurrentLocation
          userProfile={userProfile}
          updateUser={handleUserUpdate}
        />

        <Favorites userProfile={userProfile} updateUser={handleUserUpdate} />

        <AlertsNotifications
          alerts={alerts}
          favorites={userProfile.favorite}
          updateAlerts={handleAlertsUpdate}
        />
        {statistic && (
          <AlertsStatistic
            {...statistic}
            handleRefresh={handleRefreshStatistic}
            isLoading={statLoading}
          />
        )}
      </div>
    </div>
  );
};

AccountSettings.fetchData = function (
  store: Store,
  _route: match,
  req?: Request
) {
  return fetchData(store.dispatch, req);
};

export default AccountSettings;

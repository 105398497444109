import { useSelector, useDispatch } from ".";
import { useEffect } from "react";
import { getWeatherLocation } from "../selectors/weather";
import { searchPlaceholder } from "../actions/search";

export function useSearchPlaceholderWeatherLocation() {
  const location = useSelector(getWeatherLocation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchPlaceholder(location ? location.name : ""));
    return () => {
      dispatch(searchPlaceholder(""));
    };
  }, [location, dispatch]);
}

import React from "react";
import styles from "./WeatherBreadcrumb.module.scss";
import { Link } from "react-router-dom";
import {
  getContinentUrl,
  getCountryUrl,
  getRegionUrl,
} from "../../../utils/url";
import { useSelector } from "../../../store/hooks";
import {
  getWeatherType,
  getWeatherLocation,
} from "../../../store/selectors/weather";
import { getLocationRegion } from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";
import { getCountryContinent } from "../../../store/selectors/countries";
import { useLocale, MessageKey } from "../../../i18n";
import { LocaleMessage } from "../../../components/Locale";
import { WeatherType } from "../../../types/weatherType";

type WeatherBreadcrumbProps = {
  dark?: boolean;
};

const WeatherBreadcrumb: React.FC<WeatherBreadcrumbProps> = ({ dark }) => {
  const locale = useLocale();
  const location = useSelector(getWeatherLocation);
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));
  const weatherType = useSelector(getWeatherType);

  return (
    <div className={`${styles.wrap} ${dark ? styles.dark : ""}`}>
      {continent && country && region && location ? (
        <>
          <Link to={getContinentUrl(locale, location.type, continent)}>
            {continent ? continent.name : "?"}
          </Link>
          {" > "}
          <Link to={getCountryUrl(locale, location.type, continent, country)}>
            {country.name}
          </Link>
          {" > "}
          <Link
            to={getRegionUrl(locale, location.type, continent, country, region)}
          >
            {region.name}
          </Link>
          {" > "}
          <LocaleMessage
            id={("BreadcrumbName" + WeatherType[weatherType]) as MessageKey}
            values={{ name: location.name }}
          />
        </>
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
};

export default WeatherBreadcrumb;

import React from "react";
import Helmet from "react-helmet";
import { useFormatMessage } from "../../i18n";
import { useSelector } from "../../store/hooks";
import {
  getSelectedContinent,
  getSelectedCountry,
  getSelectedRegion,
  getSelectedLocationType,
} from "../../store/selectors/locationSelector";

const WeatherSelectionPageTitle: React.FC = () => {
  const formatMessage = useFormatMessage();
  const locationType = useSelector(getSelectedLocationType);
  const continent = useSelector(getSelectedContinent);
  const country = useSelector(getSelectedCountry);
  const region = useSelector(getSelectedRegion);

  const name = region
    ? region.name
    : country
    ? country.name
    : continent
    ? continent.name
    : "";

  const title = formatMessage(
    locationType === "c"
      ? "WeatherSelectionTitle"
      : "WeatherSelectionPlaceTitle",
    { name }
  );

  const description = formatMessage(
    locationType === "c" ? "WeatherSelectionDesc" : "WeatherSelectionPlaceDesc",
    { name }
  );

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default WeatherSelectionPageTitle;

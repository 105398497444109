import React, { useEffect } from "react";
import { match, RouteComponentProps } from "react-router-dom";
import { Store, RootDispatch } from "../../store";
import { DataPage } from "../DataPage";
import { useDispatch } from "../../store/hooks";
import { useSearchPlaceholderWeatherLocation } from "../../store/hooks/search";
import { useScrollTop } from "../../utils/hooks";
import { Request } from "express";
import { tryInit } from "../../store/actions/init";
import {
  weatherSelectHome,
  tryLoadWeatherNearbyCities,
  tryLoadCurrentWeather,
  tryLoadHourlyForecast,
  tryLoadDailyForecast,
  tryLoadWeatherAlerts,
} from "../../store/actions/weather";
import HomeWeatherHead from "./HomeWeatherHead";
import NearbyCitiesAndTowns from "../../components/NearbyCitiesAndTowns";
import PopularCities from "./PopularCities";
import { tryLoadPopularCities } from "../../store/actions/locations";
import FavoriteCities from "./FavoriteCities";
import Helmet from "react-helmet";
import { useFormatMessage } from "../../i18n";
import { setCacheControlHome, autoRefreshWeather } from "../../utils/cache";
import WeatherAlerts from "../../components/WeatherAlerts";
import { NotificationsPopup } from "../../components/Notifications/NotificationsPopup";

async function fetchData(
  dispatch: RootDispatch,
  routeChanged: () => boolean,
  req?: Request
) {
  const popularPromise = dispatch(tryLoadPopularCities());

  // init - for SSR
  await dispatch(tryInit(req));

  await dispatch(weatherSelectHome(routeChanged));
  if (routeChanged()) return;

  await Promise.all([
    // for alerts
    dispatch(tryLoadWeatherAlerts(req)),
    // for table
    dispatch(tryLoadCurrentWeather(req)),
    // for chart
    dispatch(tryLoadHourlyForecast(req)),
    // for background
    dispatch(tryLoadDailyForecast(req)),
    // for nearby section
    dispatch(tryLoadWeatherNearbyCities()),
    // just wait for it
    popularPromise,
  ]);
}

const HomePage: React.FC<RouteComponentProps> & DataPage = (props) => {
  const formatMessage = useFormatMessage();

  const dispatch = useDispatch();

  useEffect(() => {
    let routeChanged = false;
    const killAutoRefresh = autoRefreshWeather(() =>
      fetchData(dispatch, () => routeChanged)
    );
    return () => {
      routeChanged = true;
      killAutoRefresh();
    };
  }, [dispatch]);

  useSearchPlaceholderWeatherLocation();
  useScrollTop();

  setCacheControlHome(props);

  return (
    <div className="vh-100">
      <Helmet title={formatMessage("HomeTitle")}>
        <meta name="description" content={formatMessage("HomeDesc")} />
      </Helmet>
      <WeatherAlerts />
      <HomeWeatherHead />
      <NearbyCitiesAndTowns />
      <FavoriteCities />
      <PopularCities />
      <NotificationsPopup />
    </div>
  );
};

HomePage.fetchData = function (store: Store, _route: match, req?: Request) {
  return fetchData(store.dispatch, () => false, req);
};

export default HomePage;

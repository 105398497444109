import React from "react";
import Select, { Styles } from "react-select";
import DropdownIndicator from "./DropdownIndicator";
import styles from "./DropdownAutocomplete.module.scss";

export type Option<T> = { label: string; value: T };

type DropdownAutocompleteProps<T> = {
  options: Option<T>[];
  value: Option<T> | undefined;
  placeholder?: string;
  onChange?: (arg: Option<T>) => void;
  className?: string;
};

const controlStyles: Partial<Styles> = {
  container: (base) => {
    return {
      ...base,
      boxShadow: "0px 20px 43px #6464640D",
    };
  },
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderRadius: "3px",
      border: isFocused ? "1px solid #0066FA" : "1px solid #EBEBEB",
      background: isFocused ? "#0066FA" : "#FFFFFF",
      color: isFocused ? "#fff" : "#444",
      fontSize: "18px",
      fontWeight: "bold",
      cursor: "pointer",

      padding: "9px 20px",
      height: "45px",
    };
  },

  valueContainer: (base) => {
    return {
      ...base,
      //color: isFocused ? "#fff" : "#444",
      padding: "0 8px 0 0",
    };
  },
  indicatorsContainer: (base) => {
    return { ...base, padding: "0" };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: "0" };
  },
  singleValue: (base) => {
    return {
      ...base,
      color: undefined,
    };
  },
  input: (base) => {
    return {
      ...base,
      font: "700 18px Cabin",
      color: "#fff",
      margin: "0",
      padding: "0",
    };
  },
};

function DropdownAutocomplete<T>({
  className,
  options,
  value,
  placeholder,
  onChange,
}: DropdownAutocompleteProps<T>) {
  return (
    <Select
      isSearchable
      className={`test-class ${styles.control} ${className || ""}`}
      options={options}
      value={value !== undefined ? value : null}
      placeholder={placeholder}
      onChange={onChange ? (opt) => onChange(opt as Option<T>) : undefined}
      styles={controlStyles}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator,
      }}
    />
  );
}

export default DropdownAutocomplete;

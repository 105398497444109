import { LocaleCode, MessageKey, defaultLocale } from ".";
import { useIntl } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import React from "react";

export function getLocaleUrl(locale: LocaleCode, url: string) {
  if (locale === defaultLocale) return url;
  return `/${locale}${url}`;
}
export function useLocale(): LocaleCode {
  const intl = useIntl();
  return intl.locale as LocaleCode;
}

export function useFormatMessage() {
  const intl = useIntl();
  const result = (
    id: MessageKey,
    values?: Record<string, PrimitiveType>,
    fallback?: string
  ) => intl.formatMessage({ id, defaultMessage: fallback }, values);
  result.locale = intl.locale as LocaleCode;
  result.intl = intl;
  return result;
}

export type FormatMessageFn = ReturnType<typeof useFormatMessage>;

export function useFormatMessageReact() {
  const intl = useIntl();
  return (
    id: MessageKey,
    values?: Record<string, PrimitiveType | React.ReactElement>
  ) => intl.formatMessage({ id }, values);
}

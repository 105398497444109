import React from "react";
import WeatherPageTitle from "./WeatherPageTitle";
import RadarMap from "./RadarMap";
import RadarImages from "./RadarImages";
import SatelliteImages from "./SatelliteImages";
import CurrentConditionsHead from "./CurrentConditionsHead";

const RadarWeatherView: React.FC = () => {
  return (
    <div>
      <WeatherPageTitle />
      <CurrentConditionsHead />
      <RadarMap />
      <RadarImages />
      <SatelliteImages />
    </div>
  );
};

export default RadarWeatherView;

import React, { useState, useRef } from "react";
import styles from "./DesktopSearch.module.scss";
import SearchList from "../../SearchList";
import {
  useDispatch,
  // useSelector
} from "../../../store/hooks";
import {
  searchQuery,
  navigateToTopSearch,
  navigateToGeolocation,
} from "../../../store/actions/search";
import { useHistory } from "react-router";
// import { getSearchPlaceholder } from "../../../store/selectors/search";
import { useFormatMessage } from "../../../i18n";

type SearchPopupProps = {
  hide: () => void;
};

const SearchPopup: React.FC<SearchPopupProps> = ({ hide }) => {
  return (
    <div className={styles.searchPopup}>
      <SearchList onLinkClicked={hide} />
    </div>
  );
};

const Search: React.FC = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [text, setText] = useState("");
  const history = useHistory();
  const formatMessage = useFormatMessage();
  // const placeholder =
  //   useSelector(getSearchPlaceholder) || formatMessage("SearchPlaceholder");

  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const search = (text: string) => {
    setText(text);
    dispatch(searchQuery(text));
  };

  const hidePopup = () => {
    setPopupVisible(false);
    // search("");
  };

  const handleBlur = (e: React.FocusEvent) => {
    const currentTarget = e.currentTarget;

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        //console.log("blur");
        hidePopup();
      }
    }, 0);
  };

  return (
    <div className={styles.searchWrap}>
      <button
        aria-label={formatMessage("GpsLocation")}
        title={formatMessage("GpsLocation")}
        className={styles.gpsButton}
        onClick={() => dispatch(navigateToGeolocation(history))}
      />
      <div className={styles.search} onBlur={handleBlur}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!(await dispatch(navigateToTopSearch(text, history)))) return;
            if (ref.current) {
              ref.current.blur();
              //console.log("Blur");
            }
          }}
        >
          <input
            aria-label="Search"
            type="text"
            className={styles.searchInput}
            ref={ref}
            onFocus={() => setPopupVisible(true)}
            value={text}
            // placeholder={popupVisible ? "" : placeholder}
            onChange={(e) => search(e.target.value)}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 27) search("");
            // }}
          />
          <button
            aria-label="Clear"
            className={styles.clearButton}
            type="button"
            onClick={() => search("")}
          />

          <input type="submit" style={{ display: "none" }} />
        </form>

        {popupVisible && <SearchPopup hide={hidePopup} />}
      </div>
    </div>
  );
};

export default Search;

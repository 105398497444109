import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
// import { HOST_ROOT } from "./config";
import { isServer } from "./utils/browser";
import notificationLogo from "./assets/icons/logo-notification.png";

function initClient() {
  const config = {
    apiKey: "AIzaSyADSqfm4HDnDfhnTcvwSrp5kSpFquP0HY0",
    authDomain: "weatherman-24303.firebaseapp.com",
    databaseURL: "https://weatherman-24303-default-rtdb.firebaseio.com",
    projectId: "weatherman-24303",
    storageBucket: "weatherman-24303.appspot.com",
    messagingSenderId: "168797367695",
    appId: "1:168797367695:web:af02ac31311c0448642ffa",
    measurementId: "G-P1G88E1EJ7",
  };

  firebase.initializeApp(config);
  const messaging = firebase.messaging();

  messaging.onMessage((payload) => {
    console.log("[firebase-messaging] Received foreground message ", payload);

    const title = payload.data.title;
    const options = {
      body: payload.data.body,
      icon: notificationLogo,
      data: {
        action: payload.data.action,
      },
      requireInteraction: true,
    };
    const notification = new Notification(title, options);
    notification.addEventListener("click", (e) => {
      window.open(notification.data.action);
      notification.close();
    });
  });

  const requestFirebaseNotificationPermission = async () => {
    const swUrl = "firebase-messaging-sw-v.2.js";

    // await navigator.serviceWorker
    //   .getRegistrations()
    //   .then(function (registrations) {
    //     for (let registration of registrations) {
    //       if (
    //         registration.active &&
    //         registration.active.scriptURL !== `${HOST_ROOT}/${swUrl}`
    //       ) {
    //         registration.unregister();
    //       }
    //     }
    //   });
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      swUrl
    );

    return new Promise((resolve, reject) => {
      Notification.requestPermission()
        .then(() =>
          messaging.getToken({ serviceWorkerRegistration, vapidKey: "" })
        )
        .then((firebaseToken: string) => {
          resolve(firebaseToken);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  // const onMessageListener = () =>
  //   new Promise((resolve) => {
  //     messaging.onMessage((payload: any) => {
  //       resolve(payload);
  //     });
  //   });

  return {
    // onMessageListener,
    requestFirebaseNotificationPermission,
  };
}

function init() {
  if (isServer()) {
    return {
      // onMessageListener: () => Promise.reject(new Error("SSR")),
      requestFirebaseNotificationPermission: () => Promise.resolve(),
    };
  }

  return initClient();
}

export const {
  requestFirebaseNotificationPermission,
  // onMessageListener
} = init();

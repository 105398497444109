import { RootState } from "..";

const getHomeShort = (state: RootState) => state.homeShort.now;

export const getHomeForecastShort = (state: RootState) => {
  const forecast = getHomeShort(state);
  if (!forecast) return undefined;

  return forecast[0];
};

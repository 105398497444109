import {
  createSelector as createSelectorDefault,
  createSelectorCreator
} from "reselect";

const createSelector =
  typeof window === "undefined"
    ? createSelectorCreator(x => x)
    : createSelectorDefault;

export { createSelector };

import { RootState } from "..";
import { createLocationListSelect, getGeoipLocation } from "./locations";
import { getRecentCities } from "./recent";
import { createRegionListSelect } from "./regions";
import { createCountryListSelect } from "./countries";

export const getSearchQuery = (state: RootState) => state.search.query;
export const getSearchPlaceholder = (state: RootState) =>
  state.search.placeholder;

export const getSearchLoading = (state: RootState) => state.search.loading;

export const getSearchLocations = createLocationListSelect(
  state => state.search.locationIds
);

export const getSearchRegions = createRegionListSelect(
  state => state.search.regionIds
);

export const getSearchCountries = createCountryListSelect(
  state => state.search.countryIds
);

export const searchNoResults = (state: RootState) =>
  !!state.search.query &&
  !state.search.loading &&
  !state.search.locationIds.length;

export const getTopSearchLocation = (state: RootState) => {
  const search = getSearchLocations(state);
  if (search.length) return search[0];
  const recent = getRecentCities(state);
  if (recent.length) return recent[0];
  return getGeoipLocation(state);
};

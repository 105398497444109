import { History } from "history";

function gaExec(cb: (ga: any) => any) {
  const ga = (window as any).ga;
  if (!ga) return;
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/ga-object-methods-reference#method-summary
  ga(function () {
    var trackers = ga.getAll();
    trackers.forEach(function (tracker: any) {
      cb(tracker);
    });
  });
}

export function linkAnalyticsToHistory(history: History) {
  history.listen((location) => {
    gaExec((ga) => {
      ga.set("page", location.pathname + location.search);
      ga.send("pageview");
    });
  });
}

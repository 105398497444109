import {
  LocaleCode,
  defaultLocale,
  getPathnameWithoutLocale,
  getLocaleUrl,
  langPrefix,
} from "../../i18n";
import { RootDispatch, GetState } from "..";
import { getLocale } from "../selectors/locale";
import { LOCALE_SET } from "../types/locale";
import { matchPath } from "react-router";

// @ts-ignore
import { push } from "react-router-redux";
import { getAuthToken } from "../selectors/auth";
import { tryUpdateUser } from "../../api/authApi";

export function localeSet(locale: LocaleCode) {
  return (dispatch: RootDispatch, getState: GetState) => {
    if (getLocale(getState()) === locale) return;
    dispatch({ type: LOCALE_SET, locale });
  };
}

export function localeChange(locale: LocaleCode, save: boolean = true) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (typeof window === "undefined") return;
    const pathname = window.location.pathname;
    const match = matchPath<{ lang?: string }>(pathname, langPrefix);
    const lang = match ? match.params.lang : defaultLocale;

    if (lang === locale) return;
    //console.log("lang", lang, "path", pathname);
    const pathWithoutLocale = getPathnameWithoutLocale(pathname, lang);
    const newPath = getLocaleUrl(locale, pathWithoutLocale);

    await dispatch(push(newPath));
    dispatch(localeSet(locale));

    if (save) {
      const token = getAuthToken(getState());
      if (token) {
        await tryUpdateUser(token, { locale });
      }
    }
  };
}

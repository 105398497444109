import React from "react";
import { useLocalStorage, LocalStorageKeys } from "../../utils/localStorage";
import { Link } from "react-router-dom";
import { LocaleMessage } from "../Locale";
import { getPrivacyPolicyUrl } from "../../utils/url";
import { useFormatMessage } from "../../i18n";
import styles from "./CookieConsent.module.scss";
import { useDidMount } from "../../utils/hooks";
import { useSelector } from "../../store/hooks";
import { getGeoipCountryId } from "../../store/selectors/locations";

const euCountries = [
  "at",
  "be",
  "bg",
  "cy",
  "cz",
  "de",
  "dk",
  "es",
  "ee",
  "fi",
  "fr",
  "gb",
  "gr",
  "hr",
  "hu",
  "ie",
  "it",
  "lt",
  "lu",
  "lv",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "si",
  "se",
];

const CookieConsent: React.FC = () => {
  const formatMessage = useFormatMessage();
  const [consent, setConsent] = useLocalStorage(
    LocalStorageKeys.consent,
    false
  );
  const mounted = useDidMount();
  const geoCountryId = useSelector(getGeoipCountryId);
  const notEuCountry =
    geoCountryId && !euCountries.includes(geoCountryId.toLowerCase());

  if (consent || notEuCountry || !mounted) return null;

  const text = formatMessage("CookieText").replace("\n", "<br/>");

  return (
    <div className={styles.consent}>
      <div className="container">
        <div className={styles.text}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
          <Link to={getPrivacyPolicyUrl(formatMessage.locale)}>
            <LocaleMessage id="PrivacyPolicy" />
          </Link>
          .
        </div>
        <button className={styles.button} onClick={() => setConsent(true)}>
          <LocaleMessage id="CookieOk" />
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;

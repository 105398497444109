import React, { useState } from "react";
import { DailyForecast } from "../../../api/weatherApi";
import styles from "./DailyForecastHorizontal.module.scss";
import DayForecastHorizontal from "../DayForecastHorizontal";
import DayForecastTable from "../DayForecastTable";
import { useFormatMessage } from "../../../i18n";

type DailyForecastHorizontalProps = {
  days: DailyForecast[];
  today: DailyForecast;
  expanded?: DailyForecast;
};

type CardProps = {
  day: DailyForecast;
  today: DailyForecast;
  initialSelected: boolean;
};

const Card: React.FC<CardProps> = ({ day, today, initialSelected }) => {
  const [selected, setSelected] = useState(initialSelected);
  const formatMessage = useFormatMessage();

  return (
    <div
      key={day.date.getTime()}
      className={`${styles.cardWrap} ${selected ? styles.selected : ""}`}
    >
      <DayForecastHorizontal
        className={styles.card}
        day={day}
        title={day === today ? formatMessage("Today") : undefined}
        selected={selected}
        onClick={() => setSelected(!selected)}
      />
      {selected && <DayForecastTable className={styles.table} day={day} />}
    </div>
  );
};

const DailyForecastHorizontal: React.FC<DailyForecastHorizontalProps> = ({
  days,
  today,
  expanded,
}) => {
  return (
    <div className={styles.wrap}>
      {days.map((day, i) => (
        <Card
          key={day.date.getTime()}
          day={day}
          today={today}
          initialSelected={day === expanded}
        />
      ))}
    </div>
  );
};

export default DailyForecastHorizontal;

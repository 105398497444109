import React, { useState, useRef } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import styles from "./ShareButton.module.scss";
import { useLocation } from "react-router";
import FacebookImg from "../../assets/facebook.svg";
import TwitterImg from "../../assets/twitter.svg";
import EmailImg from "../../assets/email.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "../../store/hooks";
import { errorsAdd } from "../../store/actions/errors";
import { LocaleMessage } from "../Locale";
import { useClickOutsideClose } from "../../utils/hooks";

const Popup: React.FC<{ title: string; close: () => void }> = ({
  title,
  close,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutsideClose(wrapperRef, true, close);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const url = typeof window !== "undefined" ? window.location.href : "";
  if (!url || !url.endsWith(pathname)) return null;

  return (
    <div className={styles.popup} ref={wrapperRef}>
      <div className={styles.popupTitle}>
        <div>
          <LocaleMessage id="Share" />
        </div>
        <CopyToClipboard
          text={url}
          onCopy={(_, r) => {
            if (r) {
              close();
            } else {
              dispatch(errorsAdd("Copy failed!"));
            }
          }}
        >
          <button className={styles.copyLink}>
            <LocaleMessage id="ShareCopyLink" />
          </button>
        </CopyToClipboard>
      </div>
      <div className={styles.share}>
        <div>
          <FacebookShareButton
            beforeOnClick={async () => close()}
            url={url}
            quote={title}
            hashtag="#weatherman"
          >
            <img alt="Facebook" title="Facebook" src={FacebookImg} />
          </FacebookShareButton>
        </div>
        <div>
          <TwitterShareButton
            beforeOnClick={async () => close()}
            url={url}
            title={title}
            //via="Share Via"
            hashtags={["#weatherman", "#weather"]}
            related={["@weatherman", "@leanylabs"]}
          >
            <img alt="Twitter" title="Twitter" src={TwitterImg} />
          </TwitterShareButton>
        </div>
        <div>
          <EmailShareButton
            beforeOnClick={async () => close()}
            url={url}
            subject={title}
            //body="Share body"
          >
            <img alt="Email" title="Email" src={EmailImg} />
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};

type ShareButtonProps = {
  dark?: boolean;
  title: string;
};

const ShareButton: React.FC<ShareButtonProps> = ({ dark, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.wrap}>
      <button
        aria-label="Share"
        className={`${styles.button} ${dark ? styles.dark : ""}`}
        onClick={() => setOpen(true)}
      />
      {open && <Popup title={title} close={() => setOpen(false)} />}
    </div>
  );
};

export default ShareButton;

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import {
  Checkbox,
  Radio,
  Switch,
  TimePicker,
} from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import { convertMsToTimeArr, convertTimeToMS } from "../../../utils/date";
import { CardSection, CardSectionTitle } from "../Card/Card";

export enum WeekDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

const allWeekDays = Object.values(WeekDays);

type AlertsDailyProps = {
  alerts: UserWeatherAlerts;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsDaily: React.FC<AlertsDailyProps> = ({ alerts, updateAlerts }) => {
  const handleDailyChange = (daily: boolean) => {
    updateAlerts({ daily, desktop: true });
  };

  const handleDailyTimeChange = (time: string) => {
    updateAlerts({ dailyTimeMs: convertTimeToMS(time) });
  };

  const handleAllDaysChange = (value: string) => {
    const allDays = value === "All week";

    updateAlerts({
      dailyDays: allDays ? allWeekDays : [],
      dailySelector: allDays ? "all" : "",
    });
  };

  const handleDaysAlertChange = (checked: boolean, selected: string) => {
    const newDays = alerts.dailyDays
      ? checked
        ? [...alerts.dailyDays, selected]
        : alerts.dailyDays.filter((x) => x !== selected)
      : [selected];

    updateAlerts({
      dailyDays: newDays,
      dailySelector: newDays.length < 7 ? "" : "all",
    });
  };

  return (
    <>
      <CardSection className={styles.alertsSection}>
        <h3>
          Activate personalized weather notifications that are important to you:
        </h3>
      </CardSection>

      <CardSection>
        <Switch
          label="Daily Forecast"
          checked={!!alerts.daily}
          onChange={handleDailyChange}
        />
      </CardSection>
      {!!alerts.daily && (
        <>
          <CardSection className={styles.timePickerSection}>
            <CardSectionTitle title="Delivery time" />
            <TimePicker
              value={
                alerts.dailyTimeMs
                  ? convertMsToTimeArr(alerts.dailyTimeMs)
                  : undefined
              }
              onChange={handleDailyTimeChange}
            />
          </CardSection>
          <CardSection className={styles.sectionColumn}>
            <CardSectionTitle title="Select days:" />

            <div className={styles.radioDays}>
              <Radio
                checked={alerts.dailySelector === "all"}
                name="daysAlerts"
                label="All week"
                onChange={handleAllDaysChange}
              />
              <Radio
                checked={alerts.dailySelector !== "all"}
                name="daysAlerts"
                label="Certain days"
                onChange={handleAllDaysChange}
              />
            </div>
            <div className={styles.days}>
              <Checkbox
                label={WeekDays.Sunday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Sunday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Sunday)
                }
              />
              <Checkbox
                label={WeekDays.Monday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Monday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Monday)
                }
              />
              <Checkbox
                label={WeekDays.Tuesday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Tuesday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Tuesday)
                }
              />
              <Checkbox
                label={WeekDays.Wednesday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Wednesday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Wednesday)
                }
              />
              <Checkbox
                label={WeekDays.Thursday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Thursday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Thursday)
                }
              />
              <Checkbox
                label={WeekDays.Friday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Friday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Friday)
                }
              />
              <Checkbox
                label={WeekDays.Saturday}
                checked={
                  !alerts.dailyDays ||
                  alerts.dailyDays.includes(WeekDays.Saturday)
                }
                onClick={(checked) =>
                  handleDaysAlertChange(checked, WeekDays.Saturday)
                }
              />
            </div>
          </CardSection>
        </>
      )}

      <HSplit />
    </>
  );
};

export default AlertsDaily;

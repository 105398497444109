export const FAVORITE_TOGGLE = "FAVORITE_TOGGLE";
export const FAVORITE_RESTORE = "FAVORITE_RESTORE";

type FavoriteToggleAction = {
  type: typeof FAVORITE_TOGGLE;
  locationId: string;
};

type FavoriteRestoreAction = {
  type: typeof FAVORITE_RESTORE;
  locationIds: string[];
};

export type FavoriteActionTypes = FavoriteToggleAction | FavoriteRestoreAction;

import { HOST_ROOT } from "./config";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    const swUrl = "firebase-messaging-sw-v.2.js";
    // remove old service worker

    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        if (
          registration.active &&
          registration.active.scriptURL !== `${HOST_ROOT}/${swUrl}`
        ) {
          console.log(registration.active.scriptURL, `${HOST_ROOT}/${swUrl}`);
          registration.unregister();
        }
      }
    });

    if (!isLocalhost) {
      // Is not local host. Just register service worker
      // register(swUrl);
    } else {
      // This is running on localhost. Lets check if a service worker still exists or not.
      checkValidServiceWorker(swUrl);
    }
  }
};

export function register(swUrl: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then(function (registration) {
      // eslint-disable-next-line no-console
      console.log("[SW]: SCOPE: ", registration.scope);
      return registration.scope;
    })
    .catch(function (err) {
      return err;
    });
}

export function checkValidServiceWorker(swUrl: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get("content-type")!.indexOf("javascript") === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        // register(swUrl);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import {
  ClassificationInfo,
  ClassificationPopupBody,
  classificationUv,
} from "./ClassificationInfo";
import {
  Radio,
  NumberRange,
  RangeFields,
  Switch,
} from "../../../components/Controls";
import { CardSection } from "../Card/Card";

type AlertsUvProps = {
  alerts: UserWeatherAlerts;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsUv: React.FC<AlertsUvProps> = ({ alerts, updateAlerts }) => {
  const handleUvChange = (uv: boolean) => {
    if (uv) {
      updateAlerts({
        uv,
        uvIndex: [8, undefined],
        uvSelector: "",
        desktop: true,
      });
    } else {
      updateAlerts({ uv, desktop: true });
    }
  };

  const handleUvIndexRadioChange = (label: string) => {
    if (label === "Custom parameters (UV Index):") {
      updateAlerts({ uvSelector: "custom" });
      return;
    }
    updateAlerts({ uvIndex: [8, undefined], uvSelector: "" });
  };

  const handleUvIndexChange = (range: NumberRange) => {
    updateAlerts({ uvIndex: [range.from, range.to] });
  };

  return (
    <>
      <CardSection
        className={`${styles.alertsSection} ${styles.wrapperClassification}`}
      >
        <Switch
          label="Ultraviolet (UV) index"
          checked={!!alerts.uv}
          onChange={handleUvChange}
        />
        <ClassificationInfo
          hideDivider={true}
          className={styles.classification}
          popupBody={<ClassificationPopupBody {...classificationUv} />}
        />
      </CardSection>
      {!!alerts.uv && (
        <CardSection className={styles.sectionColumn}>
          <div className={styles.radioAir}>
            <Radio
              checked={alerts.uvSelector !== "custom"}
              name="uvAlerts"
              label="Notify me about the very high risk of harm from unprotected sun exposure (UV Index: > 8)"
              onChange={handleUvIndexRadioChange}
            />
            <Radio
              checked={alerts.uvSelector === "custom"}
              name="uvAlerts"
              label="Custom parameters (UV Index):"
              onChange={handleUvIndexRadioChange}
            />
          </div>

          <RangeFields
            disabled={alerts.uvSelector !== "custom"}
            className={styles.precipitationIntensityRange}
            from={alerts.uvIndex && alerts.uvIndex[0]}
            to={alerts.uvIndex && alerts.uvIndex[1]}
            onChange={handleUvIndexChange}
            min={0}
            max={40}
          />
        </CardSection>
      )}
    </>
  );
};

export default AlertsUv;

import React, { useCallback, useState, useEffect } from "react";
import styles from "./Notifications.module.scss";
import weatherImg from "../../assets/images/weather-alerts-popup.png";
import { useDispatch, useSelector } from "../../store/hooks";
import { getNotificationsPopupShow } from "../../store/selectors/notifications";
import { notificationsPopupHide } from "../../store/actions/notifications";
import { LocaleMessage } from "../Locale";
import { loginGoogle, loginFacebook } from "../../store/actions/auth";
import googleImg from "../../assets/logos/google.svg";
import facebookImg from "../../assets/logos/facebook.svg";
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../config";
import { useFormatMessage } from "../../i18n";
import { useHistory } from "react-router-dom";
import { getAuthorized } from "../../store/selectors/auth";
import { getAccountSettingsAlertsUrl } from "../../utils/url";
import { isMobile, isServer } from "../../utils/browser";
import { getWeatherLocationId } from "../../store/selectors/weather";
import { getUserFavorite } from "../../store/selectors/userProfile";
import { userProfileUpdate } from "../../store/actions/userProfile";
import { favoriteToggle } from "../../store/actions/favorite";
import { getFavoriteLocationIds } from "../../store/selectors/favorite";

type LoginPopupProps = {
  closePopup: () => void;
};

const LoginPopup: React.FC<LoginPopupProps> = ({ closePopup }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const hangleLoginGoogle = useCallback(
    (r: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      closePopup();
      dispatch(loginGoogle(r, history, getAccountSettingsAlertsUrl()));
    },
    [dispatch, closePopup, history]
  );

  const handleLoginFacebook = useCallback(
    (r: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      closePopup();
      dispatch(loginFacebook(r, history, getAccountSettingsAlertsUrl()));
    },
    [dispatch, closePopup, history]
  );

  return (
    <div className={styles.loginPopup}>
      <h3 className={styles.title}>
        <LocaleMessage id="LoginWelcome" />
      </h3>
      <div className={styles.continue}>
        <LocaleMessage id="LoginContinue" />
      </div>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Login"
        onSuccess={hangleLoginGoogle}
        onFailure={(e) => console.error(e)}
        render={(props) => (
          <button {...props} className={styles.loginBtn}>
            <img alt="Google" src={googleImg} />
            <LocaleMessage id="LoginGoogle" />
          </button>
        )}
      />
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        disableMobileRedirect={true}
        fields="name,email,picture"
        callback={handleLoginFacebook}
        cssClass={styles.loginBtn}
        icon={<img alt="Facebook" src={facebookImg} />}
        textButton={formatMessage("LoginFacebook")}
      />
    </div>
  );
};

export const NotificationsPopup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const locationId = useSelector(getWeatherLocationId);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const [showLogin, setShowLogin] = useState(false);
  const show = useSelector((state) =>
    getNotificationsPopupShow(state, locationId)
  );
  const loggedIn = useSelector(getAuthorized);
  const favorite = useSelector(getFavoriteLocationIds);
  const userFavorite = useSelector(getUserFavorite);

  const handleClose = useCallback(() => {
    dispatch(notificationsPopupHide());
  }, [dispatch]);

  const handleConfirm = useCallback(async () => {
    if (loggedIn) {
      const newFavorite = userFavorite.includes(locationId)
        ? userFavorite
        : [locationId, ...userFavorite];
      dispatch(
        userProfileUpdate({
          favorite: newFavorite,
        })
      );
      history.push(getAccountSettingsAlertsUrl(), { softRedirect: true });
      handleClose();
    } else {
      if (!favorite.includes(locationId)) {
        await dispatch(favoriteToggle(locationId));
      }
      setShowLogin(true);
    }
  }, [
    setShowLogin,
    history,
    loggedIn,
    handleClose,
    favorite,
    userFavorite,
    dispatch,
    locationId,
  ]);

  if (isMobile() || isServer()) return null;
  if (!show || !showPopup || !locationId) return null;

  return (
    <div className={styles.popup}>
      <div className={styles.body}>
        <img src={weatherImg} alt="weather img" />
        <h1>Get weather alerts for this area</h1>
        <p>Never miss weather changes that are important to you</p>
      </div>
      <div className={styles.controls}>
        <button className={styles.btnCancel} onClick={handleClose}>
          No, thanks
        </button>
        <button className={styles.btnOk} onClick={handleConfirm}>
          Turn on desktop notifications
        </button>
      </div>

      {showLogin && <LoginPopup closePopup={handleClose} />}
    </div>
  );
};

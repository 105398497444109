import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../../store/hooks";
import { tryLoadDailyShort } from "../../../store/actions/dailyShort";
import { LocaleMessage } from "../../../components/Locale";
import { getFavoriteLocationIds } from "../../../store/selectors/favorite";
import { WeatherType } from "../../../types/weatherType";
import PlaceList from "../../../components/PlaceList";
import heartImg from "./heart.svg";
import { tryLoadLocationsByIds } from "../../../store/actions/locations";
import { autoRefreshDailyShort } from "../../../utils/cache";

const FavoriteCities: React.FC = () => {
  const favoriteIds = useSelector(getFavoriteLocationIds);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!favoriteIds.length) return;
    //console.log("tryLoad");
    dispatch(tryLoadLocationsByIds(favoriteIds));

    return autoRefreshDailyShort(() =>
      dispatch(tryLoadDailyShort(favoriteIds))
    );
  }, [dispatch, favoriteIds]);

  if (!favoriteIds.length) return null;

  return (
    <div className="container section">
      <h3 className="title title-solo">
        <img alt="" src={heartImg} className="mr-7" />
        <LocaleMessage id="FavoriteCities" />
      </h3>
      <PlaceList locationIds={favoriteIds} weatherType={WeatherType.Today} />
    </div>
  );
};

export default FavoriteCities;

import { RootState } from "..";
import { getLocale } from "./locale";
import { getContinent } from "../../types/continents";
import { getCountriesByContinent, getCountryById } from "./countries";
import { getRegionsByCountry, getRegionById } from "./regions";
import { getLocationsByRegion, getLocationIdsByRegion } from "./locations";

export const getSelectedLocationType = (state: RootState) =>
  state.locationSelector.locationType;
const continentId = (state: RootState) => state.locationSelector.continentId;
const countryId = (state: RootState) => state.locationSelector.countryId;
const regionId = (state: RootState) => state.locationSelector.regionId;

export const getLocationSelectorError = (state: RootState) =>
  state.locationSelector.error;

export const getSelectedContinent = (state: RootState) =>
  getContinent(getLocale(state), continentId(state));

export const getSelectedContinentCountries = (state: RootState) =>
  getCountriesByContinent(
    state,
    getSelectedLocationType(state),
    continentId(state)
  );

export const getSelectedCountry = (state: RootState) =>
  getCountryById(state, countryId(state));

export const getSelectedCountryRegions = (state: RootState) =>
  getRegionsByCountry(state, getSelectedLocationType(state), countryId(state));

export const getSelectedRegion = (state: RootState) =>
  getRegionById(state, regionId(state));

export const getSelectedRegionLocations = (state: RootState) =>
  getLocationsByRegion(state, getSelectedLocationType(state), regionId(state));

export const getSelectedRegionLocationIds = (state: RootState) =>
  getLocationIdsByRegion(
    state,
    getSelectedLocationType(state),
    regionId(state)
  );

import { apiGet } from "../http";
import {
  Country,
  Region,
  Location,
  CountryRegionLocation,
  CountriesRegionsLocations,
  SearchResults,
  LocationType
} from "./types";
import { LocaleCode } from "../../i18n";
export * from "./types";

async function placesGet<T>(url: string, what: string): Promise<T> {
  return await apiGet<T>("/places" + url, what);
}

export async function fetchCountriesByContinent(
  type: LocationType,
  continentId: string,
  lang: LocaleCode
): Promise<Country[]> {
  return await placesGet(
    `/countries/by-continent/${type}/${encodeURIComponent(
      continentId
    )}?lang=${lang}`,
    "Countries"
  );
}

export async function fetchRegionsByCountry(
  type: LocationType,
  countryId: string,
  lang: LocaleCode
): Promise<Region[]> {
  return await placesGet(
    `/regions/by-country/${type}/${encodeURIComponent(countryId)}?lang=${lang}`,
    "Regions"
  );
}

export async function fetchLocationsByRegion(
  type: LocationType,
  regionId: string,
  lang: LocaleCode,
  limit?: number
): Promise<Location[]> {
  let url = `/locations/by-region/${type}/${encodeURIComponent(
    regionId
  )}?lang=${lang}`;
  if (typeof limit === "number") url += "&limit=" + limit.toFixed(0);
  return await placesGet(url, "Cities");
}

export async function fetchLocationByCode(
  type: LocationType,
  countryId: string,
  regionCode: string,
  cityCode: string,
  lang: LocaleCode
): Promise<CountryRegionLocation> {
  return await placesGet(
    `/locations/by-code/${type}/${encodeURIComponent(
      countryId
    )}/${encodeURIComponent(regionCode)}/${encodeURIComponent(
      cityCode
    )}?lang=${lang}`,
    "City"
  );
}

export async function fetchLocationByIp(
  ip: string | undefined,
  lang: LocaleCode
): Promise<CountryRegionLocation> {
  return await placesGet(
    `/locations/by-ip/${ip ? encodeURIComponent(ip) : ""}?lang=${lang}`,
    "City"
  );
}

export async function fetchNearbyLocationsById(
  type: LocationType,
  locationId: string,
  lang: LocaleCode
): Promise<CountriesRegionsLocations> {
  return await placesGet(
    `/locations/nearby/${type}/${encodeURIComponent(locationId)}?lang=${lang}`,
    "Nearby Cities"
  );
}

export async function fetchLocationsByIds(
  locationIds: string[],
  lang: LocaleCode
): Promise<CountriesRegionsLocations> {
  return await placesGet(
    `/locations/by-ids/${encodeURIComponent(
      locationIds.join("|")
    )}?lang=${lang}`,
    "Cities"
  );
}

export async function fetchGeoSearch(
  query: string,
  lang: LocaleCode
): Promise<SearchResults> {
  return await placesGet(
    `/search/${encodeURIComponent(query)}?lang=${lang}`,
    "Search"
  );
}

import { UserProfile } from "../../api/authApi";

export const AUTH_DIALOG = "AUTH_DIALOG";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

type AuthDialogAction = {
  type: typeof AUTH_DIALOG;
  dialog: boolean;
};

type AuthSuccessAction = {
  type: typeof AUTH_SUCCESS;
  user: UserProfile;
  token: string;
};

type AuthLogoutAction = {
  type: typeof AUTH_LOGOUT;
};

export type AuthActionTypes =
  | AuthDialogAction
  | AuthSuccessAction
  | AuthLogoutAction;

import { UserProfile } from "../../api/authApi";
import {
  UserProfileActionTypes,
  USER_PROFILE_UPDATE,
} from "../types/userProfile";

type userProfileState = UserProfile;

const initialState: userProfileState = {
  _id: "",
  name: "",
  units: "",
  locale: "",
  recent: [],
  favorite: [],
};

export default function userProfileReducer(
  state: userProfileState = initialState,
  action: UserProfileActionTypes
): userProfileState {
  switch (action.type) {
    case USER_PROFILE_UPDATE:
      const { userProfile } = action;
      return { ...state, ...userProfile };

    default:
      return state;
  }
}

import { DailyForecastShort } from "../../api/weatherApi";
import { HomeShortActionTypes, HOME_SHORT_LOADED } from "../types/homeShort";

type HomeShortState = {
  now?: DailyForecastShort[];
};

const initialState: HomeShortState = {};

export default function reducer(
  state: HomeShortState = initialState,
  action: HomeShortActionTypes
): HomeShortState {
  switch (action.type) {
    case HOME_SHORT_LOADED: {
      return { ...state, now: [action.forecast] };
    }

    default:
      return state;
  }
}

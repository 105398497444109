import { RootDispatch, GetState } from "..";
import {
  LocalStorageKeys,
  localStorageGet,
  localStorageSet,
} from "../../utils/localStorage";
import {
  getFavoriteLocationIds,
  isFavoriteRestored,
} from "../selectors/favorite";
import {
  FAVORITE_TOGGLE,
  FAVORITE_RESTORE,
  FavoriteActionTypes,
} from "../types/favorite";
import { getAuthorized, getAuthToken } from "../selectors/auth";
import { tryUpdateUser } from "../../api/authApi";
import { userProfileUpdate } from "./userProfile";

export function favoriteToggle(locationId: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationId) return;
    try {
      dispatch({ type: FAVORITE_TOGGLE, locationId });

      if (isFavoriteRestored(getState())) {
        const favorite = getFavoriteLocationIds(getState());

        const token = getAuthToken(getState());
        if (token) {
          await tryUpdateUser(token, { favorite });
          await dispatch(userProfileUpdate({ favorite }));
        } else {
          localStorageSet(LocalStorageKeys.favorite, favorite);
        }
      }
    } catch (e) {
      console.error("favoriteToggle: ", e);
    }
  };
}

export function favoriteRestore(locationIds: string[]): FavoriteActionTypes {
  return { type: FAVORITE_RESTORE, locationIds };
}

export function favoriteLocalRestore() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      if (getAuthorized(getState())) return;
      //if (isFavoriteRestored(getState())) return;

      try {
        let favorite =
          localStorageGet<string[]>(LocalStorageKeys.favorite, []) || [];
        //console.log("favorite", favorite);
        if (
          !Array.isArray(favorite) ||
          !favorite.every((x) => typeof x === "string")
        )
          favorite = [];

        dispatch(favoriteRestore(favorite));
      } catch (e) {
        console.error("favoriteLocalRestore: ", e);
      }

      const favorite = getFavoriteLocationIds(getState());
      localStorageSet(LocalStorageKeys.favorite, favorite);
    } catch (e) {
      console.error("favoriteLocalRestore: save ", e);
    }
  };
}

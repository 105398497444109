import {
  CountriesActionTypes,
  COUNTRIES_LOADED,
  COUNTRIES_BY_CONTINENT_LOADING,
  COUNTRIES_BY_CONTINENT_LOADED,
} from "../types/countries";
import { Country, LocationType } from "../../api/placesApi";
import { toMap } from "../../utils/array";
import { combineReducers } from "redux";
import { LocaleActionTypes, LOCALE_SET } from "../types/locale";

type ByContinentState = {
  [continentId: string]: {
    loading: boolean;
    ids: string[];
  };
};

type ByIdState = { [_id: string]: Country };

export type CountriesState = {
  readonly byId: ByIdState;
  readonly byContinent: ByContinentState;
  readonly selectedId: string;
};

function byIdReducer(
  state: ByIdState = {},
  action: CountriesActionTypes | LocaleActionTypes
): ByIdState {
  switch (action.type) {
    case COUNTRIES_LOADED:
      return { ...state, ...toMap(action.countries, (x) => x._id) };
    case LOCALE_SET:
      return {};
    default:
      return state;
  }
}

function createByContinentReducer(locationType: LocationType) {
  return function (
    state: ByContinentState = {},
    action: CountriesActionTypes | LocaleActionTypes
  ): ByContinentState {
    switch (action.type) {
      case COUNTRIES_BY_CONTINENT_LOADING:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.continentId]: { ...state[action.continentId], loading: true },
        };
      case COUNTRIES_BY_CONTINENT_LOADED:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.continentId]: {
            loading: false,
            ids: action.countryIds,
          },
        };
      case LOCALE_SET:
        return {};
      default:
        return state;
    }
  };
}

const byContinentReducer = combineReducers({
  p: createByContinentReducer("p"),
  c: createByContinentReducer("c"),
});

export default combineReducers({
  byContinent: byContinentReducer,
  byId: byIdReducer,
});

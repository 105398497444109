import React, { useEffect } from "react";
import styles from "./Favorites.module.scss";
import iconFav from "../../../assets/icons/i-favorite.svg";
import iconFavRemove from "../../../assets/icons/i-favorite-remove.svg";
import iconFavSmall from "../../../assets/icons/i-favorite-small.svg";
import { LocaleMessage } from "../../../components/Locale";
import { LocationChip } from "../LocationChip";
import { UserProfile } from "../../../api/authApi";
import { useDispatch } from "../../../store/hooks";
import { favoriteToggle } from "../../../store/actions/favorite";
import { tryLoadLocationsByIds } from "../../../store/actions/locations";
import LocationsSearch from "../Search";
import { Card, CardSection, CardTitle } from "../Card/Card";

type FavoritesProps = {
  userProfile: UserProfile;
  updateUser: (userProfile: Partial<UserProfile>) => void;
};

const Favorites: React.FC<FavoritesProps> = ({ userProfile, updateUser }) => {
  const dispatch = useDispatch();
  const favoriteIds = userProfile.favorite;

  useEffect(() => {
    if (!favoriteIds.length) return;
    dispatch(tryLoadLocationsByIds(favoriteIds));
  }, [dispatch, favoriteIds]);

  const handleLocationIconClick = (locationId: string) => {
    const favorite = favoriteIds.filter((x) => x !== locationId);
    updateUser({ favorite });
    dispatch(favoriteToggle(locationId));
  };

  const handleLinkClick = (locationId: string) => {
    if (favoriteIds.includes(locationId)) {
      updateUser({ favorite: favoriteIds.filter((x) => x !== locationId) });
    } else {
      updateUser({ favorite: [...favoriteIds, locationId] });
    }
    dispatch(favoriteToggle(locationId));
  };

  return (
    <Card>
      <CardTitle
        title={<LocaleMessage id="Favorites" />}
        icon={<img src={iconFavSmall} alt="favorite icon" />}
      />

      <CardSection>
        <LocationsSearch
          onLinkClick={handleLinkClick}
          label="Add location to favorites"
          placeholder="Search for a location..."
        />
      </CardSection>

      <CardSection className={styles.favWrap}>
        {favoriteIds.map((locationId) => (
          <LocationChip
            key={locationId}
            className={styles.chip}
            locationId={locationId}
            icon={<img src={iconFav} alt="favorite icon" />}
            hoverIcon={<img src={iconFavRemove} alt="remove favorite icon" />}
            onClick={handleLocationIconClick}
          />
        ))}
      </CardSection>
    </Card>
  );
};

export default Favorites;

import React, { useState } from "react";
import styles from "./MobileHeader.module.scss";
import logoImg from "../assets/Logo_horizontal.svg";
import { Link } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { useLocale } from "../../../i18n";
import { getHomeUrl, getSearchUrl } from "../../../utils/url";

const MobileHeader: React.FC = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const locale = useLocale();

  return (
    <div className="d-block d-lg-none">
      <div className={styles.wrap}>
        <div className={`container ${styles.header}`}>
          <Link className={styles.brand} to={getHomeUrl(locale)}>
            <img src={logoImg} alt="WeatherMan" />
          </Link>
          <div className={styles.buttons}>
            <Link to={getSearchUrl(locale)} className={styles.searchButton} />
            <button
              aria-label="Menu"
              className={styles.menuButton}
              onClick={() => setSideBarOpen(true)}
            />
          </div>
        </div>
      </div>
      <SidebarMenu open={sideBarOpen} close={() => setSideBarOpen(false)} />
    </div>
  );
};

export default MobileHeader;

import React, { ReactNode } from "react";
import { DailyForecast } from "../../../api/weatherApi";
import styles from "./DayForecastTable.module.scss";
import {
  formatTempShort,
  formatWindDirectionShort,
  formatSpeed,
  formatPercent,
  formatDistance,
  formatUvIndex,
  formatPressure,
  formatRainfall,
  formatIndex,
} from "../../../types/units";
import { useSelector } from "react-redux";
import { getUnits } from "../../../store/selectors/units";
import { MessageKey, useFormatMessage } from "../../../i18n";
import {
  LocaleMessage,
  LocaleTime,
  formatMinutesDuration,
} from "../../../components/Locale";

type DayForecastTableProps = {
  day: DailyForecast;
  className?: string;
};

const DayForecastTable: React.FC<DayForecastTableProps> = ({
  day,
  className = "",
}) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();

  const allIndices = Object.keys(day.indices || {});

  const renderRow = (
    header: MessageKey,
    item: (day: DailyForecast) => ReactNode
  ) => {
    return (
      <div key={header} className={styles.row}>
        <div className={styles.headerItem}>
          <LocaleMessage id={header as MessageKey} />
        </div>
        <div className={styles.dayItem}>{item(day)}</div>
      </div>
    );
  };

  return (
    <div className={`${styles.wrap} ${className}`}>
      {renderRow("FeelsLike", ({ tempFeels }) =>
        formatTempShort(tempFeels, units)
      )}
      {renderRow("Wind", ({ windDir, windSpeed }) => (
        <>
          {formatWindDirectionShort(formatMessage, windDir)}{" "}
          {formatSpeed(formatMessage, windSpeed, units)}
        </>
      ))}
      {renderRow("Humidity", ({ humidity }) => formatPercent(humidity))}
      {renderRow("Visibility", ({ visibility }) =>
        formatDistance(formatMessage, visibility, units)
      )}
      {renderRow("UVIndex", ({ uvIndex }) =>
        formatUvIndex(formatMessage, uvIndex)
      )}
      {renderRow("DewPt", ({ dewPoint }) => formatTempShort(dewPoint, units))}
      {renderRow("Pressure", ({ pressure }) =>
        formatPressure(formatMessage, pressure)
      )}
      {renderRow("Sunrise", ({ sunrise }) =>
        sunrise ? <LocaleTime value={sunrise} /> : "-"
      )}
      {renderRow("Sunset", ({ sunset }) =>
        sunset ? <LocaleTime value={sunset} /> : "-"
      )}
      {renderRow("Moonrise", ({ moonrise }) =>
        moonrise ? <LocaleTime value={moonrise} /> : "-"
      )}
      {renderRow("Moonset", ({ moonset }) =>
        moonset ? <LocaleTime value={moonset} /> : "-"
      )}
      {/* NEW FIELDS */}
      {renderRow("MoonAge", ({ moonAge }) =>
        moonAge !== undefined ? moonAge : ""
      )}
      {renderRow("FeelsLikeShade", ({ tempFeelsShade }) =>
        formatTempShort(tempFeelsShade, units)
      )}
      {renderRow("PrecipitationProbability", ({ precipChance: percipChance }) =>
        formatPercent(percipChance)
      )}
      {renderRow("TStormProbability", ({ tstormChance }) =>
        formatPercent(tstormChance)
      )}
      {renderRow("SnowProbability", ({ snowChance }) =>
        formatPercent(snowChance)
      )}
      {renderRow("IceProbability", ({ iceChance }) => formatPercent(iceChance))}
      {renderRow("PrecipitationDuration", ({ precipMinutes: percipMinutes }) =>
        formatMinutesDuration(formatMessage, percipMinutes)
      )}
      {renderRow("RainDuration", ({ rainMinutes }) =>
        formatMinutesDuration(formatMessage, rainMinutes)
      )}
      {renderRow("SnowDuration", ({ snowMinutes }) =>
        formatMinutesDuration(formatMessage, snowMinutes)
      )}
      {renderRow("IceDuration", ({ iceMinutes }) =>
        formatMinutesDuration(formatMessage, iceMinutes)
      )}
      {renderRow("WindGust", ({ windGustSpeed }) =>
        formatSpeed(formatMessage, windGustSpeed, units)
      )}
      {renderRow("TotalLiquid", ({ totalLiquid }) =>
        formatRainfall(formatMessage, totalLiquid, units)
      )}
      {renderRow("Rain", ({ rain }) =>
        formatRainfall(formatMessage, rain, units)
      )}
      {renderRow("Snow", ({ snow }) =>
        formatRainfall(formatMessage, snow, units)
      )}
      {renderRow("Ice", ({ ice }) => formatRainfall(formatMessage, ice, units))}
      {renderRow("CloudCover", ({ cloudCover }) => formatPercent(cloudCover))}
      {renderRow("PrecipitationType", ({ precipType }) =>
        precipType
          ? formatMessage(precipType as MessageKey, undefined, precipType)
          : ""
      )}
      {renderRow("PrecipitationIntensity", ({ precipIntensityS }) =>
        formatIndex(formatMessage, undefined, precipIntensityS)
      )}
      {allIndices.map((name) =>
        renderRow(name as MessageKey, ({ indices }) => {
          if (!indices) return "";
          const index = indices[name];
          if (!index) return "";
          return formatIndex(formatMessage, index.value, index.category);
        })
      )}
    </div>
  );
};

export default DayForecastTable;

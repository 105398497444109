export const SEARCH_PLACEHOLDER = "SEARCH_PLACEHOLDER";
export const SEARCH_QUERY = "SEARCH_QUERY";
export const SEARCH_LOADED = "SEARCH_LOADED";
export const SEARCH_FAILED = "SEARCH_FAILED";

type SearchPlaceholderAction = {
  type: typeof SEARCH_PLACEHOLDER;
  placeholder: string;
};

type SearchQueryAction = {
  type: typeof SEARCH_QUERY;
  query: string;
};

type SearchLoadedAction = {
  type: typeof SEARCH_LOADED;
  query: string;
  locationIds: string[];
  regionIds: string[];
  countryIds: string[];
};

type SearchFailedAction = {
  type: typeof SEARCH_FAILED;
  query: string;
  error: string;
};

export type SearchActionTypes =
  | SearchPlaceholderAction
  | SearchQueryAction
  | SearchLoadedAction
  | SearchFailedAction;

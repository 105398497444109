import {
  ImageryResult,
  CurrentWeatherResult,
  DailyForecastResult,
  HourlyForecastResult,
  MonthlyStatsResult,
  DailyForecastsShort,
  Image,
} from ".";
import { WeatherAlertsResult, TidesResult } from "./types";

export function normalizeCurrentWeather(result: CurrentWeatherResult) {
  if (result.expires) result.expires = new Date(result.expires);
  if (result.now) result.now.time = new Date(result.now.time);
}

export function normalizeDailyForecasts(result: DailyForecastResult) {
  if (result.expires) result.expires = new Date(result.expires);

  if (result.daily)
    result.daily.forEach((x) => {
      x.date = new Date(x.date);
      if (x.sunrise) x.sunrise = new Date(x.sunrise);
      if (x.sunset) x.sunset = new Date(x.sunset);
      if (x.moonrise) x.moonrise = new Date(x.moonrise);
      if (x.moonset) x.moonset = new Date(x.moonset);
    });
}

export function normalizeHourlyForecasts(result: HourlyForecastResult) {
  if (result.expires) result.expires = new Date(result.expires);

  if (result.hourly)
    result.hourly.forEach((x) => {
      x.time = new Date(x.time);
    });
}

export function normalizeDailyForecastsShort(forecasts: DailyForecastsShort) {
  Object.values(forecasts).forEach((x) => {
    x.daily.forEach((x) => {
      x.date = new Date(x.date);
    });

    x.expires = new Date(x.expires);
  });

  return forecasts;
}

export function normalizeImages(images: Image[]) {
  images.forEach((x) => {
    x.date = new Date(x.date);
  });
}

export function normalizeImagery(result: ImageryResult) {
  if (result.expires) result.expires = new Date(result.expires);

  normalizeImages(result.radar);
  normalizeImages(result.satellite);
}

export function normalizeMonthlyStats(result: MonthlyStatsResult) {
  if (result.expires) result.expires = new Date(result.expires);
}

export function normalizeAlerts(result: WeatherAlertsResult) {
  if (result.expires) result.expires = new Date(result.expires);

  result.alerts.forEach((x) => {
    if (x.startTime) x.startTime = new Date(x.startTime);
    if (x.endTime) x.endTime = new Date(x.endTime);
  });
}

export function normalizeTides(result: TidesResult) {
  if (result.expires) result.expires = new Date(result.expires);

  result.stations.forEach((x) => {
    x.hourly.forEach((x) => {
      x.time = new Date(x.time);
    });
    x.hilo.forEach((x) => {
      x.time = new Date(x.time);
    });
  });
}

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import { HSplit } from "../../../components/Divider";
import {
  RangeFields,
  NumberRange,
  Switch,
  Checkbox,
  NumberField,
  Radio,
} from "../../../components/Controls";
import PrecipitationIntensitySelector from "./PrecipitationIntensitySelector";
import {
  convertPrecip,
  convertPrecipToMmH,
  getPrecipitUnitShort,
  UnitKey,
} from "../../../types/units";
import { CardSection, CardSectionTitle } from "../Card/Card";

export enum PrecipitationType {
  Rain = "Rain",
  Snow = "Snow",
  Hail = "Hail",
  Sleet = "Sleet",
}

export enum PrecipitationIntensity {
  light = `[0, 2,5]`,
}

type PrecipitationAlertsProps = {
  alerts: UserWeatherAlerts;
  units: UnitKey;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const PrecipitationAlerts: React.FC<PrecipitationAlertsProps> = ({
  alerts,
  units,
  updateAlerts,
}) => {
  const handlePrecipitationChange = (precipitation: boolean) => {
    updateAlerts({ precipitation, desktop: true });
  };

  const handlePrecipitationTypeChange = (
    checked: boolean,
    selected: string
  ) => {
    const newTypes = alerts.precipitationType
      ? checked
        ? [...alerts.precipitationType, selected]
        : alerts.precipitationType.filter((x) => x !== selected)
      : [selected];

    updateAlerts({ precipitationType: newTypes });
  };

  const handlePrecipitationProbabilityChange = (
    precipitationProbability: number
  ) => {
    updateAlerts({ precipitationProbability });
  };

  const handlePrecipitationIntensityCustomChange = (range: NumberRange) => {
    const from = range.from
      ? convertPrecipToMmH(range.from, units)
      : range.from;
    const to = range.to ? convertPrecipToMmH(range.to, units) : range.to;
    updateAlerts({ precipitationIntensity: [from, to] });
  };

  const handlePrecipRadioChange = (label: string) => {
    if (label.includes("Custom parameters")) {
      updateAlerts({
        precipitationIntensity: [0],
        precipitationSelector: "custom",
      });
    } else {
      updateAlerts({ precipitationSelector: "" });
    }
  };

  const handlePrecipitationIntensityChange = (
    precipitationIntensity: (string | undefined | number)[]
  ) => {
    updateAlerts({ precipitationIntensity: precipitationIntensity });
  };

  return (
    <>
      <CardSection className={styles.alertsSection}>
        <Switch
          label="Precipitation"
          checked={!!alerts.precipitation}
          onChange={handlePrecipitationChange}
        />
      </CardSection>
      {!!alerts.precipitation && (
        <>
          <CardSection
            className={`${styles.sectionColumn}  ${styles.mobileSection}`}
          >
            <CardSectionTitle title="Precipitation Type" />
            <div className={styles.precipitationType}>
              <Checkbox
                label={PrecipitationType.Rain}
                checked={
                  !alerts.precipitationType ||
                  alerts.precipitationType.includes(PrecipitationType.Rain)
                }
                onClick={(checked) =>
                  handlePrecipitationTypeChange(checked, PrecipitationType.Rain)
                }
              />
              <Checkbox
                label={PrecipitationType.Snow}
                checked={
                  !alerts.precipitationType ||
                  alerts.precipitationType.includes(PrecipitationType.Snow)
                }
                onClick={(checked) =>
                  handlePrecipitationTypeChange(checked, PrecipitationType.Snow)
                }
              />
              <Checkbox
                label={PrecipitationType.Hail}
                checked={
                  !alerts.precipitationType ||
                  alerts.precipitationType.includes(PrecipitationType.Hail)
                }
                onClick={(checked) =>
                  handlePrecipitationTypeChange(checked, PrecipitationType.Hail)
                }
              />
              <Checkbox
                label={PrecipitationType.Sleet}
                checked={
                  !alerts.precipitationType ||
                  alerts.precipitationType.includes(PrecipitationType.Sleet)
                }
                onClick={(checked) =>
                  handlePrecipitationTypeChange(
                    checked,
                    PrecipitationType.Sleet
                  )
                }
              />
            </div>
          </CardSection>

          <CardSection
            className={`${styles.sectionColumn} ${styles.mobileSection}`}
          >
            <CardSectionTitle title="Precipitation Probability:" />
            <NumberField
              label="Notifications apply when the precipitation probability is more than"
              value={alerts.precipitationProbability || 0}
              units="%"
              min={0}
              max={100}
              onChange={handlePrecipitationProbabilityChange}
            />
          </CardSection>

          <CardSection className={styles.sectionColumn}>
            <CardSectionTitle title="Precipitation Intensity:" />
            <Radio
              checked={alerts.precipitationSelector !== "custom"}
              name="precipitationIntensity"
              label="Select the intensity."
              onChange={handlePrecipRadioChange}
            />

            <PrecipitationIntensitySelector
              disabled={alerts.precipitationSelector === "custom"}
              intensity={alerts.precipitationIntensity}
              onChange={handlePrecipitationIntensityChange}
              units={units}
            />

            <Radio
              checked={alerts.precipitationSelector === "custom"}
              name="precipitationIntensity"
              label={`Custom parameters (${getPrecipitUnitShort(units)}):`}
              onChange={handlePrecipRadioChange}
            />
            <RangeFields
              disabled={alerts.precipitationSelector !== "custom"}
              className={styles.precipitationIntensityRange}
              from={
                alerts.precipitationIntensity &&
                typeof alerts.precipitationIntensity[0] === "number"
                  ? convertPrecip(alerts.precipitationIntensity[0], units)
                  : undefined
              }
              to={
                alerts.precipitationIntensity &&
                typeof alerts.precipitationIntensity[1] === "number"
                  ? convertPrecip(alerts.precipitationIntensity[1], units)
                  : undefined
              }
              onChange={handlePrecipitationIntensityCustomChange}
              min={0}
              max={300}
            />
          </CardSection>
        </>
      )}
      <HSplit />
    </>
  );
};

export default PrecipitationAlerts;

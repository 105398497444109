import React, { useState } from "react";
import styles from "./AlertsNotifications.module.scss";
import iconArrowDown from "../../../assets/icons/i-arrow-down.svg";
import iconArrowDownActive from "../../../assets/icons/i-arrow-down-active.svg";
import { LocaleMessage } from "../../../components/Locale";
import { UserWeatherAlerts } from "../../../api/authApi";
import { Switch } from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import AlertsMain from "./AlertsMain";
import AlertsDaily from "./AlertsDaily";
import AlertsSignificantChanges from "./AlertsWeatherChanges";
import AlertsWind from "./AlertsWind";
import PrecipitationAlerts from "./AlertsPrecipitation";
import AlertsVisibility from "./AlertsVisibility";
import AlertsAir from "./AlertsAir";
import AlertsUv from "./AlertsUv";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import { Card, CardSection, CardTitle } from "../Card/Card";

type AlertsNotificationsProps = {
  alerts: UserWeatherAlerts;
  favorites: string[];
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsNotifications: React.FC<AlertsNotificationsProps> = ({
  alerts,
  favorites,
  updateAlerts,
}) => {
  const [showAdvanced, setShowAdnvanced] = useState(false);
  const [hovered, setHovered] = useState(false);

  const units = useSelector(getUnits);
  const handleGovernmentChange = (government: boolean) => {
    updateAlerts({ government, desktop: true });
  };
  const handleNextHourPrecipitationChnage = (
    nextHourPrecipitation: boolean
  ) => {
    updateAlerts({ nextHourPrecipitation, desktop: true });
  };

  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Card id="notifications">
      <CardTitle title={<LocaleMessage id="AccSettingsLocationInfoMsg" />} />

      <AlertsMain
        alerts={alerts}
        favorites={favorites}
        updateAlerts={updateAlerts}
      />

      <AlertsDaily alerts={alerts} updateAlerts={updateAlerts} />

      <CardSection className={styles.alertsSection}>
        <Switch
          label="Government-issued alerts"
          info="Real-time safety alerts for your location"
          checked={!!alerts.government}
          onChange={handleGovernmentChange}
        />
      </CardSection>
      <HSplit />

      <CardSection className={styles.alertsSection}>
        <Switch
          label="Next-hour precipitation alerts (rain, snow, hail, sleet)"
          info="Get notified within the coming hour"
          checked={!!alerts.nextHourPrecipitation}
          onChange={handleNextHourPrecipitationChnage}
        />
      </CardSection>
      <HSplit />

      <AlertsSignificantChanges alerts={alerts} updateAlerts={updateAlerts} />

      <CardSection className={styles.advancedSection}>
        <button
          className={styles.btnAdnvanced}
          onClick={() => setShowAdnvanced(!showAdvanced)}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {showAdvanced ? "Hide" : "Show"} Advanced Notifications
          <img
            className={`${styles.iconAdvanced} ${
              !showAdvanced ? styles.iconAdvancedDown : ""
            }`}
            src={hovered ? iconArrowDownActive : iconArrowDown}
            alt="arrow down icon"
          />
        </button>
        {showAdvanced && (
          <p className={styles.advancedSubtitle}>
            Advanced notifications with delivery up to 48 hours in advance
          </p>
        )}
      </CardSection>

      {showAdvanced && (
        <>
          <AlertsWind
            alerts={alerts}
            updateAlerts={updateAlerts}
            units={units}
          />

          <PrecipitationAlerts
            alerts={alerts}
            updateAlerts={updateAlerts}
            units={units}
          />

          <AlertsVisibility
            alerts={alerts}
            updateAlerts={updateAlerts}
            units={units}
          />

          <AlertsAir
            alerts={alerts}
            updateAlerts={updateAlerts}
            units={units}
          />

          <AlertsUv alerts={alerts} updateAlerts={updateAlerts} />
        </>
      )}
    </Card>
  );
};

export default AlertsNotifications;

import React, { useEffect } from "react";
import { RouteComponentProps, match } from "react-router-dom";
import { Store, RootDispatch } from "../../store";
import { DataPage } from "../DataPage";
import { useDispatch } from "../../store/hooks";
import { useSearchPlaceholderWeatherLocation } from "../../store/hooks/search";
import DayWeatherView from "./DayWeatherView";
import DaysWeatherView from "./DaysWeatherView";
import { useScrollTop } from "../../utils/hooks";
import { getWeatherTypeFromUrl } from "../../utils/url";
import { WeatherType } from "../../types/weatherType";
import { Request } from "express";
import { tryInit } from "../../store/actions/init";
import {
  tryLoadWeatherNearbyCities,
  weatherSelectByCode,
  tryLoadDailyForecast,
  tryLoadHourlyForecast,
  //tryLoadMonthlyStats,
  tryLoadCurrentWeather,
  tryLoadImagery,
  tryLoadWeatherNearbyPlaces,
  tryLoadWeatherAlerts,
  tryLoadTides,
} from "../../store/actions/weather";
import { useSelector } from "react-redux";
import {
  getWeatherType,
  getWeatherSelectorError,
} from "../../store/selectors/weather";
import RadarWeatherView from "./RadarWeatherView";
import { LocationType } from "../../api/placesApi";
import NotFound from "../NotFound";
import { isNotFoundError } from "../../utils/errors";
import { setCacheControlWeather, autoRefreshWeather } from "../../utils/cache";
import TidesView from "./TidesView";
import WeatherAlerts from "../../components/WeatherAlerts";
import { NotificationsPopup } from "../../components/Notifications/NotificationsPopup";

type WeatherRouteParams = {
  locationType: string;
  continentId: string;
  countryId: string;
  regionCode: string;
  cityType: string;
};

type WeatherPageProps = RouteComponentProps<WeatherRouteParams>;

async function fetchData(
  dispatch: RootDispatch,
  locationType: LocationType,
  countryId: string,
  regionCode: string,
  cityCode: string,
  weatherType: WeatherType | undefined,
  routeChanged: () => boolean,
  req?: Request
) {
  const doFetch = async (dispatch: RootDispatch) => {
    if (weatherType === undefined) return;

    await dispatch(
      weatherSelectByCode(
        locationType,
        countryId,
        regionCode,
        cityCode,
        weatherType,
        routeChanged
      )
    );

    if (routeChanged()) return;

    const promises: Promise<any>[] = [dispatch(tryLoadWeatherAlerts(req))];

    switch (weatherType) {
      case WeatherType.Today:
        promises.push(dispatch(tryLoadCurrentWeather(req)));
      // eslint-disable-next-line
      case WeatherType.Tomorrow:
        promises.push(dispatch(tryLoadHourlyForecast(req)));
        //promises.push(dispatch(tryLoadMonthlyStats()));
        promises.push(dispatch(tryLoadWeatherNearbyCities()));
        promises.push(dispatch(tryLoadWeatherNearbyPlaces()));
      // eslint-disable-next-line
      case WeatherType.Days10:
        promises.push(dispatch(tryLoadDailyForecast(req)));
        break;
      case WeatherType.Tides:
        promises.push(dispatch(tryLoadCurrentWeather(req)));
        promises.push(dispatch(tryLoadDailyForecast(req)));
        promises.push(dispatch(tryLoadTides(req)));
        break;
      case WeatherType.Radar:
        promises.push(dispatch(tryLoadCurrentWeather(req)));
        promises.push(dispatch(tryLoadDailyForecast(req)));
        promises.push(dispatch(tryLoadImagery(req)));
        break;
    }

    await Promise.all(promises);
  };

  await dispatch(tryInit(req));
  await dispatch(doFetch);
}

const PageWrap: React.FC = ({ children }) => {
  return (
    <>
      <WeatherAlerts />
      <NotificationsPopup />
      {children}
    </>
  );
};

const WeatherPage: React.FC<WeatherPageProps> & DataPage<WeatherRouteParams> = (
  props
) => {
  const {
    match: {
      params: {
        locationType = "c",
        continentId,
        countryId,
        regionCode,
        cityType,
      },
    },
  } = props;

  const dispatch = useDispatch();

  const { code: cityCode, weatherType } = getWeatherTypeFromUrl(cityType);

  useEffect(() => {
    let routeChanged = false;
    const killAutoRefresh = autoRefreshWeather(() =>
      fetchData(
        dispatch,
        locationType as LocationType,
        countryId,
        regionCode,
        cityCode,
        weatherType,
        () => routeChanged
      )
    );
    return () => {
      routeChanged = true;
      killAutoRefresh();
    };
  }, [dispatch, locationType, countryId, regionCode, cityCode, weatherType]);

  const key = `${continentId}${countryId}${regionCode}${cityCode}${weatherType}`;
  useScrollTop([key]);
  useSearchPlaceholderWeatherLocation();

  const stateWeatherType = useSelector(getWeatherType);
  //if (stateWeatherType !== weatherType) return null;
  const weatherSelectedError = useSelector(getWeatherSelectorError);

  setCacheControlWeather(props);

  if (weatherType === undefined || isNotFoundError(weatherSelectedError)) {
    // console.log("NF1", weatherType, weatherSelectedError);
    return <NotFound {...props} />;
  }

  switch (stateWeatherType) {
    case WeatherType.Today:
    case WeatherType.Tomorrow:
      return (
        <PageWrap>
          <DayWeatherView key={key} />
        </PageWrap>
      );
    case WeatherType.Days10:
      return (
        <PageWrap>
          <DaysWeatherView key={key} />
        </PageWrap>
      );
    case WeatherType.Tides:
      return (
        <PageWrap>
          <TidesView key={key} />
        </PageWrap>
      );
    case WeatherType.Radar:
      return (
        <PageWrap>
          <RadarWeatherView key={key} />
        </PageWrap>
      );
    default:
      return <NotFound {...props} />;
  }
};

WeatherPage.fetchData = async function (
  store: Store,
  route: match<WeatherRouteParams>,
  req?: Request
) {
  const {
    params: { locationType = "c", countryId, regionCode, cityType },
  } = route;

  const { code: cityCode, weatherType } = getWeatherTypeFromUrl(cityType);

  return await fetchData(
    store.dispatch,
    locationType as LocationType,
    countryId,
    regionCode,
    cityCode,
    weatherType,
    () => false,
    req
  );
};

export default WeatherPage;

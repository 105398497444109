// export const API_ROOT =
//   process.env.REACT_APP_API_ROOT || "http://localhost:7000/api";
// export const API_ROOT =
//   process.env.REACT_APP_API_ROOT || "http://10.7.4.179:7000/api";
export const API_ROOT =
  process.env.REACT_APP_API_ROOT_DEV ||
  (typeof window !== "undefined"
    ? "/api"
    : process.env.REACT_APP_API_ROOT || "http://api:7000/api");

export const HOST_ROOT = "https://weatherman.com";

export const OWM_MAP_KEY = "c2d227200fa26a957c08a5335103bdf3";

export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY || "";

export const FACEBOOK_APP_ID =
  process.env.REACT_APP_FACEBOOK_ID || "185651059556005";
export const FACEBOOK_REDIRECT_URL =
  process.env.REACT_APP_FACEBOOK_REDIRECT || "https://weatherman.com";
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_ID ||
  "1739770261-q85lk8ev8l7map426lmu4m6b0mg5iibg.apps.googleusercontent.com";

export const weatherAlertsStatisticEmails = [
  "parker.n.swift@gmail.com",
  "serhii@leanylabs.com",
];

//console.log(`API_ROOT: ${API_ROOT}`);

import React, { useRef, useState, useEffect } from "react";

import styles from "./ShortHourlyForecast.module.scss";
import {
  HourlyForecast,
  DailyForecast,
  CurrentWeather,
} from "../../../api/weatherApi";
import { isTodayWeatherType } from "../../../types/weatherType";
import { UnitKey, formatTempShort } from "../../../types/units";
import sunsetImg from "../../../assets/sunset.svg";
import sunriseImg from "../../../assets/sunrise.svg";
import WeatherCondition, {
  WeatherConditionProps,
} from "../../../components/WeatherCondition";
import rightArrow from "./right-arrow.svg";
import {
  SelectDayWeather,
  SelectedDayWeather,
  SelectedDayWeatherKey,
} from "../DayWeatherHead";
import { useSelector } from "../../../store/hooks";
import {
  getSelectedCurrentWeather,
  getSelectedDailyForecasts,
  getSelectedHoursOrNext12,
  getWeatherType,
} from "../../../store/selectors/weather";
import { getUnits } from "../../../store/selectors/units";
import { LocaleMessage, LocaleTime } from "../../../components/Locale";

type CardPropsData = {
  top: React.ReactNode;
  bottom: React.ReactNode;
  img: string | WeatherConditionProps;
  forecast: SelectedDayWeather;
  time?: Date;
};

type CardProps = CardPropsData & {
  selected: SelectedDayWeatherKey | undefined;
  select: SelectDayWeather;
};

const Card: React.FC<CardProps> = ({
  select,
  top,
  bottom,
  img,
  selected,
  forecast,
  time,
}) => {
  const timerRef = useRef(0);
  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const doSelect = forecast ? () => select(forecast, time) : undefined;
  const doUnselect = forecast
    ? () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(
          (() => select(forecast, time, true)) as TimerHandler,
          0
        );
      }
    : undefined;
  const isSelected =
    forecast &&
    selected &&
    selected.forecast === forecast &&
    selected.time === time;

  return (
    <div className={styles.cardItem}>
      <div
        onClick={doSelect}
        onMouseEnter={doSelect}
        onMouseLeave={doUnselect}
        className={`${styles.cardWrap} ${
          isSelected ? styles.cardSelected : ""
        }`}
      >
        <div className={styles.card}>
          <div className={styles.cardTop}>{top}</div>
          {typeof img === "string" ? (
            <img src={img} className={styles.cardImg} alt="" />
          ) : (
            <WeatherCondition {...img} className={styles.cardImg} />
          )}
          <div className={styles.cardBottom}>{bottom}</div>
        </div>
      </div>
    </div>
  );
};

const CardCarousel: React.FC = ({ children }) => {
  const itemWidth = 147; // has to match with the CSS
  const carouselRef = useRef<HTMLDivElement>(null);
  const [scrollState, setScrollState] = useState<{ left: number; max: number }>(
    { left: 0, max: 0 }
  );

  const updateScroll = () => {
    const c = carouselRef.current;
    if (!c) return;
    const left = c.scrollLeft;
    const max = c.scrollWidth - c.clientWidth;
    if (left !== scrollState.left || max !== scrollState.max)
      setScrollState({ left, max });
  };

  useEffect(updateScroll);

  const showLeft = scrollState.left > 0;
  const showRight = scrollState.left < scrollState.max;
  const previous = () => {
    const c = carouselRef.current;
    if (!c) return;
    const target =
      Math.round((c.scrollLeft - 3 * itemWidth) / itemWidth) * itemWidth;
    try {
      c.scroll({
        left: target,
        behavior: "smooth",
      });
    } catch {
      try {
        c.scrollLeft = target;
      } catch {}
    }
    //updateScroll();
  };
  const next = () => {
    const c = carouselRef.current;
    if (!c) return;
    const target =
      Math.round((c.scrollLeft + 3 * itemWidth) / itemWidth) * itemWidth;
    try {
      c.scroll({
        left: target,
        behavior: "smooth",
      });
    } catch {
      try {
        c.scrollLeft = target;
      } catch {}
    }
    //updateScroll();
  };

  return (
    <div className={styles.carouselWrap}>
      <button
        aria-label="Previous hours"
        onClick={previous}
        className={`${styles.leftArrow} ${!showLeft ? styles.disabled : ""}`}
      >
        <img alt="Left" src={rightArrow} />
      </button>
      <div
        className={styles.carousel}
        ref={carouselRef}
        onScroll={updateScroll}
      >
        {children}
      </div>
      <button
        aria-label="Next hours"
        onClick={next}
        className={`${styles.rightArrow} ${!showRight ? styles.disabled : ""}`}
      >
        <img alt="Right" src={rightArrow} />
      </button>
    </div>
  );
};

type GetCardPropsParams = {
  currentWeather?: CurrentWeather;
  hourly: HourlyForecast[];
  daily: DailyForecast[];
  units: UnitKey;
};

type CardPropsWithTime = CardPropsData & {
  sortTime: Date;
};

function getClosestHourly(hourly: HourlyForecast[], time: Date) {
  const timeD = time.getTime();

  let result: HourlyForecast = hourly[0];
  let minDiff = Number.MAX_VALUE;

  for (const hour of hourly) {
    const diff = Math.abs(timeD - hour.time.getTime());
    if (diff < minDiff) {
      minDiff = diff;
      result = hour;
    }
  }
  return result;
}

function getCardProps({
  currentWeather,
  hourly,
  daily,
  units,
}: GetCardPropsParams) {
  const result: CardPropsWithTime[] = [];

  if (currentWeather) {
    result.push({
      sortTime: new Date(0),
      top: <LocaleMessage id="Now" />,
      forecast: currentWeather,
      bottom: formatTempShort(currentWeather.temp, units),
      img: {
        weatherCode: currentWeather.weatherCode,
        day: currentWeather.day,
        weatherDesc: currentWeather.weatherDesc,
      },
    });
  }

  if (!hourly.length) return result;

  for (const forecast of hourly) {
    const { time, temp, weatherCode, weatherDesc, day } = forecast;
    result.push({
      sortTime: time,
      top: <LocaleTime value={time} />,
      img: {
        weatherCode,
        day,
        weatherDesc,
      },
      bottom: formatTempShort(temp, units),
      forecast,
    });
  }

  const startTime = currentWeather ? currentWeather.time : hourly[0].time;
  const endTime = hourly[hourly.length - 1].time;

  for (const { sunrise, sunset } of daily) {
    if (sunrise && sunrise >= startTime && sunrise <= endTime) {
      result.push({
        sortTime: sunrise,
        time: sunrise,
        top: <LocaleTime value={sunrise} />,
        img: sunriseImg,
        bottom: <LocaleMessage id="Sunrise" />,
        forecast: getClosestHourly(hourly, sunrise),
      });
    }

    if (sunset && sunset >= startTime && sunset <= endTime) {
      result.push({
        sortTime: sunset,
        time: sunset,
        top: <LocaleTime value={sunset} />,
        img: sunsetImg,
        bottom: <LocaleMessage id="Sunset" />,
        forecast: getClosestHourly(hourly, sunset),
      });
    }
  }

  result.sort((a, b) => a.sortTime.getTime() - b.sortTime.getTime());

  return result;
}

type ShortHourlyForecastProps = {
  selected: SelectedDayWeatherKey | undefined;
  select: SelectDayWeather;
  className?: string;
};

const ShortHourlyForecast: React.FC<ShortHourlyForecastProps> = ({
  selected,
  select,
  className = "",
}) => {
  const units = useSelector(getUnits);

  const weatherType = useSelector(getWeatherType);
  const daily = useSelector(getSelectedDailyForecasts);
  const hourly = useSelector(getSelectedHoursOrNext12);
  const currentWeather = useSelector(getSelectedCurrentWeather);

  //console.log(hourly);

  const cardPropsParams: GetCardPropsParams = {
    hourly,
    daily,
    units,
  };

  if (isTodayWeatherType(weatherType) && currentWeather)
    cardPropsParams.currentWeather = currentWeather;

  const cardProps = getCardProps(cardPropsParams);

  // if (today && currentWeather) {
  //   const nowTime = currentWeather ? currentWeather.time : hourly[0].time;

  //   if (currentWeather) {
  //     cardProps.push({
  //       //top: nowTime,
  //       top: "Now",
  //       forecast: currentWeather,
  //       bottom: formatTempShort(currentWeather.temp, units),
  //       img: {
  //         weatherCode: currentWeather.weatherCode,
  //         day: currentWeather.day,
  //         weatherDesc: currentWeather.weatherDesc
  //       }
  //     });
  //   }

  //   cardProps.push(
  //     ...getCardProps({
  //       hourly,
  //       daily,
  //       units,
  //       timeFilter: nowTime ? x => x >= nowTime : undefined
  //     })
  //   );
  // } else {
  //   cardProps.push(...getCardProps({ hourly, sunrise, sunset, units }));
  // }

  return (
    <div className={`container ${styles.container} ${className}`}>
      <CardCarousel>
        {cardProps.map((x, i) => (
          <Card key={i} selected={selected} select={select} {...x} />
        ))}
      </CardCarousel>
    </div>
  );
};

export default ShortHourlyForecast;

import React from "react";
import styles from "./Controls.module.scss";

type NumberFieldProps = {
  label: string;
  value: number;
  suffix?: string;
  units?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
};

export const NumberField: React.FC<NumberFieldProps> = ({
  label,
  value,
  suffix,
  units,
  min,
  max,
  disabled,
  onChange,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    onChange(+value);
  };
  const handleIncrement = () => {
    const newValue = max !== undefined && value >= max ? max : value + 1;
    if (!disabled) onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = min !== undefined && value <= min ? min : value - 1;
    if (!disabled) onChange(newValue);
  };
  return (
    <>
      <label className={styles.numberField}>
        <span className={styles.numberLabel}>{label}</span>
        <div className={styles.fieldWrap}>
          <div
            className={`${styles.numberFieldWrap} ${
              disabled ? styles.numberFieldWrapDisabled : ""
            }`}
          >
            <input
              className={styles.numberInput}
              type="number"
              value={value}
              onChange={handleChange}
              min={min}
              max={max}
              disabled={disabled}
            />
            {suffix && value !== undefined && (
              <span className={styles.numberSuffix}>{suffix}</span>
            )}
            <div className={styles.numberButtons}>
              <button onClick={handleIncrement}>+</button>
              <button onClick={handleDecrement}>-</button>
            </div>
          </div>
          {units && <span className={styles.numberUnits}>{units}</span>}
        </div>
      </label>
    </>
  );
};

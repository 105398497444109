import { apiPost } from "../http";

export type ContactUsForm = {
  name: string;
  email: string;
  message: string;
  captcha: string;
};

export async function postContactUs(values: ContactUsForm) {
  //console.log(values);
  return await apiPost<{ messageId: string }>("/contact", "Contact Us", values);
}

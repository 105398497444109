import "react-app-polyfill/ie9";
//import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";

import "intl";
import "intl/locale-data/jsonp/en.js";
import "intl/locale-data/jsonp/uk.js";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/dist/locale-data/en";
import "@formatjs/intl-pluralrules/dist/locale-data/uk";
import "./utils/modernizr";

smoothscroll.polyfill();

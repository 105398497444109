import { fetchDailyForecast } from "../../api/weatherApi";
import { ThunkDispatch } from "redux-thunk";
import { RootState, GetState } from "..";
import { HOME_SHORT_LOADED } from "../types/homeShort";

export function loadHomeShort(locationId: string) {
  return async (
    dispatch: ThunkDispatch<RootState, undefined, any>,
    getState: GetState
  ) => {
    if (!locationId) return;

    try {
      const forecasts = await fetchDailyForecast(locationId, undefined);
      if (!forecasts) return;

      const forecast = forecasts.daily[0];
      dispatch({ type: HOME_SHORT_LOADED, forecast });
    } catch (e) {
      console.error("loadHomeShort: ", e);
    }
  };
}

import React, { useState, ReactNode } from "react";
import { DailyForecast } from "../../../api/weatherApi";
import { getWeatherTypeDaysCount } from "../../../types/weatherType";
import {
  UnitKey,
  formatTempShort,
  formatPercent,
  formatWindDirectionShort,
  formatSpeed,
  formatDistance,
  formatUvIndex,
  formatPressure,
  formatRainfall,
  formatIndex,
} from "../../../types/units";
import Card from "../../../components/Card";
import CardTitle from "../../../components/Card/CardTitle";
import styles from "./DetailedDailyForecast.module.scss";
import { getWeatherDescription } from "../../../types/weatherCodes";
import RainChance from "../../../components/RainChance";
import TempConditionChart, {
  TempConditionPoint,
} from "../../../components/TempConditionChart";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import { getWeatherType } from "../../../store/selectors/weather";
import { getSelectedDailyForecasts } from "../../../store/selectors/weather";
import { MessageKey, useFormatMessage } from "../../../i18n";
import {
  LocaleMessage,
  LocaleTime,
  LocaleDayShort,
  LocaleDateShort,
  formatMinutesDuration,
} from "../../../components/Locale";
import LocationName from "../../../components/LocationName";

type DetailedDailyForecastTableProps = {
  days: DailyForecast[];
  today: DailyForecast;
  units: UnitKey;
};

const DetailedDailyForecastTable: React.FC<DetailedDailyForecastTableProps> = ({
  days,
  today,
  units,
}) => {
  const formatMessage = useFormatMessage();
  const [selected, setSelected] = useState(-1);
  const renderRow = (
    header: MessageKey | "",
    item: (day: DailyForecast, i: number) => ReactNode,
    className: string = styles.dayItem,
    headerClassName: string = styles.headerItem
  ) => {
    return (
      <div className={styles.row}>
        <div className={`${styles.col} ${header ? headerClassName : ""}`}>
          {header ? <LocaleMessage id={header as MessageKey} /> : ""}
        </div>

        {days.map((x, i) => {
          return (
            <div
              key={x.date.getTime()}
              className={`${styles.col} ${className} ${
                selected === i ? styles.selected : ""
              }`}
              onMouseEnter={() => setSelected(i)}
              onMouseLeave={() => setSelected(-1)}
            >
              {item(x, i)}
            </div>
          );
        })}
      </div>
    );
  };

  const allIndices = Object.keys(
    days.reduce((p, day) => {
      if (!day.indices) return p;
      Object.keys(day.indices).forEach((x) => (p[x] = true));
      return p;
    }, {})
  );

  return (
    <>
      {renderRow("", () => null, styles.tempConditionDummy)}
      {renderRow(
        "",
        (x) =>
          x === today ? (
            <LocaleMessage id="Today" />
          ) : (
            <LocaleDayShort value={x.date} />
          ),
        styles.title
      )}
      {renderRow(
        "",
        ({ date }) => (
          <LocaleDateShort value={date} />
        ),
        styles.date
      )}
      {renderRow("", () => null, styles.splitter)}
      {renderRow(
        "",
        ({ rainChance }) => (
          <RainChance className={styles.rain} rainChance={rainChance} />
        ),
        styles.rain
      )}
      {renderRow(
        "",
        ({ weatherCode, weatherDesc }) =>
          getWeatherDescription(formatMessage, weatherCode, weatherDesc),
        styles.condition
      )}
      {renderRow("FeelsLike", ({ tempFeels }) =>
        formatTempShort(tempFeels, units)
      )}
      {renderRow("Wind", ({ windDir, windSpeed }) => (
        <>
          {formatWindDirectionShort(formatMessage, windDir)}{" "}
          {formatSpeed(formatMessage, windSpeed, units)}
        </>
      ))}
      {renderRow("Humidity", ({ humidity }) => formatPercent(humidity))}
      {renderRow("Visibility", ({ visibility }) =>
        formatDistance(formatMessage, visibility, units)
      )}
      {renderRow("UVIndex", ({ uvIndex }) =>
        formatUvIndex(formatMessage, uvIndex)
      )}
      {renderRow("DewPt", ({ dewPoint }) => formatTempShort(dewPoint, units))}
      {renderRow(
        "Pressure",
        ({ pressure }) => formatPressure(formatMessage, pressure),
        `${styles.dayItem} ${styles.pressure}`,
        `${styles.headerItem} ${styles.pressure}`
      )}
      {renderRow(
        "Sunrise",
        ({ sunrise }) => (sunrise ? <LocaleTime value={sunrise} /> : "-"),
        `${styles.dayItem} ${styles.sunrise}`,
        `${styles.headerItem} ${styles.sunrise}`
      )}
      {renderRow("Sunset", ({ sunset }) =>
        sunset ? <LocaleTime value={sunset} /> : "-"
      )}
      {renderRow("Moonrise", ({ moonrise }) =>
        moonrise ? <LocaleTime value={moonrise} /> : "-"
      )}
      {renderRow("Moonset", ({ moonset }) =>
        moonset ? <LocaleTime value={moonset} /> : "-"
      )}
      {/* NEW FIELDS */}
      {renderRow("MoonAge", ({ moonAge }) =>
        moonAge !== undefined ? moonAge : ""
      )}
      {renderRow("FeelsLikeShade", ({ tempFeelsShade }) =>
        formatTempShort(tempFeelsShade, units)
      )}
      {renderRow("PrecipitationProbability", ({ precipChance: percipChance }) =>
        formatPercent(percipChance)
      )}
      {renderRow("TStormProbability", ({ tstormChance }) =>
        formatPercent(tstormChance)
      )}
      {renderRow("SnowProbability", ({ snowChance }) =>
        formatPercent(snowChance)
      )}
      {renderRow("IceProbability", ({ iceChance }) => formatPercent(iceChance))}
      {renderRow("PrecipitationDuration", ({ precipMinutes: percipMinutes }) =>
        formatMinutesDuration(formatMessage, percipMinutes)
      )}
      {renderRow("RainDuration", ({ rainMinutes }) =>
        formatMinutesDuration(formatMessage, rainMinutes)
      )}
      {renderRow("SnowDuration", ({ snowMinutes }) =>
        formatMinutesDuration(formatMessage, snowMinutes)
      )}
      {renderRow("IceDuration", ({ iceMinutes }) =>
        formatMinutesDuration(formatMessage, iceMinutes)
      )}
      {renderRow("WindGust", ({ windGustSpeed }) =>
        formatSpeed(formatMessage, windGustSpeed, units)
      )}
      {renderRow("TotalLiquid", ({ totalLiquid }) =>
        formatRainfall(formatMessage, totalLiquid, units)
      )}
      {renderRow("Rain", ({ rain }) =>
        formatRainfall(formatMessage, rain, units)
      )}
      {renderRow("Snow", ({ snow }) =>
        formatRainfall(formatMessage, snow, units)
      )}
      {renderRow("Ice", ({ ice }) => formatRainfall(formatMessage, ice, units))}
      {renderRow("CloudCover", ({ cloudCover }) => formatPercent(cloudCover))}
      {renderRow("PrecipitationType", ({ precipType }) =>
        precipType
          ? formatMessage(precipType as MessageKey, undefined, precipType)
          : ""
      )}
      {renderRow("PrecipitationIntensity", ({ precipIntensityS }) =>
        formatIndex(formatMessage, undefined, precipIntensityS)
      )}
      {allIndices.map((name) => (
        <div key={name}>
          {renderRow(name as MessageKey, ({ indices }) => {
            if (!indices) return "";
            const index = indices[name];
            if (!index) return "";
            return formatIndex(formatMessage, index.value, index.category);
          })}
        </div>
      ))}
    </>
  );
};

const DetailedTitle: React.FC = () => {
  return (
    <CardTitle
      title={
        <LocaleMessage
          id="DetailedDailyForecastTitle10"
          values={{ name: <LocationName /> }}
        />
      }
    />
  );
};

type DetailedDailyForecastProps = {
  offset: number;
};

const DetailedDailyForecast = React.forwardRef<
  HTMLDivElement,
  DetailedDailyForecastProps
>(({ offset }, ref) => {
  const units = useSelector(getUnits);
  const daily = useSelector(getSelectedDailyForecasts);
  const weatherType = useSelector(getWeatherType);

  const days = daily.slice(
    offset,
    offset + getWeatherTypeDaysCount(weatherType)
  );

  const today = daily[0];

  const chartData = days.map<TempConditionPoint>(
    ({ temp, weatherCode, weatherDesc }) => ({
      temp,
      weatherCode,
      weatherDesc,
      day: true,
    })
  );

  return (
    <div ref={ref} className="container mt-100 d-none d-xl-block">
      <Card>
        <DetailedTitle />

        <div className={`${styles.grid}`}>
          <div className={styles.chartWrap}>
            <TempConditionChart
              baseId="detailedDaily_tempCond"
              data={chartData}
              ssrWidth={986}
              height={Number.parseFloat(styles.chartHeight)}
              stroke="#0066FA"
              count={10}
              dot={{ fill: "#fff" }}
              renderTemp={({ index }) => (
                <>
                  <tspan className={styles.tempMax}>
                    {formatTempShort(days[index].tempMax, units)}
                  </tspan>
                  <tspan className={styles.tempMin} dx={-3}>
                    {" "}
                    / {formatTempShort(days[index].tempMin, units)}
                  </tspan>
                </>
              )}
            />
          </div>
          <DetailedDailyForecastTable days={days} today={today} units={units} />
        </div>
      </Card>
    </div>
  );
});

export default DetailedDailyForecast;

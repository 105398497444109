import React from "react";
import { DailyForecast } from "../../../api/weatherApi";
import styles from "./DayHeadTempCondition.module.scss";
import { formatTempShort } from "../../../types/units";
import { getWeatherDescription } from "../../../types/weatherCodes";
import { SelectedDayWeather } from "../DayWeatherHead";
import { useSelector } from "react-redux";
import { getUnits } from "../../../store/selectors/units";
import { LocaleMessage } from "../../../components/Locale";
import { useFormatMessage } from "../../../i18n";

type DayHeadTempConditionProps = {
  weather: SelectedDayWeather | undefined;
  className?: string;
};

const DayHeadTempCondition: React.FC<DayHeadTempConditionProps> = ({
  weather,
  className,
}) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();

  const { temp, tempFeels, weatherCode, weatherDesc = "" } = weather || {};
  const weatherD = weather as DailyForecast;
  const hasTempMinMax = weatherD && typeof weatherD.tempMin !== "undefined";
  const { tempMin, tempMax } = weatherD || {};
  return (
    <div className={className}>
      <div className={styles.tempCodition}>
        {hasTempMinMax ? (
          <div className={styles.tempMinMax}>
            {formatTempShort(tempMax, units) || <>&nbsp;</>}
            <span className={styles.tempMin}>
              {" / " + formatTempShort(tempMin, units) || <>&nbsp;</>}
            </span>
          </div>
        ) : (
          <div className={styles.temp}>
            {temp !== undefined ? formatTempShort(temp, units) : <>&nbsp;</>}
          </div>
        )}

        {weather && (
          <div className={styles.conditionFeels}>
            <div className={styles.condition}>
              {getWeatherDescription(formatMessage, weatherCode, weatherDesc)}
            </div>
            <div className={styles.feels}>
              {tempFeels !== undefined && (
                <LocaleMessage
                  id="FeelsLikeTemp"
                  values={{ temp: formatTempShort(tempFeels, units) }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DayHeadTempCondition;

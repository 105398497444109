import { INIT_COMMON, INIT_CLIENT, InitActionTypes } from "../types/init";

export type InitState = {
  common: boolean;
  client: boolean;
};

const initialState: InitState = {
  common: false,
  client: false
};

export default function reducer(
  state: InitState = initialState,
  action: InitActionTypes
) {
  switch (action.type) {
    case INIT_COMMON:
      return { ...state, common: true };
    case INIT_CLIENT:
      return { ...state, client: true };
    default:
      return state;
  }
}

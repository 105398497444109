import React from "react";
import styles from "./Switch.module.scss";

type SwitchProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  info?: string;
};

export const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  label,
  info,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    onChange(value);
  };
  return (
    <label className={styles.switchWrap}>
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <div
        className={`${styles.switch} ${
          checked ? styles.checked : styles.unchecked
        }`}
      >
        {checked && <span className={styles.switchText}>ON</span>}
        <div
          className={`${styles.switchBox} ${
            checked ? styles.switchBoxOn : styles.switchBoxOff
          }`}
        ></div>
        {!checked && <span className={styles.switchText}>OFF</span>}
      </div>

      <span className={styles.label}>
        <span className={styles.title}>{label}</span>
        {info && <span className={styles.subtitle}>{info}</span>}
      </span>
    </label>
  );
};

import React from "react";
import styles from "./SidebarMenu.module.scss";
import loginImg from "./assets/login.svg";
import logoutImg from "./assets/logout.svg";
import { LocaleMessage } from "../../Locale";
import { authShowDialog, authLogout } from "../../../store/actions/auth";
import { useDispatch, useSelector } from "../../../store/hooks";
import { getAuthUser } from "../../../store/selectors/auth";
import { Link } from "react-router-dom";
import { getAccountSettingsUrl } from "../../../utils/url";

type AuthStateProps = {
  close: () => void;
};

const AuthState: React.FC<AuthStateProps> = ({ close }) => {
  const dispatch = useDispatch();
  const user = useSelector(getAuthUser);

  return (
    <div className={styles.authState}>
      {user ? (
        <>
          <Link
            onClick={close}
            className={styles.navLink}
            to={getAccountSettingsUrl()}
          >
            {user.pictureUrl ? (
              <img
                className={styles.avatar}
                src={user.pictureUrl}
                alt={user.name}
              />
            ) : (
              <div className={styles.avatar} />
            )}
            {user.name}
          </Link>
          <button
            className={styles.navLink}
            aria-label="Logout"
            onClick={() => {
              dispatch(authLogout());
              close();
            }}
          >
            <img className={styles.icon} alt="Logout" src={logoutImg} />
            <LocaleMessage id="Logout" />
          </button>
        </>
      ) : (
        <button
          className={styles.navLink}
          aria-label="Login"
          onClick={() => {
            dispatch(authShowDialog(true));
            close();
          }}
        >
          <img className={styles.icon} alt="Login" src={loginImg} />
          <LocaleMessage id="Login" />
        </button>
      )}
    </div>
  );
};

export default AuthState;

import { UserProfile } from "../../api/authApi";

export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";

type UserProfileUpdateAction = {
  type: typeof USER_PROFILE_UPDATE;
  userProfile: Partial<UserProfile>;
};

export type UserProfileActionTypes = UserProfileUpdateAction;

import React, {useEffect} from "react";
import Helmet from "react-helmet";
import { useFormatMessage, useLocale } from "../../i18n";
import { RouteComponentProps, Link } from "react-router-dom";
import { setCacheControlStatic } from "../../utils/cache";
import { useScrollTop } from "../../utils/hooks";
import { getPrivacyPolicyUrl } from "../../utils/url";
import {useDispatch} from "react-redux";
import {RootDispatch} from "../../store";
import {Request} from "express";
import {tryInit} from "../../store/actions/init";

async function fetchData(dispatch: RootDispatch, req?: Request) {
  await dispatch(tryInit(req));
}

const TermsOfUsePage: React.FC<RouteComponentProps> = (props) => {
  const formatMessage = useFormatMessage();
  const locale = useLocale();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch]);

  setCacheControlStatic(props);
  useScrollTop();

  return (
    <div className="container text-html">
      <Helmet title={formatMessage("TermsOfUseTitle")}>
        <meta name="description" content={formatMessage("TermsOfUseDesc")} />
      </Helmet>

      <h1>Terms of Use</h1>

      <p>
        The weatherman.com site is (the "Site") is owned and operated by Parker
        Swift. Listed below are the terms and conditions of use (the "Terms")
        for this Site. By using, accessing and/or viewing information on the
        Site, you (the .Participant.) agree to be bound by these Terms. If you
        violate these Terms, Parker Swift has the right to terminate your use of
        the Site and/or take appropriate legal actions against you. We reserve
        the right to change these Terms at any time by posting on the Site.
        participant understands and agrees that its use of this Site is a
        benefit voluntarily given by Parker Swift and that Parker Swift may
        withdraw that benefit and rescind your participation at any time for any
        reason in its sole discretion. If you do not agree to the Terms you
        cannot use this Site or the services and information offered herein.
      </p>
      <ol>
        <li>
          <p>
            PERMITTED USE. You may use the Site and the features, information,
            pictures and other data contained therein (collectively, the "Data")
            only for personal, non-commercial purposes. You may access, view and
            make copies of the Data in the Site for your personal,
            non-commercial use and will not publish or otherwise distribute the
            Data for any other purpose. Without limiting the foregoing, you may
            not utilize the Site to sell a product or service, to advertise or
            direct activity to other websites or for similar commercial
            activities without our express written consent. You may not modify,
            publish, transmit, display, participate in the transfer or sale,
            create derivative works, or in any way exploit, any of the Data, in
            whole or in part. Further restrictions on the use of this Site and
            its Data are provided in Sections 2, 6 and 9 of these Terms.
          </p>
        </li>
        <li>
          <p>
            OWNERSHIP/TRADEMARKS. Participant does not acquire or have any
            ownership, license or other proprietary interest in the Site or the
            Data. You understand that the Site and the Data are protected by
            copyrights, trademarks, service marks, patents and other proprietary
            rights and laws. You cannot use any protected material without the
            express written consent of the owner of such material except as
            permitted by law. Specifically, some of Data on this Site. This
            website, is unique and proprietary to Parker Swift, is not part of
            the National Weather Service data network and is not available for
            commercial use without the advance written consent from Parker
            Swift. ANY USE OF THIS DATA SHALL ACKNOWLEDGE Weatherman.com AS THE
            SOURCE. Parker Swift will take appropriate legal action to protect
            and safeguard any unauthorized use of this Data.
          </p>
        </li>
        <li>
          <p>
            PRIVACY POLICY. The use of personal information for this Site is
            governed by our 
            <Link to={getPrivacyPolicyUrl(locale)}>Privacy Policy</Link>.
          </p>
        </li>
        <li>
          <p>
            COPYRIGHT. If you believe that your copyrighted work has been copied
            in a way that constitutes copyright infringement, please provide
            Parker Swift's Copyright Agent the following information in writing,
            as required by the Digital Millennium Copyright Act, 17 U.S.C. 512
            ("DMCA"):
          </p>
          <ul>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </li>
            <li>
              Identification of the copyright work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit Parker Swift to locate the material;
            </li>
            <li>
              Information reasonably sufficient to permit Parker Swift to
              contact you;
            </li>
            <li>
              A statement that you have a good-faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law; and
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that you are authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ul>
          <p>
            Our agent for notice of claims of copyright infringement on the
            Services can be reached as follows:
          </p>
          <blockquote>
            <div>Parker Swift</div>
            <div>231 Madaket Road</div>
            <div>Nantucket, MA USA 02554</div>
            <div>
              Email: 
              <a href="mailto:support@weatherman.com">support@weatherman.com</a>
            </div>
          </blockquote>
          <p>
            Parker Swift has adopted and implemented a policy that provides for
            the termination in appropriate circumstances of account holders who
            repeatedly infringe the rights of copyright holders.
          </p>
          <p>
            To report other alleged violations of rights (including alleged
            copyright violations under the laws of countries other than the
            United States), or for all other enquiries, complaints and questions
            related to the Services, please use the contact information
            specified in paragraph 4 above.
          </p>
        </li>
        <li>
          <p>
            MONITORING. Parker Swift shall have the right in its sole discretion
            to refuse to post or remove any material submitted to or posted on
            the site. Without limiting the foregoing, Parker Swift shall have
            the right, but not the obligation, to remove any material we in our
            sole discretion, find to be in violation of the provisions hereof or
            otherwise objectionable. However, any opinions, advice, statements,
            services, offers, or other information or content expressed or made
            available by third parties, including information providers and
            users, are those of the respective author(s) or distributor(s) and
            not that of Parker Swift.
          </p>
        </li>
        <li>
          <p>
            LINKS. The Site may include links and advertisements to other
            websites, products or services. You acknowledge that these links are
            provided solely as a convenience to you and not as an endorsement by
            Parker Swift or by any of its third-party providers regarding the
            content of those other websites. Neither Parker Swift nor its
            third-party providers are responsible for or will have any liability
            with respect to the information contained in any websites that you
            may access as a result of such links including any objectionable or
            offensive content. If you decide to visit any other website or forum
            for any reason, you do so at your own risk.
          </p>
        </li>

        <li>
          <p>
            ADVERTISERS/VENDORS/SERVICE PROVIDERS. The Site may contain
            advertising and sponsorship. Advertisers and sponsors are
            responsible for insuring that materials submitted for inclusion on
            the Site are accurate and comply with applicable laws. Parker Swift
            will not be responsible for the illegality of or any error in
            accuracy in any advertisers. or sponsors. materials. participant
            understands and agrees that, in order to provide for the use of this
            Site, Parker Swift contracts with certain vendors and service
            providers, which have certain rights, limitations of warranties, and
            limitations of liability in those contracts. Participant understands
            and agrees that it is not a third party beneficiary under any such
            agreements, that it has no rights whatsoever pursuant to such
            agreements, and that it has no contractual or other relationship
            with any advertiser, business affiliate, vendor or service provider
            by reason of the use or information provided on this Site or any
            agreement relating to the Site. To the extent that Participant has
            any such rights or claims by operation of law or otherwise,
            Participant hereby waives any and all rights and claims whatsoever
            against those vendors and service providers whether resulting from
            the exercise of any right by such vendors or service providers
            pursuant to such agreements, the any error or omission in the
            services provided, not provided, withdrawn or terminated, or the use
            of this Site.
          </p>
        </li>
        <li>
          <p>
            LIMITATION OF LIABILITY. Parker Swift assumes no responsibility for,
            and shall not be liable for, any damages or expenses you may incur
            as a result of any inaccuracy, incompleteness or obsolescence of any
            Data contained in the Site. THE SERVICES OF THE SITE ARE PROVIDED
            .AS IS., WITH NO WARRANTIES WHATSOEVER. Without limiting the
            generality of the forgoing, you agree that neither Parker Swift nor
            any of its affiliates, employees or agents will be liable to you or
            to any other party for any direct or indirect damages, or for any
            special, exemplary, punitive, incidental, consequential or other
            damages (including, but not limited to, lost profits or lost time),
            whether based on contract, tort, strict liability or otherwise,
            which arise out of or are in any way connected with any access to
            the Site or any viewing or use of any information on the Site. You
            acknowledge that the limitations in this Section are reasonable and
            appropriate. Parker Swift DISCLAIMS ALL WARRANTIES REGARDING THE
            ACCURACY, COMPLETENESS, CURRENCY OR RELIABILITY OF THE DATA IN THE
            SITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT
            OR OTHERWISE ARISING BY LAW OR STATUTE. YOU AGREE THAT YOUR USE OF,
            OR RELIANCE UPON, ALL DATA OBTAINED THROUGH THE SITE IS AT YOUR OWN
            DISCRETION AND RISK.
          </p>
        </li>
        <li>
          <p>
            UNAUTHORIZED USE/NO INTERFERENCE. You agree that you will not use or
            attempt to use any method, device, software or routine to harm
            others or interfere with functioning of the Site or use and/or
            monitor any information in or related to the Site for any
            unauthorized purpose. Specifically, each Participant, whether
            authorized or unauthorized, agrees that it will not (and will not
            permit any of its employees, agents, and any other third party) do
            any of the following:
          </p>
          <ul>
            <li>
              Upload, post, email, transmit or otherwise make available any Data
              or content on the Site as to which you do not possess all of the
              patent, trademark, copyright, trade secret, license or other
              rights to do so;
            </li>
            <li>
              Violate any applicable laws and regulations, whether federal,
              state, local, foreign or international, applicable to the Site,
              its Data and/or your use of either, including without limitation
              U.S. export and re-export control laws and regulations, and you
              will not otherwise engage in any illegal, manipulative or
              misleading activity through use of this Site or its Data;
            </li>
            <li>
              Use this Site or the Data or provide, link, or otherwise make
              available, any information on the Site that is unlawful,
              threatening, abusive, harassing, tortuous, defamatory, vulgar,
              obscene, libelous, invasive of another.s privacy, hateful, harmful
              to minors or racially, ethnically or otherwise objectionable;
            </li>
            <li>
              Impersonate any person or entity, or otherwise misrepresent your
              affiliation;
            </li>
            <li>
              Manipulate, forge, or otherwise modify information or identifiers
              in a manner which may hide or disguise the origin of any
              information;
            </li>
            <li>
              Upload, post, email, transmit, link to or otherwise make available
              any information on the Site that infringes any intellectual
              property rights or other proprietary rights of others, or that
              contains any virus, computer code, file, or other material which
              has as its intended purpose, the interruption, destruction, or
              loss of functionality of any computer software, Data, hardware,
              telecommunications equipment, or communications ability;
            </li>
            <li>
              Attempt to gain access to confidential information to which you
              are not entitled;
            </li>
            <li>
              Modify, reverse engineer, reverse assemble, decompile or hack into
              any of the software applications or related tools or utilities
              used by this Site;
            </li>
            <li>
              Alter or delete information not provided by you, or interfere with
              the operation of this Site including, but not limited to, by
              distribution of unsolicited advertising or mail messages or
              propagation of worms, viruses and the like.
            </li>
            <li>
              By providing Data or material, you also agree to be solely liable
              for any damage resulting from any infringement of copyrights,
              proprietary rights, or any other harm resulting from such a
              submission. Subject to Parker Swift's policies regarding privacy,
              all e-mails, messages, postings, ideas, suggestions, concepts or
              other ideas or materials submitted will be treated as
              non-confidential and non-proprietary and may be used freely
              thereafter by Parker Swift for any purposes it deems appropriate
              including commercial gain. By submitting information to Parker
              Swift, you automatically grant, or warrant that the owner of such
              material has expressly granted Parker Swift a royalty-free,
              worldwide, perpetual, irrevocable, non-exclusive right and license
              to use, reproduce, modify, adapt, publish, display, translate and
              distribute such material (in whole or in part) and/or to
              incorporate it in other works in any form, media or technology now
              known or hereafter developed. You also agree to permit any other
              user of the Site to access, view, download or reproduce the
              material for any use permitted by the Site. You grant Parker Swift
              the right to edit, copy, display, publish and distribute any
              information or material made available on the Site by you.
            </li>
          </ul>
        </li>
        <li>
          <p>
            INDEMNIFICATION. You agree to indemnify and to hold Parker Swift,
            its subsidiaries, affiliates, business partners, officers, employers
            and other agents, harmless from and against any loss, liability,
            damage or expense (including reasonable attorneys fees) arising out
            of your use of the Site, including, without limitation, any
            violations by you in the terms and conditions for your use of the
            Site.
          </p>
        </li>
        <li>
          <p>
            VIRUSES. We will use commercially reasonable efforts to maintain the
            Site free of viruses and other harmful components. However, as the
            Site is accessible by third parties, we cannot assume any
            responsibility for any viruses or other harmful components that may
            affect your computer equipment or other property as a result of your
            access to, viewing of or downloading any Data from the Site. We do
            not warrant that the Site, its servers or any Data downloaded from
            the Site is free from viruses, bugs or other harmful components.
          </p>
        </li>
        <li>
          <p>
            MODIFICATIONS/TERMINATION. Without limiting its other remedies,
            Parker Swift may immediately discontinue, suspend, terminate or
            block your and any user's access to this Site at any time in its
            sole discretion. Parker Swift may amend or modify these terms and
            conditions at any time. Any such changes will become effective
            immediately upon the date they are first posted to this Site. You
            agree to be bound by these revised terms and conditions. It is your
            responsibility to return to this page from time to time to review
            the most current terms and conditions. Parker Swift does not assume
            any obligation to notify you of changes to these terms and
            conditions.
          </p>
        </li>
        <li>
          <p>
            GOVERNING LAW/JURISDICTION. These terms and conditions and your
            access to and use of information from the Site are governed by the
            laws of the State of Massachusetts. In the event of any dispute
            regarding the interpretation or compliance with these terms and
            conditions, you consent to the exclusive jurisdiction of the state
            and federal courts located in Suffolk County, Massachusetts.
          </p>
        </li>
        <li>
          <p>
            ENTIRE AGREEMENT. These terms and conditions of use for this website
            constitute the entire agreement between you and Parker Swift
            regarding your access to the Site and your reproduction and use of
            any information in the Site. Any waiver of any term or condition
            shall not be effective unless in a written document signed by an
            authorized representative of Weatherman.com.
          </p>
        </li>
      </ol>
      <hr />
      <h3>No Charge Subscriber Agreement</h3>
      <p>
        By clicking on "Yes - I agree", you will be subscribing for the
        Weatherman.com No Charge Email Weather Service. As a subscriber, you
        will receive from Weatherman.com updates of National Weather Service
        forecasts and weather alerts (the "Weather Updates"). By your
        subscribing for the Weather Updates (the "Subscription"), you agree to
        the USER TERMS AND CONDITIONS detailed below.
      </p>
      <h4>USER TERMS AND CONDITIONS</h4>
      <ol>
        <li>
          <p>
            PERMITTED USE. You may access, view and make copies of information
            in the Weather Updates only for your personal use. You may not
            publish or otherwise distribute any information in the Weather
            Updates for any purpose.
          </p>
        </li>
        <li>
          <p>
            OWNERSHIP/TRADEMARKS. The Weather Updates, and all information
            contained in the Weather Updates, is the property of Weatherman.com
            or its third party providers. All information in the Weather Updates
            is proprietary to Weatherman.com or its third party providers. You
            do not acquire or have any ownership or other proprietary interest
            in any information contained in the Weather Updates. You agree that
            information contained in the Weather Updates is protected by
            copyrights, trademarks, service marks, patents and other proprietary
            rights and laws.
          </p>
        </li>
        <li>
          <p>
            ACCURACY OF INFORMATION. All information in the Weather Updates is
            provided to you on an "as-is" basis. YOU ACKNOWLEDGE THAT REPORTS,
            FORECASTS AND OTHER INFORMATION REGARDING WEATHER AND CLIMATIC
            CONDITIONS ARE UNCERTAIN. Weatherman.com.COM DISCLAIMS ALL
            WARRANTIES REGARDING THE ACCURACY, COMPLETENESS, CURRENCY OR
            RELIABILITY OF ANY INFORMATION IN THE WEATHER UPDATES, INCLUDING,
            WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR OTHERWISE
            ARISING BY LAW OR STATUTE.
          </p>
        </li>
        <li>
          <p>
            TRANSMISSION FAILURES. Weatherman.com will use commercially
            reasonable efforts to publish and transmit the Weather Updates on a
            continuous and timely basis. Weatherman.com is not, however,
            responsible for any failure or malfunction in communications; for
            any failure or refusal of any third party transmission
            intermediaries to transmit any Weather Updates; or for the failure
            to transmit any Weather Updates resulting from any natural
            disasters, wars, labor strike or other events beyond the control of
            Weatherman.com.com. YOU ACKNOWLEDGE THAT, IN CIRCUMSTANCES INVOLVING
            NATURAL DISASTERS, FEDERAL, STATE AND MUNICIPAL GOVERNMENTAL
            AGENCIES WILL ISSUE OFFICIAL WEATHER WATCHES, WARNINGS, ADVISORIES,
            BULLETINS AND SIMILAR COMMUNICATIONS.
          </p>
        </li>
        <li>
          <p>
            LIMITATION OF LIABILITY. You acknowledge that, Weatherman.com cannot
            and does not assume any responsibility for, and shall not be liable
            for, any damages or expenses you may incur as a result of receiving
            the email such as pager charges, or as a result of any inaccuracy,
            incompleteness or obsolescence of any information contained in the
            Weather Updates. You agree that Weatherman.com.com will not be
            liable to you or to any other party for any direct or indirect
            damages, or for any special, exemplary, punitive, incidental,
            consequential or other damages (including, but not limited to, lost
            profits or lost time), whether based on contract, tort, strict
            liability or otherwise, which arise out of or are in any way
            connected with any access to the Weather Updates or any contractual
            or other dealings or relationships you may have with third parties
            based upon any information included in the Weather Updates.
          </p>
        </li>
        <li>
          <p>
            INDEMNIFICATION. You agree to indemnify and to hold Weatherman.com,
            its subsidiaries, affiliates, officers, employers and other agents,
            harmless from and against any loss, liability, damage or expense
            (including reasonable attorney fees) arising out of your use of the
            Weather Updates, including, without limitation, any violations by
            you in the terms and conditions for your use of the Weather Updates.
          </p>
        </li>
        <li>
          <p>
            GOVERNING LAW/JURISDICTION. This Subscription Agreement and your
            access to and use of information from the Weather Updates are
            governed by the laws of the State of Massachusetts. In the event of
            any dispute regarding the enforcement or interpretation of this
            Subscription Agreement or your compliance with these terms and
            conditions, you consent to the exclusive jurisdiction of the state
            and federal courts located in Suffolk County, Massachusetts.
          </p>
        </li>
        <li>
          <p>
            ENTIRE AGREEMENT. This Subscription Agreement constitutes the entire
            agreement between you and Weatherman.com regarding your access to
            the Weather Updates and your reproduction and use of any information
            in the Weather Updates.
          </p>
        </li>
      </ol>
      <br />
      <br />
    </div>
  );
};

export default TermsOfUsePage;

import React from "react";
import CountrySelector from "./CountrySelector";
import RegionSelector from "./RegionSelector";
import styles from "./CountryRegionSelector.module.scss";
import LocationTypeSelector from "./LocationTypeSelector";
import ContinentSelectorMobile from "./ContinentSelectorMobile";

const CountryRegionSelector: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <LocationTypeSelector />
      <ContinentSelectorMobile />
      <CountrySelector />
      <RegionSelector />
    </div>
  );
};

export default CountryRegionSelector;

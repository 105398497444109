import {
  Continent,
  Country,
  Region,
  Location,
  LocationType,
  ContinentKey,
} from "../api/placesApi";
import { WeatherType } from "../types/weatherType";
import { LocaleCode, getLocaleUrl, getDefaultWeatherPath } from "../i18n";
import { HOST_ROOT } from "../config";

export function getFullUrl(path: string): string {
  return typeof URL !== "undefined"
    ? new URL(path, HOST_ROOT).toString()
    : HOST_ROOT + path;
}

export function getHomeUrl(locale: LocaleCode) {
  return getLocaleUrl(locale, "/");
}

// export function getWidgetUrl(locale: LocaleCode) {
//   return getLocaleUrl(locale, "/widget");
// }

export function getSearchUrl(locale: LocaleCode) {
  return getLocaleUrl(locale, "/search");
}

export function getContanctUsUrl(locale: LocaleCode) {
  return getLocaleUrl(locale, "/contact");
}

export function getPrivacyPolicyUrl(locale: LocaleCode) {
  return getLocaleUrl(locale, "/privacy-policy");
}

export function getTosUrl(locale: LocaleCode) {
  return getLocaleUrl(locale, "/terms-of-use");
}

function getLocaleLocationUrl(
  locale: LocaleCode,
  locationType: LocationType,
  url: string
) {
  return getLocaleUrl(locale, locationType === "c" ? url : "/p" + url);
}

export function getWeatherUrl(locale: LocaleCode) {
  const path = getDefaultWeatherPath(locale);
  return getLocaleUrl(locale, path);
}

export function getPlacesUrl(locale: LocaleCode) {
  const path = getDefaultWeatherPath(locale);
  return getLocaleLocationUrl(locale, "p", path);
}

const continentDefaultUrl: { [key in ContinentKey]: string } = {
  // af: "botswana",
  // an: "antarctica",
  // as: "armenia",
  // eu: "aland-islands",
  // na: ["anguilla", "antigua-and-barbuda"],
  // sa: "argentina",
  // oc: "american-samoa",
  af: "/eg/alexandria",
  an: "/aq/antarctica",
  as: "/cn/anhui",
  eu: "/de/baden-wuerttemberg",
  na: "/us/alabama",
  sa: "/br/alagoas",
  oc: "/au/australian-capital-territory",
};

export function getContinentUrl(
  locale: LocaleCode,
  locationType: LocationType,
  continent: Continent | undefined
) {
  if (!continent) return getWeatherUrl(locale);
  //return getLocaleLocationUrl(locale, locationType, "/" + continent.longCode);
  const defaultUrl = continentDefaultUrl[continent._id];
  // if (Array.isArray(countryCode))
  //   countryCode = countryCode[locationType === "c" ? 0 : 1];
  return getLocaleLocationUrl(
    locale,
    locationType,
    `/${continent._id}${defaultUrl}`
  );
}

export function getCountryUrl(
  locale: LocaleCode,
  locationType: LocationType,
  continent: Continent,
  country: Country
) {
  return getLocaleLocationUrl(
    locale,
    locationType,
    `/${continent._id}/${country.longCode}`
  );
}

export function getRegionUrl(
  locale: LocaleCode,
  locationType: LocationType,
  continent: Continent,
  country: Country,
  region: Region
) {
  return getLocaleLocationUrl(
    locale,
    locationType,
    `/${continent._id}/${country._id}/${region.longCode}`
  );
}

export function getLocationSelectorUrl(
  locale: LocaleCode,
  locationType: LocationType,
  continent: Continent | undefined,
  country: Country | undefined,
  region: Region | undefined
) {
  if (region && country && continent)
    return getRegionUrl(locale, locationType, continent, country, region);
  if (country && continent)
    return getCountryUrl(locale, locationType, continent, country);
  if (continent) return getContinentUrl(locale, locationType, continent);
  return locationType === "p" ? getPlacesUrl(locale) : getWeatherUrl(locale);
}

export function getLocationWeatherUrl(
  locale: LocaleCode,
  continent: Continent | undefined,
  country: Country | undefined,
  region: Region | undefined,
  location: Location | undefined,
  weatherType: WeatherType = WeatherType.Today
) {
  if (!continent || !country || !region || !location) return "#";

  return getLocaleLocationUrl(
    locale,
    location.type,
    `/${continent._id}/${country._id}/${region.code}/${encodeUrlWeatherType(
      location.code,
      weatherType
    )}`
  );
}

const weatherTypeUrl: { [key in WeatherType]: string } = {
  [WeatherType.Today]: "-weather",
  [WeatherType.Tomorrow]: "-forecast",
  [WeatherType.Days10]: "-10-day-forecast",
  [WeatherType.Tides]: "-tides",
  [WeatherType.Radar]: "-radar",
};

const urlToWeatherType = Object.entries(weatherTypeUrl).map(([type, url]) => ({
  weatherType: +type as WeatherType,
  url,
}));
urlToWeatherType.sort((a, b) => b.url.length - a.url.length);

type CodeType = {
  code: string;
  weatherType: WeatherType | undefined;
};

export function getWeatherTypeFromUrl(codeType: string): CodeType {
  const found = urlToWeatherType.find(({ url }) => codeType.endsWith(url));
  if (!found) return { code: codeType, weatherType: undefined };
  const { weatherType, url } = found;
  return {
    weatherType,
    code: codeType.substring(0, codeType.length - url.length),
  };
}

function encodeUrlWeatherType(code: string, type: WeatherType) {
  return code + (weatherTypeUrl[type] || "-weather");
}

export const getAccountSettingsUrl = () => "/account-settings";
export const getAccountSettingsAlertsUrl = () =>
  "/account-settings#notifications";

import React from "react";
import { StationTides } from "../../../api/weatherApi";
import TideStationChart from "./TideStationChart";
import TideStationHighLow from "./TideStationHighLow";
import { LocaleMessage } from "../../../components/Locale";
import { capitalizeFirstIfAllCaps } from "../../../utils/string";

type TideStationCardProps = {
  data: StationTides;
};

const TideStationCard: React.FC<TideStationCardProps> = ({ data }) => {
  const { station } = data;

  return (
    <div className="section">
      <h3 className="title title-solo">
        <LocaleMessage
          id="TidePredictions"
          values={{ name: capitalizeFirstIfAllCaps(station) }}
        />
      </h3>
      <TideStationHighLow tides={data} />

      <TideStationChart data={data} />
      {/* <TideStationHourly data={data} /> */}
    </div>
  );
};

export default TideStationCard;

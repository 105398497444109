import React from "react";
import { FormattedDate, useIntl, IntlShape } from "react-intl";
import { capitalizeFirst } from "../../utils/string";

type LocaleDateProps = {
  value: Date;
};

export const LocaleDate: React.FC<LocaleDateProps> = ({ value }) => {
  return (
    <FormattedDate
      value={value}
      weekday="long"
      month="long"
      day="numeric"
      timeZone="utc"
    />
  );
};

export const LocaleDateOnly: React.FC<LocaleDateProps> = ({ value }) => {
  return (
    <FormattedDate value={value} month="long" day="numeric" timeZone="utc" />
  );
};

export function formatDayOfWeekShort(intl: IntlShape, value: Date) {
  return capitalizeFirst(
    intl.formatDate(value, { weekday: "short", timeZone: "utc" })
  );
}

export function formatDayOfWeek(intl: IntlShape, value: Date) {
  return capitalizeFirst(
    intl.formatDate(value, { weekday: "long", timeZone: "utc" })
  );
}

export const LocaleDayShort: React.FC<LocaleDateProps> = ({ value }) => {
  const intl = useIntl();
  return <>{formatDayOfWeekShort(intl, value)}</>;
};

export function formatMonth(intl: IntlShape, month: number) {
  const monthDate = new Date(2000, month);
  return capitalizeFirst(intl.formatDate(monthDate, { month: "long" }));
}

export const LocaleMonth: React.FC<{ month: number }> = ({ month }) => {
  const intl = useIntl();
  return <>{formatMonth(intl, month)}</>;
};

const enMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatMonthShort(intl: IntlShape, month: number) {
  if (intl.locale === "en")
    return month >= 0 && month < 12 ? enMonths[month] : "";
  const monthDate = new Date(2000, month);
  return intl.formatDate(monthDate, { month: "short" });
}

export const LocaleDateShort: React.FC<LocaleDateProps> = ({ value }) => {
  const intl = useIntl();
  return (
    <>
      {intl
        .formatDate(value, { day: "numeric", month: "short", timeZone: "utc" })
        .toUpperCase()}
    </>
  );
};

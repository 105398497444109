import React from "react";
import WeatherSelectorTitle from "../WeatherSelectorTitle";
import { LocaleMessage } from "../../../components/Locale";
import { useSelector } from "../../../store/hooks";
import {
  getSelectedCountry,
  getSelectedRegion,
  getSelectedLocationType,
  getSelectedContinent,
} from "../../../store/selectors/locationSelector";
import { getLocationSelectorUrl } from "../../../utils/url";
import { useLocale } from "../../../i18n";
import { Link } from "react-router-dom";
import styles from "./CountryRegionSelector.module.scss";

const LocationTypeSelector: React.FC = () => {
  const locale = useLocale();
  const locationType = useSelector(getSelectedLocationType);
  const continent = useSelector(getSelectedContinent);
  const country = useSelector(getSelectedCountry);
  const region = useSelector(getSelectedRegion);

  return (
    <div className="section">
      <WeatherSelectorTitle>
        <LocaleMessage id="WeatherSelectionLocationType" />
      </WeatherSelectorTitle>
      <div className={styles.locationTypeWrap}>
        <Link
          className={`${styles.locationType} ${
            locationType === "c" ? styles.active : ""
          }`}
          to={{
            pathname: getLocationSelectorUrl(
              locale,
              "c",
              continent,
              country,
              region
            ),
            state: {
              softRedirect: true,
            },
          }}
        >
          <LocaleMessage id="WeatherSelectionCities" />
        </Link>
        <Link
          className={`${styles.locationType} ${
            locationType === "p" ? styles.active : ""
          }`}
          to={{
            pathname: getLocationSelectorUrl(
              locale,
              "p",
              continent,
              country,
              region
            ),
            state: {
              softRedirect: true,
            },
          }}
        >
          <LocaleMessage id="WeatherSelectionPlaces" />
        </Link>
      </div>
    </div>
  );
};

export default LocationTypeSelector;

import {
  RecentActionTypes,
  RECENT_ADD,
  RECENT_RESTORE,
  RECENT_CLEAR,
} from "../types/recent";

type RecentState = {
  locationIds: string[];
  restored: boolean;
};

const recentCount = 5;

const initialState: RecentState = {
  locationIds: [],
  restored: false,
};

export default function recentReducer(
  state: RecentState = initialState,
  action: RecentActionTypes
): RecentState {
  switch (action.type) {
    case RECENT_ADD:
      const { locationId } = action;
      if (state.locationIds.length && state[0] === locationId) return state;
      return {
        ...state,
        locationIds: [
          locationId,
          ...state.locationIds
            .filter((x) => locationId !== x)
            .slice(0, recentCount - 1),
        ],
      };
    case RECENT_RESTORE:
      const { locationIds } = action;
      return {
        ...state,
        restored: true,
        locationIds: [
          //...state.locationIds.filter(x => !locationIds.includes(x)),
          ...locationIds,
        ].slice(0, recentCount),
      };
    case RECENT_CLEAR:
      return { ...state, locationIds: [] };
    default:
      return state;
  }
}

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import {
  NumberRange,
  RangeFields,
  Radio,
  Switch,
  // TimePicker,
} from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import {
  ClassificationInfo,
  ClassificationPopupBody,
  classificationWind,
} from "./ClassificationInfo";
// import { convertMsToTimeArr, convertTimeToMS } from "../../../utils/date";
import {
  convertWindSpeed,
  convertWindSpeedToMS,
  formatWindSpeed,
  UnitKey,
} from "../../../types/units";
import {
  CardSection,
  // CardSectionTitle
} from "../Card/Card";

type AlertsWindProps = {
  alerts: UserWeatherAlerts;
  units: UnitKey;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsWind: React.FC<AlertsWindProps> = ({
  alerts,
  updateAlerts,
  units,
}) => {
  const handleWindChange = (wind: boolean) => {
    if (wind) {
      updateAlerts({
        wind,
        windSelector: "strong wind",
        windRange: [10.8, undefined],
        desktop: true,
      });
    } else {
      updateAlerts({ wind, desktop: true });
    }
  };

  const handleWindRangeChange = (range: NumberRange) => {
    const from = range.from
      ? convertWindSpeedToMS(range.from, units)
      : range.from;
    const to = range.to ? convertWindSpeedToMS(range.to, units) : range.to;
    updateAlerts({ windRange: [from, to] });
  };

  const handleWindSelectorChnage = (label: string) => {
    if (label.includes("Notify me about strong breeze and higher")) {
      updateAlerts({
        windSelector: "strong wind",
        windRange: [10.8, undefined],
      });
      return;
    }
    if (label.includes("Notify me about strong gale and higher")) {
      updateAlerts({ windSelector: "windstorm", windRange: [20.8, undefined] });
      return;
    }
    updateAlerts({ windSelector: "custom" });
  };

  // const handleWindTimeChange = (time: string) => {
  //   updateAlerts({ windTimeMs: convertTimeToMS(time) });
  // };

  return (
    <>
      <CardSection>
        <Switch
          label="Wind"
          checked={!!alerts.wind}
          onChange={handleWindChange}
        />
        <ClassificationInfo
          popupBody={<ClassificationPopupBody {...classificationWind} />}
        />
      </CardSection>
      {!!alerts.wind && (
        <>
          <CardSection
            className={`${styles.sectionColumn} ${styles.mobileSection}`}
          >
            <div className={styles.radioDays}>
              <Radio
                checked={alerts.windSelector === "strong wind"}
                name="windAlerts"
                label={`Notify me about strong breeze and higher (> ${formatWindSpeed(
                  10.8,
                  units
                )}).`}
                onChange={handleWindSelectorChnage}
              />
              <Radio
                checked={alerts.windSelector === "windstorm"}
                name="windAlerts"
                label={`Notify me about strong gale and higher (> ${formatWindSpeed(
                  20.8,
                  units
                )}).`}
                onChange={handleWindSelectorChnage}
              />
              <Radio
                checked={alerts.windSelector === "custom"}
                name="windAlerts"
                label={`Custom parameters (${
                  units === "C_ms" ? "m/s" : "mph"
                }):`}
                onChange={handleWindSelectorChnage}
              />
            </div>
            <RangeFields
              disabled={alerts.windSelector !== "custom"}
              from={
                alerts.windRange &&
                alerts.windRange[0] &&
                convertWindSpeed(alerts.windRange[0], units)
              }
              to={
                alerts.windRange &&
                alerts.windRange[1] &&
                convertWindSpeed(alerts.windRange[1], units)
              }
              onChange={handleWindRangeChange}
              min={0}
              max={units === "C_ms" ? 110 : 250}
            />
          </CardSection>

          {/* <CardSection className={styles.timePickerSection}>
            <CardSectionTitle title="Notification delivery time" />
            <div className={styles.timePickerWrap}>
              <TimePicker
                value={
                  alerts.windTimeMs
                    ? convertMsToTimeArr(alerts.windTimeMs)
                    : [4, 0]
                }
                format="hh:mm"
                format12Hours={false}
                onChange={handleWindTimeChange}
              />
              <p className={styles.timePickerInfo}>
                hours before the occurances
              </p>
            </div>
          </CardSection> */}
        </>
      )}
      <HSplit />
    </>
  );
};

export default AlertsWind;

import React, { useEffect } from "react";
import {
  getHomeLocationId,
  getHomeLocation,
} from "../../../store/selectors/locations";
import { useSelector, useDispatch } from "../../../store/hooks";
import WeatherCondition from "../../../components/WeatherCondition";
import { getUnits } from "../../../store/selectors/units";
import styles from "./HomeWidget.module.scss";
import { autoRefreshWeather } from "../../../utils/cache";
import { formatTempShort } from "../../../types/units";
import { Link } from "react-router-dom";
import { weatherNavigateToHomeLocation } from "../../../store/actions/weather";
import { LocaleMessage } from "../../../components/Locale";
import { tryLoadHomeLocation } from "../../../store/actions/locations";
import { loadHomeShort } from "../../../store/actions/homeShort";
import { getHomeForecastShort } from "../../../store/selectors/homeShort";

const HomeWidget: React.FC = () => {
  const dispatch = useDispatch();
  const units = useSelector(getUnits);
  const locationId = useSelector(getHomeLocationId);
  const location = useSelector(getHomeLocation);
  const weather = useSelector(getHomeForecastShort);

  // load home location @ client-side
  useEffect(() => {
    dispatch(tryLoadHomeLocation());
  }, [dispatch]);

  // load weather
  useEffect(() => {
    if (!locationId) return;
    return autoRefreshWeather(() => dispatch(loadHomeShort(locationId)));
  }, [dispatch, locationId]);

  return (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        dispatch(weatherNavigateToHomeLocation());
      }}
    >
      <span className={styles.today}>
        <LocaleMessage id="TodaysWeather" />
      </span>
      <div className={styles.wrap}>
        <div className={styles.name}>
          {location ? location.name : <LocaleMessage id="TodaysWeather" />}
        </div>
        {weather && (
          <>
            <WeatherCondition
              weatherCode={weather.weatherCode}
              day
              weatherDesc=""
              className={styles.condition}
            />
            <div className={styles.temp}>
              {formatTempShort(weather.tempMax, units)}

              <span className={styles.tempMin}>
                {" "}
                / {formatTempShort(weather.tempMin, units)}
              </span>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default HomeWidget;

import { DailyForecastsShort } from "../../api/weatherApi";

export const DAILY_SHORT_LOADED = "DAILY_SHORT_LOADED";

type DailyShortLoadedAction = {
  type: typeof DAILY_SHORT_LOADED;
  forecasts: DailyForecastsShort;
};

export type DailyShortActionTypes = DailyShortLoadedAction;

export const popularCityIds = [
  "1609350", // "Bangkok",
  "2643743", // "London",
  "2988507", // "Paris",
  "292223", // "Dubai",
  "1880252", // "Singapore",
  "5128581", // "New York",
  "1735161", // "Kuala Lumpur",
  "1850147" // "Tokyo"
];

import {
  NotificationsActionTypes,
  NOTIFICATIONS_POPUP_DATE_UPDATE,
  NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE,
} from "../types/notifications";

type notificationsState = {
  notificationsPopupDate?: number;
  notificationsPopupLocationId?: string;
};

const initialState: notificationsState = {
  notificationsPopupDate: undefined,
  notificationsPopupLocationId: undefined,
};

export default function notificationsReducer(
  state: notificationsState = initialState,
  action: NotificationsActionTypes
): notificationsState {
  switch (action.type) {
    case NOTIFICATIONS_POPUP_DATE_UPDATE:
      return {
        ...state,
        notificationsPopupDate: action.notificationsPopupDate,
      };
    case NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE:
      return {
        ...state,
        notificationsPopupLocationId: action.notificationsPopupLocationId,
      };

    default:
      return state;
  }
}

import React, { useRef } from "react";
import styles from "./DaysWeatherHead.module.scss";
import WeatherTypeSelector from "../WeatherTypeSelector";
import { useScrollRefTop } from "../../../utils/hooks";
import WeatherBreadcrumb from "../WeatherBreadcrumb";
import PlaceTitle from "../../../components/PlaceTitle";
import DaysPageButtons from "../DaysPageButtons";
import ShortDaysForecast from "../ShortDaysForecast";
import DaysForecastHorizontal from "../DaysForecastHorizontal";
import { LocaleMessage } from "../../../components/Locale";

type DaysWeatherHeadProps = {
  offset: number;
  seeDetailed: () => void;
  setOffset: (x: number) => void;
};

const DaysWeatherHead: React.FC<DaysWeatherHeadProps> = ({
  offset,
  setOffset,
  seeDetailed
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  useScrollRefTop(titleRef, [offset]);

  return (
    <div className={styles.wrap}>
      <div className="container">
        <WeatherTypeSelector dark />
        <WeatherBreadcrumb dark />
        <div ref={titleRef} className={styles.titleButtons}>
          <PlaceTitle dark />
          <div className="d-none d-md-block">
            <DaysPageButtons offset={offset} setOffset={setOffset} />
          </div>
        </div>
        <div className="d-none d-xl-block">
          <ShortDaysForecast offset={offset} />
        </div>
        <div className="d-block d-xl-none">
          <DaysForecastHorizontal offset={offset} />
        </div>
        <div className={`${styles.bottomPageButtons} d-md-none`}>
          <DaysPageButtons offset={offset} setOffset={setOffset} />
        </div>
        <button
          className={`${styles.seeDetailed} d-none d-xl-block`}
          onClick={() => seeDetailed()}
        >
          <LocaleMessage id="SeeDetailedForecast10" />
        </button>
      </div>
    </div>
  );
};

export default DaysWeatherHead;

import * as React from "react";
import styles from "./NotFound.module.scss";
import { LocaleMessage } from "../../components/Locale";
import { RouteComponentProps } from "react-router-dom";
import { setCacheControlStatic } from "../../utils/cache";

export const NotFound: React.FC<RouteComponentProps<any>> = (props) => {
  if (props.staticContext) {
    props.staticContext.statusCode = 404;
    setCacheControlStatic(props);
  }

  return (
    <div className="container">
      <h1 className={styles.notFound}>
        <LocaleMessage id="PageNotFound" />
      </h1>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./DayWeatherHead.module.scss";
import {
  DailyForecast,
  HourlyForecast,
  CurrentWeather,
} from "../../../api/weatherApi";
import { useSelector } from "react-redux";
import DayHeadSummary from "../DayHeadSummary";
import WeatherDescription from "../WeatherDescription";
import ShortHourlyForecast from "../ShortHourlyForecast";
import { getDefaultDailyForecast } from "../../../store/selectors/weather";
import NextHoursForecastChart from "../../../components/NextHoursForecastChart";
import DayBackground from "../../../components/DayBackground";
import WeatherHead from "../WeatherHead";

export type SelectedDayWeather =
  | DailyForecast
  | HourlyForecast
  | CurrentWeather;

export type SelectedDayWeatherKey = {
  forecast: SelectedDayWeather;
  time?: Date;
};

export type SelectDayWeather = (
  forecast: SelectedDayWeather,
  time?: Date,
  unselect?: boolean
) => void;

const DayWeatherHeadDynamic: React.FC = () => {
  const defaultForecast = useSelector(getDefaultDailyForecast);

  const [selectedWeather, setSelectedWeatherState] = useState<
    SelectedDayWeatherKey | undefined
  >(defaultForecast ? { forecast: defaultForecast } : undefined);

  const selectedWeatherRef = useRef<SelectedDayWeatherKey | undefined>(
    selectedWeather
  );

  const setSelectedWeather = useCallback(
    (v: SelectedDayWeatherKey | undefined) => {
      setSelectedWeatherState(v);
      selectedWeatherRef.current = v;
    },
    [setSelectedWeatherState]
  );

  useEffect(() => {
    if (defaultForecast) {
      setSelectedWeather({ forecast: defaultForecast });
    } else {
      setSelectedWeather(undefined);
    }
  }, [defaultForecast, setSelectedWeather]);

  const select: SelectDayWeather = (forecast, time, unselect) => {
    if (unselect) {
      const current = selectedWeatherRef.current;
      //console.log("unselectR", day, time);
      if (!current) return;
      if (current.forecast === forecast && current.time === time) {
        // console.log("unselect", (forecast as any).time, time);
        // console.log(
        //   "unselect.was",
        //   (selectedWeather.forecast as any).time,
        //   selectedWeather.time
        // );
        if (defaultForecast) setSelectedWeather({ forecast: defaultForecast });
      }
      return;
    }
    //console.log("select", (forecast as any).time, time);
    setSelectedWeather({ forecast, time });
  };

  //if (!selectedWeather) return null;
  const { time, forecast } = selectedWeather || {};

  const backgroundTime =
    time || (forecast ? (forecast as any).time : undefined);

  return (
    <>
      <DayBackground className={styles.background} time={backgroundTime} />
      <div className={styles.content}>
        <WeatherHead forecast={forecast} time={time} />
        <ShortHourlyForecast
          selected={selectedWeather}
          select={select}
          className={styles.shortHourlyForecast}
        />

        <div className="container">
          <DayHeadSummary className={styles.summary} weather={forecast} />
          <WeatherDescription weather={forecast} />
        </div>
      </div>
    </>
  );
};

const DayWeatherHead: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <DayWeatherHeadDynamic />
      <div className={`container d-md-none my-40 ${styles.chart}`}>
        <NextHoursForecastChart />
      </div>
    </div>
  );
};

export default DayWeatherHead;

import {
  ERRORS_ADD,
  ErrorsActionTypes,
  ERRORS_REMOVE,
  ErrorMsg
} from "../types/errors";

export function errorsAdd(message: string): ErrorsActionTypes {
  return { type: ERRORS_ADD, error: { message, timestamp: new Date() } };
}

export function errorsRemove(error: ErrorMsg): ErrorsActionTypes {
  return { type: ERRORS_REMOVE, error };
}

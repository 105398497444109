import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";
// @ts-ignore
import { syncHistoryWithStore } from "react-router-redux";
import { createBrowserHistory } from "history";

import configureStore, { RootState } from "./store";
import "./styles/index.scss";
import "react-dropdown/style.css";

import App from "./scenes/App";
import { normalizeState } from "./store/normalizeState";
import { linkAnalyticsToHistory } from "./utils/google-analytics";
import { registerServiceWorker } from "./serviceWorker";

// If provided by server, use it, else let the reducers handle initial state
// tslint:disable-next-line:no-any
const windowData = (window as any).DATA;

const initialState: Partial<RootState> = windowData
  ? normalizeState(windowData)
  : {};

const browserHistory = createBrowserHistory();

linkAnalyticsToHistory(browserHistory);

const store = configureStore(browserHistory, initialState);

const history = syncHistoryWithStore(browserHistory, store);

const renderMethod = windowData ? ReactDOM.hydrate : ReactDOM.render;

renderMethod(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root") as HTMLElement
);

registerServiceWorker();

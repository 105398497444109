import React, { useEffect } from "react";
import { Location, Country, Region } from "../../api/placesApi";
import { useSelector, useDispatch } from "../../store/hooks";
import {
  getRecentLocationIds,
  getRecentCities,
} from "../../store/selectors/recent";
import { tryLoadLocationsByIds } from "../../store/actions/locations";
import {
  getGeoipNearestCities,
  getLocationRegion,
  getLocationsCountries,
} from "../../store/selectors/locations";
import styles from "./SearchList.module.scss";
import { getRegionCountry } from "../../store/selectors/regions";
import { getCountryContinent } from "../../store/selectors/countries";
import {
  getLocationWeatherUrl,
  getCountryUrl,
  getRegionUrl,
  getWeatherUrl,
} from "../../utils/url";
import { Link as RouterLink } from "react-router-dom";
// import { recentLocationsClear } from "../../store/actions/recent";
import {
  getSearchLocations,
  getSearchQuery,
  getSearchRegions,
  getSearchCountries,
  getSearchLoading,
} from "../../store/selectors/search";
import worldImg from "./world.svg";
import { LocaleCode, useLocale } from "../../i18n";
import CountryFlag from "../CountryFlag";
import { LocaleMessage } from "../Locale";

type OnLinkClickedProps = {
  onLinkClicked: () => void;
};

type LinkProps = {
  to: string;
} & OnLinkClickedProps;

const Link: React.FC<LinkProps> = ({ children, to, onLinkClicked }) => {
  return (
    <RouterLink onClick={onLinkClicked} to={to} className={styles.link}>
      {children}
    </RouterLink>
  );
};

type LocationListItemProps = {
  location: Location;
  locale: LocaleCode;
} & OnLinkClickedProps;

const LocationListItem: React.FC<LocationListItemProps> = ({
  location,
  locale,
  onLinkClicked,
}) => {
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));

  if (!continent || !country || !region) return null;

  return (
    <Link
      onLinkClicked={onLinkClicked}
      to={getLocationWeatherUrl(locale, continent, country, region, location)}
    >
      <span className={styles.flag}>
        <CountryFlag countryCode={country._id} />
      </span>
      {location.name}
      {"  "}
      <span className={styles.regionCountry}>
        {region.name}, {country.name}
      </span>
    </Link>
  );
};

type LocationListProps = {
  locations: Location[];
  locale: LocaleCode;
} & OnLinkClickedProps;

const LocationList: React.FC<LocationListProps> = ({
  locations: cities,
  locale,
  onLinkClicked,
}) => {
  return (
    <div>
      {cities.map((x) => (
        <LocationListItem
          key={x._id}
          locale={locale}
          location={x}
          onLinkClicked={onLinkClicked}
        />
      ))}
    </div>
  );
};

type RegionListItemProps = {
  region: Region;
  locale: LocaleCode;
} & OnLinkClickedProps;

const RegionListItem: React.FC<RegionListItemProps> = ({
  locale,
  region,
  onLinkClicked,
}) => {
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));

  if (!continent || !country) return null;

  return (
    <Link
      to={getRegionUrl(locale, "c", continent, country, region)}
      onLinkClicked={onLinkClicked}
    >
      <span className={styles.flag}>
        <CountryFlag countryCode={country._id} />
      </span>
      {region.name}
    </Link>
  );
};

type RegionListProps = {
  regions: Region[];
  locale: LocaleCode;
} & OnLinkClickedProps;

const RegionList: React.FC<RegionListProps> = ({
  locale,
  regions,
  onLinkClicked,
}) => {
  return (
    <div>
      <div className={styles.head}>
        <LocaleMessage id="SearchRegions" />
      </div>
      {regions.map((x) => (
        <RegionListItem
          key={x._id}
          locale={locale}
          region={x}
          onLinkClicked={onLinkClicked}
        />
      ))}
    </div>
  );
};

type CountryListItemProps = {
  country: Country;
  locale: LocaleCode;
} & OnLinkClickedProps;

const CountryListItem: React.FC<CountryListItemProps> = ({
  locale,
  country,
  onLinkClicked,
}) => {
  const continent = useSelector((x) => getCountryContinent(x, country));

  if (!continent) return null;

  return (
    <Link
      to={getCountryUrl(locale, "c", continent, country)}
      onLinkClicked={onLinkClicked}
    >
      <span className={styles.flag}>
        <CountryFlag countryCode={country._id} />
      </span>
      {country.name}
    </Link>
  );
};

type CountryListProps = {
  countries: Country[];
  locale: LocaleCode;
} & OnLinkClickedProps;

const CountryList: React.FC<CountryListProps> = ({
  locale,
  countries,
  onLinkClicked,
}) => {
  return (
    <div>
      <div className={styles.head}>
        <LocaleMessage id="SearchCountries" />
      </div>
      {countries.map((x) => (
        <CountryListItem
          key={x._id}
          locale={locale}
          country={x}
          onLinkClicked={onLinkClicked}
        />
      ))}
      <Link to={getWeatherUrl(locale)} onLinkClicked={onLinkClicked}>
        <img src={worldImg} alt="World" className={styles.flag} />
        World
      </Link>
    </div>
  );
};

type SearchListProps = OnLinkClickedProps & { mobile?: boolean };

const SearchList: React.FC<SearchListProps> = ({ mobile, onLinkClicked }) => {
  const dispatch = useDispatch();
  const locale = useLocale();

  const recentIds = useSelector(getRecentLocationIds);
  useEffect(() => {
    //console.log("tryLoad");
    dispatch(tryLoadLocationsByIds(recentIds));
  }, [dispatch, recentIds]);

  const recentCities = useSelector(getRecentCities);
  const geoip = useSelector(getGeoipNearestCities).slice(0, 3);
  const defaultCountries = useSelector((x) =>
    getLocationsCountries(x, [...recentCities, ...geoip])
  );

  const hasSearchQuery = !!useSelector(getSearchQuery);
  const searchLoading = useSelector(getSearchLoading);
  const searchLocations = useSelector(getSearchLocations);
  const searchRegions = useSelector(getSearchRegions);
  const searchCountries = useSelector(getSearchCountries);
  const noMatch =
    !searchLocations.length && !searchRegions.length && !searchCountries.length;
  const hasSearch = hasSearchQuery; // && (!searchLoading || !noMatch);
  const searchCities = searchLocations.filter((x) => x.type === "c");
  const searchPlaces = searchLocations.filter((x) => x.type === "p");

  const props = { onLinkClicked, locale };
  return (
    <div className={mobile ? styles.mobile : undefined}>
      {!hasSearch ? (
        <>
          {!!recentCities.length && (
            <>
              <div className={styles.recentHead}>
                <LocaleMessage id="SearchRecent" />
                {/* <button
                  className={styles.clearButton}
                  onClick={() => dispatch(recentLocationsClear())}
                >
                  <LocaleMessage id="SearchClear" />
                </button> */}
              </div>
              <LocationList locations={recentCities} {...props} />
            </>
          )}
          {!!geoip.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchNearestByIp" />
              </div>
              <LocationList locations={geoip} {...props} />
            </>
          )}
          {!!defaultCountries.length && (
            <CountryList countries={defaultCountries} {...props} />
          )}
        </>
      ) : (
        <>
          {!!searchCities.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchCities" />
              </div>
              <LocationList locations={searchCities} {...props} />
            </>
          )}
          {!!searchPlaces.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchPlaces" />
              </div>
              <LocationList locations={searchPlaces} {...props} />
            </>
          )}
          {!!searchRegions.length && (
            <RegionList regions={searchRegions} {...props} />
          )}
          {!!searchCountries.length && (
            <CountryList countries={searchCountries} {...props} />
          )}
          {!!noMatch && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchResults" />
              </div>
              <div className={styles.noResults}>
                {searchLoading ? (
                  <LocaleMessage id="SearchLoading" />
                ) : (
                  <LocaleMessage id="SearchNoMatch" />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchList;

import { LocaleCode } from "../../i18n";

export const LOCALE_SET = "LOCALE_SET";

type LocaleSetAction = {
  type: typeof LOCALE_SET;
  locale: LocaleCode;
};

export type LocaleActionTypes = LocaleSetAction;

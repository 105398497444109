import { Location, LocationType } from "../../api/placesApi";

export const LOCATIONS_LOADED = "LOCATIONS_LOADED";

export const LOCATIONS_BY_REGION_LOADING = "LOCATIONS_BY_REGION_LOADING";
export const LOCATIONS_BY_REGION_LOADED = "LOCATIONS_BY_REGION_LOADED";

export const LOCATIONS_GEOIP_SET = "LOCATIONS_GEOIP_SET";
export const LOCATIONS_HOME_SET = "LOCATIONS_HOME_SET";
export const LOCATIONS_HOME_GEOLOCATED = "LOCATIONS_HOME_GEOLOCATED";
export const LOCATIONS_NEARBY_LOADED = "LOCATIONS_NEARBY_LOADED";

type LocationsByRegionLoadingAction = {
  type: typeof LOCATIONS_BY_REGION_LOADING;
  regionId: string;
  locationType: LocationType;
};

type LocationsByRegionLoadedAction = {
  type: typeof LOCATIONS_BY_REGION_LOADED;
  regionId: string;
  locationType: LocationType;
  locationIds: string[];
};

type LocationsLoadedAction = {
  type: typeof LOCATIONS_LOADED;
  locations: Location[];
};

type LocationsNearbyLoadedAction = {
  type: typeof LOCATIONS_NEARBY_LOADED;
  locationId: string;
  locationType: LocationType;
  locations: Location[];
};

type LocationsHomeSetAction = {
  type: typeof LOCATIONS_HOME_SET;
  locationId: string;
};

type LocationsHomeGeolocatedAction = {
  type: typeof LOCATIONS_HOME_GEOLOCATED;
  locationId: string | undefined;
};

type LocationsGeoipSetAction = {
  type: typeof LOCATIONS_GEOIP_SET;
  locationId: string;
  countryId: string;
};

export type LocationsActionTypes =
  | LocationsByRegionLoadingAction
  | LocationsByRegionLoadedAction
  | LocationsLoadedAction
  | LocationsHomeSetAction
  | LocationsHomeGeolocatedAction
  | LocationsGeoipSetAction
  | LocationsNearbyLoadedAction;

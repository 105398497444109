import { DailyShortActionTypes, DAILY_SHORT_LOADED } from "../types/dailyShort";
import { DailyForecastsShort } from "../../api/weatherApi";

type DailyShortState = DailyForecastsShort;

const initialState: DailyShortState = {};

export default function reducer(
  state: DailyShortState = initialState,
  action: DailyShortActionTypes
): DailyShortState {
  switch (action.type) {
    case DAILY_SHORT_LOADED: {
      return { ...state, ...action.forecasts };
    }

    default:
      return state;
  }
}

import React from "react";
import styles from "./ExtendedForecast.module.scss";
import DayForecastShort from "../DayForecastShort";
import { useSelector } from "../../../store/hooks";
import {
  getSelectedDailyForecasts,
  getSelectedDailyForecast,
  getWeatherType,
} from "../../../store/selectors/weather";
import DailyForecastHorizontal from "../DailyForecastHorizontal";
import LocationName from "../../../components/LocationName";
import { LocaleMessage } from "../../../components/Locale";
import { useFormatMessage } from "../../../i18n";
import { isTodayWeatherType } from "../../../types/weatherType";

const ExtendedForecast: React.FC = () => {
  const formatMessage = useFormatMessage();
  const dailyForecasts = useSelector(getSelectedDailyForecasts);
  const dailyForecast = useSelector(getSelectedDailyForecast);
  const weatherType = useSelector(getWeatherType);

  if (!dailyForecasts.length) return null;

  const days = dailyForecasts.slice(0, 7);

  return (
    <div className="container section">
      <h3 className="title title-solo">
        <LocaleMessage
          id={
            isTodayWeatherType(weatherType)
              ? "ExtendedForecastTitleToday"
              : "ExtendedForecastTitle"
          }
          values={{ name: <LocationName /> }}
        />
      </h3>
      <div className={styles.wrap}>
        {days.map((x, i) => (
          <DayForecastShort
            className={styles.card}
            key={x.date.getTime()}
            day={x}
            title={i === 0 ? formatMessage("Today") : undefined}
          />
        ))}
      </div>
      <div className="d-lg-none">
        <DailyForecastHorizontal
          key={dailyForecast ? dailyForecast.date.getTime() : 0}
          days={days}
          today={days[0]}
          expanded={dailyForecast}
        />
      </div>
    </div>
  );
};

export default ExtendedForecast;

import React from "react";
import { getWeatherTypeDaysCount } from "../../../types/weatherType";
import styles from "./ShortDaysForecast.module.scss";
import DayForecastShort from "../DayForecastShort";
import { useSelector } from "../../../store/hooks";
import {
  getWeatherType,
  getSelectedDailyForecasts
} from "../../../store/selectors/weather";
import { useFormatMessage } from "../../../i18n";

type ShortDaysForecastProps = {
  offset: number;
};

const ShortDaysForecast: React.FC<ShortDaysForecastProps> = ({ offset }) => {
  const formatMessage = useFormatMessage();
  const daily = useSelector(getSelectedDailyForecasts);
  const weatherType = useSelector(getWeatherType);
  const days = daily.slice(
    offset,
    offset + getWeatherTypeDaysCount(weatherType)
  );
  const today = daily[0];

  return (
    <div className={styles.wrap}>
      {days.map(x => {
        return (
          <DayForecastShort
            className={styles.card}
            small={true}
            key={x.date.getTime()}
            day={x}
            title={x === today ? formatMessage("Today") : undefined}
          />
        );
      })}
    </div>
  );
};

export default ShortDaysForecast;

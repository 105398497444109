export function isMobile() {
  if (typeof navigator === "undefined") return false;
  //return true;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function isServer() {
  return typeof window === "undefined";
}

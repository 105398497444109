import React, { useState, useRef, useCallback } from "react";
import DaysWeatherHead from "./DaysWeatherHead";
import DetailedDailyForecast from "./DetailedDailyForecast";
import WeatherPageTitle from "./WeatherPageTitle";

const DaysWeatherView: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const detailedForecast = useRef<HTMLDivElement>(null);
  const seeDetailed = useCallback(() => {
    if (detailedForecast.current)
      detailedForecast.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div>
      <WeatherPageTitle />
      <DaysWeatherHead
        offset={offset}
        setOffset={setOffset}
        seeDetailed={seeDetailed}
      />
      <DetailedDailyForecast ref={detailedForecast} offset={offset} />
    </div>
  );
};

export default DaysWeatherView;

import React from "react";
import styles from "./DesktopHeader.module.scss";
import UnitSelector from "./UnitSelector";
// import LanguageSelector from "./LanguageSelector";
import AuthState from "./AuthState";
import { Link } from "react-router-dom";
import Search from "./Search";
import logoImg from "../assets/Logo_horizontal.svg";
import { LocaleMessage } from "../../Locale";
import { useLocale } from "../../../i18n";
import {
  getHomeUrl,
  getWeatherUrl,
  //getWidgetUrl
} from "../../../utils/url";
import HomeWidget from "./HomeWidget";

const DesktopHeader: React.FC = () => {
  const locale = useLocale();

  return (
    <div className="d-none d-lg-block">
      <div className={styles.topHeaderWrap}>
        <div className={`container ${styles.topHeader}`}>
          <UnitSelector className="mr-20" />
          {/* <LanguageSelector /> */}
          <AuthState />
        </div>
      </div>
      <div className={styles.headerWrap}>
        <div className={`container ${styles.header}`}>
          <div className={styles.brand}>
            <Link to={getHomeUrl(locale)}>
              <img src={logoImg} alt="WeatherMan" />
            </Link>
          </div>
          <div className={styles.menu}>
            <HomeWidget />
            <Link to={getWeatherUrl(locale)}>
              <LocaleMessage id="WorldWeather" />
            </Link>
            {/* <Link to={getWidgetUrl(locale)}>
              <LocaleMessage id="WeatherWidget" />
            </Link> */}
          </div>

          <Search />
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;

import React from "react";
import styles from "./PlaceTitle.module.scss";
import FavoriteButton from "../FavoriteButton";
import ShareButton from "../ShareButton";
import { useSelector } from "../../store/hooks";
import {
  getWeatherLocation,
  getWeatherSelectorError,
  getWeatherLocationNameWithShortRegion,
  getWeatherType,
} from "../../store/selectors/weather";
import { useFormatMessage, MessageKey } from "../../i18n";
import { WeatherType } from "../../types/weatherType";

type PlaceTitleProps = {
  dark?: boolean;
};

const PlaceTitle: React.FC<PlaceTitleProps> = ({ dark }) => {
  const location = useSelector(getWeatherLocation);
  const weatherSelectorError = useSelector(getWeatherSelectorError);
  const formatMessage = useFormatMessage();
  const name = useSelector(getWeatherLocationNameWithShortRegion);
  const weatherType = useSelector(getWeatherType);

  const title = formatMessage(
    ("WeatherTitle" + WeatherType[weatherType]) as MessageKey,
    {
      name,
    }
  );

  return (
    <div>
      <h1 className={`${styles.name} ${dark ? styles.dark : ""} mr-20`}>
        {location
          ? location.name
          : weatherSelectorError
          ? "Error"
          : "Loading..."}
      </h1>
      <div
        className={styles.buttons}
        style={!location ? { visibility: "hidden" } : undefined}
      >
        <FavoriteButton dark={dark} className="mr-20" />
        <ShareButton title={title} dark={dark} />
      </div>
    </div>
  );
};

export default PlaceTitle;

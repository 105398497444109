import React from "react";
import styles from "./WeatherTypeSelector.module.scss";
import { Link } from "react-router-dom";
import { formatWeatherType, getWeatherTypes } from "../../../types/weatherType";
import { getLocationWeatherUrl } from "../../../utils/url";
import { useSelector } from "../../../store/hooks";
import {
  getWeatherType,
  getWeatherLocation,
} from "../../../store/selectors/weather";
import { getLocationRegion } from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";
import { getCountryContinent } from "../../../store/selectors/countries";
import { useLocale } from "../../../i18n";

type WeatherTypeSelectorProps = {
  dark?: boolean;
};

const WeatherTypeSelector: React.FC<WeatherTypeSelectorProps> = ({ dark }) => {
  const locale = useLocale();
  const weatherType = useSelector(getWeatherType);
  const location = useSelector(getWeatherLocation);
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));

  return (
    <div className={`${styles.wrap} ${dark ? styles.dark : ""}`}>
      {getWeatherTypes(location).map((type) => {
        const activeStyle = weatherType === type ? styles.active : "";
        return (
          <Link
            key={type}
            to={getLocationWeatherUrl(
              locale,
              continent,
              country,
              region,
              location,
              type
            )}
            className={`${styles.type} ${activeStyle}`}
          >
            {/* <LocaleMessage id={`WeatherType${type}`} */}
            {formatWeatherType(locale, type)}
          </Link>
        );
      })}
    </div>
  );
};

export default WeatherTypeSelector;

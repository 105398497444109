import { RootState } from "..";
import { createLocationListSelect } from "./locations";

export const getFavoriteLocationIds = (state: RootState) =>
  state.favorite.locationIds;

export const isFavoriteRestored = (state: RootState) => state.favorite.restored;
export const getFavoriteLocations = createLocationListSelect(
  getFavoriteLocationIds
);

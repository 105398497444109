import { createStore, applyMiddleware, compose, Reducer } from "redux";
import reducers from "./reducers";
import thunkMiddleware, { ThunkMiddleware } from "redux-thunk";
import { History } from "history";
//@ts-ignore
import { routerMiddleware } from "react-router-redux";

export type RootState = ReturnType<typeof reducers>;

export type RootAction = typeof reducers extends Reducer<unknown, infer A>
  ? A
  : never;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export default function configureStore(
  history: History,
  ssrState: RootState | {} = {}
) {
  const middleware = applyMiddleware(
    thunkMiddleware as ThunkMiddleware<RootState, RootAction>,
    routerMiddleware(history)
  );

  const enchancers =
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
      : compose(middleware);

  const store = createStore(reducers, ssrState, enchancers);

  return store;
}

export type Store = ReturnType<typeof configureStore>;
export type RootDispatch = Store["dispatch"];
export type GetState = () => RootState;

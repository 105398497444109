import React, { useState } from "react";
import { useSelector } from "../../../store/hooks";
import styles from "./LocationChip.module.scss";
import {
  getLocationById,
  getLocationRegion,
} from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";

type LocationChipProps = {
  locationId: string;
  icon: React.ReactNode;
  hoverIcon?: React.ReactNode;
  onClick?: (locationId: string) => void;
  className?: string;
};

export const LocationChip: React.FC<LocationChipProps> = ({
  locationId,
  icon,
  hoverIcon,
  onClick,
  className,
}) => {
  const [hovered, setHovered] = useState(false);
  const location = useSelector((x) => getLocationById(x, locationId));
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));

  const handleClick = () => {
    if (onClick) onClick(locationId);
  };

  const handleMouseOver = () => {
    if (hoverIcon) setHovered(true);
  };
  const handleMouseLeave = () => {
    if (hoverIcon) setHovered(false);
  };

  if (!locationId) return null;

  return (
    <div
      className={`${styles.chip} ${
        hovered ? styles.chipActive : ""
      }  ${className}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div>
        <h3 className={styles.title}>{location?.name}</h3>
        <h6 className={styles.subTitle}>
          {region?.name || "?"}, {country?.name || "?"}
        </h6>
      </div>
      <span className={`${hovered && hoverIcon ? styles.chipHover : ""}`}>
        {hovered && hoverIcon ? hoverIcon : icon}
      </span>
    </div>
  );
};

import React from "react";
import styles from "./Card.module.scss";

type CardTitleProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
};

const CardTitle: React.FC<CardTitleProps> = ({ title, subTitle }) => {
  return (
    <div className={styles.cardTitle}>
      <h3 className="title mr-20">{title}</h3>
      {!!subTitle && <div className="sub-title">{subTitle}</div>}
    </div>
  );
};

export default CardTitle;

import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook
} from "react-redux";
import { RootState, RootDispatch } from "..";

//export const useDispatch = () => useReduxDispatch<RootDispatch>();
export const useDispatch: () => RootDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

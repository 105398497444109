import { RootDispatch, GetState } from "..";
import {
  LocalStorageKeys,
  localStorageGet,
  localStorageSet,
  localStorageRemove,
} from "../../utils/localStorage";
import { getRecentLocationIds, isRecentRestored } from "../selectors/recent";
import {
  RECENT_ADD,
  RECENT_RESTORE,
  RECENT_CLEAR,
  RecentActionTypes,
} from "../types/recent";
import { getAuthorized, getAuthToken } from "../selectors/auth";
import { tryUpdateUser } from "../../api/authApi";

export function recentLocationAdd(locationId: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationId) return;
    try {
      let recent = getRecentLocationIds(getState());
      if (recent && recent.length && recent[0] === locationId) return;
      dispatch({ type: RECENT_ADD, locationId });

      if (isRecentRestored(getState())) {
        recent = getRecentLocationIds(getState());

        const token = getAuthToken(getState());
        if (token) {
          await tryUpdateUser(token, { recent });
        } else {
          localStorageSet(LocalStorageKeys.recent, recent);
        }
      }
    } catch (e) {
      console.error("recentLocationAdd: ", e);
    }
  };
}

export function recentRestore(locationIds: string[]): RecentActionTypes {
  return { type: RECENT_RESTORE, locationIds };
}

export function recentLocalRestore() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      if (getAuthorized(getState())) return;
      //if (isRecentRestored(getState())) return;

      try {
        let recent =
          localStorageGet<string[]>(LocalStorageKeys.recent, []) || [];
        //console.log("recent", recent);
        if (
          !Array.isArray(recent) ||
          !recent.every((x) => typeof x === "string")
        )
          recent = [];

        dispatch(recentRestore(recent));
      } catch (e) {
        console.error("recentLocalRestore: ", e);
      }

      const recent = getRecentLocationIds(getState());
      localStorageSet(LocalStorageKeys.recent, recent);
    } catch (e) {
      console.error("recentLocalRestore: save ", e);
    }
  };
}

export function recentLocationsClear() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      let recent = getRecentLocationIds(getState());
      if (!recent || !recent.length) return;
      dispatch({ type: RECENT_CLEAR });

      const token = getAuthToken(getState());
      if (token) {
        await tryUpdateUser(token, { recent: [] });
      } else {
        localStorageRemove(LocalStorageKeys.recent);
      }
    } catch (e) {
      console.error("recentLocationsClear: ", e);
    }
  };
}

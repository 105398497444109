import React from "react";

type SVGDropShadowFilterProps = {
  id: string;
  color: React.ReactText;
};

const SVGDropShadowFilter: React.FC<SVGDropShadowFilterProps> = ({
  id,
  color
}) => {
  return (
    <filter id={id} height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
      <feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
      <feFlood floodColor={color} floodOpacity="0.5" result="offsetColor" />
      <feComposite
        in="offsetColor"
        in2="offsetBlur"
        operator="in"
        result="offsetBlur"
      />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
};

export default SVGDropShadowFilter;

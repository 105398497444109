import React, { useEffect } from "react";
import { Location } from "../../../api/placesApi";
import { useSelector, useDispatch } from "../../../store/hooks";
import {
  getRecentLocationIds,
  getRecentCities,
} from "../../../store/selectors/recent";
import { tryLoadLocationsByIds } from "../../../store/actions/locations";
import {
  getGeoipNearestCities,
  getLocationRegion,
} from "../../../store/selectors/locations";
import styles from "./Search.module.scss";
import { getRegionCountry } from "../../../store/selectors/regions";
import { getCountryContinent } from "../../../store/selectors/countries";
// import { recentLocationsClear } from "../../../store/actions/recent";
import {
  getSearchLocations,
  getSearchQuery,
  getSearchRegions,
  getSearchCountries,
  getSearchLoading,
} from "../../../store/selectors/search";
import CountryFlag from "../../../components/CountryFlag";
import { LocaleMessage } from "../../../components/Locale";

type OnLinkClickProps = {
  onLinkClick: (locationId: string) => void;
};

type LocationListItemProps = {
  location: Location;
} & OnLinkClickProps;

const LocationListItem: React.FC<LocationListItemProps> = ({
  location,
  onLinkClick,
}) => {
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));

  if (!continent || !country || !region) return null;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onLinkClick(location._id);
  };

  return (
    <div onClick={handleClick} className={styles.link}>
      <span className={styles.flag}>
        <CountryFlag countryCode={country._id} />
      </span>
      {location.name}

      <span className={styles.regionCountry}>
        {region.name}, {country.name}
      </span>
    </div>
  );
};

type LocationListProps = {
  locations: Location[];
} & OnLinkClickProps;

export const LocationList: React.FC<LocationListProps> = ({
  locations: cities,
  onLinkClick,
}) => {
  return (
    <div>
      {cities.map((x) => (
        <LocationListItem key={x._id} location={x} onLinkClick={onLinkClick} />
      ))}
    </div>
  );
};

type SearchListProps = OnLinkClickProps & { mobile?: boolean };

const SearchList: React.FC<SearchListProps> = ({ mobile, onLinkClick }) => {
  const dispatch = useDispatch();

  const recentIds = useSelector(getRecentLocationIds);
  useEffect(() => {
    dispatch(tryLoadLocationsByIds(recentIds));
  }, [dispatch, recentIds]);

  const recentCities = useSelector(getRecentCities);
  const geoip = useSelector(getGeoipNearestCities).slice(0, 3);

  const hasSearchQuery = !!useSelector(getSearchQuery);
  const searchLoading = useSelector(getSearchLoading);
  const searchLocations = useSelector(getSearchLocations);
  const searchRegions = useSelector(getSearchRegions);
  const searchCountries = useSelector(getSearchCountries);
  const noMatch =
    !searchLocations.length && !searchRegions.length && !searchCountries.length;
  const hasSearch = hasSearchQuery;
  const searchCities = searchLocations.filter((x) => x.type === "c");
  const searchPlaces = searchLocations.filter((x) => x.type === "p");

  const props = { onLinkClick };
  return (
    <div className={mobile ? styles.mobile : undefined}>
      {!hasSearch ? (
        <>
          {!!recentCities.length && (
            <>
              <div className={styles.recentHead}>
                <LocaleMessage id="SearchRecent" />
                {/* <button
                  className={styles.clearButton}
                  onClick={() => dispatch(recentLocationsClear())}
                >
                  <LocaleMessage id="SearchClear" />
                </button> */}
              </div>
              <LocationList locations={recentCities} {...props} />
            </>
          )}
          {!!geoip.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchNearestByIp" />
              </div>
              <LocationList locations={geoip} {...props} />
            </>
          )}
        </>
      ) : (
        <>
          {!!searchCities.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchCities" />
              </div>
              <LocationList locations={searchCities} {...props} />
            </>
          )}
          {!!searchPlaces.length && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchPlaces" />
              </div>
              <LocationList locations={searchPlaces} {...props} />
            </>
          )}
          {!!noMatch && (
            <>
              <div className={styles.head}>
                <LocaleMessage id="SearchResults" />
              </div>
              <div className={styles.noResults}>
                {searchLoading ? (
                  <LocaleMessage id="SearchLoading" />
                ) : (
                  <LocaleMessage id="SearchNoMatch" />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchList;

export const RECENT_ADD = "RECENT_ADD";
export const RECENT_RESTORE = "RECENT_RESTORE";
export const RECENT_CLEAR = "RECENT_CLEAR";

type RecentAddAction = {
  type: typeof RECENT_ADD;
  locationId: string;
};

type RecentRestoreAction = {
  type: typeof RECENT_RESTORE;
  locationIds: string[];
};

type RecentClearAction = {
  type: typeof RECENT_CLEAR;
};

export type RecentActionTypes =
  | RecentAddAction
  | RecentRestoreAction
  | RecentClearAction;

import { WeatherCode } from "../api/weatherApi";
import { MessageKey, FormatMessageFn, defaultLocale } from "../i18n";

export function getWeatherDescription(
  formatMessage: FormatMessageFn,
  weatherCode: WeatherCode | undefined,
  weatherDesc: string
) {
  if (!weatherCode) return weatherDesc;
  if (weatherDesc && formatMessage.locale === defaultLocale) return weatherDesc;

  const key = ("WeatherCode" + WeatherCode[weatherCode]) as MessageKey;
  return formatMessage(key, undefined, weatherDesc);
}

export const INIT_COMMON = "INIT_COMMON";
export const INIT_CLIENT = "INIT_CLIENT";

type InitCommonAction = {
  type: typeof INIT_COMMON;
};

type InitClientAction = {
  type: typeof INIT_CLIENT;
};

export type InitActionTypes = InitCommonAction | InitClientAction;

import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "../../store/hooks";
import styles from "./MobileSearchPage.module.scss";
import {
  searchQuery,
  navigateToTopSearch,
  navigateToGeolocation,
} from "../../store/actions/search";
import { RouteComponentProps, match } from "react-router";
import SearchList from "../../components/SearchList";
import { RootDispatch, Store } from "../../store";
import { tryInit } from "../../store/actions/init";
import { Request } from "express";
import { DataPage } from "../DataPage";
import { RemoveScroll } from "react-remove-scroll";
import { setCacheControlStatic } from "../../utils/cache";

async function fetchData(dispatch: RootDispatch, req?: Request) {
  // init - for SSR
  await dispatch(tryInit(req));
}

const MobileSearchPage: React.FC<RouteComponentProps> & DataPage = (props) => {
  const { history } = props;
  const [text, setText] = useState("");

  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const search = (text: string) => {
    setText(text);
    dispatch(searchQuery(text));
  };

  useEffect(() => {
    fetchData(dispatch);
    //if (ref.current) ref.current.focus();
    return () => {
      dispatch(searchQuery(""));
    };
  }, [dispatch]);

  setCacheControlStatic(props);

  return (
    <RemoveScroll enabled noIsolation>
      <div className={`${styles.wrap} ${RemoveScroll.classNames.fullWidth}`}>
        <form
          className={styles.form}
          onSubmit={async (e) => {
            e.preventDefault();
            if (!(await dispatch(navigateToTopSearch(text, history)))) return;
          }}
        >
          <button
            aria-label="Back"
            type="button"
            className={styles.backButton}
            onClick={() => history.goBack()}
          />
          <input
            autoFocus
            type="text"
            className={styles.searchInput}
            ref={ref}
            value={text}
            onChange={(e) => search(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 27) search("");
            }}
          />
          <button
            aria-label="Clear"
            className={styles.clearButton}
            type="button"
            onClick={() => search("")}
          />
          <button
            aria-label="GPS Location"
            className={styles.gpsButton}
            type="button"
            onClick={() => dispatch(navigateToGeolocation(history))}
          />

          <input type="submit" style={{ display: "none" }} />
        </form>
        <SearchList mobile onLinkClicked={() => search("")} />
      </div>
    </RemoveScroll>
  );
};

MobileSearchPage.fetchData = function (
  store: Store,
  _route: match,
  req?: Request
) {
  return fetchData(store.dispatch, req);
};

export default MobileSearchPage;

import { UNITS_SET, UnitActionTypes } from "../types/units";
import { UnitKey } from "../../types/units";
import {
  localStorageSet,
  LocalStorageKeys,
  localStorageGet,
} from "../../utils/localStorage";
import { RootDispatch, GetState } from "..";
import { getAuthorized, getAuthToken } from "../selectors/auth";
import { tryUpdateUser } from "../../api/authApi";

export function unitSet(units: UnitKey, user: boolean): UnitActionTypes {
  return {
    type: UNITS_SET,
    units,
    user,
  };
}

export function unitSelect(units: UnitKey) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    dispatch(unitSet(units, true));

    const token = getAuthToken(getState());
    if (token) {
      await tryUpdateUser(token, { units });
    } else {
      localStorageSet(LocalStorageKeys.units, units);
    }
  };
}

export function unitLocalRestore() {
  return (dispatch: RootDispatch, getState: GetState) => {
    if (getAuthorized(getState())) return;

    const units = localStorageGet(LocalStorageKeys.units, "");
    if (!units || typeof units !== "string") return;

    dispatch(unitSet(units as UnitKey, true));
  };
}

// farentheit countries
const farentheitCountries = [
  "ag", //Antigua and Barbuda	97.929
  "bs", //Bahamas	393.244
  "bz", // Belize	397.628
  "bm", // Bermuda	62.278
  "vg", // British Virgin Islands	30.231
  "ky", // Cayman Islands	65.722
  "lr", // Liberia	5057.681
  "mh", // Marshall Islands	59.19
  "fm", // Micronesia	115.023
  "ms", // Montserrat	4.992
  "pw", // Palau	18.094
  "kn", // Saint Kitts and Nevis	53.199
  "us", // United States	331002.651
];

export function unitSelectByCountry(country: string) {
  const units: UnitKey =
    !country || farentheitCountries.includes(country.toLocaleLowerCase())
      ? "F_mph"
      : "C_ms";
  return unitSet(units, false);
}

import { combineReducers } from "redux";

//@ts-ignore
import { routerReducer } from "react-router-redux";

import errors from "./errors";
import auth from "./auth";

import countries from "./countries";
import regions from "./regions";
import locations from "./locations";
import weather from "./weather";
import units from "./units";
import dailyShort from "./dailyShort";
import homeShort from "./homeShort";
import init from "./init";
import recent from "./recent";
import search from "./search";
import locale from "./locale";
import favorite from "./favorite";
import locationSelector from "./locationSelector";
import userProfile from "./userProfile";
import userWeatherAlerts from "./userWeatherAlerts";
import notifications from "./notifications";

const rootReducer = combineReducers({
  errors,
  auth,

  countries,
  regions,
  locations,

  dailyShort,
  homeShort,

  weather,
  units,
  init,
  recent,
  search,
  locale,
  favorite,
  userProfile,
  userWeatherAlerts,
  notifications,

  locationSelector,

  routing: routerReducer,
});

export default rootReducer;

export function chunkArray<T>(array: T[], size: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += size)
    result.push(array.slice(i, size + i));

  return result;
}

export function toMap<T>(
  array: T[],
  selector: (x: T) => string | number | undefined
): { [key: string]: T } {
  return toMap2(array, selector, x => x);
}

export function toMap2<T, P>(
  array: T[],
  selector: (x: T) => string | number | undefined,
  value: (x: T) => P
): { [key: string]: P } {
  return array.reduce<{ [key: string]: P }>((p, x) => {
    const key = selector(x);
    if (key !== undefined) p[key] = value(x);
    return p;
  }, {});
}

export function distinct<T>(array: T[]) {
  if (!array || !array.length) return array;
  return Array.from(new Set(array));
}

import React, { useCallback } from "react";
import unitImg from "./assets/units.svg";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import { units, UnitKey } from "../../../types/units";
import { useDispatch } from "react-redux";
import { unitSelect } from "../../../store/actions/units";
import MenuSelector, { Option } from "./MenuSelector";
import { LocaleMessage } from "../../Locale";

const unitOptions = Object.entries(units).map<Option>(([value, label]) => ({
  value,
  label
}));

type UnitSelectorProps = {
  close: () => void;
};

const UnitSelector: React.FC<UnitSelectorProps> = ({ close }) => {
  //const [open, setOpen] = useState(false);
  const active = useSelector(getUnits);
  const dispatch = useDispatch();

  const setActive = useCallback(
    (value: string) => {
      dispatch(unitSelect(value as UnitKey));
      close();
    },
    [dispatch, close]
  );

  return (
    <MenuSelector
      img={unitImg}
      title={<LocaleMessage id="Units" />}
      options={unitOptions}
      active={active}
      setActive={setActive}
    />
  );
};

export default UnitSelector;

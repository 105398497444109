//import "date-time-format-timezone";

import en from "./en";
import uk from "./uk";
export * from "./hooks";
export * from "./defaults";

export type LanguageStrings = typeof en;
export type MessageKey = keyof LanguageStrings;

const messages = {
  en,
  uk,
};

export function isMessageKey(key: string) {
  return typeof en[key] !== "undefined";
}

export type LocaleCode = keyof typeof messages;
export const defaultLocale: LocaleCode = "en";
export const locales = Object.keys(messages) as LocaleCode[];

export const localeNames: { [key in LocaleCode]: string } = {
  en: "English",
  uk: "Українська",
};

export function getLocaleMessages(locale: string) {
  return messages[locale || ""] || en;
}

export function getPathnameWithoutLocale(pathname: string, lang?: string) {
  return lang && pathname.startsWith("/" + lang)
    ? pathname.substring(3)
    : pathname;
}

const langRegex = locales.filter((x) => x !== defaultLocale).join("|");
export const langPrefix = `/:lang(${langRegex})`;

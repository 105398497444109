import { LatLon } from "../api/types";

export async function getGeoLocation(): Promise<LatLon> {
  return new Promise<LatLon>((resolve, reject) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lon } }) =>
          resolve({ lat, lon }),
        err => reject(err)
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
}

import { RootState } from "..";
import { createSelector } from "./createSelector";
import { Region, LocationType } from "../../api/placesApi";
import { getCountryById } from "./countries";

const byCountry = (state: RootState) => state.regions.byCountry;
const byId = (state: RootState) => state.regions.byId;

export const getRegionsByCountry = (
  state: RootState,
  locationType: LocationType,
  countryId: string
) => {
  if (!countryId) return [];
  const { ids = [] } = byCountry(state)[locationType][countryId] || {};
  return ids.map((x) => getRegionById(state, x)).filter((x) => x) as Region[];
};

export const createRegionListSelect = (
  getIds: (state: RootState) => string[]
) =>
  createSelector(byId, getIds, (byId, ids) => {
    return ids.map((x) => byId[x]).filter((x) => x);
  });

export function areRegionsByCountryLoaded(
  state: RootState,
  locationType: LocationType,
  countryId: string
) {
  const country = byCountry(state)[locationType][countryId];
  return country && !country.loading;
}

export const getRegionById = (state: RootState, regionId: string) =>
  regionId ? byId(state)[regionId] : undefined;

export const getRegionCountry = (
  state: RootState,
  region: Region | undefined
) => (region ? getCountryById(state, region.countryId) : undefined);

export function getRegionByCode(
  state: RootState,
  countryId: string,
  regionCode: string
) {
  if (!countryId || !regionCode) return undefined;
  // use hack here
  const regionId = `${countryId}.${regionCode}`;
  return getRegionById(state, regionId);
}

import React from "react";
import styles from "./Divider.module.scss";

export const HSplit: React.FC = () => {
  return <div className={`${styles.hSplit} `}></div>;
};

export const VSplit: React.FC = () => {
  return <div className={`${styles.vSplit} `}></div>;
};

import React from "react";
import { WeatherCode } from "../../api/weatherApi";
//import { isDayTime } from "../../types/time";
import { getWeatherDescription } from "../../types/weatherCodes";
import { useFormatMessage } from "../../i18n";

import noImage from "./assets/No Image.svg";

import sunnyDay from "./assets/Sunny.svg";
import partlyCloudyDay from "./assets/Partly cloudy (day).svg";
import cloudy from "./assets/Cloudy.svg";
import overcast from "./assets/Overcast.svg";
import mistDay from "./assets/Mist (day).svg";
import patchyRainPossibleDay from "./assets/Patchy rain possible (day).svg";
import patchySnowPossibleDay from "./assets/Patchy snow possible (day).svg";
import patchySleetPossibleDay from "./assets/Patchy sleet possible (day).svg";
import patchyFreezingDrizzlePossibleDay from "./assets/Patchy freezing drizzle possible (day).svg";
import thunderyOutbreaksPossibleDay from "./assets/Thundery outbreaks possible (day).svg";
import blowingSnow from "./assets/Blowing snow.svg";
import blizzard from "./assets/Blizzard.svg";
import fog from "./assets/Fog.svg";
import freezingFog from "./assets/Freezing fog.svg";
import patchyLightDrizzleDay from "./assets/Patchy light drizzle (day).svg";
import lightDrizzleDay from "./assets/Patchy light drizzle (day).svg";
import freezingDrizzleDay from "./assets/Freezing drizzle (day).svg";
import heavyFreezingDrizzleDay from "./assets/Heavy freezing drizzle (day).svg";
import patchyLightRainDay from "./assets/Patchy light rain (day).svg";
import lightRainDay from "./assets/Light rain (day).svg";
import moderateRainAtTimesDay from "./assets/Moderate rain at times (day).svg";
import moderateRainDay from "./assets/Moderate rain (day).svg";
import heavyRainAtTimesDay from "./assets/Heavy rain at times (day).svg";
import heavyRain from "./assets/Heavy rain.svg";
import lightFreezingRainDay from "./assets/Light freezing rain (day).svg";
import moderateOrHeavyFreezingRain from "./assets/Moderate Or Heavy Freezing Rain.svg";
import lightSleetDay from "./assets/Light Sleet (day).svg";
import moderateOrHeavySleetDay from "./assets/Moderate Or Heavy Sleet (day).svg";
import patchyLightSnowDay from "./assets/Patchy Light Snow (day).svg";
import lightSnowDay from "./assets/Light Snow (day).svg";
import patchyModerateSnowDay from "./assets/Patchy Moderate Snow (day).svg";
import moderateSnowDay from "./assets/Moderate Snow (day).svg";
import patchyHeavySnow from "./assets/Patchy Heavy Snow.svg";
import heavySnow from "./assets/Heavy Snow.svg";
import icePelletsDay from "./assets/Ice Pellets (day).svg";
import lightRainShowerDay from "./assets/Light Rain Shower (day).svg";
import moderateOrHeavyRainShowers from "./assets/Moderate Or Heavy Rain Showers.svg";
import torrentialRainShower from "./assets/Torrential Rain Shower.svg";
import lightSleetShowerDay from "./assets/Light Sleet Showers (day).svg";
import moderateOrHeavySleetShowers from "./assets/Moderate Or Heavy Sleet Showers.svg";
import lightSnowShowerDay from "./assets/Light Snow Showers (day).svg";
import moderateOrHeavySnowShowers from "./assets/Moderate Or Heavy Snow Showers.svg";
import lightShowersOfIcePelletsDay from "./assets/Light Showers Of Ice Pellets (day).svg";
import moderateOrHeavyShowersOfIcePellets from "./assets/Moderate Or Heavy Showers Of Ice Pellets.svg";
import patchyLightRainInAreaWithThunderDay from "./assets/Patchy Light Rain In Area With Thunder (day).svg";
import moderateOrHeavyRainInAreaWithThunder from "./assets/Moderate Or Heavy Rain In Area With Thunder (day).svg";
import patchyLightSnowInAreaWithThunderDay from "./assets/Patchy Light Snow In Area With Thunder (day).svg";
import moderateOrHeavySnowInAreaWithThunder from "./assets/Moderate Or Heavy Snow In Area With Thunder.svg";
import mostlySunny from "./assets/MostlySunny = 1001.svg";
import partlySunny from "./assets/PartlySunny = 1002.svg";
import intermittentCloudsDay from "./assets/IntermittentClouds = 1003 (day).svg";
import hazySunshine from "./assets/HazySunshine = 1004.svg";
import mostlyCloudy from "./assets/MostlyCloudy = 1005.svg";
import showersDay from "./assets/Showers = 1006 (day).svg";
import mostlyCloudyWithShowers from "./assets/MostlyCloudyWithShowers = 1007 (day).svg";
import partlySunnyWithShowersDay from "./assets/PartlySunnyWithShowers = 1008 (day).svg";
import tStorms from "./assets/TStorms = 1009.svg";
import mostlyCloudyWithTStorms from "./assets/MostlyClodyWithTStorms = 1010.svg";
import partlySunnyWithTStormsDay from "./assets/PartlySunnyWithTStorms = 1011 (day).svg";
import rain from "./assets/Rain = 1012.svg";
import flurries from "./assets/Flurries = 1013.svg";
import mostlyCloudyWithFlurries from "./assets/MostlyCloudyWithFlurries = 1014.svg";
import partlySunnyWithFlurriesDay from "./assets/PartlySunnyWithFlurries = 1015 (day).svg";
import snow from "./assets/Snow = 1016.svg";
import mostlyClodyWithSnow from "./assets/MostlyClodyWithSnow = 1017.svg";
import iceDay from "./assets/Ice = 1018 (day).svg";
import sleetDay from "./assets/Sleet = 1019 (day).svg";
import freezingRainDay from "./assets/FreezingRain = 1020 (day).svg";
import rainAndSnowDay from "./assets/RainAndSnow = 1021 (day).svg";
import hotDay from "./assets/Hot = 1022 (day).svg";
import coldDay from "./assets/Cold = 1023 (day).svg";
import windy from "./assets/Windy = 1024.svg";
import clearDay from "./assets/Clear = 1025 (day).svg";
import mostlyClearDay from "./assets/MostlyClear = 1026 (day).svg";
import hazyMoonlight from "./assets/HazyMoonlight = 1027.svg";
import partlyCloudyWithShowersDay from "./assets/PartlyCloudyWithShowers = 1028 (day).svg";
import partlyClodyWithTStormsDay from "./assets/PartlyClodyWithTStorms = 1029 (day).svg";
//import mostlyCloudyWithTStorms from "./assets/MostlyCloudyWithTStorms = 1030.svg";
import mostlyCloudyWithSnow from "./assets/MostlyCloudyWithSnow = 1031.svg";

import sunnyNight from "./assets/Night.svg";
import partlyCloudyNight from "./assets/Partly cloudy (night).svg";
import mistNight from "./assets/Mist (night).svg";
import patchyRainPossibleNight from "./assets/Patchy rain possible (night).svg";
import patchySnowPossibleNight from "./assets/Patchy snow possible (night).svg";
import patchySleetPossibleNight from "./assets/Patchy sleet possible (night).svg";
import patchyFreezingDrizzlePossibleNight from "./assets/Patchy freezing drizzle possible (night).svg";
import thunderyOutbreaksPossibleNight from "./assets/Thundery outbreaks possible (night).svg";
import patchyLightDrizzleNight from "./assets/Patchy light drizzle (night).svg";
import lightDrizzleNight from "./assets/Patchy light drizzle (night).svg";
import freezingDrizzleNight from "./assets/Freezing drizzle (night).svg";
import heavyFreezingDrizzleNight from "./assets/Heavy freezing drizzle (night).svg";
import patchyLightRainNight from "./assets/Patchy light rain (night).svg";
import lightRainNight from "./assets/Light rain (night).svg";
import moderateRainAtTimesNight from "./assets/Moderate rain at times (night).svg";
import moderateRainNight from "./assets/Moderate rain (night).svg";
import heavyRainAtTimesNight from "./assets/Heavy rain at times (night).svg";
import lightFreezingRainNight from "./assets/Light freezing rain (night).svg";
import lightSleetNight from "./assets/Light Sleet (night).svg";
import moderateOrHeavySleetNight from "./assets/Moderate Or Heavy Sleet (night).svg";
import patchyLightSnowNight from "./assets/Patchy Light Snow (night).svg";
import lightSnowNight from "./assets/Light Snow (night).svg";
import patchyModerateSnowNight from "./assets/Patchy Moderate Snow (night).svg";
import moderateSnowNight from "./assets/Moderate Snow (night).svg";
import icePelletsNight from "./assets/Ice Pellets (night).svg";
import lightRainShowerNight from "./assets/Light Rain Shower (night).svg";
import lightSleetShowerNight from "./assets/Light Sleet Showers (night).svg";
import lightSnowShowerNight from "./assets/Light Snow Showers (night).svg";
import lightShowersOfIcePelletsNight from "./assets/Light Showers Of Ice Pellets (night).svg";
import patchyLightRainInAreaWithThunderNight from "./assets/Patchy Light Rain In Area With Thunder (night).svg";
import patchyLightSnowInAreaWithThunderNight from "./assets/Patchy Light Snow In Area With Thunder (night).svg";
import intermittentCloudsNight from "./assets/IntermittentClouds = 1003 (night).svg";
import showersNight from "./assets/Showers = 1006 (night).svg";
import partlySunnyWithShowersNight from "./assets/PartlySunnyWithShowers = 1008 (night).svg";
import partlySunnyWithTStormsNight from "./assets/PartlySunnyWithTStorms = 1011 (night).svg";
import partlySunnyWithFlurriesNight from "./assets/PartlySunnyWithFlurries = 1015 (night).svg";
import iceNight from "./assets/Ice = 1018 (night).svg";
import sleetNight from "./assets/Sleet = 1019 (night).svg";
import freezingRainNight from "./assets/FreezingRain = 1020 (night).svg";
import rainAndSnowNight from "./assets/RainAndSnow = 1021 (night).svg";
import hotNight from "./assets/Hot = 1022 (night).svg";
import coldNight from "./assets/Cold = 1023 (night).svg";
import clearNight from "./assets/Clear = 1025 (night).svg";
import mostlyClearNight from "./assets/MostlyClear = 1026 (night).svg";
import partlyCloudyWithShowersNight from "./assets/PartlyCloudyWithShowers = 1028 (night).svg";
import partlyClodyWithTStormsNight from "./assets/PartlyClodyWithTStorms = 1029 (night).svg";

const dayImages: { [key in WeatherCode]: string } = {
  [WeatherCode.Sunny]: sunnyDay,
  [WeatherCode.PartlyCloudy]: partlyCloudyDay,
  [WeatherCode.Cloudy]: cloudy,
  [WeatherCode.Overcast]: overcast,
  [WeatherCode.Mist]: mistDay,
  [WeatherCode.PatchyRainPossible]: patchyRainPossibleDay,
  [WeatherCode.PatchySnowPossible]: patchySnowPossibleDay,
  [WeatherCode.PatchySleetPossible]: patchySleetPossibleDay,
  [WeatherCode.PatchyFreezingDrizzlePossible]: patchyFreezingDrizzlePossibleDay,
  [WeatherCode.ThunderyOutbreaksPossible]: thunderyOutbreaksPossibleDay,
  [WeatherCode.BlowingSnow]: blowingSnow,
  [WeatherCode.Blizzard]: blizzard,
  [WeatherCode.Fog]: fog,
  [WeatherCode.FreezingFog]: freezingFog,
  [WeatherCode.PatchyLightDrizzle]: patchyLightDrizzleDay,
  [WeatherCode.LightDrizzle]: lightDrizzleDay,
  [WeatherCode.FreezingDrizzle]: freezingDrizzleDay,
  [WeatherCode.HeavyFreezingDrizzle]: heavyFreezingDrizzleDay,
  [WeatherCode.PatchyLightRain]: patchyLightRainDay,
  [WeatherCode.LightRain]: lightRainDay,
  [WeatherCode.ModerateRainAtTimes]: moderateRainAtTimesDay,
  [WeatherCode.ModerateRain]: moderateRainDay,
  [WeatherCode.HeavyRainAtTimes]: heavyRainAtTimesDay,
  [WeatherCode.HeavyRain]: heavyRain,
  [WeatherCode.LightFreezingRain]: lightFreezingRainDay,
  [WeatherCode.ModerateOrHeavyFreezingRain]: moderateOrHeavyFreezingRain,
  [WeatherCode.LightSleet]: lightSleetDay,
  [WeatherCode.ModerateOrHeavySleet]: moderateOrHeavySleetDay,
  [WeatherCode.PatchyLightSnow]: patchyLightSnowDay,
  [WeatherCode.LightSnow]: lightSnowDay,
  [WeatherCode.PatchyModerateSnow]: patchyModerateSnowDay,
  [WeatherCode.ModerateSnow]: moderateSnowDay,
  [WeatherCode.PatchyHeavySnow]: patchyHeavySnow,
  [WeatherCode.HeavySnow]: heavySnow,
  [WeatherCode.IcePellets]: icePelletsDay,
  [WeatherCode.LightRainShower]: lightRainShowerDay,
  [WeatherCode.ModerateOrHeavyRainShower]: moderateOrHeavyRainShowers,
  [WeatherCode.TorrentialRainShower]: torrentialRainShower,
  [WeatherCode.LightSleetShower]: lightSleetShowerDay,
  [WeatherCode.ModerateOrHeavySleetShower]: moderateOrHeavySleetShowers,
  [WeatherCode.LightSnowShower]: lightSnowShowerDay,
  [WeatherCode.ModerateOrHeavySnowShower]: moderateOrHeavySnowShowers,
  [WeatherCode.LightShowerOfIcePellets]: lightShowersOfIcePelletsDay,
  [WeatherCode.ModerateOrHeavyShowerOfIcePellets]: moderateOrHeavyShowersOfIcePellets,
  [WeatherCode.PatchyLightRainInAreaWithThunder]: patchyLightRainInAreaWithThunderDay,
  [WeatherCode.ModerateOrHeavyRainInAreaWithThunder]: moderateOrHeavyRainInAreaWithThunder,
  [WeatherCode.PatchyLightSnowInAreaWithThunder]: patchyLightSnowInAreaWithThunderDay,
  [WeatherCode.ModerateOrHeavySnowInAreaWithThunder]: moderateOrHeavySnowInAreaWithThunder,

  // AccuWeather
  [WeatherCode.MostlySunny]: mostlySunny,
  [WeatherCode.PartlySunny]: partlySunny,
  [WeatherCode.IntermittentClouds]: intermittentCloudsDay,
  [WeatherCode.HazySunshine]: hazySunshine,
  [WeatherCode.MostlyCloudy]: mostlyCloudy,
  [WeatherCode.Showers]: showersDay,
  [WeatherCode.MostlyCloudyWithShowers]: mostlyCloudyWithShowers,
  [WeatherCode.PartlySunnyWithShowers]: partlySunnyWithShowersDay,
  [WeatherCode.TStorms]: tStorms,
  [WeatherCode.MostlyCloudyWithTStorms]: mostlyCloudyWithTStorms,
  [WeatherCode.PartlySunnyWithTStorms]: partlySunnyWithTStormsDay,
  [WeatherCode.Rain]: rain,
  [WeatherCode.Flurries]: flurries,
  [WeatherCode.MostlyCloudyWithFlurries]: mostlyCloudyWithFlurries,
  [WeatherCode.PartlySunnyWithFlurries]: partlySunnyWithFlurriesDay,
  [WeatherCode.Snow]: snow,
  [WeatherCode.MostlyClodyWithSnow]: mostlyCloudyWithSnow,
  [WeatherCode.Ice]: iceDay,
  [WeatherCode.Sleet]: sleetDay,
  [WeatherCode.FreezingRain]: freezingRainDay,
  [WeatherCode.RainAndSnow]: rainAndSnowDay,
  [WeatherCode.Hot]: hotDay,
  [WeatherCode.Cold]: coldDay,
  [WeatherCode.Windy]: windy,
  [WeatherCode.Clear]: clearDay,
  [WeatherCode.MostlyClear]: mostlyClearDay,
  [WeatherCode.HazyMoonlight]: hazyMoonlight,
  [WeatherCode.PartlyCloudyWithShowers]: partlyCloudyWithShowersDay,
  [WeatherCode.PartlyClodyWithTStorms]: partlyClodyWithTStormsDay,
  //[WeatherCode.MostlyCloudyWithTStorms]: mostlyCloudyWithTStorms,
  [WeatherCode.MostlyCloudyWithSnow]: mostlyClodyWithSnow,
};

const nightImages: { [key in WeatherCode]: string } = {
  [WeatherCode.Sunny]: sunnyNight,
  [WeatherCode.PartlyCloudy]: partlyCloudyNight,
  [WeatherCode.Cloudy]: cloudy,
  [WeatherCode.Overcast]: overcast,
  [WeatherCode.Mist]: mistNight,
  [WeatherCode.PatchyRainPossible]: patchyRainPossibleNight,
  [WeatherCode.PatchySnowPossible]: patchySnowPossibleNight,
  [WeatherCode.PatchySleetPossible]: patchySleetPossibleNight,
  [WeatherCode.PatchyFreezingDrizzlePossible]: patchyFreezingDrizzlePossibleNight,
  [WeatherCode.ThunderyOutbreaksPossible]: thunderyOutbreaksPossibleNight,
  [WeatherCode.BlowingSnow]: blowingSnow,
  [WeatherCode.Blizzard]: blizzard,
  [WeatherCode.Fog]: fog,
  [WeatherCode.FreezingFog]: freezingFog,
  [WeatherCode.PatchyLightDrizzle]: patchyLightDrizzleNight,
  [WeatherCode.LightDrizzle]: lightDrizzleNight,
  [WeatherCode.FreezingDrizzle]: freezingDrizzleNight,
  [WeatherCode.HeavyFreezingDrizzle]: heavyFreezingDrizzleNight,
  [WeatherCode.PatchyLightRain]: patchyLightRainNight,
  [WeatherCode.LightRain]: lightRainNight,
  [WeatherCode.ModerateRainAtTimes]: moderateRainAtTimesNight,
  [WeatherCode.ModerateRain]: moderateRainNight,
  [WeatherCode.HeavyRainAtTimes]: heavyRainAtTimesNight,
  [WeatherCode.HeavyRain]: heavyRain,
  [WeatherCode.LightFreezingRain]: lightFreezingRainNight,
  [WeatherCode.ModerateOrHeavyFreezingRain]: moderateOrHeavyFreezingRain,
  [WeatherCode.LightSleet]: lightSleetNight,
  [WeatherCode.ModerateOrHeavySleet]: moderateOrHeavySleetNight,
  [WeatherCode.PatchyLightSnow]: patchyLightSnowNight,
  [WeatherCode.LightSnow]: lightSnowNight,
  [WeatherCode.PatchyModerateSnow]: patchyModerateSnowNight,
  [WeatherCode.ModerateSnow]: moderateSnowNight,
  [WeatherCode.PatchyHeavySnow]: patchyHeavySnow,
  [WeatherCode.HeavySnow]: heavySnow,
  [WeatherCode.IcePellets]: icePelletsNight,
  [WeatherCode.LightRainShower]: lightRainShowerNight,
  [WeatherCode.ModerateOrHeavyRainShower]: moderateOrHeavyRainShowers,
  [WeatherCode.TorrentialRainShower]: torrentialRainShower,
  [WeatherCode.LightSleetShower]: lightSleetShowerNight,
  [WeatherCode.ModerateOrHeavySleetShower]: moderateOrHeavySleetShowers,
  [WeatherCode.LightSnowShower]: lightSnowShowerNight,
  [WeatherCode.ModerateOrHeavySnowShower]: moderateOrHeavySnowShowers,
  [WeatherCode.LightShowerOfIcePellets]: lightShowersOfIcePelletsNight,
  [WeatherCode.ModerateOrHeavyShowerOfIcePellets]: moderateOrHeavyShowersOfIcePellets,
  [WeatherCode.PatchyLightRainInAreaWithThunder]: patchyLightRainInAreaWithThunderNight,
  [WeatherCode.ModerateOrHeavyRainInAreaWithThunder]: moderateOrHeavyRainInAreaWithThunder,
  [WeatherCode.PatchyLightSnowInAreaWithThunder]: patchyLightSnowInAreaWithThunderNight,
  [WeatherCode.ModerateOrHeavySnowInAreaWithThunder]: moderateOrHeavySnowInAreaWithThunder,

  // AccuWeather
  [WeatherCode.MostlySunny]: mostlySunny,
  [WeatherCode.PartlySunny]: partlySunny,
  [WeatherCode.IntermittentClouds]: intermittentCloudsNight,
  [WeatherCode.HazySunshine]: hazySunshine,
  [WeatherCode.MostlyCloudy]: mostlyCloudy,
  [WeatherCode.Showers]: showersNight,
  [WeatherCode.MostlyCloudyWithShowers]: mostlyCloudyWithShowers,
  [WeatherCode.PartlySunnyWithShowers]: partlySunnyWithShowersNight,
  [WeatherCode.TStorms]: tStorms,
  [WeatherCode.MostlyCloudyWithTStorms]: mostlyCloudyWithTStorms,
  [WeatherCode.PartlySunnyWithTStorms]: partlySunnyWithTStormsNight,
  [WeatherCode.Rain]: rain,
  [WeatherCode.Flurries]: flurries,
  [WeatherCode.MostlyCloudyWithFlurries]: mostlyCloudyWithFlurries,
  [WeatherCode.PartlySunnyWithFlurries]: partlySunnyWithFlurriesNight,
  [WeatherCode.Snow]: snow,
  [WeatherCode.MostlyClodyWithSnow]: mostlyCloudyWithSnow,
  [WeatherCode.Ice]: iceNight,
  [WeatherCode.Sleet]: sleetNight,
  [WeatherCode.FreezingRain]: freezingRainNight,
  [WeatherCode.RainAndSnow]: rainAndSnowNight,
  [WeatherCode.Hot]: hotNight,
  [WeatherCode.Cold]: coldNight,
  [WeatherCode.Windy]: windy,
  [WeatherCode.Clear]: clearNight,
  [WeatherCode.MostlyClear]: mostlyClearNight,
  [WeatherCode.HazyMoonlight]: hazyMoonlight,
  [WeatherCode.PartlyCloudyWithShowers]: partlyCloudyWithShowersNight,
  [WeatherCode.PartlyClodyWithTStorms]: partlyClodyWithTStormsNight,
  //[WeatherCode.MostlyCloudyWithTStorms]: mostlyCloudyWithTStorms,
  [WeatherCode.MostlyCloudyWithSnow]: mostlyClodyWithSnow,
};

export function getConditionImage(
  weatherCode: WeatherCode | undefined,
  day: boolean
): string {
  if (!weatherCode) return noImage;
  const images = day ? dayImages : nightImages;
  return images[weatherCode] || noImage;
}

// function getConditionImageTime(
//   weatherCode: WeatherCode,
//   time: Date,
//   sunrise: Date,
//   sunset: Date
// ) {
//   const day = isDayTime(time, sunrise, sunset);
//   return getConditionImage(weatherCode, day);
// }

export type WeatherConditionProps = {
  weatherCode: WeatherCode | undefined;
  weatherDesc: string;
  day: boolean;
  className?: string;
};

const WeatherCondition: React.FC<WeatherConditionProps> = ({
  weatherCode,
  weatherDesc,
  day,
  className,
}) => {
  const formatMessage = useFormatMessage();
  const src = getConditionImage(weatherCode, day);
  const description = getWeatherDescription(
    formatMessage,
    weatherCode,
    weatherDesc
  );

  return (
    <img
      src={src}
      alt={description}
      title={description}
      className={className}
    ></img>
  );
};

export default WeatherCondition;

import {
  FavoriteActionTypes,
  FAVORITE_TOGGLE,
  FAVORITE_RESTORE
} from "../types/favorite";

type FavoriteState = {
  locationIds: string[];
  restored: boolean;
};

const favoriteMaxCount = 100;

const initialState: FavoriteState = {
  locationIds: [],
  restored: false
};

export default function favoriteReducer(
  state: FavoriteState = initialState,
  action: FavoriteActionTypes
): FavoriteState {
  switch (action.type) {
    case FAVORITE_TOGGLE:
      const { locationId } = action;
      if (state.locationIds.includes(locationId))
        return {
          ...state,
          locationIds: state.locationIds.filter(x => x !== locationId)
        };
      else
        return {
          ...state,
          locationIds: [locationId, ...state.locationIds].slice(
            0,
            favoriteMaxCount
          )
        };
    case FAVORITE_RESTORE:
      const { locationIds } = action;
      return {
        ...state,
        restored: true,
        locationIds: locationIds.slice(0, favoriteMaxCount)
      };
    default:
      return state;
  }
}

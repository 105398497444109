import React from "react";
import styles from "./Controls.module.scss";

type TextFieldProps = {
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  label,
  placeholder,
  value = "",
  onChange,
  disabled,
  className = "",
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (onChange) onChange(value);
  };
  return (
    <div className={`${styles.textField} ${className}`}>
      <label className={styles.label}>
        {label}
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          className={`${styles.inputText}`}
          onChange={handleChange}
          disabled={disabled}
        />
        <button
          aria-label="Clear"
          className={styles.clearButton}
          type="button"
          onClick={() => {
            if (onChange) onChange("");
          }}
        />
      </label>
    </div>
  );
};

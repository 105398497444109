import React from "react";
import { formatTempShort } from "../../types/units";
import styles from "./NextHoursForecastChart.module.scss";
import RainChance from "../RainChance";
import TempConditionChart, { TempConditionPoint } from "../TempConditionChart";
import { useSelector } from "../../store/hooks";
import { getUnits } from "../../store/selectors/units";
import {
  getSelected5HoursOrNext12,
  getWeatherType,
} from "../../store/selectors/weather";
import { LocaleMessage, LocaleTime } from "../Locale";
import { WeatherType } from "../../types/weatherType";

const NextHoursChartData: React.FC = () => {
  const units = useSelector(getUnits);
  const hourly = useSelector(getSelected5HoursOrNext12);
  //console.log(hourly);

  if (!hourly || !hourly.length) return null;

  const chartData = hourly.map<TempConditionPoint>(
    ({ temp, weatherCode, weatherDesc, day }) => ({
      temp,
      weatherCode,
      weatherDesc,
      day,
    })
  );

  return (
    <div className={styles.chartWrap}>
      <TempConditionChart
        baseId="next12h_tempCond"
        data={chartData}
        stroke="#fff"
        count={5}
        ssrWidth={653}
        height={170}
        dot={{ fill: "#6250C3" }}
        renderTemp={({ point: { temp } }) => (
          <tspan className={styles.temp}>{formatTempShort(temp, units)}</tspan>
        )}
      />
      <div className={styles.tableWrap}>
        <div className={styles.row}>
          {hourly.map(({ time }) => (
            <div key={time.getTime()} className={styles.time}>
              <LocaleTime value={time} />
            </div>
          ))}
        </div>
        <div className={styles.row}>
          {hourly.map(({ time }) => (
            <div key={time.getTime()} className={styles.splitter} />
          ))}
        </div>
        <div className={styles.row}>
          {hourly.map(({ time, rainChance }) => (
            <RainChance
              key={time.getTime()}
              className={styles.rain}
              rainChance={rainChance}
              light
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type NextHoursForecastChartProps = {
  className?: string;
};

const NextHoursForecastChart: React.FC<NextHoursForecastChartProps> = ({
  className = "",
}) => {
  const weatherType = useSelector(getWeatherType);
  return (
    <div className={`${styles.wrap} ${className}`}>
      <h3 className={styles.title}>
        <LocaleMessage
          id={
            weatherType === WeatherType.Today
              ? "Next12Hours"
              : "WeatherTypeToday"
          }
        />
      </h3>
      <NextHoursChartData />
    </div>
  );
};

export default NextHoursForecastChart;

import React from "react";
import WeatherPageTitle from "./WeatherPageTitle";
import { useSelector } from "../../store/hooks";
import { getSelectedTideStations } from "../../store/selectors/weather";
import SunAndMoonCard from "./SunAndMoonCard";
import TideStationCard from "./TideStationCard";
import CurrentConditionsHead from "./CurrentConditionsHead";

const TidesView: React.FC = () => {
  const stations = useSelector(getSelectedTideStations);

  return (
    <div>
      <WeatherPageTitle />
      <CurrentConditionsHead />
      <SunAndMoonCard />
      <div className="container">
        {stations.map((x) => (
          <TideStationCard key={x.station} data={x} />
        ))}
      </div>
    </div>
  );
};

export default TidesView;

import React from "react";
import { SelectedDayWeather } from "../DayWeatherHead";
import styles from "./WeatherHead.module.scss";
import WeatherTypeSelector from "../WeatherTypeSelector";
import WeatherBreadcrumb from "../WeatherBreadcrumb";
import WeatherIllustration from "../WeatherIllustration";
import PlaceTitle from "../../../components/PlaceTitle";
import PlaceTime from "../../../components/PlaceTime";
import DayHeadTempCondition from "../DayHeadTempCondition";

type WeatherHeadProps = {
  forecast: SelectedDayWeather | undefined;
  time?: Date;
};

const WeatherHead: React.FC<WeatherHeadProps> = ({ forecast, time }) => {
  return (
    <div className="container">
      <div className={styles.head}>
        <WeatherTypeSelector />
        <WeatherBreadcrumb />
      </div>

      <div className={styles.illustrationContainer}>
        <div className={styles.illustration}>
          <WeatherIllustration weather={forecast} />
        </div>
        <div className={styles.titleTemp}>
          <div className={styles.title}>
            <PlaceTitle />
            <PlaceTime weather={forecast} time={time} />
          </div>

          <DayHeadTempCondition
            className={styles.tempCondition}
            weather={forecast}
          />
        </div>
      </div>
    </div>
  );
};

export default WeatherHead;

import React, { useEffect, useRef, useState } from "react";
import styles from "./AlertsNotifications.module.scss";
import iconInfo from "../../../assets/icons/i-info.svg";
import iconInfoActive from "../../../assets/icons/i-info-active.svg";
import { HSplit, VSplit } from "../../../components/Divider";
import { isMobile } from "../../../utils/browser";

type ClassificationInfoProps = {
  popupBody: React.ReactNode;
  className?: string;
  hideDivider?: boolean;
};

export const ClassificationInfo: React.FC<ClassificationInfoProps> = ({
  popupBody,
  className,
  hideDivider,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [positionLeft, setPositionLeft] = useState<number | undefined>(
    undefined
  );
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const togglePopup = () => setOpen(!open);

  useEffect(() => {
    if (ref && ref.current && isMobile()) {
      const position = ref.current.getBoundingClientRect();
      setPositionLeft(position.left);
    }
  }, [setPositionLeft]);
  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  return (
    <div
      ref={ref}
      className={`${styles.classificationInfo} ${className ?? ""}`}
      // onClick={togglePopup}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.classificationButton} onClick={togglePopup}>
        <div className={styles.classificationInfoTextWrap}>
          {isMobile() ? !hideDivider && <VSplit /> : <VSplit />}

          <span
            className={`${styles.classificationText} ${
              open ? styles.classificationTextActive : ""
            }`}
          >
            Classification
          </span>
        </div>

        <img
          className={styles.classificationIcon}
          src={open || hovered ? iconInfoActive : iconInfo}
          alt="info icon"
        />
      </div>

      {open && (
        <div
          style={{ left: positionLeft ? -positionLeft + 10 : 21 }}
          className={styles.classificationPopup}
        >
          {popupBody}
        </div>
      )}
    </div>
  );
};

type ClassificationPopupBodyProps = {
  width: number;
  title: string;
  subtitle?: string;
  tHead?: string[];
  indicatorColors?: string[];
  rows: string[][];
  descCols?: number[];
  titleCols?: number[];
  subTitleCols?: number[];
  stickyCols?: number;
};

export const ClassificationPopupBody: React.FC<ClassificationPopupBodyProps> =
  ({
    width,
    title,
    subtitle,
    tHead,
    indicatorColors,
    rows,
    descCols,
    titleCols,
    subTitleCols,
    stickyCols,
  }) => {
    return (
      <div
        className={styles.classificationPopupBody}
        style={{ width: `${width}px` }}
      >
        <header className={styles.classificationHeader}>
          <h3>{title}</h3>
          {subtitle && (
            <>
              <h6 className={styles.classificationSubtitle}>{subtitle}</h6>
              <HSplit />
            </>
          )}
        </header>
        <div className={styles.tableWrap}>
          <table className={styles.classificationTable}>
            {tHead && (
              <thead>
                <tr>
                  {tHead.map((th, i) => (
                    <th
                      key={th}
                      className={`${
                        stickyCols && i < stickyCols ? styles.stickyCol : ""
                      }`}
                    >
                      {th}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {rows.map((row, rowI) => (
                <tr>
                  {row.map((td, colI) => {
                    const descStyles = descCols?.includes(colI)
                      ? styles.descCol
                      : "";
                    const titleStyles = titleCols?.includes(colI)
                      ? styles.titleCol
                      : "";
                    const subtitleStyles = subTitleCols?.includes(colI)
                      ? styles.subtitleCol
                      : "";
                    return (
                      <td
                        key={colI}
                        className={`${descStyles} ${titleStyles} ${subtitleStyles} ${
                          stickyCols && colI < stickyCols
                            ? styles.stickyCol
                            : ""
                        }`}
                      >
                        {indicatorColors && colI === 0 ? (
                          <div
                            className={styles.classificationRowIndicatorWrap}
                          >
                            <span
                              className={styles.classificationRowIndicator}
                              style={{ background: indicatorColors[rowI] }}
                            ></span>
                            {td}
                          </div>
                        ) : (
                          td
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export const classificationWind = {
  stickyCols: 2,
  width: 850,
  title: "Beaufort Scale (Classification of Wind Speeds)",
  tHead: [
    "Beaufort #",
    "Description",
    "mph",
    "km/h",
    "m/s",
    "knot",
    "Land conditions",
  ],
  indicatorColors: [
    "#F3FEFF",
    "#AEF1F9",
    "#96F7DC",
    "#96F7B4",
    "#6FF46F",
    "#73ED12",
    "#A4ED12",
    "#DAED12",
    "#EDC212",
    "#ED8F12",
    "#ED6312",
    "#ED2912",
    "#D5102D",
  ],
  rows: [
    ["0", "Calm", "< 1", "< 2", "< 0.5", "< 1", "Smoke rises vertically."],
    [
      "1",
      "Light air",
      "1-3",
      "2-5",
      "0.5-1.5",
      "1-3",
      "Direction shown by smoke drift but not by wind vanes.",
    ],
    [
      "2",
      "Light breeze",
      "4-7",
      "6-11",
      "1.6-3.3",
      "4-6",
      "Wind felt on face; leaves rustle; wind vane moved by wind.",
    ],
    [
      "3",
      "Gentle breeze",
      "8-12",
      "12-19",
      "3.4-5.5",
      "7-10",
      "Leaves and small twigs in constant motion; light flags extended.",
    ],
    [
      "4",
      "Moderate breeze",
      "13-18",
      "20-28",
      "5.5-7.9",
      "11-16",
      "Raises dust and loose paper; small branches moved.",
    ],
    [
      "5",
      "Fresh breeze",
      "19-24",
      "29-38",
      "8-10.7",
      "17-21",
      "Small trees in leaf begin to sway; crested wavelets form on inland waters.",
    ],
    [
      "6",
      "Strong breeze",
      "25-31",
      "39-49",
      "10.8-13.8",
      "22-27",
      "Large branches in motion; whistling heard in telegraph wires; umbrellas used with difficulty.",
    ],
    [
      "7",
      "High wind, moderate gale",
      "32-38",
      "50-61",
      "13.9-17.1",
      "28-33",
      "Whole trees in motion; inconvenience felt when walking against the wind.",
    ],
    [
      "8",
      "Fresh gale",
      "39-46",
      "62-74",
      "17.2-20.7",
      "34-40",
      "Twigs break off trees; generally impedes progress.",
    ],
    [
      "9",
      "Strong/severe gale",
      "47-54",
      "75-88",
      "20.8-24.4",
      "41-47",
      "Slight structural damage (chimney pots and slates removed).",
    ],
    [
      "10",
      "Storm, whole gale",
      "55-63",
      "89-102",
      "24.5-28.4",
      "48-55",
      "Seldom experienced inland; trees uprooted; considerable structural damage.",
    ],
    [
      "11",
      "Violent storm",
      "64-72",
      "103-117",
      "28.5-32.6",
      "56-63",
      "Very rarely experienced; accompanied by widespread damage.",
    ],
    ["12", "Hurricane", "≥ 73", "≥ 118", "≥ 32.7", "≥ 64", "Devastation."],
  ],
  descCols: [6],
  titleCols: [0],
  subtitleCols: [1],
};

export const classificationVisibility = {
  width: 400,
  title: "Visibility Classification",
  tHead: ["Km", "Miles", "Description"],
  rows: [
    ["> 40 km", "> 25 mi", "Excellent visibility"],
    ["10 - 40 km", "6.25 - 25 mi", "Good visibility"],
    ["4 - 10 km", "2.5 - 6.25 mi", "Moderate visibility"],
    ["2 - 4 km", "1.25 - 2.5 mi", "Poor visibility"],
    ["1 - 2 km", "0.63 - 1.25 mi", "Mist, haze"],
    ["0.2 - 1 km", "0.13 - 0.63 mi", "Fog"],
    ["0.04 - 0.2 km", "0.03 - 0.13 mi", "Thick fog"],
    ["< 0.04 km", "< 0.03 mi", "Dense fog"],
  ],
  titleCols: [0, 1],
  subtitleCols: [2],
};

export const classificationAir = {
  width: 430,
  title: "Dew point comfort scale",
  tHead: ["Dew point °C", "Dew point °F", "Human perception"],
  rows: [
    ["< 10", "< 50", "A bit dry for some"],
    ["10-12", "50-54", "Very comfortable"],
    ["13-16", "55-59", "Comfortable"],
    [
      "16-18",
      "60-64",
      "OK for most, but all perceive the the humidity at upper edge",
    ],
    ["18-21", "65-69", "Somewhat uncomfortable for most people at upper edge"],
    ["21-24", "70-74", "Very humid, quite uncomfortable"],
    ["24-26", "75-80", "Extremely uncomfortable, fairly oppressive"],
    ["> 26", "> 80", "Severely high. Even deadly for asthma-related illnesses"],
  ],
  titleCols: [0, 1],
};

export const classificationUv = {
  width: 455,
  title: "UV Index Scale",
  subtitle:
    "The higher the UVI, the greater the potential for damage to the skin and eye, and the less time it takes for harm to occur.",
  tHead: ["UV Index", "UV Strength", "Sunburn time", "Protection"],
  indicatorColor: ["#9BC602", "#FFBB01", "#FF9000", "#F54F25", "#9A49C9"],
  rows: [
    ["0-2", "Low", "1 Hour +", "No protection needed"],
    ["3-5", "Moderate", "≈ 40 min", "Some protection is required"],
    ["6-7", "High", "≈ 30 min", "Protection is essential"],
    ["8-10", "Very High", "≈ 20 min", "Extra protection is required"],
    ["11+", "Extreme", "< 15 min", "Stay inside"],
  ],
  titleCols: [0.1],
};

import {
  LocationSelectorActions,
  LOCATION_SELECTOR_SELECT,
} from "../types/locationSelector";
import { LocationType } from "../../api/placesApi";

export type LocationSelectorState = {
  locationType: LocationType;
  continentId: string;
  countryId: string;
  regionId: string;
  error: string;
};

const initialState: LocationSelectorState = {
  locationType: "c",
  continentId: "",
  countryId: "",
  regionId: "",
  error: "",
};

export default function reducer(
  state: LocationSelectorState = initialState,
  action: LocationSelectorActions
): LocationSelectorState {
  switch (action.type) {
    case LOCATION_SELECTOR_SELECT:
      return {
        ...state,
        locationType: action.locationType,
        continentId: action.continentId,
        countryId: action.countryId,
        regionId: action.regionId,
        error: action.error,
      };

    default:
      return state;
  }
}

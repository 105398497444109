import { useState } from "react";

export enum LocalStorageKeys {
  token = "token",
  units = "units",
  recent = "recent",
  favorite = "favorite",
  consent = "consent",
  userProfile = "userProfile",
  userWeatherAlerts = "userWeatherAlerts",
  notificationsPopup = "notificationsPopup",
}

export function localStorageSet<T>(key: LocalStorageKeys, value: T) {
  if (typeof localStorage === "undefined") return;

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error("localStorageSet: ", e);
  }
}

export function localStorageGet<T>(key: LocalStorageKeys, defaultValue: T): T {
  if (typeof localStorage === "undefined") return defaultValue;

  try {
    const v = localStorage.getItem(key);
    return v !== null ? JSON.parse(v) : defaultValue;
  } catch (e) {
    console.error("localStorageGet: ", e);
    return defaultValue;
  }
}

export function localStorageRemove(key: LocalStorageKeys) {
  if (typeof localStorage === "undefined") return;

  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("localStorageRemove: ", e);
  }
}

export function useLocalStorage<T>(
  key: LocalStorageKeys,
  initialValue: T
): [T, (value: T) => void] {
  const [state, setState] = useState(localStorageGet<T>(key, initialValue));

  const setValue = (value: T) => {
    localStorageSet(key, value);
    setState(value);
  };

  return [state, setValue];
}

import { RootDispatch, GetState } from "..";
import {
  localStorageGet,
  LocalStorageKeys,
  localStorageSet,
} from "../../utils/localStorage";
import { getAuthToken } from "../selectors/auth";
import {
  fetchUsersAlertsStatistic,
  tryUpdateUserWeatherAlerts,
  UserWeatherAlerts,
} from "../../api/authApi";
import {
  UserWeatherAlertsActionTypes,
  USER_WEATHER_ALERTS_RESET,
  USER_WEATHER_ALERTS_UPDATE,
} from "../types/userWeatherAlerts";
import { getUserWeatherAlerts } from "../selectors/userWeatherAlerts";
import {
  defaultNotificationsPopupStorage,
  notificationsPopupLocationIdSet,
} from "./notifications";
import { getUserProfile } from "../selectors/userProfile";
import { weatherAlertsStatisticEmails } from "../../config";

export function userWeatherAlertsReset(): UserWeatherAlertsActionTypes {
  return {
    type: USER_WEATHER_ALERTS_RESET,
  };
}

export function userWeatherAlertsSet(
  userWeatherAlerts: Partial<UserWeatherAlerts>
): UserWeatherAlertsActionTypes {
  return {
    type: USER_WEATHER_ALERTS_UPDATE,
    userWeatherAlerts,
  };
}

export function userWeatherAlertsUpdate(
  userWeatherAlerts: Partial<UserWeatherAlerts>
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const state = getState();
    if (!userWeatherAlerts) return;
    dispatch(userWeatherAlertsSet(userWeatherAlerts));
    try {
      const token = getAuthToken(state);
      const alerts = getUserWeatherAlerts(state);
      if (token) {
        await tryUpdateUserWeatherAlerts(token, {
          ...alerts,
          ...userWeatherAlerts,
        });
      } else {
        localStorageSet(LocalStorageKeys.userWeatherAlerts, {
          ...alerts,
          ...userWeatherAlerts,
        });
      }
      const locationId = userWeatherAlerts.locationId;
      if (locationId) {
        const { popupDate } = localStorageGet(
          LocalStorageKeys.notificationsPopup,
          defaultNotificationsPopupStorage
        );

        dispatch(notificationsPopupLocationIdSet(locationId));
        localStorageSet(LocalStorageKeys.notificationsPopup, {
          popupDate,
          popupLocationId: locationId,
        });
      }
    } catch (e) {
      console.error("userWeatherAlertsUpdate: ", e);
    }
  };
}

export function getUsersWeatherAlertsStatistic() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const state = getState();

    const token = getAuthToken(state);
    if (!token) return;

    const userProfile = getUserProfile(state);
    const email = userProfile.email;
    if (!email || !weatherAlertsStatisticEmails.includes(email)) return;

    try {
      const statistic = await fetchUsersAlertsStatistic(token);
      return statistic;
    } catch (e) {
      console.error("getUsersWeatherAlertsStatistic: ", e);
    }
    return;
  };
}

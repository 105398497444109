import React from "react";
import styles from "./WeatherDescription.module.scss";
import {
  formatSpeed,
  formatTemp,
  formatWindDirection,
} from "../../../types/units";
import { getWeatherDescription } from "../../../types/weatherCodes";
import { SelectedDayWeather } from "../DayWeatherHead";
import { useSelector } from "react-redux";
import { getUnits } from "../../../store/selectors/units";
import { useFormatMessage } from "../../../i18n";

type WeatherDescriptionProps = {
  weather: SelectedDayWeather | undefined;
};

const WeatherDescription: React.FC<WeatherDescriptionProps> = ({ weather }) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();
  const description = weather
    ? formatMessage("WeatherDescription", {
        condition: getWeatherDescription(
          formatMessage,
          weather.weatherCode,
          weather.weatherText || weather.weatherDesc
        ),
        windDir: formatWindDirection(formatMessage, weather.windDir),
        windSpeed: formatSpeed(formatMessage, weather.windSpeed, units),
        temp: formatTemp(weather.temp, units),
      })
    : "";

  return (
    <div className={styles.wrap}>{description ? description : <>&nbsp;</>}</div>
  );
};

export default WeatherDescription;

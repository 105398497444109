import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./DesktopHeader.module.scss";
import { LocaleMessage } from "../../Locale";
import { useDispatch, useSelector } from "../../../store/hooks";
import { authShowDialog, authLogout } from "../../../store/actions/auth";
import { getAuthUser } from "../../../store/selectors/auth";
import iconLogout from "../../../assets/icons/i-logout.svg";
import iconProfile from "../../../assets/icons/i-profile-settings.svg";
import { getAccountSettingsUrl } from "../../../utils/url";

const AuthState: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getAuthUser);
  const [open, setOpen] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const nameInitials = user?.name
    ? user.name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
        .toUpperCase()
    : "";

  const checkImgLoaded = async (src: string | undefined) => {
    if (!src) return setImgLoaded(false);
    const res = await fetch(src, { mode: "no-cors" });
    return setImgLoaded(!!res.ok);
  };

  useEffect(() => {
    if (!imgLoaded) {
      checkImgLoaded(user?.pictureUrl);
    }
  }, [imgLoaded, user]);

  if (!user) {
    return (
      <div className={styles.loginWrap}>
        <button
          className={styles.loginBtn}
          onClick={() => dispatch(authShowDialog(true))}
        >
          <LocaleMessage id="Login" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.loginWrap}>
      <button
        className={`${styles.popupBtn} ${open ? styles.open : ""}`}
        onClick={() => setOpen(!open)}
      >
        {imgLoaded ? (
          <img
            className={styles.avatar}
            src={user.pictureUrl}
            alt={user.name}
          />
        ) : (
          <div className={styles.emptyAvatar}>{nameInitials}</div>
        )}
        <div className={styles.userName}>{user.name}</div>
      </button>
      {open && (
        <div className={styles.popup}>
          <Link
            className={styles.logoutBtn}
            to={getAccountSettingsUrl()}
            onClick={() => setOpen(false)}
          >
            <img
              src={iconProfile}
              alt="settings"
              className={styles.logoutImg}
            />{" "}
            <LocaleMessage id="AccountSettings" />
          </Link>
          <Link
            className={styles.logoutBtn}
            to="/"
            onClick={() => {
              dispatch(authLogout());
              setOpen(false);
            }}
          >
            <img src={iconLogout} alt="logout" className={styles.logoutImg} />
            <LocaleMessage id="Logout" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default AuthState;

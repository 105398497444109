import React from "react";
import styles from "./Card.module.scss";

type CardSectionTitleProps = {
  title?: string;
};

export const CardSectionTitle: React.FC<CardSectionTitleProps> = ({
  title,
}) => {
  return <h3 className={styles.cardSectionTitle}>{title}</h3>;
};

type CardSectionProps = {
  className?: string;
};

export const CardSection: React.FC<CardSectionProps> = ({
  children,
  className = "",
}) => {
  return (
    <section className={`${styles.cardSection} ${className}`}>
      {children}
    </section>
  );
};

type CardTitleProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
};

export const CardTitle: React.FC<CardTitleProps> = ({
  title,
  subTitle,
  icon,
  className,
}) => {
  return (
    <div className={`${styles.cardTitle} ${className ?? ""} `}>
      {icon}
      <h3
        className={`title mr-20 ${styles.cardTitleText} ${
          icon ? styles.cardTitleWithIcon : ""
        }`}
      >
        {title}
      </h3>
      {!!subTitle && <div className="sub-title">{subTitle}</div>}
    </div>
  );
};

type CardProps = {
  id?: string;
  className?: string;
};

export const Card: React.FC<CardProps> = ({
  children,
  className = "",
  id = "",
}) => {
  return (
    <div id={id} className={`${styles.card} ${className}`}>
      {children}
    </div>
  );
};

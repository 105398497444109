import React from "react";
import { formatTempShort } from "../../../types/units";
import styles from "./HomeWeatherHead.module.scss";
import PlaceTime from "../../../components/PlaceTime";
import WeatherCondition from "../../../components/WeatherCondition";
import { getWeatherDescription } from "../../../types/weatherCodes";
import { Link } from "react-router-dom";
import { getLocationWeatherUrl } from "../../../utils/url";
import NextHoursForecastChart from "../../../components/NextHoursForecastChart";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import {
  getWeatherLocation,
  getSelectedCurrentWeather,
  getSelectedDailyForecast
} from "../../../store/selectors/weather";
import { getLocationRegion } from "../../../store/selectors/locations";
import { getRegionCountry } from "../../../store/selectors/regions";
import { getCountryContinent } from "../../../store/selectors/countries";
import { useLocale, useFormatMessage } from "../../../i18n";
import { LocaleMessage } from "../../../components/Locale";
import DayBackground from "../../../components/DayBackground";

const FullForecastLink: React.FC<{ className: string }> = ({ className }) => {
  const locale = useLocale();
  const location = useSelector(getWeatherLocation);
  const region = useSelector(x => getLocationRegion(x, location));
  const country = useSelector(x => getRegionCountry(x, region));
  const continent = useSelector(x => getCountryContinent(x, country));

  const fullUrl = getLocationWeatherUrl(
    locale,
    continent,
    country,
    region,
    location
  );
  return (
    <Link
      to={fullUrl}
      className={className}
      style={!location ? { visibility: "hidden" } : undefined}
    >
      <LocaleMessage id="GoToFullForecast" />
    </Link>
  );
};

const PlaceName: React.FC = () => {
  const location = useSelector(getWeatherLocation);
  return (
    <div className={styles.placeName}>
      {location ? location.name : <LocaleMessage id="Loading" />}
    </div>
  );
};

const HomeWeatherDetails: React.FC = () => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();
  const currentWeather = useSelector(getSelectedCurrentWeather);
  const dailyForecast = useSelector(getSelectedDailyForecast);

  const weather = currentWeather || dailyForecast;
  const day = currentWeather ? currentWeather.day : true;
  const { temp = undefined, tempFeels = undefined } = weather || {};

  return (
    <div className={styles.currentWrap}>
      <div className={styles.currentWeather}>
        {weather ? (
          currentWeather ? (
            <LocaleMessage id="CurrentWeather" />
          ) : (
            <LocaleMessage id="TodayWeather" />
          )
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <PlaceName />
      <PlaceTime weather={weather} />

      <div className={styles.tempConditionWrap}>
        <div className={styles.temp}>
          {temp ? formatTempShort(temp, units) : <>&nbsp;</>}
        </div>
        {weather && <div className={styles.tempConditionSplit} />}

        {weather && (
          <WeatherCondition
            weatherCode={weather.weatherCode}
            weatherDesc={weather.weatherDesc}
            day={day}
            className={styles.conditionImg}
          />
        )}

        <div>
          <div className={styles.weatherDesc}>
            {weather &&
              getWeatherDescription(
                formatMessage,
                weather.weatherCode,
                weather.weatherDesc
              )}
          </div>
          <div className={styles.tempFeels}>
            {tempFeels && (
              <LocaleMessage
                id="FeelsLikeTemp"
                values={{ temp: formatTempShort(tempFeels, units) }}
              />
            )}
          </div>
        </div>
      </div>

      <FullForecastLink className={styles.fullForecast} />
    </div>
  );
};

const HomeWeatherHead: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <DayBackground />
      <div className={`container ${styles.container}`}>
        <HomeWeatherDetails />
        <NextHoursForecastChart className={styles.next12hours} />
        <FullForecastLink className={styles.fullForecastMobile} />
      </div>
    </div>
  );
};

export default HomeWeatherHead;

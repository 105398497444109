import React from "react";
import styles from "./Controls.module.scss";

type RadioProps = {
  name: string;
  onChange: (selected: string) => void;
  label: string;
  checked?: boolean;
};

export const Radio: React.FC<RadioProps> = ({
  name,
  onChange,
  label,
  checked,
}) => {
  return (
    <label className={styles.radio}>
      <span
        className={`${styles.radioView} ${checked ? styles.radioActive : ""}`}
      >
        {checked && <span className={styles.radionIconCheck}></span>}
      </span>
      <input
        checked={checked}
        name={name}
        className={styles.radioInput}
        type="radio"
        value={label}
        onChange={() => onChange(label)}
      />
      <h3 className={styles.radioLabel}>{label}</h3>
    </label>
  );
};

import React from "react";
import { LocaleMessage } from "../../../components/Locale";
import { useSelector } from "../../../store/hooks";
import { getSelectedRadarImages } from "../../../store/selectors/weather";
import WeatherImagesCard from "../WeatherImagesCard";
import LocationName from "../../../components/LocationName";

const RadarImages: React.FC = () => {
  const images = useSelector(getSelectedRadarImages);
  if (!images || !images.length) return null;

  return (
    <div className="container section">
      <WeatherImagesCard
        title={
          <LocaleMessage id="RadarTitle" values={{ name: <LocationName /> }} />
        }
        images={images}
      />
    </div>
  );
};

export default RadarImages;

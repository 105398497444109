import {
  RegionsActionTypes,
  REGIONS_LOADED,
  REGIONS_BY_COUNTRY_LOADING,
  REGIONS_BY_COUNTRY_LOADED,
} from "../types/regions";
import { Region, LocationType } from "../../api/placesApi";
import { toMap } from "../../utils/array";
import { combineReducers } from "redux";
import { LocaleActionTypes, LOCALE_SET } from "../types/locale";

type ByCountryState = {
  [countryId: string]: {
    loading: boolean;
    ids: string[];
  };
};

type ByIdState = { [_id: string]: Region };

export type RegionsState = {
  readonly byId: ByIdState;
  readonly byCountry: ByCountryState;
  readonly selectedId: string;
};

function byIdReducer(
  state: ByIdState = {},
  action: RegionsActionTypes | LocaleActionTypes
): ByIdState {
  switch (action.type) {
    case REGIONS_LOADED:
      return { ...state, ...toMap(action.regions, (x) => x._id) };
    case LOCALE_SET:
      return {};
    default:
      return state;
  }
}

function createByCountryReducer(locationType: LocationType) {
  return function (
    state: ByCountryState = {},
    action: RegionsActionTypes | LocaleActionTypes
  ): ByCountryState {
    switch (action.type) {
      case REGIONS_BY_COUNTRY_LOADING:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.countryId]: { ...state[action.countryId], loading: true },
        };
      case REGIONS_BY_COUNTRY_LOADED:
        if (action.locationType !== locationType) return state;
        return {
          ...state,
          [action.countryId]: {
            loading: false,
            ids: action.regionIds,
          },
        };
      case LOCALE_SET:
        return {};
      default:
        return state;
    }
  };
}

const byCountryReducer = combineReducers({
  p: createByCountryReducer("p"),
  c: createByCountryReducer("c"),
});

export default combineReducers({
  byCountry: byCountryReducer,
  byId: byIdReducer,
});

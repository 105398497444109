import { DailyForecast } from "../../api/weatherApi";

export const HOME_SHORT_LOADED = "HOME_SHORT_LOADED";

type HomeShortUpdateAction = {
  type: typeof HOME_SHORT_LOADED;
  forecast: DailyForecast;
};

export type HomeShortActionTypes = HomeShortUpdateAction;

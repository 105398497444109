import { LanguageStrings } from ".";

const uk: LanguageStrings = {
  // Page titles
  HomeTitle: "Weatherman: Глобальний, національний і місцевий прогноз погоди",
  ContactUsTitle: "Зворотний зв'язок | Weatherman",
  PrivacyPolicyTitle: "Політика конфіденційності | Weatherman",
  TermsOfUseTitle: "Правила користування | Weatherman",
  WeatherSelectionTitle: "{name}: прогноз погоди і температури | Weatherman",
  WeatherSelectionPlaceTitle:
    "{name}: прогноз погоди і температури у місцях | Weatherman",
  WeatherTitleToday: "{name}: погодинний прогноз на сьогодні | Weatherman",
  WeatherTitleTomorrow: "{name}: погодинний прогноз на завтра | Weatherman",
  WeatherTitleDays10: "{name}: прогноз погоди на 10 днів | Weatherman",
  WeatherTitleTides: "{name}: графік хвиль | Weatherman",
  WeatherTitleRadar: "{name}: супутникові знімки погоди | Weatherman",

  // Page descriptions
  HomeDesc:
    "Weatherman показує прогнози погоди для міст, сіл там місць, а також погодні карти, радар, та інформацію про хвилі.",
  ContactUsDesc:
    "Зв'яжіться з Weatherman з усіма питаннями та побажаннями які ви маєте відносно нашого сайту та сервісу.",
  PrivacyPolicyDesc:
    "Будь ласка, прочитайте нашу політику конфіденційності. Ваша приватність важлива для нас та нашого комюніті. Будь ласка, зв'яжіться з нами з усіма питаннями.",
  TermsOfUseDesc:
    "Сподіваємось Вам сподобався Weatherman.com. Будь ласка, прочитайте наші правила користування щоб зрозуміти як ми працюємо та як працюють наші сервіси.",
  WeatherSelectionDesc:
    "Weatherman показує прогноз погоди, виміри температури, та погодні карти для міст у {name}.",
  WeatherSelectionPlaceDesc:
    "Weatherman показує прогноз погоди, виміри температури, та погодні карти для місць і напрямків у {name}.",
  WeatherDescToday:
    "Погодинні температури і детальний прогноз погоди для {name}.",
  WeatherDescTomorrow:
    "Завтрашній годинний прогноз і детальні погодні звіти для {name}.",
  WeatherDescDays10:
    "10-денний погодний прогноз і детальні погодні звіти для {name}.",
  WeatherDescTides: "Графік хвиль для {name} і навколишніх територій.",
  WeatherDescRadar: "Знімки зі супутника та Доплерівського радару для {name}.",

  PageNotFound: "Вибачте, сторінку не знайдено",
  Login: "Вхід",
  Logout: "Вихід",
  AccountSettings: "Налаштування",
  ContactInformation: "Контактна інформація",
  CurrentLocation: "Поточне місцезнаходження",
  DetectAutomatically: "Визначити автоматично",
  SelectManually: "Вибрати вручну",
  AlertAccountSettingsManualLocation:
    "Ви можете встановити поточне місцезнаходження вручну. Майте на увазі, коли ви заходите на сайт, місцезнаходження не буде встановлено GPS.",
  HomePage: "Головна",
  TodaysWeather: "Погода сьогодні",
  WorldWeather: "Світ",
  WeatherWidget: "Погодний віджет",
  GpsLocation: "GPS координати",
  SearchPlaceholder: "Пошук локації",
  Units: "Одиниці",
  Language: "Мова",
  Navigation: "Навігація",
  Information: "Інформація",
  JoinUs: "Стежте за нами",
  ContactUs: "Зворотний зв'язок",
  PrivacyPolicy: "Політика конфіденційності",
  TermsOfUse: "Правила користування",
  Copyright: "© {year} WeatherMan. Всі права захищено",
  NearbyCitiesAndTowns: "Міста та села неподалік",
  NearbyPlaces: "Цікаві місця",
  GoToPlacesPage: "На сторінку міст >",
  GoToFullForecast: "Детальний прогноз >",
  CurrentWeather: "Погода зараз",
  TodayWeather: "Погода сьогодні",
  Loading: "Завантажується...",
  FeelsLikeTemp: "Відчувається як {temp}",
  Next12Hours: "Наступні 12 годин",
  Next24Hours: "Наступні 24 години",
  WeatherSelectionHeader: "Погода у світі",
  WeatherSelectionHeader2:
    "Виберіть місто для якого ви хочете побачити детальний проноз погоди",
  WeatherSelectionPlacesHeader2:
    "Виберіть місце для якого ви хочете побачити детальний проноз погоди",
  WeatherSelectionLocationType: "Виберіть тип локації",
  WeatherSelectionContinents: "Континент",
  WeatherSelectionCountries: "Країна",
  WeatherSelectionRegions: "Область / регіон / штат",
  WeatherSelectionCities: "Міста",
  WeatherSelectionPlaces: "Місця",
  WeatherDescription:
    "{condition} з {windDir} вітром швидкістю {windSpeed} і температурою {temp}.",
  Now: "Зараз",
  Sunrise: "Схід сонця",
  Sunset: "Захід сонця",
  Moonrise: "Схід місяця",
  Moonset: "Захід місяця",
  SunAndMoon: "Сонце і Місяць",
  LengthOfVisibleLight: "Тривалість світлового дня",
  LengthOfDay: "Тривалість дня",
  DetailedHourlyForecastTitleToday: "{name}: Детальна годинна погода",
  DetailedHourlyForecastTitle: "{name}: Детальний годинний прогноз",
  ExtendedForecastTitleToday: "{name}: Розширений прогноз",
  ExtendedForecastTitle: "{name}: Розширений прогноз",
  Today: "Сьогодні",
  AverageTemperatureTitle: "{name}: Середня температура",
  MaxTemp: "Максимальна",
  AvgTemp: "Середня",
  MinTemp: "Мінімальна",
  AverageTemperatureTempAxis: "Темп, {unit}",
  AverageRainfallTitle: "{name}: Рівень опадів",
  AverageRainfall: "Середні опади",
  AvgRainfall: "Рівень опадів",
  RainfallAxis: "Опади",
  NextXDays:
    "{days, plural, =1 {Наступний день} few {Наступні # дні} other {Наступні # днів}}",
  SeeDetailedForecast10: "Переглянути детальний прогноз на 10 днів",
  DetailedDailyForecastTitle10: "{name}: Детальний прогноз на 10 днів",
  MinutesDuration: "{hours}:{minutes}",
  PopularCities: "Популярні міста",
  FavoriteCities: "Улюблені",

  // Consent
  CookieOk: "Добре",
  CookieText:
    "Ми використовуєм кукі щоб персоналізувати вміст сайту та аналізувати трафік.\nВаша приватність важлива для нас. Прочитайте більше у ",

  // Login
  LoginWelcome: "Ласкаво просимо до Weatherman",
  LoginContinue: "Будь-ласк авторизуйтесь",
  LoginGoogle: "Зайти з Google",
  LoginFacebook: "Зайти з Facebook",

  // Share
  Share: "Поділитись",
  ShareCopyLink: "Копіювати",

  // Radar
  RadarMapTitle: "Погодні умови: {name}",
  WeatherMap: "Погодня карта:",
  WeatherMaps: "Карти погоди",
  HideAdvancedMaps: "Сховати розширені карти",
  ShowAdvancedMaps: "Показати розширені карти",
  RadarTitle: "Доплерівський радар: {name}",
  SatelliteTitle: "Погода з супутника: {name}",
  RadarPause: "Пауза",
  RadarPlay: "Грати",

  // Map layers
  MapPrecipitationIntensity: "Інтенсивність опадів",
  MapCloudiness: "Хмарність",
  MapHumidity: "Відносна вологість",
  MapWindDir: "Напрямок вітру",
  MapAccPrecipitation: "Сумарні опади",
  MapAccPrecipitationRain: "Сумарні опади - дощ",
  MapAccPrecipitationSnow: "Сумарні опади - сніг",
  MapSnowDepth: "Глибина снігу",
  MapConvectivePrecipitation: "Конвективні опади",
  MapWindSpeed: "Швидкість вітру",
  MapPressure: "Атмосферний тиск",
  MapTemperature: "Температура повітря",
  MapDewPoint: "Точка роси",
  MapSoil0: "Температура грунту 0-10 см",
  MapSoil10: "Температура грунту >10 см",

  // Tides
  TidePredictions: "Графік хвиль: {name}",
  TidesHourly: "Погодинно",
  TideHourlyPredictions: "Годинний прогноз хвиль",

  TideHeight: "Висота хвиль",
  TideHeightAxis: "Вис, {unit}",

  TimeAt: "в",

  TideTime: "Час",
  TideLevel: "Висота",

  TideH: "Високий",
  TideL: "Низький",

  // Alerts
  AlertSummary:
    "{description} діє{start, select, none {} other { з {start}}}{end, select, none {} other { до {end}}}. Джерело: {source}.",
  AlertMore: "Більше",
  AlertWarning: "УВАГА!",
  AlertCloseBtn: "Закрити",

  //Contact Us
  ContactName: "Ім'я",
  ContactEmail: "Електронна адреса",
  ContactMessage: "Повідомлення",
  ContactSubmit: "Відправити",
  FieldRequired: "Обов'язкове поле",
  ContactBadEmail: "Неправильна електронна адреса",
  ContactThankyou: "Дякуємо за зворотний зв'язок!",

  // Search
  SearchRecent: "Нещодавно переглянуті",
  SearchClear: "Очистити",
  SearchNearestByIp: "Найближчі міста за IP адресою",
  SearchCities: "Міста та села",
  SearchPlaces: "Місця",
  SearchCountries: "Країни",
  SearchRegions: "Регіони / штати",
  SearchResults: "Результати пошуку",
  SearchLoading: "Завантаження...",
  SearchNoMatch: "Не знайдено",

  // Continents
  ContinentAF: "Африка",
  ContinentAN: "Антарктика",
  ContinentAS: "Азія",
  ContinentEU: "Європа",
  ContinentNA: "Північна Америка",
  ContinentSA: "Південна Америка",
  ContinentOC: "Океанія",
  // Weather types
  WeatherTypeToday: "Сьогодні",
  WeatherTypeTomorrow: "Завтра",
  WeatherTypeDays10: "10 днів",
  WeatherTypeTides: "Хвилі",
  WeatherTypeRadar: "Супутник",
  BreadcrumbNameToday: "{name} сьогодні",
  BreadcrumbNameTomorrow: "{name} завтра",
  BreadcrumbNameDays10: "{name}: Прогноз на 10 днів",
  BreadcrumbNameTides: "{name}: Хвилі",
  BreadcrumbNameRadar: "{name}: Супутник",
  // MoonPhases
  MoonPhase0: "Новий",
  MoonPhase1: "Зростаючий півмісяць",
  MoonPhase2: "Перша четверть",
  MoonPhase3: "Зростаючий опуклий",
  MoonPhase4: "Повний",
  MoonPhase5: "Спадаючий опуклий",
  MoonPhase6: "Остання четверть",
  MoonPhase7: "Спадаючий півмісяць",
  // Directions
  DirShortN: "Пн",
  DirShortNNE: "-",
  DirShortNE: "Пн-Сх",
  DirShortENE: "-",
  DirShortE: "Сх",
  DirShortESE: "-",
  DirShortSE: "Пд-Сх",
  DirShortSSE: "-",
  DirShortS: "Пд",
  DirShortSSW: "-",
  DirShortSW: "Пд-Зх",
  DirShortWSW: "-",
  DirShortW: "Зх",
  DirShortWNW: "-",
  DirShortNW: "Пн-Зх",
  DirShortNNW: "-",
  DirFullN: "північним",
  DirFullW: "західним",
  DirFullS: "південним",
  DirFullE: "східним",
  DirFullNW: "північно-західним",
  DirFullNE: "північно-східним",
  DirFullSW: "південно-західним",
  DirFullSE: "південно-східним",
  DirFullNNW: "-",
  DirFullNNE: "-",
  DirFullENE: "-",
  DirFullESE: "-",
  DirFullSSW: "-",
  DirFullSSE: "-",
  DirFullWSW: "-",
  DirFullWNW: "-",
  // Units
  UnitMiles:
    "{value, plural, =1 {#{suffix} миля} few {#{suffix} милі} other {#{suffix} миль}}",
  UnitKm: "{value}{suffix} км",
  UnitMilesPerHour:
    "{value, plural, =1 {1 миля} few {# милі} other {# миль}}/год",
  UnitMetersPerSecond: "{value} м/с",
  UnitMm: "{value} мм",
  UnitM: "{value} м",
  UnitFt: "{value} фт",
  UnitInches: '{value} "',
  UnitMmHg: "{value} мм рт.ст.",
  UnitILow: "малий",
  UnitILight: "легкий",
  UnitIModerate: "середній",
  UnitIHigh: "високий",
  UnitIHeavy: "сильний",
  UnitIVeryHigh: "дуже високий",
  UnitIExtreme: "екстрім",
  UnitIPoor: "поганий",
  UnitIFair: "задовільний",
  UnitIGood: "добрий",
  UnitIVeryGood: "дуже добрий",
  UnitIExcellent: "відмінний",
  UnitIndex: "{value} ({level})",
  // Fields
  Wind: "Вітер",
  Visibility: "Видимість",
  DewPt: "Точка роси",
  Humidity: "Вологість",
  UVIndex: "УФ індекс",
  Pressure: "Тиск",
  FeelsLike: "Відчувається як",
  WetBulbTemp: "Температура мокрого термометра",
  WindGust: "Пориви вітру",
  CloudCeiling: "Висота хмар",
  CloudCover: "Хмарність",
  PrecipitationProbability: "Імовірність опадів",
  SnowProbability: "Імовірність снігу",
  IceProbability: "Імовірність льоду",
  TStormProbability: "Імовірність бурі",
  TotalLiquid: "Всього опадів",
  Rain: "Дощ",
  Snow: "Сніг",
  Ice: "Лід",
  Mixed: "Змішані",
  MoonAge: "Вік місяця",
  FeelsLikeShade: "Відчувається як (в тіні)",
  PrecipitationDuration: "Тривалість опадів",
  RainDuration: "Тривалість дощу",
  SnowDuration: "Тривалість снігу",
  IceDuration: "Тривалість граду",
  PrecipitationType: "Тип опадів",
  PrecipitationIntensity: "Інтенсивність опадів",
  AirQuality: "Якість повітря",
  Grass: "Трава",
  Mold: "Цвіль",
  Ragweed: "Амброзія",
  Tree: "Дерево",

  // Weather codes
  WeatherCodeSunny: "Сонячно",
  WeatherCodePartlyCloudy: "Мінлива хмарність",
  WeatherCodeCloudy: "Хмарно",
  WeatherCodeOvercast: "Похмуро",
  WeatherCodeMist: "Туман",
  WeatherCodePatchyRainPossible: "Місцями можливий дощ",
  WeatherCodePatchySnowPossible: "Місцями можливий сніг",
  WeatherCodePatchySleetPossible: "Місцями можливий мокрий сніг",
  WeatherCodePatchyFreezingDrizzlePossible:
    "Місцями може моросити морозний дощ",
  WeatherCodeThunderyOutbreaksPossible: "Можливі спалахи грози",
  WeatherCodeBlowingSnow: "Дме сніг",
  WeatherCodeBlizzard: "Завірюха",
  WeatherCodeFog: "Туман",
  WeatherCodeFreezingFog: "Морозний туман",
  WeatherCodePatchyLightDrizzle: "Місцями моросить легкий дощ",
  WeatherCodeLightDrizzle: "Моросить легкий дощ",
  WeatherCodeFreezingDrizzle: "Моросить морозний дощ",
  WeatherCodeHeavyFreezingDrizzle: "Моросить сильний морозний дощ",
  WeatherCodePatchyLightRain: "Місцями легкий дощ",
  WeatherCodeLightRain: "Легкий дощ",
  WeatherCodeModerateRainAtTimes: "Часом помірний дощ",
  WeatherCodeModerateRain: "Помірний дощ",
  WeatherCodeHeavyRainAtTimes: "Часом сильний дощ",
  WeatherCodeHeavyRain: "Сильний дощ",
  WeatherCodeLightFreezingRain: "Легкий морозний дощ",
  WeatherCodeModerateOrHeavyFreezingRain: "Помірний або сильний морозний дощ",
  WeatherCodeLightSleet: "Легкий мокрий сніг",
  WeatherCodeModerateOrHeavySleet: "Помірний або сильний мокрий сніг",
  WeatherCodePatchyLightSnow: "Місцями легкий сніг",
  WeatherCodeLightSnow: "Легкий сніг",
  WeatherCodePatchyModerateSnow: "Місцями помірний сніг",
  WeatherCodeModerateSnow: "Помірний сніг",
  WeatherCodePatchyHeavySnow: "Місцями сильний сніг",
  WeatherCodeHeavySnow: "Сильний сніг",
  WeatherCodeIcePellets: "Град",
  WeatherCodeLightRainShower: "Легка злива",
  WeatherCodeModerateOrHeavyRainShower: "Помірна або сильна злива",
  WeatherCodeTorrentialRainShower: "Проливна злива",
  WeatherCodeLightSleetShower: "Легкий снігопад з мокрим снігом",
  WeatherCodeModerateOrHeavySleetShower:
    "Помірний або сильний снігопад з мокрим снігом",
  WeatherCodeLightSnowShower: "Легкий снігопад",
  WeatherCodeModerateOrHeavySnowShower: "Помірний або сильний снігопад",
  WeatherCodeLightShowerOfIcePellets: "Легка злива з градом",
  WeatherCodeModerateOrHeavyShowerOfIcePellets:
    "Помірна або сильна злива з градом",
  WeatherCodePatchyLightRainInAreaWithThunder: "Місцями невелика гроза",
  WeatherCodeModerateOrHeavyRainInAreaWithThunder: "Помірна або сильна гроза",
  WeatherCodePatchyLightSnowInAreaWithThunder:
    "Місцями невеликий сніг з грозою",
  WeatherCodeModerateOrHeavySnowInAreaWithThunder:
    "Помірний або сильний сніг з грозою",
  // AccuWeather codes
  WeatherCodeMostlySunny: "Переважно сонячно",
  WeatherCodePartlySunny: "Місцями сонячно",
  WeatherCodeIntermittentClouds: "Змінна хмарність",
  WeatherCodeHazySunshine: "Сонце у тумані",
  WeatherCodeMostlyCloudy: "Переважно хмарно",
  WeatherCodeShowers: "Зливи",
  WeatherCodeMostlyCloudyWithShowers: "Переважно хмарно зі зливами",
  WeatherCodePartlySunnyWithShowers: "Місцями сонячно зі зливами",
  WeatherCodeTStorms: "Грози",
  WeatherCodeMostlyCloudyWithTStorms: "Переважно хмарно з грозами",
  WeatherCodePartlySunnyWithTStorms: "Місцями сонячно з грозами",
  WeatherCodeRain: "Дощ",
  WeatherCodeFlurries: "Короткочасний сніг",
  WeatherCodeMostlyCloudyWithFlurries: "Переважно хмарно з легким снігом",
  WeatherCodePartlySunnyWithFlurries: "Місцями сонячно з легким снігом",
  WeatherCodeSnow: "Сніг",
  WeatherCodeMostlyClodyWithSnow: "Переважно хмарно зі снігом",
  WeatherCodeIce: "Град",
  WeatherCodeSleet: "Мокрий сніг",
  WeatherCodeFreezingRain: "Крижаний дощ",
  WeatherCodeRainAndSnow: "Дощ і сніг",
  WeatherCodeHot: "Спекотно",
  WeatherCodeCold: "Холодно",
  WeatherCodeWindy: "Вітряно",
  WeatherCodeClear: "Ясно",
  WeatherCodeMostlyClear: "Переважно ясно",
  WeatherCodeHazyMoonlight: "Місяць у тумані",
  WeatherCodePartlyCloudyWithShowers: "Місцями хмарно зі зливами",
  WeatherCodePartlyClodyWithTStorms: "Місцями хмарно з грозами",
  //WeatherCodeMostlyCloudyWithTStorms: "Переважно хмарно з грозами",
  WeatherCodeMostlyCloudyWithSnow: "Переважно хмарно зі снігом",

  Favorites: "Вибрані",
  AccSettingsLocationInfoMsg: "Сповіщення",
  AccSettingsAlertsStatistic: "Статистика сповіщень",

  //Alerts
  Alert1: "Попередження про снігопади",
  Alert10: "Спостереження за смерчем",
  Alert11: "Попередження про смерч",
  Alert12: "Попередження про сильні вітри",
  Alert13: "Попередження про зливи",
  Alert14: "Попередження про штормові вітри",
  Alert15: "Попередження про стрімке зниження температури",
  Alert16: "Попередження про хвилю похолодання",
  Alert17: "Попередження про арктичні повітряні потоки",
  Alert18: "Попередження про екстремальний холод",
  Alert19: "Попередження про снігові шквали",
  Alert2: "Попередження про хуртовину",
  Alert21: "Попередження про руйнівні вітри",
  Alert22: "Спостереження за тропічним штормом",
  Alert23: "Попередження про тропічний шторм",
  Alert24: "Спостереження за ураганом",
  Alert25: "Попередження про ураган",
  Alert26: "Попередження про штормовий нагін",
  Alert27: "Попередження про пилову бурю",
  Alert28: "Попередження про крижану мряку",
  Alert29: "Попередження про низові заметілі",
  Alert3: "Попередження про снігові шквали",
  Alert30: "Попередження про фронтальні шквали",
  Alert31: "Попередження про морози",
  Alert32: "Попередження про спеку",
  Alert33: "Попередження про сильні південно-східні вітри",
  Alert34: "Спостереження за сніговими шквалами",
  Alert35: "Спостереження за сніговими шквалами",
  Alert36: "Метеозведення про морози",
  Alert37: "Метеозведення про туман",
  Alert38: "Спеціальне повідомлення про якість повітря",
  Alert39: "Спеціальне повідомлення про погодні умови",
  Alert4: "Попередження про вітри",
  Alert40: "Інформаційне повідомлення про тропічний циклон",
  Alert5: "Спостереження за бураном",
  Alert6: "Попередження про буран",
  Alert7: "Попередження про крижаний дощ",
  Alert8: "Попередження про надзвичайно сильні грози",
  Alert9: "Спостереження за ситуацією із надзвичайно сильними грозами",
  AlertAFW: "Попередження про викиди вулканічного попелу",
  AlertAFY: "Метеозведення про викиди вулканічного попелу",
  AlertASY: "Метеозведення про застоювання повітря",
  AlertAWXAQA: "Попередження щодо якості повітря",
  AlertAWXAVA: "Спостереження за ситуацією зі сходженням лавин",
  AlertAWXAVW: "Попередження про можливість сходження лавин",
  AlertAWXAWG: "Попередження ППО і ПРО країни",
  AlertAWXCAE: "Надзвичайне повідомлення про викрадення дитини",
  AlertAWXCDW: "Попередження громадськості про небезпеку",
  AlertAWXCEM: "Повідомлення громадськості про надзвичайну ситуацію",
  AlertAWXEQW: "Попередження про землетрус",
  AlertAWXEVI: "Негайна евакуація",
  AlertAWXFFS: "Повідомлення про несподіваний паводок",
  AlertAWXFLS: "Повідомлення про паводок",
  AlertAWXFRW: "Попередження про пожежі",
  AlertAWXHMW: "Попередження про небезпечні речовини",
  AlertAWXLAE: "Локальна надзвичайна ситуація",
  AlertAWXLEW: "Попередження про правозастосування",
  AlertAWXNUW: "Попередження АЕС",
  AlertAWXRHW: "Попередження про радіаційну небезпеку",
  AlertAWXSDS: "Спеціальне повідомлення про розповсюдження",
  AlertAWXSPS: "Спеціальне повідомлення про погодні умови",
  AlertAWXSPW: "Знайти найближче укриття",
  AlertAWXTOE: "Вихід з ладу телефонної лінії 911",
  AlertAWXVOW: "Попередження про виверження вулкану",
  AlertBSY: "Метеозведення про низові заметілі",
  AlertBWY: "Метеозведення про сильні вітри",
  AlertBZA: "Спостереження за ситуацією з хуртовиною",
  AlertBZW: "Попередження про хуртовину",
  AlertCFA: "Спостереження за паводковою ситуацією у прибережних територіях",
  AlertCFS: "Повідомлення про паводок у прибережних територіях",
  AlertCFW: "Попередження про паводок у прибережних територіях",
  AlertCFY: "Метеозведення про паводок у прибережних територіях",
  AlertDSW: "Попередження про пилову бурю",
  AlertDUY: "Метеозведення про пилові бурі",
  AlertECA: "Спостереження за ситуацією з екстремальним холодом",
  AlertECW: "Попередження про екстремальний холод",
  AlertEHA: "Спостереження за ситуацією з екстремальною спекою",
  AlertEHW: "Попередження про екстремальну спеку",
  AlertEWW: "Попередження про значні штормові вітри",
  AlertFAA: "Спостереження за паводковою ситуацією в регіоні",
  AlertFAW: "Попередження про паводок в регіоні",
  AlertFAY: "Метеозведення про паводок в регіоні",
  AlertFFA: "Спостереження за ситуацією з несподіваним паводком",
  AlertFFW: "Попередження про несподіваний паводок",
  AlertFGY: "Метеозведення про густий туман",
  AlertFLA: "Спостереження за паводковою ситуацією",
  AlertFLW: "Попередження про паводок",
  AlertFLY: "Метеозведення про паводок",
  AlertFRY: "Метеозведення про морози",
  AlertFWA: "Спостереження за погодними умовами, що сприяють виникненню пожеж",
  AlertFWW: "Попередження про небезпеку",
  AlertFZA: "Спостереження за ситуацією із заморозками",
  AlertFZW: "Попередження про заморозки",
  AlertGLA: "Спостереження за штормовою ситуацією",
  AlertGLW: "Штормове попередження",
  AlertHFA: "Спостереження за вітрами ураганної сили",
  AlertHFW: "Попередження про вітри ураганної сили",
  AlertHIA: "Спостереження за ураганним вітром",
  AlertHIW: "Попередження про ураганний вітер",
  AlertHSW: "Попередження про сильні снігопади",
  AlertHTY: "Метеозведення про спеку",
  AlertHUA: "Спостереження за ураганом",
  AlertHUS: "Повідомлення про тропічний циклон",
  AlertHUW: "Попередження про ураган",
  AlertHWA: "Спостереження за сильними вітрами",
  AlertHWW: "Попередження про сильні вітри",
  AlertHZA: "Спостереження за ситуацією із сильними заморозками",
  AlertHZW: "Попередження про сильні заморозки",
  AlertIPW: "Попередження про опади у вигляді крижаної крупи",
  AlertIPY: "Метеозведення про опади у вигляді крижаної крупи",
  AlertISW: "Попередження про крижану бурю",
  AlertLBY:
    "Метеозведення про снігопади, спричинені проходженням мас повітря над незамерзлими озерами/низові заметілі (т.зв. «Сніговий ефект озера»)",
  AlertLEA:
    "Спостереження за снігопадами, спричиненими проходженням мас повітря над незамерзлими озерами (т.зв. «Сніговим ефектом озера»)",
  AlertLEW:
    "Попередження про снігопади, спричинені проходженням мас повітря над незамерзлими озерами (т.зв. «Сніговий ефект озера»)",
  AlertLEY:
    "Метеозведення про снігопади, спричинені проходженням мас повітря над незамерзлими озерами (т.зв. «Сніговий ефект озера»)",
  AlertLOY: "Метеозведення про відплив",
  AlertLSA: "Спостереження за ситуацією із затопленням берегів озера",
  AlertLSS: "Повідомлення про затоплення берегів озера",
  AlertLSW: "Попередження про затоплення берегів озера",
  AlertLSY: "Метеозведення про затоплення берегів озера",
  AlertLWY:
    "Метеозведення про вітри, спричинені проходженням мас повітря над озерами",
  AlertMAS: "Повідомлення про погодні умови на морі",
  AlertMAW: "Спеціальне морське попередження",
  AlertRBY:
    "Метеозведення для невеликих суден (небезпечне хвилювання на мілині)",
  AlertRPS: "Повідомлення про потужні зворотні хвилі",
  AlertSBY: "Метеозведення про снігопади/низові заметілі",
  AlertSCY: "Метеозведення для невеликих суден",
  AlertSEA: "Спостереження за небезпечним хвилюванням на морі",
  AlertSEW: "Попередження про небезпечне хвилювання на морі",
  AlertSIY: "Метеозведення для невеликих суден (вітри)",
  AlertSMY: "Метеозведення про густий дим",
  AlertSNY: "Метеозведення про снігопади",
  AlertSRA: "Спостереження за штормом",
  AlertSRW: "Штормове попередження",
  AlertSUW: "Попередження про високий прибій",
  AlertSUY: "Метеозведення про високий прибій",
  AlertSVA: "Спостереження за ситуацією із надзвичайно сильними грозами",
  AlertSVW: "Попередження про надзвичайно сильні грози",
  AlertSWY: "Метеозведення для невеликих суден (небезпечне хвилювання на морі)",
  AlertTIA: "Спостереження за вітрами тропічного шторму",
  AlertTIW: "Попередження про вітри тропічного шторму",
  AlertTOA: "Спостереження за смерчем",
  AlertTOW: "Попередження про смерч",
  AlertTRA: "Спостереження за тропічним штормом",
  AlertTRW: "Попередження про тропічний шторм",
  AlertTSA: "Спостереження за цунамі",
  AlertTSW: "Попередження про цунамі",
  AlertTSY: "Метеозведення про цунамі",
  AlertTYA: "Спостереження за тайфуном",
  AlertTYW: "Попередження про тайфун",
  AlertUPA:
    "Спостереження за ситуацією із рясними опадами у вигляді крижаного пилу",
  AlertUPW: "Попередження про рясні опади у вигляді крижаного пилу",
  AlertUPY: "Метеозведення про крижаний пил",
  AlertWCA: "Спостереження за охолодженням вітром",
  AlertWCW: "Попередження про охолодження вітром",
  AlertWCY: "Метеозведення про охолодження вітром",
  AlertWIY: "Метеозведення про вітри",
  AlertWSA: "Спостереження за бураном",
  AlertWSW: "Попередження про буран",
  AlertWWY: "Метеозведення про зимові погодні умови",
  AlertZFY: "Метеозведення про крижаний туман",
  AlertZRY: "Метеозведення про крижаний дощ",
};

export default uk;

export const NOTIFICATIONS_POPUP_DATE_UPDATE =
  "NOTIFICATIONS_POPUP_DATE_UPDATE";
export const NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE =
  "NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE";

type NotificationsPopupDateUpdateAction = {
  type: typeof NOTIFICATIONS_POPUP_DATE_UPDATE;
  notificationsPopupDate: number;
};

type NotificationsPopupLocationIdUpdateAction = {
  type: typeof NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE;
  notificationsPopupLocationId: string;
};

export type NotificationsActionTypes =
  | NotificationsPopupDateUpdateAction
  | NotificationsPopupLocationIdUpdateAction;

import { RootState } from "..";
import { createSelector } from "./createSelector";
import { Country, LocationType } from "../../api/placesApi";
import { getContinent } from "../../types/continents";
import { getLocale } from "./locale";

const byContinent = (state: RootState) => state.countries.byContinent;
const byId = (state: RootState) => state.countries.byId;

export const getCountriesByContinent = (
  state: RootState,
  locationType: LocationType,
  continentId: string
) => {
  if (!continentId) return [];
  const { ids = [] } = byContinent(state)[locationType][continentId] || {};

  return ids.map((x) => getCountryById(state, x)).filter((x) => x) as Country[];
};

export const getCountryById = (state: RootState, countryId: string) =>
  countryId ? byId(state)[countryId] : undefined;

export const createCountryListSelect = (
  getIds: (state: RootState) => string[]
) =>
  createSelector(byId, getIds, (byId, ids) => {
    return ids.map((x) => byId[x]).filter((x) => x);
  });

export function areCountriesByContinentLoaded(
  state: RootState,
  locationType: LocationType,
  continentId: string
) {
  const continent = byContinent(state)[locationType][continentId];
  return continent && !continent.loading;
}

export const getCountryContinent = (
  state: RootState,
  country: Country | undefined
) =>
  country ? getContinent(getLocale(state), country.continentId) : undefined;

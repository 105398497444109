import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { renderRoutes } from "react-router-config";
import routes from "../routes";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Messages from "../components/Messages";
import { IntlProvider } from "react-intl";
import { RouteComponentProps, Switch, Route } from "react-router";
import {
  defaultLocale,
  locales,
  LocaleCode,
  getLocaleMessages,
  langPrefix,
} from "../i18n";
import { useDispatch } from "../store/hooks";
import { localeSet } from "../store/actions/locale";
import { useSelector } from "react-redux";
import { getLocale } from "../store/selectors/locale";
// import { LocaleHelmet } from "../components/Locale";
import CookieConsent from "../components/CookieConsent";

type AppLocaleProps = {
  lang?: string;
};

const AppLocale: React.FC<RouteComponentProps<AppLocaleProps>> = ({
  match: {
    params: { lang = defaultLocale },
  },
}) => {
  const dispatch = useDispatch();
  const currentLocale = useSelector(getLocale);

  useEffect(() => {
    //console.log("lang", lang);
    if (locales.includes(lang as LocaleCode)) {
      dispatch(localeSet(lang as LocaleCode));
    }
  }, [lang, dispatch]);

  if (currentLocale !== lang && locales.includes(lang as LocaleCode))
    return null;

  return (
    <IntlProvider
      locale={lang}
      key={lang}
      defaultLocale={defaultLocale}
      messages={getLocaleMessages(lang)}
    >
      <Helmet defaultTitle="Weatherman"></Helmet>
      {/* <LocaleHelmet /> */}
      <Messages />

      <Header />

      {renderRoutes(routes)}
      <Footer />
      <CookieConsent />
    </IntlProvider>
  );
};

const App: React.FC = () => {
  return (
    <Switch>
      <Route path={langPrefix + "/"} component={AppLocale} />
      <Route component={AppLocale} />
    </Switch>
  );
};

export default App;

import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import { useFormatMessage, FormatMessageFn } from "../../i18n";
import { setCacheControlStatic} from "../../utils/cache";
import { RouteComponentProps } from "react-router-dom";
import { LocaleMessage } from "../../components/Locale";
import { Formik, Form, Field } from "formik";
import { ContactUsForm, postContactUs } from "../../api/contactApi";
import styles from "./ContactUsPage.module.scss";
import { useDispatch } from "react-redux";
import { errorsAdd } from "../../store/actions/errors";
import { getHomeUrl } from "../../utils/url";
import { useScrollTop } from "../../utils/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY } from "../../config";
import {RootDispatch} from "../../store";
import {Request} from "express";
import {tryInit} from "../../store/actions/init";

function validate(
  formatMessage: FormatMessageFn,
  { name, email, message, captcha }: ContactUsForm
) {
  const errors: Partial<{ [key in keyof ContactUsForm]: string }> = {};
  if (!name) {
    errors.name = formatMessage("FieldRequired");
  }

  if (!email) {
    errors.email = formatMessage("FieldRequired");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = formatMessage("ContactBadEmail");
  }

  if (!message) {
    errors.message = formatMessage("FieldRequired");
  }

  if (!captcha) {
    errors.captcha = formatMessage("FieldRequired");
  }

  return errors;
}

async function fetchData(dispatch: RootDispatch, req?: Request) {
  await dispatch(tryInit(req));
}

const ContactUsPage: React.FC<RouteComponentProps> = (props) => {
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch]);

  const formatMessage = useFormatMessage();

  setCacheControlStatic(props);

  useScrollTop([done]);

  return (
    <div className="container section">
      <Helmet title={formatMessage("ContactUsTitle")} />
      <h3 className="title title-solo">
        <LocaleMessage id="ContactUs" />
      </h3>
      {done ? (
        <LocaleMessage id="ContactThankyou" />
      ) : (
        <Formik<ContactUsForm>
          initialValues={{
            name: "",
            email: "",
            message: "",
            captcha: "",
          }}
          onSubmit={async (values) => {
            try {
              await postContactUs(values);
              setDone(true);
              await new Promise((res) => setTimeout(res, 3000));
              props.history.push(getHomeUrl(formatMessage.locale));
            } catch (e) {
              console.error(e);
              dispatch(errorsAdd(e.message));
            }
          }}
          validate={(values) => validate(formatMessage, values)}
        >
          {({ errors, touched, setFieldValue }) => {
            return (
              <Form className={styles.form}>
                <label htmlFor="name">
                  <LocaleMessage id="ContactName" />
                </label>
                <Field name="name" />
                {errors.name && touched.name && (
                  <div className={styles.error}>{errors.name}</div>
                )}
                <label htmlFor="email">
                  <LocaleMessage id="ContactEmail" />
                </label>
                <Field name="email" />
                {errors.email && touched.email && (
                  <div className={styles.error}>{errors.email}</div>
                )}
                <label htmlFor="message">
                  <LocaleMessage id="ContactMessage" />
                </label>
                <Field name="message" as="textarea" />
                {errors.message && touched.message && (
                  <div className={styles.error}>{errors.message}</div>
                )}
                <div className={styles.captcha}>
                  <ReCAPTCHA
                    sitekey={CAPTCHA_KEY}
                    size="normal"
                    hl={formatMessage.locale}
                    onChange={(v) => setFieldValue("captcha", v)}
                  />
                  {errors.captcha && touched.captcha && (
                    <div className={styles.error}>{errors.captcha}</div>
                  )}
                </div>

                <button type="submit">
                  <LocaleMessage id="ContactSubmit" />
                </button>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default ContactUsPage;

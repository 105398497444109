import React from "react";
import DayWeatherHead from "./DayWeatherHead";
import SunAndMoonCard from "./SunAndMoonCard";
import DetailedHourlyForecast from "./DetailedHourlyForecast";
import ExtendedForecast from "./ExtendedForecast";
// import TempAveragesChart from "./TempAveragesChart";
// import RainfallAveragesChart from "./RainfallAveragesChart";
import NearbyCitiesAndTowns from "../../components/NearbyCitiesAndTowns";
import WeatherPageTitle from "./WeatherPageTitle";
import NearbyPlaces from "../../components/NearbyPlaces";

const DayWeatherView: React.FC = () => {
  return (
    <div className="vh-100">
      <WeatherPageTitle />
      <DayWeatherHead />
      <SunAndMoonCard />
      <DetailedHourlyForecast />
      <ExtendedForecast />
      {/* <TempAveragesChart />
      <RainfallAveragesChart /> */}
      <NearbyPlaces />
      <NearbyCitiesAndTowns />
    </div>
  );
};

export default DayWeatherView;

import { RootDispatch, GetState } from "..";
import {
  getSearchLocations,
  getSearchQuery,
  getTopSearchLocation,
} from "../selectors/search";
import { fetchGeoSearch } from "../../api/placesApi";
import { countriesLoaded } from "./countries";
import { regionsLoaded } from "./regions";
import {
  SEARCH_LOADED,
  SEARCH_QUERY,
  SEARCH_FAILED,
  SearchActionTypes,
  SEARCH_PLACEHOLDER,
} from "../types/search";
import { locationsLoaded } from "./locations";
import { debounce } from "throttle-debounce";
import { getLocationRegion, getLocationById } from "../selectors/locations";
import { getRegionCountry } from "../selectors/regions";
import { getCountryContinent } from "../selectors/countries";
import { getLocationWeatherUrl } from "../../utils/url";
import { History } from "history";
import { errorsAdd } from "./errors";
import { getGeoLocation } from "../../utils/gps";
import { getLocale } from "../selectors/locale";

async function searchQueryInner(
  dispatch: RootDispatch,
  getState: GetState,
  query: string
) {
  try {
    if (getSearchQuery(getState()) === query) return;

    dispatch({ type: SEARCH_QUERY, query });
    if (!query || !query.trim().length) {
      dispatch({
        type: SEARCH_LOADED,
        query,
        locationIds: [],
        regionIds: [],
        countryIds: [],
      });
      return;
    }
    const lang = getLocale(getState());

    const {
      countries,
      regions,
      locations,
      match: { locationIds, regionIds },
    } = await fetchGeoSearch(query, lang);
    dispatch(countriesLoaded(countries));
    dispatch(regionsLoaded(regions));
    dispatch(locationsLoaded(locations));
    dispatch({
      type: SEARCH_LOADED,
      query,
      locationIds,
      regionIds,
      countryIds: countries.map((x) => x._id),
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: SEARCH_FAILED, query, error: e.message });
  }
}

const searchQueryFiltered = debounce(300, searchQueryInner);

export function searchQuery(query: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    // if (getSearchQuery(getState()) === query) return;

    // if (!query || !query.trim().length) {
    //   dispatch({ type: SEARCH_QUERY, query });
    //   dispatch({ type: SEARCH_LOADED, query, cities: [] });
    //   return;
    // }
    await searchQueryFiltered(dispatch, getState, query);
  };
}

export function navigateToTopSearch(query: string, history: History) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    // make sure the query string was loaded
    await searchQueryInner(dispatch, getState, query);

    const state = getState();
    const location = getTopSearchLocation(state);
    if (!location) return false;
    const locale = getLocale(state);
    const region = getLocationRegion(state, location);
    const country = getRegionCountry(state, region);
    const continent = getCountryContinent(state, country);
    const url = getLocationWeatherUrl(
      locale,
      continent,
      country,
      region,
      location
    );
    //console.log(url);
    history.push(url);
    return true;
    // TODO: Navigate
  };
}

export function getTopSearchLocationId(query: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    // make sure the query string was loaded
    await searchQueryInner(dispatch, getState, query);

    const state = getState();
    const locations = getSearchLocations(state);

    return locations[0]?._id;
  };
}

export function loadGeolocation() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const lang = getLocale(getState());

    const { lat, lon } = await getGeoLocation();
    const query = `${lat.toFixed(5)},${lon.toFixed(5)}`;

    const {
      countries,
      regions,
      locations,
      match: { locationIds },
    } = await fetchGeoSearch(query, lang);
    if (!locations.length) throw new Error("City not found");
    dispatch(countriesLoaded(countries));
    dispatch(regionsLoaded(regions));
    dispatch(locationsLoaded(locations));

    const state = getState();
    return getLocationById(state, locationIds[0]);
  };
}

export function navigateToGeolocation(history: History) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      const location = await dispatch(loadGeolocation());
      if (!location) return;

      const state = getState();
      const locale = getLocale(state);
      const region = getLocationRegion(state, location);
      const country = getRegionCountry(state, region);
      const continent = getCountryContinent(state, country);
      const url = getLocationWeatherUrl(
        locale,
        continent,
        country,
        region,
        location
      );

      history.push(url);
    } catch (e) {
      console.error(e);
      //alert(e.message);
      dispatch(errorsAdd(e.message));
    }
  };
}

export function searchPlaceholder(placeholder: string): SearchActionTypes {
  return { type: SEARCH_PLACEHOLDER, placeholder };
}

import React, { useState, useRef, useEffect } from "react";
import styles from "./Search.module.scss";
import SearchList from "./SearchList";
import { useDispatch } from "../../../store/hooks";
import {
  searchQuery,
  getTopSearchLocationId,
} from "../../../store/actions/search";

type SearchPopupProps = {
  onLinkClick: (locationId: string) => void;
  hidePopup: () => void;
  wrapRef: React.RefObject<HTMLDivElement>;
};

const SearchPopup: React.FC<SearchPopupProps> = ({
  onLinkClick,
  hidePopup,
  wrapRef,
}) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (wrapRef.current && !wrapRef.current.contains(event.target as Node)) {
      hidePopup();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  return (
    <div className={styles.searchPopup}>
      <SearchList onLinkClick={onLinkClick} />
    </div>
  );
};

type SearchProps = {
  label: string;
  onLinkClick: (locationId: string) => void;
  disabled?: boolean;
  placeholder: string;
};

const LocationsSearch: React.FC<SearchProps> = ({
  label,
  onLinkClick,
  disabled,
  placeholder,
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const [searchPopup, setSearchPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const search = (text: string) => {
    setSearchValue(text);
    dispatch(searchQuery(text));
  };

  const hidePopup = () => {
    setSearchPopup(false);
    search("");
  };

  const handleLinkClick = (locationId: string) => {
    onLinkClick(locationId);
    hidePopup();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const locationId = await dispatch(getTopSearchLocationId(searchValue));

    if (!locationId) return;
    onLinkClick(locationId);
    if (ref.current) {
      ref.current.blur();
    }
    hidePopup();
  };

  const handleClear = () => {
    search("");
    ref.current?.focus();
  };

  return (
    <div ref={wrapRef} className={styles.searchWrap}>
      <div className={styles.textField}>
        <form onSubmit={handleSubmit}>
          <label className={styles.label}>
            {label}
            <input
              type="text"
              ref={ref}
              value={searchValue}
              placeholder={searchPopup ? "" : placeholder}
              disabled={disabled}
              className={`${styles.inputText} ${styles.inputSearch}`}
              onChange={(e) => search(e.target.value)}
              onFocus={() => setSearchPopup(true)}
              onKeyDown={(e) => {
                if (e.keyCode === 27) search("");
              }}
            />
            <button
              aria-label="Clear"
              className={styles.clearButton}
              type="button"
              onClick={handleClear}
            />
            <input type="submit" style={{ display: "none" }} />
          </label>
        </form>
      </div>

      {searchPopup && (
        <SearchPopup
          onLinkClick={handleLinkClick}
          hidePopup={hidePopup}
          wrapRef={wrapRef}
        />
      )}
    </div>
  );
};

export default LocationsSearch;

import { WeatherAlert } from "../api/weatherApi";
import { FormatMessageFn, defaultLocale, MessageKey } from "../i18n";
import { IntlShape } from "react-intl";
import { formatTimeHHMM, formatDayOfWeek } from "../components/Locale";

export function formatAlertDescription(
  formatMessage: FormatMessageFn,
  alert: WeatherAlert
) {
  if (formatMessage.locale === defaultLocale) return alert.description;

  return formatMessage(
    ("Alert" + alert.type) as MessageKey,
    undefined,
    alert.description
  );
}

export function formatAlertTime(intl: IntlShape, time: Date | undefined) {
  if (!time) return "none";
  return `${formatDayOfWeek(intl, time)}, ${formatTimeHHMM(intl, time)}`;
}

export function formatAlertSummary(
  formatMessage: FormatMessageFn,
  alert: WeatherAlert
) {
  return formatMessage("AlertSummary", {
    description: formatAlertDescription(formatMessage, alert),
    start: formatAlertTime(formatMessage.intl, alert.startTime),
    end: formatAlertTime(formatMessage.intl, alert.endTime),
    source: alert.source,
    disclaimer: alert.disclaimer || "none",
  });
}

import { LocationType } from "../../api/placesApi";

export const LOCATION_SELECTOR_SELECT = "LOCATION_SELECTOR_SELECT";

type LocationSelectorSelectAction = {
  type: typeof LOCATION_SELECTOR_SELECT;
  locationType: LocationType;
  continentId: string;
  countryId: string;
  regionId: string;
  error: string;
};

export type LocationSelectorActions = LocationSelectorSelectAction;

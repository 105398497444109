import { RootState } from "..";
import { WeatherType, isTodayWeatherType } from "../../types/weatherType";

const getDailyShort = (state: RootState) => state.dailyShort;

export const getDailyForecastShort = (
  state: RootState,
  placeId: string,
  weatherType: WeatherType
) => {
  if (!placeId) return undefined;
  const forecast = getDailyShort(state)[placeId];
  if (!forecast) return undefined;

  if (isTodayWeatherType(weatherType)) return forecast.daily[0];
  if (weatherType === WeatherType.Tomorrow) return forecast.daily[1];
  return undefined;
};

export const getCurrentWeatherShort = (state: RootState, placeId: string) => {
  if (!placeId) return undefined;
  const forecast = getDailyShort(state)[placeId];
  if (!forecast) return undefined;
  return forecast.now;
};

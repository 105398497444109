import React from "react";
import { LocaleMessage } from "../../../components/Locale";
import { useSelector } from "../../../store/hooks";
import { getSelectedSatelliteImages } from "../../../store/selectors/weather";
import WeatherImagesCard from "../WeatherImagesCard";
import LocationName from "../../../components/LocationName";

const SatelliteImages: React.FC = () => {
  const images = useSelector(getSelectedSatelliteImages);
  if (!images || !images.length) return null;

  return (
    <div className="container section">
      <WeatherImagesCard
        title={
          <LocaleMessage
            id="SatelliteTitle"
            values={{ name: <LocationName /> }}
          />
        }
        images={images}
      />
    </div>
  );
};

export default SatelliteImages;

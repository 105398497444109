// we store everything in "normal"
export enum WeatherCode {
  Sunny = 113,
  PartlyCloudy = 116,
  Cloudy = 119,
  Overcast = 122,
  Mist = 143,
  PatchyRainPossible = 176,
  PatchySnowPossible = 179,
  PatchySleetPossible = 182,
  PatchyFreezingDrizzlePossible = 185,
  ThunderyOutbreaksPossible = 200,
  BlowingSnow = 227,
  Blizzard = 230,
  Fog = 248,
  FreezingFog = 260,
  PatchyLightDrizzle = 263,
  LightDrizzle = 266,
  FreezingDrizzle = 281,
  HeavyFreezingDrizzle = 284,
  PatchyLightRain = 293,
  LightRain = 296,
  ModerateRainAtTimes = 299,
  ModerateRain = 302,
  HeavyRainAtTimes = 305,
  HeavyRain = 308,
  LightFreezingRain = 311,
  ModerateOrHeavyFreezingRain = 314,
  LightSleet = 317,
  ModerateOrHeavySleet = 320,
  PatchyLightSnow = 323,
  LightSnow = 326,
  PatchyModerateSnow = 329,
  ModerateSnow = 332,
  PatchyHeavySnow = 335,
  HeavySnow = 338,
  IcePellets = 350,
  LightRainShower = 353,
  ModerateOrHeavyRainShower = 356,
  TorrentialRainShower = 359,
  LightSleetShower = 362,
  ModerateOrHeavySleetShower = 365,
  LightSnowShower = 368,
  ModerateOrHeavySnowShower = 371,
  LightShowerOfIcePellets = 374,
  ModerateOrHeavyShowerOfIcePellets = 377,
  PatchyLightRainInAreaWithThunder = 386,
  ModerateOrHeavyRainInAreaWithThunder = 389,
  PatchyLightSnowInAreaWithThunder = 392,
  ModerateOrHeavySnowInAreaWithThunder = 395,

  // AccuWeather
  MostlySunny = 1001,
  PartlySunny = 1002,
  IntermittentClouds = 1003,
  HazySunshine = 1004,
  MostlyCloudy = 1005,
  Showers = 1006,
  MostlyCloudyWithShowers = 1007,
  PartlySunnyWithShowers = 1008,
  TStorms = 1009,
  MostlyCloudyWithTStorms = 1010,
  PartlySunnyWithTStorms = 1011,
  Rain = 1012,
  Flurries = 1013,
  MostlyCloudyWithFlurries = 1014,
  PartlySunnyWithFlurries = 1015,
  Snow = 1016,
  MostlyClodyWithSnow = 1017,
  Ice = 1018,
  Sleet = 1019,
  FreezingRain = 1020,
  RainAndSnow = 1021,
  Hot = 1022,
  Cold = 1023,
  Windy = 1024,
  Clear = 1025,
  MostlyClear = 1026,
  HazyMoonlight = 1027,
  PartlyCloudyWithShowers = 1028,
  PartlyClodyWithTStorms = 1029,
  //MostlyCloudyWithTStorms = 1030,
  MostlyCloudyWithSnow = 1031,
}

export enum PrecipitationIntensity {
  Light = 1,
  Moderate = 2,
  Heavy = 3,
}

export type WeatherData = {
  weatherCode: WeatherCode | undefined; // condition code
  weatherDesc: string; // short condition description
  weatherText: string | undefined; // long condition description
  temp: number | undefined; // C
  tempFeels: number | undefined; // C

  rainChance: number | undefined; // %
  windDir: number | undefined; // wind direction
  windSpeed: number | undefined; // kmph
  visibility: number | undefined; // km
  dewPoint: number | undefined; // C
  humidity: number | undefined; // %
  uvIndex: number | undefined; // number
  pressure: number | undefined; // mmhg
  precipIntensity: PrecipitationIntensity | undefined; // 1, 2, 3 or undefined

  // new fields
  wetBulbTemp?: number; // C
  windGustSpeed?: number; // kmph
  ceiling?: number; //  km
  precipChance?: number; // %
  snowChance?: number; // %
  iceChance?: number; // %
  totalLiquid?: number; // mm
  rain?: number; // mm
  snow?: number; // mm
  ice?: number; // mm
  cloudCover?: number; // %
};

export type WeatherIndex = {
  value: number;
  category: string;
};

export type WeatherIndices = {
  [key: string]: WeatherIndex;
};

export type DailyForecast = WeatherData & {
  date: Date; // 2020-02-27

  tempMin: number | undefined; // C
  tempMax: number | undefined; // C

  sunrise: Date | undefined; // 0740
  sunset: Date | undefined; // 2000
  moonrise: Date | undefined; // 2340
  moonset: Date | undefined; // 430
  //moonPhase: string; // "Waxing Crescent" moon phase
  moonIllumination: number | undefined; // 32
  sunMinutes: number | undefined; // 696 number of sun minutes

  // new fields
  moonAge?: number; // days
  tempFeelsShade?: number;
  tstormChance?: number;
  precipMinutes?: number;
  rainMinutes?: number;
  snowMinutes?: number;
  iceMinutes?: number;
  precipType?: string;
  precipIntensityS?: string;
  indices?: WeatherIndices;
};

export type HourlyForecast = WeatherData & {
  time: Date; // 2000
  past: boolean;
  day: boolean;
};

export type CurrentWeather = WeatherData & {
  time: Date; // 2000
  day: boolean;
};

export type MonthlyStat = {
  month: number;
  tempMin: number;
  tempMax: number;
  rainfallAvg: number;
};

export type DailyForecastShort = {
  date: Date;

  tempMin: number | undefined;
  tempMax: number | undefined;
  weatherCode: number | undefined;
};

export type CurrentWeatherShort = {
  temp: number | undefined;
  weatherCode: number | undefined;
};

export type DailyForecastShortResult = {
  now: CurrentWeatherShort;
  daily: DailyForecastShort[];
  expires: Date;
};

export type DailyForecastsShort = {
  [key: string]: DailyForecastShortResult;
};

export type Image = {
  date: Date;
  url: string;
};

export type WeatherAlert = {
  _id: string;
  startTime: Date | undefined;
  endTime: Date | undefined;
  area: string | undefined;
  source: string | undefined; // U.S. National Weather Service
  type: string | undefined; // FWW, corresponds to the description
  description: string | undefined; // short text
  disclaimer: string | undefined; // must be displayed with alert data
  text: string | undefined; // Text in English
};

export type CurrentWeatherResult = {
  now: CurrentWeather | undefined;
  expires: Date;
};

export type HourlyForecastResult = {
  hourly: HourlyForecast[];
  expires: Date;
};

export type DailyForecastResult = {
  daily: DailyForecast[];
  expires: Date;
};

export type MonthlyStatsResult = {
  stats: MonthlyStat[];
  expires: Date;
};

export type ImageryResult = {
  radar: Image[];
  satellite: Image[];

  expires: Date;
};

export type WeatherAlertsResult = {
  alerts: WeatherAlert[];

  expires: Date;
};

export type TideHourly = {
  time: Date;
  level: number | undefined; // meters
  swellHeight: number | undefined;
  swellDir: number | undefined;
  swellPeriod: number | undefined;
  waterTemp: number | undefined;
};

export type TideHiLoType = "H" | "L";

export type TideHiLo = {
  time: Date;
  type: TideHiLoType;
  level: number | undefined; // meters
};

export type StationType = "R" | "S" | string;

export type StationTides = {
  station: string;
  stype: StationType;
  hourly: TideHourly[];
  hilo: TideHiLo[];
};

export type TidesResult = {
  stations: StationTides[];

  expires: Date;
};

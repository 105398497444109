export const ERRORS_ADD = "ERRORS_ADD";
export const ERRORS_REMOVE = "ERRORS_REMOVE";

export type ErrorMsg = {
  message: string;
  timestamp: Date;
};

type ErrorsAddAction = {
  type: typeof ERRORS_ADD;
  error: ErrorMsg;
};

type ErrorsRemoveAction = {
  type: typeof ERRORS_REMOVE;
  error: ErrorMsg;
};

export type ErrorsActionTypes = ErrorsAddAction | ErrorsRemoveAction;

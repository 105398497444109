import { Request } from "express";
import { unitSelectByCountry, unitLocalRestore } from "./units";
import { INIT_COMMON, INIT_CLIENT } from "../types/init";
import { tryLoadGeoip } from "./locations";
import { getGeoipCountryId } from "../selectors/locations";
import { RootDispatch, GetState } from "..";
import { recentLocalRestore } from "./recent";
import { favoriteLocalRestore } from "./favorite";
import { isServer } from "../../utils/browser";
import { authRestore } from "./auth";
import { notificationsPopupRestore } from "./notifications";

export function initCommon(req?: Request) {
  const ip = req
    ? (
        req.header("cf-connecting-ip") ||
        req.header("x-real-ip") ||
        req.header("x-forwarded-for") ||
        req.connection.remoteAddress ||
        ""
      ).replace("::ffff:", "")
    : undefined;

  return async (dispatch: RootDispatch, getState: GetState) => {
    await dispatch(tryLoadGeoip(ip));

    const countryId = getGeoipCountryId(getState());
    if (countryId) {
      dispatch(unitSelectByCountry(countryId));
    }

    dispatch({ type: INIT_COMMON });
  };
}

export function initClient() {
  return async (dispatch: RootDispatch) => {
    // don't run on a server
    if (isServer()) return;

    await dispatch(notificationsPopupRestore());
    await dispatch(authRestore());

    await dispatch(unitLocalRestore());
    await dispatch(favoriteLocalRestore());
    await dispatch(recentLocalRestore());

    await dispatch({ type: INIT_CLIENT });
  };
}

export function tryInit(req?: Request) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const init = getState().init;
    if (!init.common) await dispatch(initCommon(req));
    if (!init.client) await dispatch(initClient());
  };
}

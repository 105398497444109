import { Country, LocationType } from "../../api/placesApi";

export const COUNTRIES_BY_CONTINENT_LOADING = "COUNTRIES_BY_CONTINENT_LOADING";
export const COUNTRIES_BY_CONTINENT_LOADED = "COUNTRIES_BY_CONTINENT_LOADED";
export const COUNTRIES_LOADED = "COUNTRIES_LOADED";

type CountriesByContinentLoadingAction = {
  type: typeof COUNTRIES_BY_CONTINENT_LOADING;
  locationType: LocationType;
  continentId: string;
};

type CountriesByContinentLoadedAction = {
  type: typeof COUNTRIES_BY_CONTINENT_LOADED;
  locationType: LocationType;
  continentId: string;
  countryIds: string[];
};

type CountriesLoadedAction = {
  type: typeof COUNTRIES_LOADED;
  countries: Country[];
};

export type CountriesActionTypes =
  | CountriesByContinentLoadingAction
  | CountriesByContinentLoadedAction
  | CountriesLoadedAction;

import React from "react";
import styles from "./WeatherIllustration.module.scss";
import { SelectedDayWeather } from "../DayWeatherHead";
import { PrecipitationIntensity } from "../../../api/weatherApi";

// export const Stars: React.FC = () => {
//   return <div className={styles.stars} />;
// };

type LayerProps = {
  weather: SelectedDayWeather;
};

export const Stars: React.FC = () => {
  const duration = 2000;
  return (
    <div className={styles.layer}>
      <div
        className={styles.star}
        style={{ left: 220, top: 0, animationDelay: `${-0.9 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 145, top: 25, animationDelay: `${-0.1 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 105, top: 75, animationDelay: `${-0.7 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 300, top: 45, animationDelay: `${-0.4 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 365, top: 50, animationDelay: `${-0.9 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 440, top: 30, animationDelay: `${-0.2 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 505, top: 115, animationDelay: `${-0.5 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 255, top: 160, animationDelay: `${-0.3 * duration}ms` }}
      />
      <div
        className={styles.star}
        style={{ left: 10, top: 170, animationDelay: `${-0.8 * duration}ms` }}
      />
    </div>
  );
};

export const Moon: React.FC = () => {
  return <div className={styles.moon} />;
};

export const Sun: React.FC = () => {
  return <div className={styles.sun} />;
};

export const Cloud: React.FC = () => {
  return <div className={styles.cloud} />;
};

export const Clouds: React.FC = () => {
  return <div className={styles.clouds} />;
};

export const Mist: React.FC = () => {
  return <div className={styles.mist} />;
};

export const Overcast: React.FC = () => {
  return <div className={styles.overcast} />;
};

export const Overcast2: React.FC = () => {
  return <div className={styles.overcast2} />;
};

// export const Rain: React.FC = () => {
//   return <div className={styles.rain} />;
// };

const rainStyles: { [key in PrecipitationIntensity]: string[] } = {
  [PrecipitationIntensity.Light]: [
    styles.rainLight,
    styles.rainMedium,
    styles.rainLight,
  ],
  [PrecipitationIntensity.Moderate]: [
    styles.rainLight,
    styles.rainMedium,
    styles.rainHeavy,
  ],
  [PrecipitationIntensity.Heavy]: [
    styles.rainHeavy,
    styles.rainMedium,
    styles.rainHeavy,
  ],
};

export const Rain: React.FC<LayerProps> = ({ weather }) => {
  const dropletStyles =
    rainStyles[weather.precipIntensity || PrecipitationIntensity.Moderate] ||
    rainStyles[PrecipitationIntensity.Moderate];
  //const dropletStyles = rainStyles[PrecipitationIntensity.Heavy];
  const droplets: React.ReactElement[] = [];
  for (let i = 0; i < 33; ++i) {
    droplets.push(
      <div
        key={i}
        className={dropletStyles[(i * 7) % 3]}
        style={{
          left: 30 + i * 15,
          animationDuration: `${800 + ((i * 591) % 400)}ms`,
          animationDelay: `-${((i * i) % 10) * 100}ms`,
        }}
      />
    );
  }

  return (
    <div className={styles.layer}>
      {/* <div style={{ position: "absolute" }}>{weather.precipIntensity}</div> */}
      {droplets}
    </div>
  );
};

// export const Snow: React.FC = () => {
//   return <div className={styles.snow} />;
// };

const snowStyles: { [key in PrecipitationIntensity]: string[] } = {
  [PrecipitationIntensity.Light]: [
    styles.snowSmall,
    styles.snowSmall,
    styles.snowBig,
    styles.snowSmall,
    styles.snowBlurry,
    styles.snowSmall,
  ],
  [PrecipitationIntensity.Moderate]: [
    styles.snowBig,
    styles.snowSmall,
    styles.snowBig,
    styles.snowSmall,
    styles.snowBlurry,
    styles.snowSmall,
  ],
  [PrecipitationIntensity.Heavy]: [
    styles.snowBig,
    styles.snowBig,
    styles.snowBig,
    styles.snowSmall,
    styles.snowBlurry,
    styles.snowBig,
  ],
};

export const Snow: React.FC<LayerProps> = ({ weather }) => {
  const flakesStyles =
    snowStyles[weather.precipIntensity || PrecipitationIntensity.Moderate] ||
    snowStyles[PrecipitationIntensity.Moderate];
  //const flakesStyles = snowStyles[PrecipitationIntensity.Heavy];

  const flakes: React.ReactElement[] = [];
  for (let i = 0; i < 17; ++i) {
    flakes.push(
      <div
        key={i}
        className={flakesStyles[(i * 7) % 3]}
        style={{
          left: 30 + i * 30,
          animationDuration: `${2000 + ((i * 2591) % 1000)}ms`,
          animationDelay: `-${((i * i) % 30) * 100}ms`,
        }}
      />
    );
  }

  return <div className={styles.layer}>{flakes}</div>;
};

export const Thunder: React.FC = () => {
  return <div className={styles.thunder} />;
};

export const Wind: React.FC = () => {
  return <div className={styles.wind} />;
};

export const Wind2: React.FC = () => {
  return <div className={styles.wind2} />;
};

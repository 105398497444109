type ByDate<T> = {
  date: Date;
  data: T[];
};

export function groupByDate<T extends { time: Date }>(data: T[]): ByDate<T>[] {
  if (!data.length) return [];
  const result: ByDate<T>[] = [];
  let current: ByDate<T> | undefined = undefined;
  for (const item of data) {
    if (!current || current.date.getUTCDate() !== item.time.getUTCDate()) {
      if (current) result.push(current);
      current = {
        date: item.time,
        data: [item],
      };
    } else {
      current.data.push(item);
    }
  }
  if (current) result.push(current);

  return result;
}

import {
  WeatherActionTypes,
  WEATHER_SELECT,
  WEATHER_DAILY_LOADING,
  WEATHER_DAILY_LOADED,
  WEATHER_HOURLY_LOADING,
  WEATHER_HOURLY_LOADED,
  WEATHER_CURRENT_LOADING,
  WEATHER_CURRENT_LOADED,
  WEATHER_STATS_LOADING,
  WEATHER_STATS_LOADED,
  WEATHER_CURRENT_FAILED,
  WEATHER_HOURLY_FAILED,
  WEATHER_DAILY_FAILED,
  WEATHER_STATS_FAILED,
  WEATHER_IMAGERY_LOADING,
  WEATHER_IMAGERY_LOADED,
  WEATHER_IMAGERY_FAILED,
  WEATHER_SELECT_ERROR,
  WEATHER_ALERTS_LOADING,
  WEATHER_ALERTS_LOADED,
  WEATHER_ALERTS_FAILED,
  WEATHER_TIDES_LOADING,
  WEATHER_TIDES_LOADED,
  WEATHER_TIDES_FAILED,
} from "../types/weather";
import {
  fetchCurrentWeather,
  fetchHourlyForecast,
  fetchDailyForecast,
  fetchMonthlyStats,
  fetchImagery,
  fetchAlerts,
  fetchTides,
} from "../../api/weatherApi";
import { errorsAdd } from "./errors";
import { WeatherType } from "../../types/weatherType";
import { RootDispatch, GetState, RootState } from "..";

import {
  tryLoadHomeLocation,
  tryLoadNearbyLocations,
  tryLoadLocationByCode,
} from "./locations";
import {
  getWeatherLocationId,
  getWeatherType,
  getWeatherLocation,
} from "../selectors/weather";
import { getLocationRegion } from "../selectors/locations";
import { getRegionCountry } from "../selectors/regions";
import { recentLocationAdd } from "./recent";
import { LocationType } from "../../api/placesApi";
import { isNotFoundError } from "../../utils/errors";
import { WeatherDetailState } from "../reducers/weather";
import { getCountryContinent } from "../selectors/countries";
import { push } from "react-router-redux";
import { getLocationWeatherUrl } from "../../utils/url";
import { getLocale } from "../selectors/locale";
import { Request } from "express";

export function weatherSelect(locationId: string, weatherType: WeatherType) {
  return (dispach: RootDispatch) => {
    dispach({ type: WEATHER_SELECT, locationId, weatherType });
    dispach(recentLocationAdd(locationId));
  };
}

export function weatherSelectError(error: string): WeatherActionTypes {
  return { type: WEATHER_SELECT_ERROR, error };
}

export function weatherSelectHome(stop: () => boolean) {
  return async (dispatch: RootDispatch) => {
    const location = await dispatch(tryLoadHomeLocation());
    if (stop()) return undefined;
    dispatch(weatherSelect(location ? location._id : "", WeatherType.Today));
    return location;
  };
}

export function weatherNavigateToHomeLocation(
  weatherType?: WeatherType,
  stop?: () => boolean
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const location = await dispatch(tryLoadHomeLocation());
    if (stop && stop()) return undefined;
    const state = getState();
    const locale = getLocale(state);
    const region = getLocationRegion(state, location);
    const country = getRegionCountry(state, region);
    const continent = getCountryContinent(state, country);

    let url = getLocationWeatherUrl(
      locale,
      continent,
      country,
      region,
      location,
      weatherType
    );
    if (!url || url === "#") url = "/";

    return dispatch(push(url));
  };
}

export function weatherSelectByCode(
  locationType: LocationType,
  countryId: string,
  regionCode: string,
  locationCode: string,
  weatherType: WeatherType,
  stop: () => boolean
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const state = getState();
    const current = getWeatherLocation(state);
    const region = getLocationRegion(state, current);
    const country = getRegionCountry(state, region);

    if (
      current &&
      current.code === locationCode &&
      current.type === locationType &&
      region &&
      region.code === regionCode &&
      country &&
      country._id === countryId
    ) {
      // we have the location
      if (getWeatherType(state) === weatherType) return current;
      dispatch(weatherSelect(current._id, weatherType));
      return current;
    }
    // dispatch "loading"
    dispatch(weatherSelect("", weatherType));
    // TODO: do something when awaiting, like select weatherType and location empty or smth
    try {
      const location = await dispatch(
        tryLoadLocationByCode(locationType, countryId, regionCode, locationCode)
      );
      if (stop()) return undefined;
      dispatch(weatherSelect(location ? location._id : "", weatherType));
      return location;
    } catch (e) {
      console.error("weatherSelectByCode", e);

      dispatch(weatherSelectError(e.message));

      if (!isNotFoundError(e.message)) dispatch(errorsAdd(e.message));

      return undefined;
    }
  };
}

function tryLoadWeatherNearby(locationType: LocationType) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    const locationId = getWeatherLocationId(getState());
    if (!locationId) return;
    await dispatch(tryLoadNearbyLocations(locationId, locationType));
  };
}

export function tryLoadWeatherNearbyCities() {
  return tryLoadWeatherNearby("c");
}

export function tryLoadWeatherNearbyPlaces() {
  return tryLoadWeatherNearby("p");
}

function createDetailTryLoad<T extends { expires: Date }>(
  loading: string,
  loaded: string,
  failed: string,
  getFromState: (state: RootState) => WeatherDetailState<T>,
  fetch: (locationId: string, req: Request | undefined) => Promise<T>
) {
  return function (req: Request | undefined) {
    return async (dispatch: RootDispatch, getState: GetState) => {
      //console.log("createDetailTryLoad");
      const state = getState();
      const data = getFromState(state).data;
      if (data && data.expires > new Date()) return data;
      const locationId = getWeatherLocationId(state);
      if (!locationId) return;
      dispatch({ type: loading, locationId } as WeatherActionTypes);
      try {
        const data = await fetch(locationId, req);
        dispatch({
          type: loaded,
          locationId,
          data,
        } as WeatherActionTypes);
        return data;
      } catch (e) {
        console.error(e);

        dispatch({ type: failed } as WeatherActionTypes);

        dispatch(errorsAdd(e.message));

        return undefined;
      }
    };
  };
}

export const tryLoadCurrentWeather = createDetailTryLoad(
  WEATHER_CURRENT_LOADING,
  WEATHER_CURRENT_LOADED,
  WEATHER_CURRENT_FAILED,
  (x) => x.weather.current,
  fetchCurrentWeather
);

export const tryLoadHourlyForecast = createDetailTryLoad(
  WEATHER_HOURLY_LOADING,
  WEATHER_HOURLY_LOADED,
  WEATHER_HOURLY_FAILED,
  (x) => x.weather.hourly,
  fetchHourlyForecast
);

export const tryLoadDailyForecast = createDetailTryLoad(
  WEATHER_DAILY_LOADING,
  WEATHER_DAILY_LOADED,
  WEATHER_DAILY_FAILED,
  (x) => x.weather.daily,
  fetchDailyForecast
);

export const tryLoadMonthlyStats = createDetailTryLoad(
  WEATHER_STATS_LOADING,
  WEATHER_STATS_LOADED,
  WEATHER_STATS_FAILED,
  (x) => x.weather.stats,
  fetchMonthlyStats
);

export const tryLoadImagery = createDetailTryLoad(
  WEATHER_IMAGERY_LOADING,
  WEATHER_IMAGERY_LOADED,
  WEATHER_IMAGERY_FAILED,
  (x) => x.weather.imagery,
  fetchImagery
);

export const tryLoadWeatherAlerts = createDetailTryLoad(
  WEATHER_ALERTS_LOADING,
  WEATHER_ALERTS_LOADED,
  WEATHER_ALERTS_FAILED,
  (x) => x.weather.alerts,
  fetchAlerts
);

export const tryLoadTides = createDetailTryLoad(
  WEATHER_TIDES_LOADING,
  WEATHER_TIDES_LOADED,
  WEATHER_TIDES_FAILED,
  (x) => x.weather.tides,
  fetchTides
);

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import { Switch } from "../../../components/Controls";
import LocationRadioSelector from "./LocationRadioSelector";
import { HSplit } from "../../../components/Divider";
import { useSelector } from "../../../store/hooks";
import { getHomeLocationId } from "../../../store/selectors/locations";
import { CardSection, CardSectionTitle } from "../Card/Card";

type AlertsMainProps = {
  alerts: UserWeatherAlerts;
  favorites: string[];
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsMain: React.FC<AlertsMainProps> = ({
  alerts,
  favorites,
  updateAlerts,
}) => {
  const homeLocationId = useSelector(getHomeLocationId);
  const favLocations = Array.from(
    new Set([...favorites, alerts.locationId ?? "", homeLocationId])
  ).filter((x) => !!x);

  const handleDesktopChange = (desktop: boolean) => {
    const locationId = alerts.locationId ?? homeLocationId ?? favLocations[0];
    if (desktop) {
      updateAlerts({
        desktop,
        locationId,
      });
    } else {
      updateAlerts({
        desktop,
        locationId,
        daily: false,
        government: false,
        nextHourPrecipitation: false,
        significantChanges: false,
        wind: false,
        precipitation: false,
        visibility: false,
        air: false,
        uv: false,
      });
    }
  };
  const handleLocationChange = (locationId: string) => {
    updateAlerts({ locationId });
  };

  return (
    <>
      <CardSection className={styles.alertsSection}>
        <Switch
          label="Desktop notifications"
          checked={!!alerts.desktop}
          onChange={handleDesktopChange}
        />
      </CardSection>

      {!!alerts.desktop && (
        <CardSection className={styles.notificationsLocation}>
          <CardSectionTitle title="Send all notifications for:" />
          <LocationRadioSelector
            name="notificationsLocation"
            locations={favLocations}
            selected={alerts.locationId}
            onChange={handleLocationChange}
          />
        </CardSection>
      )}

      <HSplit />
    </>
  );
};

export default AlertsMain;

import { LocaleCode } from ".";

type LocationRoute = {
  countryId: string;
  regionCode: string;
  locationCode: string;
};

const defaultHomeLocation: { [key in LocaleCode]: LocationRoute } = {
  en: {
    countryId: "us",
    regionCode: "ny",
    locationCode: "new-york-city",
  },
  uk: {
    countryId: "us",
    regionCode: "ny",
    locationCode: "new-york-city",
  },
};

export function getDefaultHomeLocation(locale: LocaleCode): LocationRoute {
  return defaultHomeLocation[locale] || defaultHomeLocation.en;
}

const defaultWeatherRoot: { [key in LocaleCode]: string } = {
  en: "/na/us/alabama",
  uk: "/eu/ukraine",
};

export function getDefaultWeatherPath(locale: LocaleCode): string {
  return defaultWeatherRoot[locale] || defaultWeatherRoot.en;
}

import { apiGet } from "../http";
import {
  DailyForecastsShort,
  CurrentWeatherResult,
  HourlyForecastResult,
  DailyForecastResult,
  MonthlyStatsResult,
  ImageryResult,
  WeatherAlertsResult,
  TidesResult,
} from "./types";
import {
  normalizeDailyForecastsShort,
  normalizeCurrentWeather,
  normalizeHourlyForecasts,
  normalizeDailyForecasts,
  normalizeImagery,
  normalizeMonthlyStats,
  normalizeAlerts,
  normalizeTides,
} from "./normalize";
import { Request } from "express";

export * from "./types";
export * from "./normalize";

async function weatherGet<T>(
  url: string,
  what: string,
  req: Request | undefined
): Promise<T> {
  const userAgent = req && req.get("user-agent");

  return await apiGet<T>(
    "/weather" + url,
    what,
    userAgent ? { headers: { "user-agent": userAgent } } : undefined
  );
}

export async function fetchCurrentWeather(
  locationId: string,
  req: Request | undefined
): Promise<CurrentWeatherResult> {
  const result = await weatherGet<CurrentWeatherResult>(
    `/current/${encodeURIComponent(locationId)}`,
    "Current Weather",
    req
  );
  if (result) normalizeCurrentWeather(result);

  return result;
}

export async function fetchHourlyForecast(
  locationId: string,
  req: Request | undefined
): Promise<HourlyForecastResult> {
  const result = await weatherGet<HourlyForecastResult>(
    `/hourly/${encodeURIComponent(locationId)}`,
    "Hourly Forecast",
    req
  );
  if (result) normalizeHourlyForecasts(result);

  return result;
}

export async function fetchDailyForecast(
  locationId: string,
  req: Request | undefined
): Promise<DailyForecastResult> {
  const result = await weatherGet<DailyForecastResult>(
    `/daily/${encodeURIComponent(locationId)}`,
    "Daily Forecast",
    req
  );
  if (result) normalizeDailyForecasts(result);

  return result;
}

export async function fetchMonthlyStats(
  locationId: string,
  req: Request | undefined
): Promise<MonthlyStatsResult> {
  const result = await weatherGet<MonthlyStatsResult>(
    `/stats/${encodeURIComponent(locationId)}`,
    "Monthly Stats",
    req
  );

  if (result) normalizeMonthlyStats(result);

  return result;
}

export async function fetchImagery(
  locationId: string,
  req: Request | undefined
): Promise<ImageryResult> {
  const result = await weatherGet<ImageryResult>(
    `/imagery/${encodeURIComponent(locationId)}`,
    "Imagery",
    req
  );

  if (result) normalizeImagery(result);

  return result;
}

export async function fetchDailyForecastsShort(
  keys: string[]
): Promise<DailyForecastsShort> {
  if (!keys.length) return {};

  const result = await weatherGet<DailyForecastsShort>(
    `/daily/short/${encodeURIComponent(keys.join("|"))}`,
    "Weather Daily Summary",
    undefined
  );
  if (result) normalizeDailyForecastsShort(result);

  return result;
}

export async function fetchAlerts(
  locationId: string,
  req: Request | undefined
): Promise<WeatherAlertsResult> {
  const result = await weatherGet<WeatherAlertsResult>(
    `/alerts/${encodeURIComponent(locationId)}`,
    "Alerts",
    req
  );

  if (result) normalizeAlerts(result);

  return result;
}

export async function fetchTides(
  locationId: string,
  req: Request | undefined
): Promise<TidesResult> {
  const result = await weatherGet<TidesResult>(
    `/tides/${encodeURIComponent(locationId)}`,
    "Tides",
    req
  );

  if (result) normalizeTides(result);

  return result;
}

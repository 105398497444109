import React from "react";
import { useIntl, IntlShape } from "react-intl";
import { FormatMessageFn } from "../../i18n";

type LocaleTimeProps = {
  value: Date;
};

export function formatTimeHHMM(intl: IntlShape, value: Date) {
  const formatted = intl
    .formatTime(value, { hour: "numeric", minute: "numeric", timeZone: "utc" })
    .toLowerCase();
  return formatted;
}

export const LocaleTime: React.FC<LocaleTimeProps> = ({ value }) => {
  const intl = useIntl();
  const formatted = formatTimeHHMM(intl, value);
  return <>{formatted}</>;
};

export function formatMinutesDuration(
  formatMessage: FormatMessageFn,
  allMinutes: number | undefined
) {
  if (typeof allMinutes !== "number") return "";

  const hours = Math.trunc(allMinutes / 60);
  const minutes = Math.trunc(allMinutes % 60);

  return formatMessage("MinutesDuration", { hours, minutes });
}

import React from "react";
import styles from "./DayBackground.module.scss";
import { useSelector } from "../../store/hooks";
import {
  getWeatherType,
  getSelectedCurrentWeather,
  getSelectedDailyForecasts,
} from "../../store/selectors/weather";
import { isTodayWeatherType } from "../../types/weatherType";

type DayBackgroundProps = {
  className?: string;
  time?: Date;
};

const DayBackground: React.FC<DayBackgroundProps> = ({
  className = "",
  time,
}) => {
  const weatherType = useSelector(getWeatherType);
  const current = useSelector(getSelectedCurrentWeather);
  const daily = useSelector(getSelectedDailyForecasts);

  if (!time && current && isTodayWeatherType(weatherType)) time = current.time;

  const getBackgroundStyle = () => {
    if (!time || !daily || !daily.length) return styles.day;

    //console.log("time", typeof time, time);
    //console.log("daily", daily);
    for (const { sunrise, sunset, sunMinutes } of daily) {
      //console.log("time", time, "sunrise", sunrise, "sunset", sunset);
      if (!sunrise && !sunset && typeof sunMinutes === "number") {
        return sunMinutes > 0 ? styles.day : styles.night;
      }

      if (!sunrise || time < sunrise) return styles.night;
      // >= sunrise
      if (time.getTime() < sunrise.getTime() + 2 * 3600 * 1000)
        return styles.morning;
      if (!sunset || time.getTime() < sunset.getTime() - 2 * 3600 * 1000)
        return styles.day;
      if (time <= sunset) return styles.evening;
      // to next day
    }

    return time.getUTCDate() === daily[daily.length - 1].date.getUTCDate()
      ? styles.night
      : styles.day;
  };

  return (
    <div
      className={`${styles.background} ${getBackgroundStyle()} ${className}`}
    />
  );
};

export default DayBackground;

import { FormatMessageFn, MessageKey } from "../i18n";

export const units = {
  F_mph: "°F",
  C_ms: "°C",
};

export type UnitKey = keyof typeof units;

export function convertTemp(tempC: number, unit: UnitKey) {
  if (unit !== "F_mph") return +tempC.toFixed(0);

  return +Math.round((tempC * 9) / 5 + 32).toFixed(0);
}

export function convertTempToC(temp: number, unit: UnitKey) {
  if (unit === "C_ms") return temp;

  return +((temp - 32) * (5 / 9));
}

export function formatTempShortConverted(tempUnit: number) {
  return `${Math.round(tempUnit).toFixed(0)}°`;
}

export function formatTempShort(tempC: number | undefined, unit: UnitKey) {
  return typeof tempC === "number"
    ? formatTempShortConverted(convertTemp(tempC, unit))
    : "";
}

export function getTempUnit(unit: UnitKey) {
  return unit === "F_mph" ? "°F" : "°C";
}

export function formatTemp(tempC: number | undefined, unit: UnitKey) {
  return typeof tempC === "number"
    ? `${Math.round(convertTemp(tempC, unit)).toFixed(0)} ${getTempUnit(unit)}`
    : "";
}

export function convertSpeed(speedKmph: number, unit: UnitKey) {
  switch (unit) {
    case "C_ms":
      return Math.round(speedKmph / 3.6);
    // case "C_mph":
    case "F_mph":
      return Math.round(speedKmph / 1.609);
    // case "C_kmh":
    //   return speedKmph;
  }
}

function getSpeedUnitFormat(unit: UnitKey): MessageKey {
  switch (unit) {
    case "C_ms":
      return "UnitMetersPerSecond";
    // case "C_mph":
    case "F_mph":
      return "UnitMilesPerHour";
    // case "C_kmh":
    //   return "km/h";
  }
}

export function getSpeedUnit(unit: UnitKey, formatMessage: FormatMessageFn) {
  return getUnit(formatMessage, getSpeedUnitFormat(unit));
}

export function formatSpeed(
  formatMessage: FormatMessageFn,
  speedKmph: number | undefined,
  unit: UnitKey
) {
  if (typeof speedKmph !== "number") return "";
  const value = Math.round(convertSpeed(speedKmph, unit)).toFixed(0);

  return formatMessage(getSpeedUnitFormat(unit), { value });
}

export function convertDistance(distanceKm: number, unit: UnitKey) {
  switch (unit) {
    // case "C_mph":
    case "F_mph":
      return distanceKm / 1.609;
    // case "C_kmh":
    case "C_ms":
      return distanceKm;
  }
}

function getDistanceUnitFormat(unit: UnitKey): MessageKey {
  switch (unit) {
    case "F_mph":
      return "UnitMiles";
    case "C_ms":
      return "UnitKm";
  }
}

export function formatDistance(
  formatMessage: FormatMessageFn,
  distanceKm: number | undefined,
  unit: UnitKey,
  suffix: string = ""
) {
  if (typeof distanceKm !== "number") return "";
  const value = Math.round(convertDistance(distanceKm, unit)).toFixed(0);
  return formatMessage(getDistanceUnitFormat(unit), { value, suffix });
}

export function formatPercent(percent: number | undefined) {
  return typeof percent === "number" ? `${percent.toFixed(0)}%` : "";
}

export function formatIndex(
  formatMessage: FormatMessageFn,
  value: number | undefined,
  category: string | undefined
) {
  const valueStr = typeof value === "number" ? value.toFixed(0) : "";
  const categoryStr = category
    ? formatMessage(
        ("UnitI" + category.replace(" ", "")) as MessageKey,
        undefined,
        category
      )
    : "";
  if (!valueStr) return categoryStr;
  if (!categoryStr) return valueStr;
  return formatMessage("UnitIndex", { value: valueStr, level: categoryStr });
}

function getUvCategory(uvIndex: number): string {
  if (uvIndex <= 2) return "Low";
  if (uvIndex <= 5) return "Moderate";
  if (uvIndex <= 7) return "High";
  if (uvIndex <= 10) return "VeryHigh";
  return "Extreme";
}

export function formatUvIndex(
  formatMessage: FormatMessageFn,
  uvIndex: number | undefined
) {
  if (typeof uvIndex !== "number") return "";
  return formatIndex(formatMessage, uvIndex, getUvCategory(uvIndex));
}

export function getUnit(formatMessage: FormatMessageFn, message: MessageKey) {
  return formatMessage(message, { value: "" }).replace("0", "").trim();
}

export function getPressureUnit(formatMessage: FormatMessageFn) {
  return getUnit(formatMessage, "UnitMmHg");
}

export function formatPressure(
  formatMessage: FormatMessageFn,
  pressure: number | undefined
) {
  if (typeof pressure !== "number") return "";
  return formatMessage("UnitMmHg", { value: pressure.toFixed(0) });
}

const dir16Short = [
  "N",
  "NNE",
  "NE",
  "ENE",
  "E",
  "ESE",
  "SE",
  "SSE",
  "S",
  "SSW",
  "SW",
  "WSW",
  "W",
  "WNW",
  "NW",
  "NNW",
];

function degreeToDir16(dirDegree: number) {
  const index = Math.trunc(dirDegree / 22.5 + 0.5) % 16;
  return dir16Short[index] || "?";
}

const dir8Short = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

function degreeToDir8(dirDegree: number) {
  const index = Math.trunc(dirDegree / 45 + 0.5) % 8;
  return dir8Short[index] || "?";
}

export function formatWindDirectionShort(
  formatMessage: FormatMessageFn,
  dirDegree: number | undefined
) {
  if (typeof dirDegree !== "number") return "";
  const dir16 = degreeToDir16(dirDegree);
  const result = formatMessage(("DirShort" + dir16) as MessageKey);
  if (result !== "-") return result;
  const dir8 = degreeToDir8(dirDegree);
  return formatMessage(("DirShort" + dir8) as MessageKey);
}

export function formatWindDirection(
  formatMessage: FormatMessageFn,
  dirDegree: number | undefined
) {
  if (typeof dirDegree !== "number") return "";
  const dir16 = degreeToDir16(dirDegree);
  const result = formatMessage(("DirFull" + dir16) as MessageKey);
  if (result !== "-") return result;
  const dir8 = degreeToDir8(dirDegree);
  return formatMessage(("DirFull" + dir8) as MessageKey);
}

function getRainfallFormat(unit: UnitKey) {
  return unit === "C_ms" ? "UnitMm" : "UnitInches";
}

export function getRainfallUnit(unit: UnitKey, formatMessage: FormatMessageFn) {
  return getUnit(formatMessage, getRainfallFormat(unit));
}

export function convertRainfall(rainfallMm: number, unit: UnitKey) {
  if (unit === "C_ms") return Math.round(rainfallMm);

  return Math.round((rainfallMm / 25.4) * 100) * 0.01;
}

export function formatRainfallConverted(
  formatMessage: FormatMessageFn,
  rainfallUnit: number,
  unit: UnitKey
) {
  const round = unit === "C_ms" ? 0 : 2;
  const value = rainfallUnit.toFixed(round);
  return formatMessage(getRainfallFormat(unit), { value });
}

export function formatRainfall(
  formatMessage: FormatMessageFn,
  rainfallMm: number | undefined,
  unit: UnitKey
) {
  if (typeof rainfallMm !== "number") return "";

  return formatRainfallConverted(
    formatMessage,
    convertRainfall(rainfallMm, unit),
    unit
  );
}

function getMeterFormat(unit: UnitKey) {
  return unit === "C_ms" ? "UnitM" : "UnitFt";
}

export function getMeterUnit(unit: UnitKey, formatMessage: FormatMessageFn) {
  return getUnit(formatMessage, getMeterFormat(unit));
}

export function convertMeter(lengthM: number, unit: UnitKey) {
  if (unit === "C_ms") return lengthM;

  return Math.round(lengthM * 3.28084 * 100) * 0.01;
}

export function formatMeterConverted(
  formatMessage: FormatMessageFn,
  length: number,
  unit: UnitKey
) {
  return formatMessage(getMeterFormat(unit), {
    value: formatMeterConvertedShort(length),
  });
}

export function formatMeterConvertedShort(length: number) {
  return length.toFixed(1);
}

export function formatMeter(
  formatMessage: FormatMessageFn,
  lengthM: number | undefined,
  unit: UnitKey
) {
  return typeof lengthM === "number"
    ? formatMeterConverted(formatMessage, convertMeter(lengthM, unit), unit)
    : "";
}

export function getPrecipUnit(unit: UnitKey) {
  return unit === "C_ms" ? "mm per hour" : "in per hour";
}

export function getPrecipitUnitShort(unit: UnitKey) {
  return unit === "C_ms" ? "mm/h" : "in/h";
}

export function formatPrecip(precip: number | undefined, unit: UnitKey) {
  return typeof precip === "number"
    ? `${convertPrecip(precip, unit)} ${getPrecipUnit(unit)}`
    : "";
}

export function formatPrecipRange(
  precipFrom: number,
  precipTo: number,
  unit: UnitKey
) {
  return `${convertPrecip(precipFrom, unit)} - ${convertPrecip(
    precipTo,
    unit
  )} ${getPrecipUnit(unit)}`;
}

export function convertPrecip(precipMmH: number, unit: UnitKey) {
  if (unit === "C_ms") return +precipMmH.toFixed(2);

  return +(Math.round((precipMmH / 25.4) * 100) * 0.01).toFixed(2);
}

export function convertPrecipToMmH(precip: number, unit: UnitKey) {
  if (unit === "C_ms") return precip;
  return precip * 25.4;
}

export function convertDist(distanceKm: number, unit: UnitKey) {
  if (unit === "C_ms") return +distanceKm.toFixed(2);
  return +(Math.round((distanceKm / 1.609) * 100) * 0.01).toFixed(2);
}

export function convertDistToKm(distance: number, unit: UnitKey) {
  if (unit === "C_ms") return distance;
  return distance * 1.609;
}

export function getDistUnit(unit: UnitKey) {
  return unit === "F_mph" ? "miles" : "km";
}

export function formatDist(distKm: number | undefined, unit: UnitKey) {
  return typeof distKm === "number"
    ? `${convertDist(distKm, unit)} ${getDistUnit(unit)}`
    : "";
}

export function convertWindSpeed(speedMS: number, unit: UnitKey) {
  if (unit === "C_ms") return +speedMS.toFixed(2);
  return +(Math.round(speedMS * 2.2369 * 100) * 0.01).toFixed(2);
}

export function convertWindSpeedToMS(distance: number, unit: UnitKey) {
  if (unit === "C_ms") return distance;
  return distance / 2.2369;
}

export function getWindSpeedUnit(unit: UnitKey) {
  return unit === "F_mph" ? "mph" : "m/s";
}

export function formatWindSpeed(distKm: number | undefined, unit: UnitKey) {
  return typeof distKm === "number"
    ? `${convertWindSpeed(distKm, unit)} ${getWindSpeedUnit(unit)}`
    : "";
}

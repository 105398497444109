import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
// import facebookImg from "../../assets/facebook.svg";
// import twitterImg from "../../assets/twitter.svg";
// import instagramImg from "./instagram.svg";
import logoImg from "./Logo_vertical.svg";
import { useLocale } from "../../i18n";
import {
  getHomeUrl,
  getWeatherUrl,
  //getWidgetUrl,
  getContanctUsUrl,
  getPrivacyPolicyUrl,
  getTosUrl,
} from "../../utils/url";
import { LocaleMessage } from "../Locale";
import { useDispatch } from "../../store/hooks";
import { weatherNavigateToHomeLocation } from "../../store/actions/weather";

const Footer: React.FC = () => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const year = Math.max(new Date().getFullYear(), 2020);
  return (
    <footer>
      <div className={`container ${styles.footer}`}>
        <div className={styles.brand}>
          <img src={logoImg} alt="WeatherMan" />
        </div>
        <div className={styles.navigationInformation}>
          <div className={styles.list}>
            <h5 className={styles.listTitle}>
              <LocaleMessage id="Navigation" />
            </h5>
            <ul className={styles.linkList}>
              <li>
                <Link to={getHomeUrl(locale)}>
                  <LocaleMessage id="HomePage" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(weatherNavigateToHomeLocation());
                  }}
                >
                  <LocaleMessage id="TodaysWeather" />
                </Link>
              </li>
              <li>
                <Link to={getWeatherUrl(locale)}>
                  <LocaleMessage id="WorldWeather" />
                </Link>
              </li>
              {/* <li>
                <Link to={getWidgetUrl(locale)}>
                  <LocaleMessage id="WeatherWidget" />
                </Link>
              </li> */}
            </ul>
          </div>
          <div className={styles.list}>
            <h5 className={styles.listTitle}>
              <LocaleMessage id="Information" />
            </h5>
            <ul className={styles.linkList}>
              <li>
                <Link to={getContanctUsUrl(locale)}>
                  <LocaleMessage id="ContactUs" />
                </Link>
              </li>
              <li>
                <Link to={getPrivacyPolicyUrl(locale)}>
                  <LocaleMessage id="PrivacyPolicy" />
                </Link>
              </li>
              <li>
                <Link to={getTosUrl(locale)}>
                  <LocaleMessage id="TermsOfUse" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className={styles.joinUs}>
          <h5 className={styles.listTitle}>
            <LocaleMessage id="JoinUs" />
          </h5>
          <ul className={styles.socialList}>
            <li>
              <a href="https://www.facebook.com/">
                <img alt="Facebook" title="Facebook" src={facebookImg} />
              </a>
            </li>
            <li className="mx-30">
              <a href="https://twitter.com/">
                <img alt="Twitter" title="Twitter" src={twitterImg} />
              </a>
            </li>
            <li>
              <a href="http://instagram.com/">
                <img alt="Instagram" title="Instagram" src={instagramImg} />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className={styles.copyright}>
        <LocaleMessage id="Copyright" values={{ year }} />
      </div>
    </footer>
  );
};

export default Footer;

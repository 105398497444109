import React, { useEffect, useState } from "react";
import { LocaleMessage } from "../../../components/Locale";
import styles from "./ContactInformation.module.scss";
import { UserProfile } from "../../../api/authApi";
import { PhoneField, TextField } from "../../../components/Controls";
import { Card, CardSection, CardTitle } from "../Card/Card";

type ContactInformationProps = {
  account: string;
  userProfile: UserProfile;
  updateUser: (userProfile: Partial<UserProfile>) => void;
};

const ContactInformation: React.FC<ContactInformationProps> = ({
  account,
  userProfile,
  updateUser,
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const handlePhoneChange = (phone: string) => {
    updateUser({ phone });
  };
  const nameInitials = userProfile.name
    .split(" ")
    .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
    .join("")
    .toUpperCase();

  const checkImgLoaded = async (src: string | undefined) => {
    if (!src) return setImgLoaded(false);
    const res = await fetch(src, { mode: "no-cors" });
    setImgLoaded(!!res.ok);
  };

  useEffect(() => {
    if (!imgLoaded) {
      checkImgLoaded(userProfile.pictureUrl);
    }
  }, [imgLoaded, userProfile.pictureUrl]);

  return (
    <Card>
      <CardSection>
        {imgLoaded ? (
          <img
            src={userProfile.pictureUrl}
            alt="pictureUrl"
            className={styles.avatar}
          />
        ) : (
          <div className={styles.emptyAvatar}>{nameInitials}</div>
        )}

        <div>
          <h3 className={styles.userName}>{userProfile.name}</h3>
          <p className={styles.textLabel}>
            Logged in through a {account} account
          </p>
        </div>
      </CardSection>

      <CardTitle title={<LocaleMessage id="ContactInformation" />} />

      <CardSection className={styles.cardSectionMobile}>
        <TextField
          label="Email address"
          value={userProfile.email}
          disabled
          className={styles.emailField}
        />
        <PhoneField
          label="Phone number (optional)"
          value={userProfile.phone}
          onChange={handlePhoneChange}
        />
      </CardSection>
    </Card>
  );
};

export default ContactInformation;

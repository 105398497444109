import {
  REGIONS_LOADED,
  RegionsActionTypes,
  REGIONS_BY_COUNTRY_LOADING,
  REGIONS_BY_COUNTRY_LOADED,
} from "../types/regions";
import {
  fetchRegionsByCountry,
  Region,
  LocationType,
} from "../../api/placesApi";
import { errorsAdd } from "./errors";
import { areRegionsByCountryLoaded } from "../selectors/regions";
import { RootDispatch, GetState } from "..";
import { getLocale } from "../selectors/locale";

export function regionsLoaded(regions: Region[]): RegionsActionTypes {
  return { type: REGIONS_LOADED, regions };
}

function loadRegionsByCountry(locationType: LocationType, countryId: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!countryId) return;

    dispatch({ type: REGIONS_BY_COUNTRY_LOADING, locationType, countryId });

    try {
      const lang = getLocale(getState());
      const regions = await fetchRegionsByCountry(
        locationType,
        countryId,
        lang
      );

      dispatch({ type: REGIONS_LOADED, regions });
      dispatch({
        type: REGIONS_BY_COUNTRY_LOADED,
        locationType,
        countryId,
        regionIds: regions.map((x) => x._id),
      });
    } catch (e) {
      console.error(e);

      dispatch(errorsAdd(e.message));
    }
  };
}

export function tryLoadRegionsByCountry(
  locationType: LocationType,
  countryId: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!countryId) return;
    const state = getState();
    if (areRegionsByCountryLoaded(state, locationType, countryId)) return;
    await dispatch(loadRegionsByCountry(locationType, countryId));
  };
}

import { DAILY_SHORT_LOADED } from "../types/dailyShort";
import { fetchDailyForecastsShort } from "../../api/weatherApi";
import { chunkArray } from "../../utils/array";
import { ThunkDispatch } from "redux-thunk";
import { RootState, GetState, RootDispatch } from "..";
//import { errorsAdd } from "./errors";

const LOAD_AT_ONCE = 50;

function loadDailyShort(keys: string[], running?: () => boolean) {
  return async (dispatch: RootDispatch) => {
    keys = Array.from(new Set(keys));
    if (!keys.length) return;

    const keyChunks = chunkArray(keys, LOAD_AT_ONCE);

    const checkRunning = running || (() => true);

    try {
      for (const chunk of keyChunks) {
        if (!checkRunning()) return;

        const forecasts = await fetchDailyForecastsShort(chunk);

        dispatch({ type: DAILY_SHORT_LOADED, forecasts });
      }
    } catch (e) {
      console.error(e);

      //dispatch(errorsAdd(e.message));
    }
  };
}

export function tryLoadDailyShort(keys: string[], running?: () => boolean) {
  keys = Array.from(new Set(keys));

  return async (
    dispatch: ThunkDispatch<RootState, undefined, any>,
    getState: GetState
  ) => {
    //console.log("DS");
    const forecasts = getState().dailyShort;
    const now = new Date();
    const missingKeys = keys.filter((x) => {
      const forecast = forecasts[x];
      return !forecast || forecast.expires < now;
    });
    if (!missingKeys.length) return;

    return await dispatch(loadDailyShort(missingKeys, running));
  };
}

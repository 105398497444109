import React from "react";
import styles from "./RainChance.module.scss";
import { formatPercent } from "../../types/units";
import drop from "./drop.svg";
import dropLight from "./drop-light.svg";

type RainChanceProps = {
  rainChance: number | undefined;
  light?: boolean;
  className?: string;
};

const RainChance: React.FC<RainChanceProps> = ({
  rainChance,
  light,
  className = ""
}) => {
  return (
    <div
      className={`${styles.rain} ${light ? styles.light : ""} ${className}`}
      style={rainChance === undefined ? { visibility: "hidden" } : undefined}
    >
      <img alt="drop" src={light ? dropLight : drop} className={styles.drop} />
      {formatPercent(rainChance)}
    </div>
  );
};

export default RainChance;

import React from "react";
import DropdownD, { Option as DropdownOption } from "react-dropdown";
import styles from "./Dropdown.module.scss";

export type Option = DropdownOption;

type DropdownProps = {
  options: Option[];
  value?: string;
  placeholder?: string;
  onChange?: (arg: Option) => void;
  className?: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  className = "",
  options,
  value,
  placeholder,
  onChange
}) => {
  return (
    <DropdownD
      className={`${styles.wrap} ${className}`}
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default Dropdown;

import React from "react";
import { LocaleMessage } from "../../../components/Locale";
import { Card, CardTitle } from "../Card/Card";
import styles from "./AlertsNotifications.module.scss";

type RowProps = {
  title: string;
  count: number;
};

const Row: React.FC<RowProps> = ({ title, count }) => {
  return (
    <p className={styles.statisticRow}>
      {title}: <span className={styles.count}>{count}</span>
    </p>
  );
};

type AlertsStatisticsProps = {
  usersAllCount: number;
  usersAlertsCount: number;
  usersAlertsActiveCount: number;
  handleRefresh: () => void;
  isLoading: boolean;
};

const AlertsStatistic: React.FC<AlertsStatisticsProps> = ({
  usersAllCount,
  usersAlertsCount,
  usersAlertsActiveCount,
  handleRefresh,
  isLoading,
}) => {
  return (
    <Card id="notifications">
      <CardTitle title={<LocaleMessage id="AccSettingsAlertsStatistic" />} />
      <button className={styles.btnRefresh} onClick={handleRefresh}>
        {isLoading ? "..." : "Refresh"}
      </button>

      <Row title="All registered users" count={usersAllCount} />
      <Row
        title="All users who have ever tried alerts"
        count={usersAlertsCount}
      />
      <Row
        title="All users who have alerts active"
        count={usersAlertsActiveCount}
      />
    </Card>
  );
};

export default AlertsStatistic;

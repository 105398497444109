import { useEffect, RefObject, useState } from "react";

export function useScrollTop(deps?: readonly any[], disabled?: boolean) {
  useEffect(() => {
    if (!disabled && typeof window !== "undefined") {
      //console.log("scrolling");
      window.scroll(0, 0);
    }

    // eslint-disable-next-line
  }, [...(deps || []), disabled]);
}

export function tryScrollIntoView(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  if (rect.top < 0 || rect.top > window.innerHeight)
    element.scrollIntoView({ behavior: "smooth" });
}

export function useScrollRefTop(
  ref: RefObject<HTMLElement>,
  deps?: readonly any[]
) {
  useEffect(() => {
    if (ref.current) tryScrollIntoView(ref.current);
    // eslint-disable-next-line
  }, [...(deps || []), ref]);
}

export function useClickOutsideClose(
  ref: React.RefObject<HTMLElement>,
  open: boolean,
  close: () => void
) {
  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [open, close, ref]);
}

export function useDidMount() {
  const [mount, setMount] = useState(false);
  useEffect(() => setMount(true), []);
  return mount;
}

import React, { useState } from "react";
import styles from "./Controls.module.scss";
import RCTimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./TimePicker.css";
import moment from "moment";
import iconClock from "../../assets/icons/i-clock.svg";

type TimePickerProps = {
  value?: number[];
  onChange: (time: string) => void;
  format?: string;
  format12Hours?: boolean;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  value,
  onChange,
  format = "hh:mm a",
  format12Hours = true,
}) => {
  const [opened, setOpened] = useState(false);
  const handleChange = (value: any) => {
    onChange(`${moment(value).hours()}:${moment(value).minutes()}`);
  };

  const handleOpen = () => {
    setOpened(true);
  };
  const handelClose = () => {
    setOpened(false);
  };

  return (
    <div
      className={`${styles.timePicker} ${
        opened ? styles.timePickerActive : ""
      }`}
    >
      <RCTimePicker
        value={
          value ? moment(moment().hour(value[0]).minute(value[1])) : moment()
        }
        allowEmpty={false}
        clearIcon={undefined}
        showSecond={false}
        format={format}
        use12Hours={format12Hours}
        onChange={handleChange}
        inputIcon={
          <img
            className={styles.timePickerIcon}
            src={iconClock}
            alt="time icon"
          />
        }
        minuteStep={15}
        onOpen={handleOpen}
        onClose={handelClose}
      />
    </div>
  );
};

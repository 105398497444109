import React from "react";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  PresentationAttributes,
  ContentRenderer
} from "recharts";
import { getConditionImage } from "../WeatherCondition";
import styles from "./TempConditionChart.module.scss";
import SVGDropShadowFilter from "../SVG/SVGDropShadowFilter";
import { WeatherCode } from "../../api/weatherApi";
import ResponsiveContainerSsr from "../ResponsiveContainerSsr";

export type TempConditionPoint = {
  temp: number | undefined;
  weatherCode: WeatherCode | undefined;
  weatherDesc: string;
  day: boolean;
};

type WeatherConditionLabelProps = {
  x: number;
  y: number;
  point: TempConditionPoint;
  tempNode: React.ReactNode;
  width: number;
};

const WeatherConditionLabel: React.FC<WeatherConditionLabelProps> = ({
  x,
  y,
  point: { weatherCode, day },
  tempNode
}) => {
  //console.log(props);

  const height = Number.parseFloat(styles.condtionHeight);
  return (
    <>
      <image
        x={x - 25}
        y={y - height}
        width={50}
        height={50}
        href={getConditionImage(weatherCode, day)}
        // preserveAspectRatio="xMidYMid meet"
      />
      <text x={x} y={y - height + 90} textAnchor="middle">
        {tempNode}
      </text>
    </>
    // <foreignObject
    //   x={x - width / 2}
    //   y={y - height}
    //   width={width}
    //   height={height}
    // >
    //   <div className={styles.tempConditionWrap}>
    //     <WeatherCondition
    //       weatherCode={weatherCode}
    //       weatherDesc={weatherDesc}
    //       day={day}
    //       className={styles.conditionImg}
    //     />
    //     <div className={styles.temp}>{tempNode}</div>
    //   </div>
    // </foreignObject>
  );
};

export type RenderTempProps = {
  point: TempConditionPoint;
  index: number;
};

type TempConditionProps = {
  baseId: string;
  data: TempConditionPoint[];
  count: number;
  height: number;
  ssrWidth: number;
  stroke: React.ReactText;
  renderTemp: ContentRenderer<RenderTempProps>;
  dot?: Partial<PresentationAttributes<SVGCircleElement>>;
};

type TempConditionInnerProps = TempConditionProps & {
  width: number;
};

const TempConditionChartInner: React.FC<TempConditionInnerProps> = ({
  baseId,
  width,
  height,
  data,
  stroke,
  dot,
  count,
  renderTemp
}) => {
  const itemWidth = width / count;
  const leftPadding = itemWidth / 2;
  const rightPadding = leftPadding + itemWidth * (count - data.length);

  const dotProps: Partial<PresentationAttributes<SVGCircleElement>> = {
    r: 3,
    filter: "",
    ...dot
  };

  const filterId = baseId + "_shadow";

  return (
    <LineChart
      width={width}
      height={height}
      data={data}
      margin={{ bottom: 30, top: 0, left: leftPadding, right: rightPadding }}
    >
      <defs>
        <SVGDropShadowFilter id={filterId} color={stroke} />
      </defs>
      <YAxis
        padding={{ top: 110, bottom: 0 }}
        domain={["dataMin", "dataMax"]}
        hide
      />
      <XAxis padding={{ left: 0, right: 0 }} hide />
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="temp"
        stroke={stroke}
        strokeWidth={2}
        filter={`url(#${filterId})`}
        dot={dotProps}
        label={({ x, y, index }) => {
          const point = data[index];
          return (
            <WeatherConditionLabel
              x={x}
              y={y}
              tempNode={renderTemp({ point, index })}
              point={point}
              width={itemWidth}
            />
          );
        }}
      />
    </LineChart>
  );
};

const TempConditionChart: React.FC<TempConditionProps> = props => {
  const { height, ssrWidth } = props;
  return (
    <ResponsiveContainerSsr
      height={height}
      ssrWidth={ssrWidth}
      ssrHeight={height}
    >
      <TempConditionChartInner {...props} width={0} height={height} />
    </ResponsiveContainerSsr>
  );
};

export default TempConditionChart;

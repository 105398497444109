import { WeatherType } from "../../types/weatherType";
import {
  DailyForecast,
  HourlyForecast,
  CurrentWeather,
  MonthlyStat,
  ImageryResult,
  TidesResult,
  WeatherAlertsResult,
} from "../../api/weatherApi";

export const WEATHER_SELECT = "WEATHER_SELECT";
export const WEATHER_SELECT_ERROR = "WEATHER_SELECT_ERROR";

export const WEATHER_DAILY_LOADING = "WEATHER_DAILY_LOADING";
export const WEATHER_DAILY_LOADED = "WEATHER_DAILY_LOADED";
export const WEATHER_DAILY_FAILED = "WEATHER_DAILY_FAILED";

export const WEATHER_HOURLY_LOADING = "WEATHER_HOURLY_LOADING";
export const WEATHER_HOURLY_LOADED = "WEATHER_HOURLY_LOADED";
export const WEATHER_HOURLY_FAILED = "WEATHER_HOURLY_FAILED";

export const WEATHER_CURRENT_LOADING = "WEATHER_CURRENT_LOADING";
export const WEATHER_CURRENT_LOADED = "WEATHER_CURRENT_LOADED";
export const WEATHER_CURRENT_FAILED = "WEATHER_CURRENT_FAILED";

export const WEATHER_STATS_LOADING = "WEATHER_STATS_LOADING";
export const WEATHER_STATS_LOADED = "WEATHER_STATS_LOADED";
export const WEATHER_STATS_FAILED = "WEATHER_STATS_FAILED";

export const WEATHER_IMAGERY_LOADING = "WEATHER_IMAGERY_LOADING";
export const WEATHER_IMAGERY_LOADED = "WEATHER_IMAGERY_LOADED";
export const WEATHER_IMAGERY_FAILED = "WEATHER_IMAGERY_FAILED";

export const WEATHER_ALERTS_LOADING = "WEATHER_ALERTS_LOADING";
export const WEATHER_ALERTS_LOADED = "WEATHER_ALERTS_LOADED";
export const WEATHER_ALERTS_FAILED = "WEATHER_ALERTS_FAILED";

export const WEATHER_TIDES_LOADING = "WEATHER_TIDES_LOADING";
export const WEATHER_TIDES_LOADED = "WEATHER_TIDES_LOADED";
export const WEATHER_TIDES_FAILED = "WEATHER_TIDES_FAILED";

type WeatherSelectedAction = {
  type: typeof WEATHER_SELECT;
  locationId: string;
  weatherType: WeatherType;
};

type WeatherSelectedErrorAction = {
  type: typeof WEATHER_SELECT_ERROR;
  error: string;
};

type WeatherDetailLoading<Type extends string> = {
  type: Type;
  locationId: string;
};

type WeatherDetailLoaded<Type extends string, T> = {
  type: Type;
  locationId: string;
  data: T;
};

type WeatherDetailFailed<Type extends string> = {
  type: Type;
};

type WeatherDetailActionTypes<
  T,
  TLoading extends string,
  TLoaded extends string,
  TFailed extends string
> =
  | WeatherDetailLoading<TLoading>
  | WeatherDetailLoaded<TLoaded, T>
  | WeatherDetailFailed<TFailed>;

type WeatherDailyActionTypes = WeatherDetailActionTypes<
  DailyForecast[],
  typeof WEATHER_DAILY_LOADING,
  typeof WEATHER_DAILY_LOADED,
  typeof WEATHER_DAILY_FAILED
>;

type WeatherHourlyActionTypes = WeatherDetailActionTypes<
  HourlyForecast[],
  typeof WEATHER_HOURLY_LOADING,
  typeof WEATHER_HOURLY_LOADED,
  typeof WEATHER_HOURLY_FAILED
>;

type WeatherCurrentActionTypes = WeatherDetailActionTypes<
  CurrentWeather | undefined,
  typeof WEATHER_CURRENT_LOADING,
  typeof WEATHER_CURRENT_LOADED,
  typeof WEATHER_CURRENT_FAILED
>;

type WeatherStatsActionTypes = WeatherDetailActionTypes<
  MonthlyStat[],
  typeof WEATHER_STATS_LOADING,
  typeof WEATHER_STATS_LOADED,
  typeof WEATHER_STATS_FAILED
>;

type WeatherImageryActionTypes = WeatherDetailActionTypes<
  ImageryResult | undefined,
  typeof WEATHER_IMAGERY_LOADING,
  typeof WEATHER_IMAGERY_LOADED,
  typeof WEATHER_IMAGERY_FAILED
>;

type WeatherAlertsActionTypes = WeatherDetailActionTypes<
  WeatherAlertsResult | undefined,
  typeof WEATHER_ALERTS_LOADING,
  typeof WEATHER_ALERTS_LOADED,
  typeof WEATHER_ALERTS_FAILED
>;

type WeatherTidesActionTypes = WeatherDetailActionTypes<
  TidesResult | undefined,
  typeof WEATHER_TIDES_LOADING,
  typeof WEATHER_TIDES_LOADED,
  typeof WEATHER_TIDES_FAILED
>;

export type WeatherActionTypes =
  | WeatherSelectedAction
  | WeatherSelectedErrorAction
  | WeatherDailyActionTypes
  | WeatherHourlyActionTypes
  | WeatherCurrentActionTypes
  | WeatherStatsActionTypes
  | WeatherImageryActionTypes
  | WeatherAlertsActionTypes
  | WeatherTidesActionTypes;

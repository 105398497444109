import React, { useState } from "react";
import { useSelector } from "../../store/hooks";
import { getSelectedWeatherAlerts } from "../../store/selectors/weather";
import { formatAlertSummary } from "../../types/alerts";
import { useFormatMessage } from "../../i18n";
import styles from "./WeatherAlerts.module.scss";
import { WeatherAlert } from "../../api/weatherApi";
import WeatherAlertPopup from "./WeatherAlertPopup";

const WeatherAlerts: React.FC = () => {
  const alerts = useSelector(getSelectedWeatherAlerts);
  const formatMessage = useFormatMessage();
  const [dismissed, setDismissed] = useState<{ [key: string]: true }>({});
  const [current, setCurrent] = useState<WeatherAlert | undefined>(undefined);

  if (!alerts.length) return null;
  const visible = alerts.filter((x) => !dismissed[x._id]);
  if (!visible.length) return null;
  //visible.forEach((x) => (x.disclaimer = "Disclaimer text may be pretty long"));

  return (
    <div className={styles.wrap}>
      {visible.map((x) => (
        <div className={styles.alert} key={x._id}>
          <div className={styles.alertOuter}>
            <div className={styles.alertInner}>
              <div className={styles.warning}>
                <button
                  aria-label="Dismiss"
                  className={styles.dismissBtnNoBreak}
                  onClick={() => setDismissed({ ...dismissed, [x._id]: true })}
                ></button>
              </div>
              <div className={styles.summary}>
                {formatAlertSummary(formatMessage, x)}
                {!!x.disclaimer && (
                  <div className={styles.disclaimer}>{x.disclaimer}</div>
                )}
              </div>
              <button className={styles.moreBtn} onClick={() => setCurrent(x)}>
                {formatMessage("AlertMore")}
              </button>
            </div>
          </div>
          <button
            aria-label="Dismiss"
            className={styles.dismissBtnBreak}
            onClick={() => setDismissed({ ...dismissed, [x._id]: true })}
          ></button>
        </div>
      ))}
      {current && (
        <WeatherAlertPopup
          alert={current}
          close={() => setCurrent(undefined)}
        />
      )}
    </div>
  );
};

export default WeatherAlerts;

import React, { useState, useRef, useCallback } from "react";
import { StationTides, TideHiLo, TideHourly } from "../../../api/weatherApi";
import {
  LocaleMessage,
  LocaleTime,
  LocaleDayShort,
  LocaleDateOnly,
} from "../../../components/Locale";
import styles from "./TideStationHighLow.module.scss";
import { groupByDate } from "./utils";
import { formatMeter } from "../../../types/units";
import { useFormatMessage } from "../../../i18n";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import TideStationHourlyPopup from "./TideStationHourlyPopup";
import { useClickOutsideClose } from "../../../utils/hooks";

const HourlyButton: React.FC<{ hourly: TideHourly[] }> = ({ hourly }) => {
  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), [setOpen]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutsideClose(wrapperRef, open, close);

  return (
    <div className={styles.hourly} ref={wrapperRef}>
      <button className={styles.hourlyBtn} onClick={() => setOpen(!open)}>
        <LocaleMessage id="TidesHourly" />
      </button>
      {open && <TideStationHourlyPopup hourly={hourly} />}
    </div>
  );
};

type DateTableProps = {
  date: Date;
  hilo: TideHiLo[];
  hourly: TideHourly[];
  today: boolean;
};

const DateTable: React.FC<DateTableProps> = ({ date, hilo, hourly, today }) => {
  const formatMessage = useFormatMessage();
  const units = useSelector(getUnits);

  const highs = hilo.filter((x) => x.type === "H");
  const lows = hilo.filter((x) => x.type === "L");
  const max = highs.length > lows.length ? highs : lows;

  return (
    <div className={styles.dateTable}>
      <div className={styles.card}>
        <div className={styles.title}>
          <div className={styles.day}>
            {today ? (
              <LocaleMessage id="Today" />
            ) : (
              <LocaleDayShort value={date} />
            )}
          </div>
          <div className={styles.date}>
            <LocaleDateOnly value={date} />
          </div>
          {!!hourly.length && <HourlyButton hourly={hourly} />}
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.tideH}>
              <LocaleMessage id="TideH" />
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.tideL}>
              <LocaleMessage id="TideL" />
            </div>
          </div>
        </div>

        {max.map((_, i) => {
          const high = highs[i];
          const low = lows[i];

          return (
            <div key={i} className={styles.row}>
              <div className={styles.colp}>
                {high ? (
                  <>
                    <span className={styles.level}>
                      {formatMeter(formatMessage, high.level, units)}{" "}
                    </span>
                    <span className={styles.time}>
                      <LocaleMessage id="TimeAt" />{" "}
                      <LocaleTime value={high.time} />
                    </span>
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </div>
              <div className={styles.colp}>
                {low ? (
                  <>
                    <span className={styles.level}>
                      {formatMeter(formatMessage, low.level, units)}{" "}
                    </span>
                    <span className={styles.time}>
                      <LocaleMessage id="TimeAt" />{" "}
                      <LocaleTime value={low.time} />
                    </span>
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type TideStationHighLowProps = {
  tides: StationTides;
};

const TideStationHighLow: React.FC<TideStationHighLowProps> = ({ tides }) => {
  if (!tides.hilo.length) return null;

  const byDate = groupByDate(tides.hilo).slice(0, 3);

  return (
    <div className={styles.wrap}>
      {byDate.map(({ date, data }, i) => (
        <DateTable
          key={date.getTime()}
          date={date}
          hilo={data}
          hourly={tides.hourly.filter(
            (x) => x.time.getUTCDate() === date.getUTCDate()
          )}
          today={i === 0}
        />
      ))}
    </div>
  );
};

export default TideStationHighLow;

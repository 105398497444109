import {
  SearchActionTypes,
  SEARCH_QUERY,
  SEARCH_LOADED,
  SEARCH_FAILED,
  SEARCH_PLACEHOLDER
} from "../types/search";

type SearchState = {
  locationIds: string[];
  regionIds: string[];
  countryIds: string[];
  query: string;
  placeholder: string;
  loading: boolean;
  error: string;
};

const initialState: SearchState = {
  locationIds: [],
  regionIds: [],
  countryIds: [],
  query: "",
  error: "",
  placeholder: "",
  loading: false
};

export default function reducer(
  state: SearchState = initialState,
  action: SearchActionTypes
) {
  switch (action.type) {
    case SEARCH_PLACEHOLDER:
      return { ...state, placeholder: action.placeholder };
    case SEARCH_QUERY:
      return { ...state, query: action.query, loading: true, error: "" };
    case SEARCH_LOADED:
      if (action.query !== state.query) return state;
      return {
        ...state,
        locationIds: action.locationIds,
        regionIds: action.regionIds,
        countryIds: action.countryIds,
        loading: false,
        error: ""
      };
    case SEARCH_FAILED:
      if (action.query !== state.query) return state;
      return {
        ...state,
        loading: false,
        error: action.error,
        locationIds: [],
        regionIds: [],
        countryIds: []
      };
    default:
      return state;
  }
}

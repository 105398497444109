import React, {useEffect} from "react";
import Helmet from "react-helmet";
import { useFormatMessage, useLocale } from "../../i18n";
import { RouteComponentProps, Link } from "react-router-dom";
import { setCacheControlStatic } from "../../utils/cache";
import { useScrollTop } from "../../utils/hooks";
import { getTosUrl } from "../../utils/url";
import {RootDispatch} from "../../store";
import {Request} from "express";
import {tryInit} from "../../store/actions/init";
import {useDispatch} from "react-redux";

async function fetchData(dispatch: RootDispatch, req?: Request) {
  await dispatch(tryInit(req));
}

const PrivacyPolicyPage: React.FC<RouteComponentProps> = (props) => {
  const formatMessage = useFormatMessage();
  const locale = useLocale();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch]);

  setCacheControlStatic(props);
  useScrollTop();

  return (
    <div className="container text-html">
      <Helmet title={formatMessage("PrivacyPolicyTitle")}>
        <meta name="description" content={formatMessage("PrivacyPolicyDesc")} />
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy is important, and we encourage you to carefully read
        it.
      </p>
      <p>
        This Privacy Policy discloses how we collect, use, and share information
        that we gather related to your engagement with weatherman.com and any
        other of our software applications, mobile applications, and websites
        and services that reference or link to this Privacy Policy (together,
        the Services). The Services do not include websites, mobile
        applications, or other services that link to another privacy policy.
        This Privacy Policy also describes the choices you have regarding our
        use of and your ability to access, correct, or delete the information.
        Subject to applicable law, by using the Services, you accept the terms
        of this Privacy Policy and consent to the data collection, use, and
        sharing described herein. Where necessary under applicable law, we may
        seek your consent by other means such as by asking you to click on a
        button or to change a setting. Where we do so, we will also offer you a
        way to revoke your consent.
      </p>
      <ol>
        <li>
          How and Why We Collect and Use Personal Data and Other Information
        </li>
        <li>Data Collection Technologies and Consumer Choice</li>
        <li>How We Share or Disclose Your Information with Partners</li>
        <li>Other Limitations on Privacy</li>
        <li>Retention Period</li>
        <li>Data Rights</li>
        <li>Minors' Privacy and Submissions</li>
        <li>International Transfer</li>
        <li>Our Commitment to Security</li>
        <li>CCPA Notice</li>
        <li>Changes to the Privacy Policy</li>
        <li>How to Contact Us</li>
        <li>General Data Protection Regulation</li>
      </ol>
      <ol>
        <li id="01">
          <h3>
            How and Why We Collect and Use Personal Data and Other Information
          </h3>
          <p>
            In order to operate the Services and to maximize your experience of
            the Services, we may collect and use information, some of which may
            be considered personal data in your country. Please note that you
            may choose not to provide us with certain information; by doing so,
            however, you may not be able to receive the full range of Services
            from us. We collect information in the following ways:
          </p>
          <ol type="A">
            <li id="section1A">
              <p>
                <em>User Account and Registration.</em> When you choose to
                register a user account, you will be required to provide your
                e-mail address. If we can use information, alone or in
                combination, to reasonably identify you as an individual, we
                treat the information as personal data.
              </p>
              <p>
                We may use personal data that you provide to give you
                personalized treatment on the Services; to operate the Services;
                to analyze, improve, enhance, and fine tune our products and the
                Services; and for any other purpose that is permitted by
                applicable law and consistent with this Privacy Policy and the
                permissions you have provided us.
              </p>
            </li>
            <li id="section1B">
              <p>
                <em>Information Provided By You.</em> You may also choose to
                provide us with information, some of which may be personal data,
                when you interact with the Services in the following ways:
              </p>
              <ul>
                <li>
                  Complete a survey for example, for example, you may provide us
                  an e-mail address so that we can contact you about your survey
                  response;
                </li>
                <li>
                  Enter a contest, sweepstakes, or other promotion for example,
                  you may provide your telephone and home address and we may be
                  legally obligated to disclose this information if you win;
                </li>
                <li>
                  Send us an e-mail message or complete an inquiry form to
                  receive additional information for example, you might e-mail
                  us with a question and we will use your e-mail address to
                  respond to your inquiry;
                </li>
                <li>
                  Submit a comment, leave feedback, or send us a photo or video
                  for example, you might submit a cool photo of a weather event
                  you experienced that we may post on the Services so others can
                  enjoy it;
                  <br />
                  <em>Special Note about Content Submissions.</em> The Services
                  may from time to time offer areas where you and other users
                  can post or otherwise submit information, photos, videos,
                  graphics, creative suggestions, ideas, notes, concepts,
                  information, ratings, or other materials on or to the Services
                  (collectively, Submissions). Any geolocation information,
                  tags, or other metadata in Submissions may become publicly
                  available. Any information, including personal data, in your
                  Submissions can be read, collected, or used by others. We
                  cannot control and are not responsible for how others may use
                  the information contained in your Submissions
                </li>
                <li>
                  Report weather or other conditions for example, you might tell
                  us that the weather in your area is cold or rainy and we may
                  associate that information with your account;
                </li>
                <li>
                  Engage with interactive advertising we receive the information
                  you provide when you submit oral or written questions or
                  comments via the interactive ads that are placed on the
                  Services or on third-party platforms; and we may share this
                  information with our Ad Partners; and
                </li>
                <li>
                  Customize the Services for example, you might set a "home"
                  location so that every time you visit the Services, you are
                  automatically displayed the weather for that home location.
                </li>
              </ul>
            </li>
            <li id="section1C">
              <p>
                <em>Automatic Collection.</em> We also collect certain
                information through automated means. Some of the information we
                collect through automated means may, whether alone or combined
                with other data, be personal data. For example, we automatically
                collect:
              </p>
              <ul>
                <li>Information about your device and device capabilities</li>
                <li>Information about your device operating system</li>
                <li>Information about your browser</li>
                <li>Information about how you use the Services</li>
                <li>Your activities on the Services</li>
                <li>IP address</li>
                <li>Advertising identifiers</li>
                <li>Mobile or Internet Carrier</li>
                <li>Browser type</li>
                <li>Browser identifier</li>
                <li>Referring URL</li>
              </ul>
              <p>
                This information helps us to understand, analyze, and measure
                how users use the Services; manage Services' operations; deliver
                and measure the effectiveness of advertisements; help diagnose
                problems; recognize repeat visitors; and facilitate your access
                to and use of the Services.
              </p>
            </li>
            <li id="section1D">
              <p>
                <em>Location Information.</em> We, our service providers, and
                our Ad and Analytics Partners (see Section 2 for more
                information) may collect location information through the
                Services. We may share the location information we collect with
                third parties as addressed in Section 3.
              </p>
              <p>
                We collect location information to provide you location-based
                services (such as severe weather alerts and other weather
                information through our mobile applications and on our
                websites), provide advertisements that are relevant to your
                geographic location, and conduct analytics to improve the
                Services. The ways in which we collect location information, and
                the type of location information we collect, depend on the
                devices you use to access the Services and the settings for
                those devices.
              </p>
              <p>
                If you are accessing the Services through the Sites, we may
                detect your general location so that we can automatically show
                you the weather for your general area. Depending on browser type
                and configuration, your browser may inform you that the Sites
                would like to collect your specific location and request your
                permission to do so. If you do not allow it, or your browser
                does not permit it, only your general location information is
                then collected and may be stored locally on your device. Even if
                we are unable to collect direct location information on your
                device, you may manually enter a location (such as city, postal
                code, and country). If you opt out of sharing your current
                location, you may continue to receive content that is relevant
                for the city you have entered in the search bar (i.e.,
                contextual city name).
              </p>
              <p>
                If you access the Services on your mobile device, we may,
                depending on your application and operating system settings,
                directly collect precise geographic location information and
                sensor data via Global Positioning System (GPS), cellular
                network information, and other location-based features.
                Depending on your mobile devices operating system, we typically
                receive the latitude and longitude of your mobile device, as
                well as date and time and sensor data such as altimeter. The
                precision of latitude and longitude varies greatly and is
                determined by various factors including those controlled by your
                device, operating system, or mobile service provider.
              </p>

              <p>
                You can turn off direct location collection (or, on some
                operating systems, continuous direct location collection)
                through your device settings. If you turn off location-based
                services, you may continue to receive alerts for locations that
                you have selected within the Services and that do not require
                current location.
              </p>
            </li>
            <li id="section1E">
              <p>
                <em>Alerts and Notifications.</em> Certain Services offer you
                the ability to receive weather-related updates via push
                notification. To provide these Services, we may need to collect
                device information and mobile carrier information, in addition
                to the postcode or geographic area to which the alert, update,
                or notification pertains. If you no longer wish to receive these
                notifications, you may opt out by turning them off within the
                alert settings of your user account. You may also opt out
                through the settings on your mobile device or the particular
                Service for which you registered, or by deleting the
                application.
              </p>
            </li>
            <li id="section1F">
              <p>
                <em>Information from Analytics and Advertising Partners.</em> We
                may obtain information about you or your use of the Services
                from our vendors and other third parties such as analytics
                providers or advertisers if such third parties have a legal
                basis to share such information. We may combine the information
                you provide us and information we automatically collect with
                information from public or third-party sources. If we combine or
                associate information from other sources with personal data that
                we collect through the Services, we will treat the combined
                information as personal data in accordance with this Privacy
                Policy.
              </p>
            </li>
            <li>
              <p>
                <em>Processing Basis.</em> Our processing of your personal data
                in order to provide you with the Services will typically be
                based on one of the following processing bases: (i) because you
                have provided us with your specific, informed, freely-given, and
                unambiguous consent, (ii) because we need to process it in order
                to provide you with the Services or a particular feature of the
                Services; that is, because we cannot readily fulfill your
                request without performing the relevant processing, or (iii)
                because the processing is necessary for the purposes of the
                legitimate interests pursued either by us or by a third party
                and such legitimate interests are not overridden by your privacy
                and related rights as an individual. Our legitimate interests
                include the provision of market-leading weather-related products
                and services, including the Services.
              </p>
            </li>
          </ol>
        </li>
        <li id="02">
          <h3>Data Collection Technologies and Consumer Choice</h3>
          <p>
            We and third parties acting on our behalf or on behalf of our
            partners use various technologies that help us to manage the
            operations of our Services and track usage behavior so that we may
            tailor information and personalize advertisements to make your
            visits more meaningful and to offer accurate forecast data to
            everyone, everywhere, free of charge. We adhere to the
            self-regulatory principles of the Digital Advertising Alliance (DAA)
            and the European Interactive Digital Advertising Alliance (EDAA).
          </p>
          <p>
            We work with a variety of advertisers, advertising networks, and
            advertising servers (Ad Partners) that use various technologies to
            collect data about your use of the Services (such as content viewed
            or ads clicked on) in order to serve relevant ads. Ad Partners use
            various technologies on the Services on web and within our
            applications to (among other purposes): track how the Services are
            used, what pages or content users interact with, and what sites or
            mobile applications users interact with after they leave the
            Services; link users devices; and serve more relevant ads on and
            within the Services or other websites or mobile applications that
            you visit. More information about this type of advertising is
            available from the Network Advertising Initiatives website at 
            <a href="http://www.networkadvertising.org/" target="new">
              www.networkadvertising.org
            </a>
            .
          </p>
          <p>
            We also contract with certain third parties that use data collection
            technologies to track, analyze, and report data about the use of the
            Services and to analyze and optimize the performance of the Services
            (Analytics Partners).
          </p>
          <p>
            Information that we collect via the data collection technologies
            described in this section may be linked to other information about
            you such as location and may be personal data. We use the
            technologies described in this section, alone or in combination, to
            understand user behavior, target and measure the effectiveness of
            advertisements, help diagnose problems, recognize repeat visitors,
            and facilitate your access to and use of the Services.
          </p>
          <ol type="A">
            <li>
              <p>
                <em>
                  Use of Cookies, Web Beacons, and Information Saved by Other
                  Technologies
                </em>
              </p>
              <p>
                Third parties may use cookies and other technologies through use
                of our Services to support or deliver personalized advertising
                on third-party websites and online services. The data collection
                technologies we and third parties use on web include but are not
                limited to:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Cookies:</strong> Cookies are text files containing
                    small amounts of information that are downloaded to your
                    computer or mobile device when you visit a website and
                    stored within your browser. Cookies are then sent back to
                    the originating website on each subsequent visit to that
                    website or to another website that recognizes those cookies.
                    Some cookies on our Services collect IP and MAC addresses,
                    which may be considered personal data in some jurisdictions.
                    Cookies are useful because they allow a website to recognize
                    a users device. You can find more information about cookies
                    at 
                    <a href="http://www.allaboutcookies.org/" target="new">
                      www.allaboutcookies.org
                    </a>
                    . The cookies used on this website can be categorized in the
                    following ways:
                  </p>
                  <ul>
                    <li>
                      <p>Category 1: Strictly Necessary Cookies</p>
                      <p>
                        These cookies are essential in order to enable you to
                        use the Services and its features.
                      </p>
                    </li>
                    <li>
                      <p>Category 2: Performance Cookies</p>
                      <p>
                        These cookies collect information about how you use the
                        Services (such as which pages you go to most often and
                        if you receive error messages). In general, these
                        cookies do not collect information that directly
                        identifies you. However, some such cookies may collect
                        GeoIP or other identifiers that may be considered
                        personal data in some jurisdictions.
                      </p>
                    </li>
                    <li>
                      <p>Category 3: Functionality Cookies</p>
                      <p>
                        These cookies allow Services to remember choices you
                        make (such as language or the region you are in) and
                        provide enhanced features (such as local weather reports
                        or traffic news). These cookies can also be used to
                        remember changes you have made to customize the
                        Services.
                      </p>
                    </li>
                    <li>
                      <p>Category 4: Targeting Cookies</p>
                      <p>
                        These cookies collect several pieces of information
                        about your browsing habits. They are often placed by
                        advertising networks rather than website operators. They
                        collect information about your interactions over time
                        with the Services as well as with third-party websites
                        and online services. This information may be shared with
                        other organizations such as advertisers in order to
                        provide you with advertisements that may be more
                        relevant to you based on your inferred interests.
                      </p>
                    </li>
                    <li>
                      <p>Category 5: Social Network Cookies</p>
                      <p>
                        These cookies are generally generated by content embeds.
                        They allow social network users to share content and use
                        other features such as publishing comments.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Web beacons:</strong> Certain pages on the Services
                    contain web beacons. Web beacons allow third parties to
                    obtain information, such as the IP address of the computer
                    that accessed the page on which the web beacon appears, the
                    URL of the page on which the web beacon appears, the time
                    the page containing the web beacon was viewed, the type of
                    browser used to view the page, and the information in
                    cookies sent by the third party.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Web Storage:</strong> We use local storage, such as
                    HTML5 localStorage and indexedDB, to enhance user experience
                    by, for example, storing your user preferences and settings
                    (e.g., volume/mute) in connection with animated content on
                    the Services. Local storage is similar to browser cookies
                    but can store data more complex than simple text. By itself,
                    local storage cannot do anything to or with the data stored
                    on your device.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <em>Use of Advertising Identifiers</em>
              </p>
              <p>
                We and our Ad Partners may collect information about usage of
                mobile applications via advertising identifiers to support or
                deliver personalized advertising. An advertising identifier is a
                randomly-generated alphanumeric code associated with a users
                device to help advertising services. These user-resettable
                identifiers have different names depending on the operating
                system Google Advertising ID (GAID) on Android devices and
                Identifiers for Advertisers (IDFA) on iOS. Advertising
                identifiers provide advertisers a way to identify a users device
                without using a permanent device identifier, such as serial
                number.
              </p>
            </li>
            <li>
              <p>
                The opt outs described below are device- or browser-specific and
                may not be available for all devices. So, if you opt out on your
                mobile device, you may continue to receive personalized
                advertising on your desktop computer or other devices. If you
                choose to opt out through any of our Ad Partners' sites, you
                still may see advertisements, but the advertisements will not be
                based on your interests.
              </p>
              <p>
                You can also opt out of future information collection from the
                Services by adjusting the settings on your device, ceasing use
                of the Service on web, or un-installing the relevant mobile
                application.
              </p>
            </li>
            <li id="section2D">
              <p>
                <em>Web-Related Opt Outs</em>
              </p>
              <p>
                For more information about our Ad Partners technologies, how
                they use the information collected by the technologies on the
                Services, and your options not to accept cookies placed by some
                Ad Partners on the Services, please visit the DAAs 
                <a href="http://optout.aboutads.info/?c=2#!/" target="new">
                  opt-out page
                </a>
                , the Digital Advertising Alliance of Canada's (DAAC's) 
                <a href="https://youradchoices.ca/choices" target="new">
                  opt-out page
                </a>
                , or one of the EDAA's country-specific 
                <a href="http://youronlinechoices.eu/" target="new">
                  opt-out pages
                </a>
                . In certain jurisdictions, you may also opt out of additional
                third-party advertising networks by going to the 
                <a
                  href="http://optout.networkadvertising.org/?c=1#!/"
                  target="new"
                >
                  Network Advertising Initiative's website
                </a>
                .
              </p>
              <p>
                Your browser, such as Internet Explorer, Chrome, Firefox, or
                Safari, may allow you to manage your cookies and local storage.
                Because each browser is a little different, we encourage you to
                check your browsers Help feature to learn how to block cookies,
                how to receive notification of new cookies, and how to disable
                existing cookies. Please note, however, that without some
                cookies you may not be able to take full advantage of all the
                Services and some parts of the Services may not function
                properly. For more information about managing cookies, please
                visit 
                <a
                  href="http://www.allaboutcookies.org/manage-cookies/"
                  target="new"
                >
                  www.allaboutcookies.org/manage-cookies/
                </a>
                .
              </p>
            </li>
            <li id="section3E">
              <p>
                <em>Mobile Application-Related Opt Outs</em>
              </p>
              <p>
                You can opt out of personalized advertising on Android and iOS
                through your device settings. Please note that these opt outs
                will disable personalized ads from all providers on all of your
                apps, and not just on our application.
              </p>

              <p>
                The DAA offers AppChoices, a separate choice tool for users to
                exercise control over the collection and use of data through
                mobile applications for personalized advertising and other
                related uses. To exercise choice for participating companies,
                you can install the DAAs AppChoices application on your mobile
                device. Visit 
                <a href="http://youradchoices.com/appchoices" target="new">
                  here
                </a>
                 for more information on DAA's AppChoices tool.
              </p>
            </li>
          </ol>
        </li>
        <li id="03">
          <h3>How We Share or Disclose Your Information with Partners</h3>
          <p>
            We may prepare and maintain information collected from the Services
            in a format that does not identify you or other individuals, and we
            may use or share such information. For example, we may analyze
            personal data to generate aggregated or statistical data, neither of
            which is personal information. We may also share any information
            collected from the Services in the following ways:
          </p>
          <ol type="A">
            <li id="section3A">
              <p>
                <em>Third-Party Service Providers.</em> When your personal data
                is collected on the Services, it may be shared with selected
                third parties who assist us with our business operations, with
                the provision of the Services, with our marketing or advertising
                campaigns, or in delivering you the features and functionality
                that you have requested (Third-Party Service Providers). These
                Third-Party Service Providers include, without limitation,
                companies that support our advertising programs, provide data
                storage, support customer service, assist with audience research
                and product development, and facilitate or deliver materials to
                you via e-mail, other electronic communications platforms, or
                postal service. We also may provide personal data to third-party
                mapping service providers to provide you with map content for
                the Services. Third-Party Service Providers are authorized to
                use the personal data that we provide to them only to carry out
                the service they are providing for us. All such Third-Party
                Service Providers are contractually bound by us to keep the
                personal data confidential.
              </p>
            </li>
            <li id="section3B">
              <p>
                <em>Companies Offering Promotions, Products, or Services.</em>
                 We may share location data, advertising identifiers, IP
                address, and aggregate usage statistics for the Services with
                advertisers.
              </p>
              <p>
                At times, we will provide you with the opportunity to opt in to
                receive promotions, products, or services from third-party
                sponsors. We also may provide you with the opportunity to
                receive products and services directly from
                specifically-identified third parties through the Services. In
                these instances, you must explicitly consent to receive each
                offer. When you explicitly agree to receive offers from specific
                third parties, your information is shared with those specific
                third parties to enable them to provide you with the offer you
                have requested. We are not responsible for the information
                collection practices of these third parties, and all information
                provided is governed by the privacy policies of these third
                parties. You should review the privacy policies of these third
                parties before supplying personal data to them.
              </p>
            </li>
            <li id="section3C">
              <p>
                <em>Other Websites, Other Services, Plug-Ins, and Links.</em>
                 Like many websites on the Internet and other Internet-based
                services, the Services may link to webpages located on websites
                or services maintained by various other entities. In some cases,
                you may navigate to pages of other websites that are framed with
                elements of the Services such as in the header or footer. In
                that case, the URL will identify the site you are visiting. In
                other cases, such as advertisements, you will be connecting to
                another site or service when you click on or otherwise activate
                those opportunities, including click-to-call, click-to-text, and
                click-to-e-mail opportunities. These other sites and services
                are not bound by our privacy policy, and we are not responsible
                for their information collection practices. The privacy policies
                of other organizations may differ from ours, for example, with
                respect to the level of security, use of cookies, and
                collection, use and disclosure of personal data.
              </p>
              <p>
                Some of the Services allow users to interface with other
                websites or services. You will remain logged in to those other
                sites and services until you log off. Your use of those other
                sites and services is subject to the privacy policies of those
                sites and services, and not this Privacy Policy.
              </p>
              <p>
                Some of the Services use third-party operating systems,
                platforms, communication services, devices, and software
                elements (such as mobile device operating systems, wireless
                services, mobile phone, and tablet devices), and some of the
                Services are provided by third-party distributors, device
                makers, device operators, platform operators, and communication
                services. We do not control these third-party entities,
                products, and services, and they may collect, use, process,
                transmit, and disclose your information. As we do not control
                these third-parties data handling practices, we recommend that
                you review their privacy policies, terms of use, and license
                agreements (if any).
              </p>
            </li>
            <li id="section3D">
              <p>
                <em>Ad Partners and advertisers.</em> We may share information
                we collect with Ad Partners and advertisers so that they can
                deliver relevant content or ads on their own websites or apps or
                other third-party websites or apps and analyze and report on
                content or ads users see or interact with. Ad Partners and
                advertisers may also collect, or we may also provide to Ad
                Partners, information via the technologies discussed in section
                2 above or via interactive ads. The information may include data
                about how users interact with the Services, information users
                provide to or through an interactive ad, the content and ads
                users have viewed, users activities on other websites and online
                services, and physical places users have visited.
              </p>
            </li>

            <li id="section3E">
              <p>
                <em>Aggregated Information.</em> As part of the Services, we
                will prepare and maintain information collected from the
                Services that is aggregated or otherwise altered so that it does
                not directly identify you and cannot reasonably be linked to
                you. We use and share such information for our business
                purposes.
              </p>
            </li>
          </ol>
        </li>
        <li id="04">
          <h3>Other Limitations on Privacy</h3>
          <p>
            Subject to applicable local law, we, our affiliates, or our
            Third-Party Service Providers may disclose your personal data
            without your permission (i) pursuant to judicial or other government
            subpoenas, warrants, or orders or otherwise to comply with law; (ii)
            where we believe an individuals rights, property, or safety or
            security is at risk; (iii) if we find that your actions violate our 
            <Link to={getTosUrl(locale)}>Terms of Service</Link>
             or any of our other legal rights; or (iv) where otherwise required
            or permitted by applicable law (which may include a lawful access
            request by U.S. or foreign courts, governments, or law enforcement
            agencies).
          </p>
        </li>
        <li id="05">
          <h3>Retention Period</h3>
          <p>
            We will retain your information for as long as needed to provide and
            develop the Services in accordance with Section 1.G. above. We will
            also retain and use your information as necessary to comply with our
            legal obligations, resolve disputes, and enforce our agreements. We
            may retain information that no longer identifies you indefinitely.
          </p>
        </li>
        <li id="06">
          <h3>Data Rights</h3>
          <p>
            You can request to access, obtain a portable copy of, correct, or
            delete data related to your stored preferences and your use of the
            Services. Please contact us at{" "}
            <a href="mailto:support@weatherman.com">support@weatherman.com</a>.
          </p>
          <p>
            If you have registered a user account with us, you may reset your
            password, update your personal data, correct information, delete
            your account, or opt-out of certain Services by accessing your
            account.
          </p>
          <p>
            In the event that you consider our processing of data related your
            use of the Services not to be compliant with the applicable data
            protection laws, you can contact us at 
            <a href="mailto:support@weatherman.com">support@weatherman.com</a>.
          </p>
        </li>
        <li id="08">
          <h3>International Transfer</h3>
          <p>
            The Services are hosted on servers inside and outside the United
            States. If you are located in the European Union or elsewhere
            outside of the United States, information that we collect (including
            through cookies) will be processed and stored in the United States,
            a jurisdiction in which the data protection and privacy laws may not
            offer the same level of protection as those in the country where you
            reside or are a citizen. Subject to applicable law, by using our
            Services and providing information to use, you consent to the
            transfer to and processing of information in the United States and
            other jurisdictions. Where necessary under applicable law, we may
            employ appropriate cross-border transfer methods governing personal
            data.
          </p>
        </li>
        <li id="09">
          <h3>Our Commitment to Security</h3>
          <p>
            We have put in place physical, electronic, and managerial procedures
            intended to protect your data. Of course, we cannot guarantee that
            your communications with the Services will never be unlawfully
            intercepted or that your personal data will never be unlawfully
            accessed by third parties. Any transmission of information is at
            your own risk.
          </p>
        </li>
        <li id="10">
          <h3>CCPA Notice</h3>
          <p>
            The California Consumer Privacy Act of 2018 (the CCPA) requires
            certain disclosures that already are covered in our Privacy Policy.
            This section provides an overview of the information required by
            CCPA and how to exercise your rights.
          </p>
          <ol>
            <li>
              <p>Disclosures about the use of your Personal Information</p>
              <p>
                As described in our Privacy Policy, in order to operate the
                Services and to maximize your experience, we have collected and
                used the following categories of information in the past twelve
                months:
              </p>
              <ul>
                <li>Information related to User Account;</li>
                <li>Information provided by you;</li>
                <li>
                  Information automatically collected from your device, in
                  accordance with consumer choice;
                </li>
                <li>Location information;</li>
                <li>Information from Analytics and Advertising Partners.</li>
              </ul>
              <p>This includes Personal Information as defined by the CCPA:</p>
              <ol>
                <li>
                  <em>Identifiers</em> such as an online identifier or IP
                  address;
                </li>
                <li>
                  <em>Commercial information</em> relating to your use of the
                  Services;
                </li>
                <li>
                  <em>
                    Internet or other electronic network activity information
                  </em>
                   relating to your use of the Services;
                </li>
                <li>
                  <em>Geolocation data</em>; and
                </li>
                <li>
                  <em>
                    Personal information under the Customer Records provision of
                    the California Civil Code
                  </em>
                   such as your email address.
                </li>
              </ol>
              <p>
                During the past twelve months, we have disclosed, for a business
                purpose, information within the categories as defined by the
                CCPA and listed as 1-5 above.
              </p>
              <p>
                During the past twelve months, we may have sold information
                within the categories as defined by the CCPA and listed as 1-4
                above. As described in our Privacy Policy, when allowed by you,
                we may use and share your device's information with trusted
                partners for advertising services that they provide to us. Some
                partners may also use this information for their own purposes.
                The transfer of your information to such partners may be
                considered a "sale" under CCPA. Information on how you can opt
                out of sharing your information with these partners for purposes
                beyond the advertising services they provide us is provided
                below. You also can opt out of sharing your information for
                personalized advertising through available web-related opt
                outs and mobile opt outs described above.
              </p>
            </li>
            <li>
              <p>
                <u>Data Rights</u>
              </p>
              <p>
                You can learn more about the data rights you have and how to
                exercise them. You have the right to:
              </p>
              <ul>
                <li>
                  Know your Personal Information. You can
                  request information about the Personal Information collected
                  from your use of the Services.
                </li>
                <li>
                  Delete your Personal Information. You can request that
                  we delete the Personal Information that we have collected from
                  your use of the Services. We will delete your information
                  unless it is necessary for purposes permitted by CCPA.
                </li>
                <li>
                  Opt out of sale of your Personal Information. You can opt out
                  of the sale of personal information at any time in the
                  following ways (please note that we do not track users across
                  devices, so you will have to opt out from each device where
                  you access the Services):
                  <br />
                  <br />
                  <u>Web &amp; Mobile</u>
                  <br />- If you are located in California when you access the
                  Services, you can request that we not sell your information by
                  contacting{" "}
                  <a href="mailto:support@weatherman.com">
                    support@weatherman.com
                  </a>
                  .<br />
                </li>
                <li>
                  <p>
                    Non-discrimination. If you choose to exercise any of these
                    rights, we will not deny goods or services to you or provide
                    different quality of services, unless that difference is
                    reasonably related to the value provided by your data.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li id="11">
          <h3>Changes to the Privacy Policy</h3>
          <p>
            We reserve the right to change our Privacy Policy as business needs
            require. If we decide to change our Privacy Policy, we will post
            those changes here prior to the time they take effect.
          </p>
        </li>
        <li id="12">
          <h3>How to Contact Us</h3>
          <p>
            If you have a question related to this Privacy Statement, please
            contact us at 
            <a href="mailto:support@weatherman.com">support@weatherman.com</a>,
            or at:
          </p>
          <address>
            <div>Weatherman</div>
            <div>Attn: Privacy Office</div>
            <div>231 Madaket Road</div>
            <div>Nantucket, MA, USA 02554</div>
          </address>
        </li>
        <li id="13">
          <h3>General Data Protection Regulation</h3>
          <p>
            European Union Minors Privacy: Where GDPR applies, we do not
            knowingly collect personal data from children under age 16 (or such
            lower age permitted under applicable local law not being lower than
            age 13).
          </p>
          <p>
            International Transfers: If you are located where GDPR applies,
            information that we collect (including through cookies) will be
            processed and stored in the United States. The appropriate
            cross-border transfer method governing personal data included in
            such transfers is the EU Standard Contractual Clauses agreement
            pursuant to EC Decision 2010/87/EU.
          </p>
        </li>
      </ol>
      <p>This Policy was last updated June 5, 2020.</p>
    </div>
  );
};

export default PrivacyPolicyPage;

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import {
  NumberRange,
  RangeFields,
  Radio,
  Switch,
} from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import {
  ClassificationInfo,
  ClassificationPopupBody,
  classificationAir,
} from "./ClassificationInfo";
import {
  convertTemp,
  convertTempToC,
  formatTemp,
  getTempUnit,
  UnitKey,
} from "../../../types/units";
import { CardSection } from "../Card/Card";

type AlertsAirProps = {
  alerts: UserWeatherAlerts;
  units: UnitKey;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsAir: React.FC<AlertsAirProps> = ({
  alerts,
  updateAlerts,
  units,
}) => {
  const handleAirChange = (air: boolean) => {
    if (air) {
      updateAlerts({
        air,
        airTempCelcius: [22, undefined],
        airSelector: "",
        desktop: true,
      });
    } else {
      updateAlerts({ air, desktop: true });
    }
  };

  const handleAirTempChange = (label: string) => {
    if (label.includes("Custom parameters")) {
      updateAlerts({
        airSelector: "custom",
      });
      return;
    }
    updateAlerts({ airTempCelcius: [22, undefined], airSelector: "" });
  };

  const handleAirTempValueChange = (range: NumberRange) => {
    const from = range.from ? convertTempToC(range.from, units) : range.from;
    const to = range.to ? convertTempToC(range.to, units) : range.to;
    updateAlerts({ airTempCelcius: [from, to] });
  };

  return (
    <>
      <CardSection
        className={`${styles.alertsSection} ${styles.wrapperClassification}`}
      >
        <Switch
          label="Comfort of the air (dew point)"
          checked={!!alerts.air}
          onChange={handleAirChange}
        />
        <ClassificationInfo
          hideDivider={true}
          className={styles.classification}
          popupBody={<ClassificationPopupBody {...classificationAir} />}
        />
      </CardSection>
      {!!alerts.air && (
        <>
          <CardSection className={styles.sectionColumn}>
            <div className={styles.radioAir}>
              <Radio
                checked={alerts.airSelector !== "custom"}
                name="airAlerts"
                label={`Notify me about uncomfortable conditions (> ${formatTemp(
                  21,
                  units
                )})`}
                onChange={handleAirTempChange}
              />
              <Radio
                checked={alerts.airSelector === "custom"}
                name="airAlerts"
                label={`Custom parameters (Dew point ${getTempUnit(units)}):`}
                onChange={handleAirTempChange}
              />
            </div>

            <RangeFields
              disabled={alerts.airSelector !== "custom"}
              className={styles.precipitationIntensityRange}
              from={
                alerts.airTempCelcius &&
                alerts.airTempCelcius[0] &&
                convertTemp(alerts.airTempCelcius[0], units)
              }
              to={
                alerts.airTempCelcius &&
                alerts.airTempCelcius[1] &&
                convertTemp(alerts.airTempCelcius[1], units)
              }
              onChange={handleAirTempValueChange}
              min={-100}
              max={units === "C_ms" ? 50 : 100}
            />
          </CardSection>
        </>
      )}
      <HSplit />
    </>
  );
};

export default AlertsAir;

import React from "react";
import styles from "./PlaceList.module.scss";
import WeatherShortCard from "./WeatherShortCard";
import { WeatherType } from "../../types/weatherType";

type PlaceListProps = {
  locationIds: string[];
  weatherType: WeatherType;
};

const PlaceList: React.FC<PlaceListProps> = ({ locationIds, weatherType }) => {
  return (
    <div className={styles.wrap}>
      {locationIds.map(locationId => (
        <WeatherShortCard
          key={locationId}
          locationId={locationId}
          weatherType={weatherType}
        />
      ))}
    </div>
  );
};

export default PlaceList;

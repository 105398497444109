import { DailyForecast } from "../api/weatherApi";
import { FormatMessageFn, MessageKey } from "../i18n";

export function getMoonPhase(year: number, month: number, day: number) {
  //let c = (e = jd = b = 0);

  if (month < 3) {
    year--;
    month += 12;
  }

  ++month;

  const daysElapsed = 365.25 * year + 30.6 * month + day - 694039.09;

  const moonCycle = daysElapsed / 29.5305882; // total days elapsed divided by moon cycle

  const frac = moonCycle - Math.trunc(moonCycle); // take fractional

  const phase = Math.round(frac * 8) % 8; //scale fraction from 0-8 and round

  return phase;

  // 0 => New Moon
  // 1 => Waxing Crescent Moon
  // 2 => Quarter Moon
  // 3 => Waxing Gibbous Moon
  // 4 => Full Moon
  // 5 => Waning Gibbous Moon
  // 6 => Last Quarter Moon
  // 7 => Waning Crescent Moon
}

export function getMoonPhaseName(
  formatMessage: FormatMessageFn,
  moonPhase: number
) {
  if (moonPhase < 0 || moonPhase > 7) return "";
  return formatMessage(("MoonPhase" + moonPhase.toFixed(0)) as MessageKey);
}

export function getPlaceMoonPhase(dailyForecast: DailyForecast) {
  const placeDate = dailyForecast.date;
  return getMoonPhase(
    placeDate.getUTCFullYear(),
    placeDate.getUTCMonth() + 1,
    placeDate.getUTCDate()
  );
}

import fetch from "isomorphic-unfetch";
import { API_ROOT } from "../config";
import { isNotFoundError } from "../utils/errors";

type HttpOptions = {
  method?: string;
  body?: string;
  headers?: Record<string, string>;
};

export async function fetchRaw(url: string, options?: HttpOptions) {
  try {
    const res = await fetch(url, options);
    if (res.status >= 400) {
      const json = await res.json();
      throw new Error(json.error || "Server error");
    }
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function apiFetch<T>(
  url: string,
  errorMsg: string,
  options?: HttpOptions
) {
  try {
    const res = await fetchRaw(API_ROOT + url, options);
    const json = await res.json();

    return json as T;
  } catch (e) {
    const message = isNotFoundError(e.message) ? e.message : errorMsg;
    console.error(message, e);
    throw new Error(message);
  }
}

export async function apiGet<T>(
  url: string,
  what: string,
  options?: HttpOptions
) {
  return await apiFetch<T>(url, `Failed to load ${what}`, options);
}

export async function apiFetchWithBody<T>(
  url: string,
  errorMsg: string,
  method: string,
  body: object,
  options?: HttpOptions
) {
  return await apiFetch<T>(url, errorMsg, {
    ...options,
    method,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...(options && options.headers),
    },
  });
}

export async function apiPost<T>(
  url: string,
  what: string,
  body: object,
  options?: HttpOptions
) {
  return await apiFetchWithBody<T>(
    url,
    `Failed to post ${what}`,
    "POST",
    body,
    options
  );
}

export async function apiPatch<T>(
  url: string,
  what: string,
  body: object,
  options?: HttpOptions
) {
  return await apiFetchWithBody<T>(
    url,
    `Failed to update ${what}`,
    "PATCH",
    body,
    options
  );
}

import { LocaleCode, defaultLocale } from "../../i18n";
import { LocaleActionTypes, LOCALE_SET } from "../types/locale";

type LocaleState = LocaleCode;

const initialState = defaultLocale;

export default function reducer(
  state: LocaleState = initialState,
  action: LocaleActionTypes
): LocaleState {
  switch (action.type) {
    case LOCALE_SET:
      if (state === action.locale) return state;

      return action.locale;
    default:
      return state;
  }
}

import { GetState, RootDispatch } from "..";
import {
  fetchLocationsByRegion,
  fetchLocationByCode,
  Location,
  fetchLocationByIp,
  fetchNearbyLocationsById,
  fetchLocationsByIds,
  LocationType,
} from "../../api/placesApi";
import { errorsAdd } from "./errors";
import {
  LocationsActionTypes,
  LOCATIONS_LOADED,
  LOCATIONS_GEOIP_SET,
  LOCATIONS_HOME_SET,
  LOCATIONS_NEARBY_LOADED,
  LOCATIONS_HOME_GEOLOCATED,
  LOCATIONS_BY_REGION_LOADING,
  LOCATIONS_BY_REGION_LOADED,
} from "../types/locations";
import {
  getLocationByCode,
  isGeoipLoaded,
  getGeoipLocation,
  isNearbyLoaded,
  getHomeLocation,
  getLocationById,
  getHomeGeolocated,
  areLocationsByRegionLoaded,
} from "../selectors/locations";
import { regionsLoaded } from "./regions";
import { countriesLoaded } from "./countries";
import { getLocale } from "../selectors/locale";
import { getDefaultHomeLocation } from "../../i18n";
import { isMobile } from "../../utils/browser";
import { loadGeolocation } from "./search";
import { popularCityIds } from "../../types/popularCities";
import { fetchUser } from "../../api/authApi";
import { localStorageGet, LocalStorageKeys } from "../../utils/localStorage";

export function locationsLoaded(locations: Location[]): LocationsActionTypes {
  return { type: LOCATIONS_LOADED, locations };
}

function loadLocationsByRegion(locationType: LocationType, regionId: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!regionId) return;
    dispatch({ type: LOCATIONS_BY_REGION_LOADING, locationType, regionId });

    try {
      const lang = getLocale(getState());
      const locations = await fetchLocationsByRegion(
        locationType,
        regionId,
        lang
      );

      dispatch({ type: LOCATIONS_LOADED, locations });
      dispatch({
        type: LOCATIONS_BY_REGION_LOADED,
        locationType,
        regionId,
        locationIds: locations.map((x) => x._id),
      });
    } catch (e) {
      console.error(e);

      dispatch(errorsAdd(e.message));
    }
  };
}

export function tryLoadLocationsByRegion(
  locationType: LocationType,
  regionId: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!regionId) return;
    const state = getState();
    if (areLocationsByRegionLoaded(state, locationType, regionId)) return;
    await dispatch(loadLocationsByRegion(locationType, regionId));
  };
}

function loadLocationByCode(
  locationType: LocationType,
  countryId: string,
  regionCode: string,
  cityCode: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!countryId || !regionCode || !cityCode) return undefined;

    try {
      const lang = getLocale(getState());
      const { country, region, location } = await fetchLocationByCode(
        locationType,
        countryId,
        regionCode,
        cityCode,
        lang
      );

      dispatch(countriesLoaded([country]));
      dispatch(regionsLoaded([region]));
      dispatch(locationsLoaded([location]));
      return location;
    } catch (e) {
      console.error(e);

      throw e;

      // dispatch(errorsAdd(e.message));
      // return undefined;
    }
  };
}

export function tryLoadLocationByCode(
  locationType: LocationType,
  countryId: string,
  regionCode: string,
  locationCode: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!countryId || !regionCode || !locationCode) return undefined;
    const state = getState();
    const location = getLocationByCode(
      state,
      locationType,
      countryId,
      regionCode,
      locationCode
    );
    if (location) return location;
    return await dispatch(
      loadLocationByCode(locationType, countryId, regionCode, locationCode)
    );
  };
}

function loadLocationsByIds(locationIds: string[]) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationIds || !locationIds.length) return [];

    try {
      const lang = getLocale(getState());
      const { countries, regions, locations } = await fetchLocationsByIds(
        locationIds,
        lang
      );

      dispatch(countriesLoaded(countries));
      dispatch(regionsLoaded(regions));
      dispatch(locationsLoaded(locations));

      return locations;
    } catch (e) {
      console.error(e);

      dispatch(errorsAdd(e.message));

      return [];
    }
  };
}

export function tryLoadLocationsByIds(locationIds: string[]) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationIds || !locationIds.length) return;
    const state = getState();
    //console.log("tryLoadLocationsByIds", locationIds);
    locationIds = locationIds.filter((x) => !getLocationById(state, x));
    locationIds = Array.from(new Set(locationIds));
    //console.log("tryLoadLocationsByIds2", locationIds);
    if (!locationIds.length) return;
    await dispatch(loadLocationsByIds(locationIds));
  };
}

function loadGeoip(ip: string | undefined) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      const lang = getLocale(getState());
      const { country, region, location } = await fetchLocationByIp(ip, lang);

      dispatch(countriesLoaded([country]));
      dispatch(regionsLoaded([region]));
      dispatch(locationsLoaded([location]));

      dispatch({
        type: LOCATIONS_GEOIP_SET,
        locationId: location._id,
        countryId: country._id,
      });

      return location;
    } catch (e) {
      console.error(e);

      dispatch({ type: LOCATIONS_GEOIP_SET, locationId: "", countryId: "" });

      return undefined;
    }
  };
}

export function tryLoadGeoip(ip: string | undefined) {
  return async (
    dispatch: RootDispatch,
    getState: GetState
  ): Promise<Location | undefined> => {
    const state = getState();
    const location = getGeoipLocation(state);
    if (location) return location;

    if (isGeoipLoaded(state)) {
      return undefined;
    }
    return await dispatch(loadGeoip(ip));
  };
}

export function homeLocationSet(locationId: string): LocationsActionTypes {
  return {
    type: LOCATIONS_HOME_SET,
    locationId,
  };
}

export function tryLoadHomeLocation() {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (isMobile() && !getHomeGeolocated(getState())) {
      try {
        const location = await dispatch(loadGeolocation());
        dispatch({
          type: LOCATIONS_HOME_GEOLOCATED,
          locationId: location ? location._id : undefined,
        });
      } catch (e) {
        console.error(e);
        dispatch({ type: LOCATIONS_HOME_GEOLOCATED, locationId: undefined });
      }
    }

    const token = localStorageGet<string>(LocalStorageKeys.token, "");
    if (token) {
      const user = await fetchUser(token);
      if (user.locationId) {
        dispatch(homeLocationSet(user.locationId));
        return getLocationById(getState(), user.locationId);
      }
    }

    let location = getHomeLocation(getState());
    if (location) return location;

    location = await dispatch(tryLoadGeoip(undefined));
    if (location) {
      dispatch({ type: LOCATIONS_HOME_SET, locationId: location._id });
      return location;
    }

    const {
      countryId,
      regionCode,
      locationCode: cityCode,
    } = getDefaultHomeLocation(getLocale(getState()));
    try {
      location = await dispatch(
        tryLoadLocationByCode("c", countryId, regionCode, cityCode)
      );
    } catch (e) {
      console.error("tryLoadHomeLocation.default:", e);
    }
    if (location) {
      dispatch({ type: LOCATIONS_HOME_SET, locationId: location._id });
      return location;
    }

    // any other location
    location = Object.values(getState().locations.byId)[0];
    if (location) {
      dispatch({ type: LOCATIONS_HOME_SET, locationId: location._id });
      return location;
    }
    return undefined;
  };
}

function loadNearbyLocations(locationId: string, locationType: LocationType) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationId) return;

    try {
      const lang = getLocale(getState());
      const { countries, regions, locations } = await fetchNearbyLocationsById(
        locationType,
        locationId,
        lang
      );

      dispatch(countriesLoaded(countries));
      dispatch(regionsLoaded(regions));
      dispatch(locationsLoaded(locations));
      dispatch({
        type: LOCATIONS_NEARBY_LOADED,
        locationId,
        locationType,
        locations,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export function tryLoadNearbyLocations(
  locationId: string,
  locationType: LocationType
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!locationId) return;
    if (isNearbyLoaded(getState(), locationId, locationType)) return;
    await dispatch(loadNearbyLocations(locationId, locationType));
  };
}

export function tryLoadPopularCities() {
  return tryLoadLocationsByIds(popularCityIds);
}

import { UserWeatherAlerts } from "../../api/authApi";

export const USER_WEATHER_ALERTS_UPDATE = "USER_WEATHER_ALERTS_UPDATE";
export const USER_WEATHER_ALERTS_RESET = "USER_WEATHER_ALERTS_RESET";

type UserWeatherAlertsUpdateAction = {
  type: typeof USER_WEATHER_ALERTS_UPDATE;
  userWeatherAlerts: Partial<UserWeatherAlerts>;
};

type UserWeatherAlertsResetAction = {
  type: typeof USER_WEATHER_ALERTS_RESET;
};

export type UserWeatherAlertsActionTypes =
  | UserWeatherAlertsUpdateAction
  | UserWeatherAlertsResetAction;

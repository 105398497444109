import React from "react";
import styles from "./Controls.module.scss";
import iconCheck from "../../assets/icons/i-check-white.svg";

type CheckboxProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick: (checked: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  disabled,
  onClick,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const checked = e.target.checked;
    if (onClick) onClick(checked);
  };

  return (
    <label className={styles.checkbox}>
      <span
        className={`${styles.checkboxView} ${
          checked ? styles.checkboxActive : ""
        }`}
      >
        {checked && <img src={iconCheck} alt="icon check" />}
      </span>
      <input
        className={styles.checkboxInput}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />

      <span className={styles.checkboxLabel}>{label}</span>
    </label>
  );
};

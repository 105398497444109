import {
  LocationSelectorActions,
  LOCATION_SELECTOR_SELECT,
} from "../types/locationSelector";
import { LocationType } from "../../api/placesApi";

export function locationSelectorSelect(
  locationType: LocationType,
  continentId: string,
  countryId: string,
  regionId: string,
  error: string
): LocationSelectorActions {
  return {
    type: LOCATION_SELECTOR_SELECT,
    locationType,
    continentId,
    countryId,
    regionId,
    error,
  };
}

import React from "react";
import styles from "./FavoriteButton.module.scss";
import {
  getWeatherLocationFavorite,
  getWeatherLocationId,
} from "../../store/selectors/weather";
import { useSelector, useDispatch } from "../../store/hooks";
import { favoriteToggle } from "../../store/actions/favorite";

type FavoriteButtonProps = {
  className?: string;
  dark?: boolean;
};

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  dark,
  className = "",
}) => {
  const locationId = useSelector(getWeatherLocationId);
  const favorite = useSelector(getWeatherLocationFavorite);
  const dispatch = useDispatch();

  return (
    <button
      className={`${styles.button} ${dark ? styles.dark : ""} ${
        favorite ? styles.favorite : ""
      } ${className}`}
      onClick={() => dispatch(favoriteToggle(locationId))}
      aria-label="Favorite"
    />
  );
};

export default FavoriteButton;

import { apiPost, apiGet, apiPatch } from "../http";

export type User = {
  _id: string;
  name: string;
  pictureUrl?: string;
  email?: string;
};

export type UserProfile = User & {
  units: string;
  locale: string;
  recent: string[];
  favorite: string[];
  phone?: string;
  locationId?: string;
  locationSelector?: string;
};

export type UserWeatherAlerts = {
  _id: string;
  tokens?: string[];
  desktop?: boolean;
  locationId?: string;

  daily?: boolean;
  dailyLastSent?: number;
  dailyTimeMs?: number;
  dailyDays?: string[];
  dailySelector?: string;

  government?: boolean;
  nextHourPrecipitation?: boolean;
  significantChanges?: boolean;
  weatherAlerts?: string[];

  wind?: boolean;
  windSelector?: string;
  windRange?: (number | undefined)[];

  precipitation?: boolean;
  precipitationType?: string[];
  precipitationProbability?: number;
  precipitationIntensity?: (string | number | undefined)[];
  precipitationSelector?: string;

  visibility?: boolean;
  visibilitySelector?: string;
  visibilityValue?: number;

  air?: boolean;
  airSelector?: string;
  airTempCelcius?: (number | undefined)[];

  uv?: boolean;
  uvSelector?: string;
  uvIndex?: (number | undefined)[];
};

export function getAuthHeader(token: string) {
  if (!token) return undefined;
  return { Authorization: `Bearer ${token}` };
}

export async function fetchUser(token: string) {
  const resp = await apiGet<UserProfile>("/auth/profile", "User", {
    headers: getAuthHeader(token),
  });

  return resp;
}

export async function updateUser(token: string, user: Partial<UserProfile>) {
  await apiPatch("/auth/profile", "User", user, {
    headers: getAuthHeader(token),
  });
}

export async function tryUpdateUser(token: string, user: Partial<UserProfile>) {
  try {
    await updateUser(token, user);
  } catch (e) {
    console.error(e);
  }
}

export async function postAuthGoogle(
  tokenId: string,
  googleUser: User
): Promise<string> {
  const resp = await apiPost<{ token: string }>(
    "/auth/google",
    "Google Login",
    { token: tokenId, user: googleUser }
  );

  return resp.token;
}

export async function postAuthFacebook(
  accessToken: string,
  facebookUser: User
): Promise<string> {
  const resp = await apiPost<{ token: string }>(
    "/auth/facebook",
    "Facebook Login",
    { token: accessToken, user: facebookUser }
  );

  return resp.token;
}

export async function fetchUserWeatherAlerts(token: string) {
  const resp = await apiGet<UserWeatherAlerts>(
    "/alerts/user-weather-alerts",
    "UserWeatherAlerts",
    {
      headers: getAuthHeader(token),
    }
  );
  return resp;
}

export async function updateUserWeatherAlerts(
  token: string,
  alerts: Partial<UserWeatherAlerts>
) {
  await apiPatch("/alerts/user-weather-alerts", "UserWeatherAlerts", alerts, {
    headers: getAuthHeader(token),
  });
}

export async function tryUpdateUserWeatherAlerts(
  token: string,
  alerts: Partial<UserWeatherAlerts>
) {
  try {
    await updateUserWeatherAlerts(token, alerts);
  } catch (e) {
    console.error(e);
  }
}

export type UserWeatherAlertsStatistic = {
  usersAllCount: number;
  usersAlertsCount: number;
  usersAlertsActiveCount: number;
};

export async function fetchUsersAlertsStatistic(token: string) {
  const resp = await apiGet<UserWeatherAlertsStatistic | null>(
    "/alerts/user-weather-alerts-statistic",
    "Users Weather Alerts Statistic",
    { headers: getAuthHeader(token) }
  );

  return resp;
}

import React, { useRef, useEffect } from "react";
import { useSelector } from "../../store/hooks";
import { getErrors } from "../../store/selectors/errors";
import { ErrorMsg } from "../../store/types/errors";
import { useDispatch } from "react-redux";
import { errorsRemove } from "../../store/actions/errors";
import styles from "./Messages.module.scss";

const Messages: React.FC = () => {
  const errors = useSelector(getErrors);
  const onTimer = useRef<ErrorMsg[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    onTimer.current = onTimer.current.filter(x => !errors.includes(x));

    for (const error of errors) {
      if (onTimer.current.includes(error)) continue;

      setTimeout(() => {
        dispatch(errorsRemove(error));
      }, 2000);
    }
  }, [errors, dispatch]);

  return (
    <div className={styles.wrap}>
      {errors.map(({ message }) => (
        <div className={styles.error} key={message}>
          {message}
        </div>
      ))}
    </div>
  );
};

export default Messages;

import {
  AuthActionTypes,
  AUTH_DIALOG,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "../types/auth";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { GetState, RootDispatch } from "..";
import {
  postAuthGoogle,
  postAuthFacebook,
  fetchUser,
  fetchUserWeatherAlerts,
} from "../../api/authApi";
import { errorsAdd } from "./errors";
import {
  ReactFacebookLoginInfo,
  ReactFacebookFailureResponse,
} from "react-facebook-login";
import {
  localStorageSet,
  LocalStorageKeys,
  localStorageRemove,
  localStorageGet,
} from "../../utils/localStorage";
import { unitSet, unitLocalRestore } from "./units";
import { UnitKey } from "../../types/units";
import { recentRestore, recentLocalRestore } from "./recent";
import { favoriteRestore, favoriteLocalRestore } from "./favorite";
import { localeChange } from "./locale";
import { LocaleCode } from "../../i18n";
import { userProfileSet } from "./userProfile";
import {
  userWeatherAlertsReset,
  userWeatherAlertsSet,
} from "./userWeatherAlerts";
import { History } from "history";
import { homeLocationSet } from "./locations";
import { getFavoriteLocationIds } from "../selectors/favorite";

export function authShowDialog(dialog: boolean): AuthActionTypes {
  return { type: AUTH_DIALOG, dialog };
}

export function authRestore() {
  return async (dispatch: RootDispatch) => {
    const token = localStorageGet<string>(LocalStorageKeys.token, "");
    if (!token || typeof token !== "string") return;

    //console.log("Restore Token", token);

    await dispatch(authSuccess(token));

    // TODO: Check profile and do something
  };
}

function authSuccess(token: string) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    try {
      if (!token) return;

      localStorageSet(LocalStorageKeys.token, token);

      const user = await fetchUser(token);
      const favorite = getFavoriteLocationIds(getState());
      const newFavorite = [...(user.favorite ?? []), ...favorite].filter(
        (val, i, arr) => arr.indexOf(val) === i
      );
      const userMerged = { ...user, favorite: newFavorite };

      dispatch({ type: AUTH_SUCCESS, user: userMerged, token });

      const userWeatherAlerts = await fetchUserWeatherAlerts(token);

      if (userMerged.units)
        dispatch(unitSet(userMerged.units as UnitKey, true));
      if (userMerged.locationId)
        dispatch(homeLocationSet(userMerged.locationId));
      dispatch(recentRestore(userMerged.recent));
      dispatch(favoriteRestore(userMerged.favorite));
      dispatch(userProfileSet(userMerged));
      dispatch(userWeatherAlertsSet(userWeatherAlerts));
      if (userMerged.locale)
        await dispatch(localeChange(userMerged.locale as LocaleCode, false));
    } catch (e) {
      console.error(e);

      dispatch(authLogout());
    }
  };
}

export function loginGoogle(
  response: GoogleLoginResponse | GoogleLoginResponseOffline,
  history: History,
  url: string
) {
  //console.log(response);

  return async (dispatch: RootDispatch) => {
    try {
      const resp = response as GoogleLoginResponse;
      if (!resp.accessToken) return;

      const {
        tokenId,
        profileObj: { googleId, name, email, imageUrl },
      } = resp;

      const token = await postAuthGoogle(tokenId, {
        _id: googleId,
        name,
        email,
        pictureUrl: imageUrl,
      });

      await dispatch(authSuccess(token));
      history.push(url, { softRedirect: true });
    } catch (e) {
      console.error(e);
      dispatch(errorsAdd("Google Authentication Failed"));
    }
  };
}

export function loginFacebook(
  response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  history: History,
  url: string
) {
  //console.log(response);

  return async (dispatch: RootDispatch) => {
    try {
      const resp = response as ReactFacebookLoginInfo;
      if (!resp.accessToken) return;

      const { accessToken, name, email, id } = resp;
      const pictureUrl = resp.picture && resp.picture.data.url;

      const token = await postAuthFacebook(accessToken, {
        _id: id,
        name: name || id,
        pictureUrl,
        email,
      });

      await dispatch(authSuccess(token));
      history.push(url, { softRedirect: true });
    } catch (e) {
      console.error(e);
      dispatch(errorsAdd("Facebook Authentication Failed"));
    }
  };
}

export function authLogout() {
  return async (dispatch: RootDispatch) => {
    localStorageRemove(LocalStorageKeys.token);

    dispatch({ type: AUTH_LOGOUT });
    await dispatch(userWeatherAlertsReset());
    await dispatch(unitLocalRestore());
    await dispatch(recentLocalRestore());
    await dispatch(favoriteLocalRestore());
  };
}

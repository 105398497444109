import React from "react";
import { WeatherData } from "../../../api/weatherApi";
import {
  formatSpeed,
  formatDistance,
  formatTempShort,
  formatPercent,
  formatUvIndex,
  formatPressure,
  formatWindDirectionShort,
} from "../../../types/units";
import styles from "./DayHeadSummary.module.scss";
import windImg from "./assets/wind.svg";
import visibilityImg from "./assets/visibility.svg";
import dewPtImg from "./assets/dew_pt.svg";
import humidityImg from "./assets/humidity.svg";
import uvImg from "./assets/uv.svg";
import pressureImg from "./assets/pressure.svg";
import { useSelector } from "react-redux";
import { getUnits } from "../../../store/selectors/units";
import { LocaleMessage } from "../../../components/Locale";
import { useFormatMessage } from "../../../i18n";

type SummaryItemProps = {
  img: string;
  label: React.ReactNode;
  value: string;
};

const SummaryItem: React.FC<SummaryItemProps> = ({ img, label, value }) => {
  return (
    <div className={styles.item}>
      <img className={styles.img} src={img} alt="" />
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

type DayHeadSummaryProps = {
  weather: WeatherData | undefined;
  className?: string;
};

const DayHeadSummary: React.FC<DayHeadSummaryProps> = ({
  weather,
  className = "",
}) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();
  const {
    windDir,
    windSpeed,
    visibility,
    dewPoint,
    humidity,
    uvIndex,
    pressure,
  } = weather || {};

  return (
    <div className={`${styles.wrap} ${className}`}>
      <div className={styles.row}>
        <SummaryItem
          img={windImg}
          label={<LocaleMessage id="Wind" />}
          value={`${formatWindDirectionShort(
            formatMessage,
            windDir
          )} ${formatSpeed(formatMessage, windSpeed, units)}`}
        />
        <SummaryItem
          img={visibilityImg}
          label={<LocaleMessage id="Visibility" />}
          value={formatDistance(formatMessage, visibility, units, "+ ")}
        />
        <SummaryItem
          img={dewPtImg}
          label={<LocaleMessage id="DewPt" />}
          value={formatTempShort(dewPoint, units)}
        />
      </div>
      <div className={styles.row}>
        <SummaryItem
          img={humidityImg}
          label={<LocaleMessage id="Humidity" />}
          value={formatPercent(humidity)}
        />
        <SummaryItem
          img={uvImg}
          label={<LocaleMessage id="UVIndex" />}
          value={formatUvIndex(formatMessage, uvIndex)}
        />
        <SummaryItem
          img={pressureImg}
          label={<LocaleMessage id="Pressure" />}
          value={formatPressure(formatMessage, pressure)}
        />
      </div>
    </div>
  );
};

export default DayHeadSummary;

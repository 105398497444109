import React, { useEffect } from "react";
import { RouteComponentProps, match, Redirect } from "react-router-dom";
import { tryLoadCountriesByContinent } from "../../store/actions/countries";
import { useDispatch, useSelector } from "../../store/hooks";
import { Store, GetState, RootDispatch } from "../../store";
import { DataPage } from "../DataPage";
import { tryLoadRegionsByCountry } from "../../store/actions/regions";
import { tryInit } from "../../store/actions/init";
import { Request } from "express";
import { tryLoadLocationsByRegion } from "../../store/actions/locations";
import { useScrollTop } from "../../utils/hooks";
import ContinentSelector from "../../scenes/WeatherSelectionPage/ContinentSelector";
import CountryRegionSelector from "../../scenes/WeatherSelectionPage/CountryRegionSelector";
import { getContinent } from "../../types/continents";
import PlaceSelector from "./PlaceSelector";
import {
  getCountryById,
  getCountriesByContinent,
} from "../../store/selectors/countries";
import { getRegionsByCountry } from "../../store/selectors/regions";
import { LocaleCode, defaultLocale, useLocale } from "../../i18n";
import WeatherSelectionPageTitle from "./WeatherSelectionPageTitle";
import { locationSelectorSelect } from "../../store/actions/locationSelector";
import { LocationType } from "../../api/placesApi";
import { notFoundError, isNotFoundError } from "../../utils/errors";
import {
  getLocationSelectorError,
  getSelectedContinent,
  getSelectedLocationType,
  getSelectedCountry,
  getSelectedRegion,
  getSelectedContinentCountries,
  getSelectedCountryRegions,
} from "../../store/selectors/locationSelector";
import { setCacheControlPlaces } from "../../utils/cache";
import { getLocationSelectorUrl } from "../../utils/url";

type WeatherSeletionRouteParams = {
  lang?: string;
  locationType?: string;
  continentCode?: string;
  countryCode?: string;
  regionCode?: string;
};

type WeatherSelectionPageProps = RouteComponentProps<
  WeatherSeletionRouteParams
>;

async function fetchData(
  dispatch: RootDispatch,
  locale: LocaleCode,
  locationType: LocationType,
  continentCode: string,
  countryCode: string,
  regionCode: string,
  routeChanged: () => boolean,
  req?: Request
) {
  const doFetch = async (dispatch: RootDispatch, getState: GetState) => {
    // /na/united-states -- load countries
    const continent = getContinent(locale, continentCode);
    const continentId = continent ? continent._id : "";

    await dispatch(tryLoadCountriesByContinent(locationType, continentId));
    if (routeChanged()) return;

    let country;
    if (countryCode && !regionCode) {
      const countries = getCountriesByContinent(
        getState(),
        locationType,
        continentId
      );
      country = countries.find((x) => x.longCode === countryCode);
    } else {
      country = getCountryById(getState(), countryCode);
    }
    if (countryCode && !country) {
      dispatch(
        locationSelectorSelect(locationType, continentId, "", "", notFoundError)
      );
      return;
    }
    const countryId = country ? country._id : "";

    await dispatch(tryLoadRegionsByCountry(locationType, countryId));
    if (routeChanged()) return;

    const regions = getRegionsByCountry(getState(), locationType, countryId);
    const region = regions.find((x) => x.longCode === regionCode);
    if (!region) {
      dispatch(
        locationSelectorSelect(
          locationType,
          continentId,
          countryId,
          "",
          notFoundError
        )
      );
      return;
    }
    const regionId = region ? region._id : "";

    dispatch(
      locationSelectorSelect(locationType, continentId, countryId, regionId, "")
    );

    await dispatch(tryLoadLocationsByRegion(locationType, regionId));
  };

  await Promise.all([dispatch(doFetch), dispatch(tryInit(req))]);
}

const WeatherSelectionRedirect: React.FC = () => {
  const locale = useLocale();
  const locationType = useSelector(getSelectedLocationType);
  const continent = useSelector(getSelectedContinent);
  let country = useSelector(getSelectedCountry);
  let region = useSelector(getSelectedRegion);

  //console.log("Known", locationType, continent, country, region);
  const countries = useSelector(getSelectedContinentCountries);
  const regions = useSelector(getSelectedCountryRegions);

  if (!country && countries.length) country = countries[0];
  if (!region && regions.length) region = regions[0];

  const knownLocation = getLocationSelectorUrl(
    locale,
    locationType,
    continent,
    country,
    region
  );

  // const history = useHistory();

  // useEffect(() => history.replace(knownLocation, { softRedirect: true }), []);

  // if (typeof window !== "undefined") {
  //   return null;
  // }

  return (
    <Redirect
      to={{
        pathname: knownLocation,
        state: { softRedirect: true },
      }}
    />
  );
};

const WeatherSelectionPage: React.FC<WeatherSelectionPageProps> &
  DataPage<WeatherSeletionRouteParams> = (props) => {
  const {
    match: {
      params: {
        lang = defaultLocale,
        locationType = "c",
        continentCode = "",
        countryCode = "",
        regionCode = "",
      },
    },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    let routeChanged = false;
    fetchData(
      dispatch,
      lang as LocaleCode,
      locationType as LocationType,
      continentCode,
      countryCode,
      regionCode,
      () => routeChanged
    );
    return () => {
      routeChanged = true;
    };
  }, [dispatch, lang, locationType, continentCode, countryCode, regionCode]);

  // const selectorRef = useRef<HTMLDivElement>(null);
  // useScrollRefTop(selectorRef, [countryCode, regionCode]);
  const softRedirect = !!(
    props.location &&
    props.location.state &&
    (props.location.state as any).softRedirect
  );

  // console.log("SR", softRedirect);
  useScrollTop([], softRedirect);

  const locationSelectorError = useSelector(getLocationSelectorError);

  setCacheControlPlaces(props);
  const notFoundError = isNotFoundError(locationSelectorError);

  return (
    <div className="vh-100">
      {notFoundError && <WeatherSelectionRedirect />}
      <WeatherSelectionPageTitle />
      <ContinentSelector />
      <div className="container">
        <CountryRegionSelector />

        <PlaceSelector
          key={`${lang}${locationType}${continentCode}${countryCode}${regionCode}`}
        />
      </div>
    </div>
  );
};

WeatherSelectionPage.fetchData = async function (
  store: Store,
  {
    params: {
      lang = defaultLocale,
      locationType = "c",
      continentCode = "",
      countryCode = "",
      regionCode = "",
    },
  }: match<WeatherSeletionRouteParams>,
  req?: Request
) {
  return await fetchData(
    store.dispatch,
    lang as LocaleCode,
    locationType as LocationType,
    continentCode,
    countryCode,
    regionCode,
    () => false,
    req
  );
};

export default WeatherSelectionPage;

import React from "react";
import { SelectedDayWeather } from "../DayWeatherHead";
import {
  Stars,
  Moon,
  Sun,
  Clouds,
  Mist,
  Rain,
  Snow,
  Thunder,
  Wind,
  Cloud,
  Overcast,
  Wind2,
  Overcast2,
} from "./layers";
import styles from "./WeatherIllustration.module.scss";
import { CurrentWeather, WeatherCode } from "../../../api/weatherApi";

type WeatherIllustrationProps = {
  weather: SelectedDayWeather | undefined;
};

const weatherLayers: { [key in WeatherCode]: React.ComponentType<any>[] } = {
  [WeatherCode.Sunny]: [Sun],
  [WeatherCode.PartlyCloudy]: [Sun, Cloud],
  [WeatherCode.Cloudy]: [Clouds],
  [WeatherCode.Overcast]: [Overcast, Clouds],
  [WeatherCode.Mist]: [Sun, Mist],
  [WeatherCode.PatchyRainPossible]: [Sun, Rain, Clouds],
  [WeatherCode.PatchySnowPossible]: [Sun, Snow, Clouds],
  [WeatherCode.PatchySleetPossible]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.PatchyFreezingDrizzlePossible]: [Sun, Rain, Overcast, Clouds],
  [WeatherCode.ThunderyOutbreaksPossible]: [Sun, Clouds, Thunder],
  [WeatherCode.BlowingSnow]: [Snow, Clouds, Wind],
  [WeatherCode.Blizzard]: [Snow, Clouds, Wind],
  [WeatherCode.Fog]: [Overcast2],
  [WeatherCode.FreezingFog]: [Overcast2, Mist],
  [WeatherCode.PatchyLightDrizzle]: [Sun, Rain, Mist, Clouds],
  [WeatherCode.LightDrizzle]: [Sun, Rain, Mist, Clouds],
  [WeatherCode.FreezingDrizzle]: [Sun, Rain, Overcast, Clouds],
  [WeatherCode.HeavyFreezingDrizzle]: [Sun, Rain, Overcast, Clouds],
  [WeatherCode.PatchyLightRain]: [Sun, Rain, Clouds],
  [WeatherCode.LightRain]: [Sun, Rain, Clouds],
  [WeatherCode.ModerateRainAtTimes]: [Sun, Rain, Clouds],
  [WeatherCode.ModerateRain]: [Sun, Rain, Clouds],
  [WeatherCode.HeavyRainAtTimes]: [Sun, Rain, Clouds],
  [WeatherCode.HeavyRain]: [Rain, Clouds],
  [WeatherCode.LightFreezingRain]: [Sun, Rain, Mist, Clouds],
  [WeatherCode.ModerateOrHeavyFreezingRain]: [Sun, Rain, Overcast, Clouds],
  [WeatherCode.LightSleet]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.ModerateOrHeavySleet]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.PatchyLightSnow]: [Sun, Snow, Clouds],
  [WeatherCode.LightSnow]: [Sun, Snow, Clouds],
  [WeatherCode.PatchyModerateSnow]: [Sun, Snow, Clouds],
  [WeatherCode.ModerateSnow]: [Sun, Snow, Clouds],
  [WeatherCode.PatchyHeavySnow]: [Snow, Clouds],
  [WeatherCode.HeavySnow]: [Snow, Clouds],
  [WeatherCode.IcePellets]: [Sun, Rain, Clouds],
  [WeatherCode.LightRainShower]: [Sun, Rain, Clouds],
  [WeatherCode.ModerateOrHeavyRainShower]: [Rain, Clouds],
  [WeatherCode.TorrentialRainShower]: [Rain, Thunder, Clouds],
  [WeatherCode.LightSleetShower]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.ModerateOrHeavySleetShower]: [Rain, Snow, Clouds],
  [WeatherCode.LightSnowShower]: [Sun, Snow, Clouds],
  [WeatherCode.ModerateOrHeavySnowShower]: [Snow, Clouds],
  [WeatherCode.LightShowerOfIcePellets]: [Sun, Rain, Clouds],
  [WeatherCode.ModerateOrHeavyShowerOfIcePellets]: [Rain, Clouds],
  [WeatherCode.PatchyLightRainInAreaWithThunder]: [Sun, Rain, Clouds, Thunder],
  [WeatherCode.ModerateOrHeavyRainInAreaWithThunder]: [Rain, Clouds, Thunder],
  [WeatherCode.PatchyLightSnowInAreaWithThunder]: [Sun, Snow, Clouds, Thunder],
  [WeatherCode.ModerateOrHeavySnowInAreaWithThunder]: [Snow, Clouds, Thunder],

  // AccuWeather
  [WeatherCode.MostlySunny]: [Sun, Cloud],
  [WeatherCode.PartlySunny]: [Sun, Clouds],
  [WeatherCode.IntermittentClouds]: [Sun, Clouds],
  [WeatherCode.HazySunshine]: [Sun, Overcast],
  [WeatherCode.MostlyCloudy]: [Clouds],
  [WeatherCode.Showers]: [Sun, Rain, Clouds],
  [WeatherCode.MostlyCloudyWithShowers]: [Rain, Clouds],
  [WeatherCode.PartlySunnyWithShowers]: [Sun, Rain, Clouds],
  [WeatherCode.TStorms]: [Rain, Clouds, Thunder],
  [WeatherCode.MostlyCloudyWithTStorms]: [Rain, Clouds, Thunder],
  [WeatherCode.PartlySunnyWithTStorms]: [Sun, Rain, Clouds, Thunder],
  [WeatherCode.Rain]: [Rain, Clouds],
  [WeatherCode.Flurries]: [Snow, Clouds, Wind],
  [WeatherCode.MostlyCloudyWithFlurries]: [Snow, Clouds, Wind],
  [WeatherCode.PartlySunnyWithFlurries]: [Sun, Snow, Clouds, Wind],
  [WeatherCode.Snow]: [Snow, Clouds],
  [WeatherCode.MostlyClodyWithSnow]: [Snow, Clouds],
  [WeatherCode.Ice]: [Sun, Rain, Clouds],
  [WeatherCode.Sleet]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.FreezingRain]: [Sun, Rain, Clouds],
  [WeatherCode.RainAndSnow]: [Sun, Rain, Snow, Clouds],
  [WeatherCode.Hot]: [Sun],
  [WeatherCode.Cold]: [Sun],
  [WeatherCode.Windy]: [Sun, Wind2],
  [WeatherCode.Clear]: [Sun],
  [WeatherCode.MostlyClear]: [Sun, Cloud],
  [WeatherCode.HazyMoonlight]: [Sun, Overcast],
  [WeatherCode.PartlyCloudyWithShowers]: [Sun, Rain, Clouds],
  [WeatherCode.PartlyClodyWithTStorms]: [Sun, Rain, Clouds, Thunder],
  [WeatherCode.MostlyCloudyWithSnow]: [Snow, Clouds],
};

// const weatherCodes = Object.values(WeatherCode).filter(
//   (x) => typeof x === "number"
// ) as WeatherCode[];

const WeatherIllustration: React.FC<WeatherIllustrationProps> = ({
  weather,
}) => {
  // const [index, setIndex] = useState(0);

  if (!weather || !weather.weatherCode) return null;

  const dayValue = (weather as CurrentWeather).day;
  const day = dayValue === undefined || dayValue;

  //const layers = [Mist] || weatherLayers[weather.weatherCode];
  //const layers = weatherLayers[weatherCodes[index]];

  const layers = weatherLayers[weather.weatherCode];
  const sun = layers.includes(Sun);

  const props = { weather };

  return (
    <div
      key={weather.weatherCode}
      className={styles.container}
      // onClick={() => setIndex((index + 1) % weatherCodes.length)}
    >
      {/* <div style={{ position: "absolute" }}>
        {WeatherCode[weatherCodes[index]]}{" "}
      </div> */}
      {day ? (
        sun ? (
          <Sun />
        ) : null
      ) : (
        <>
          <Stars />
          {sun && <Moon />}
        </>
      )}
      {layers.map((Layer, i) =>
        Layer !== Sun ? <Layer key={i} {...props} /> : null
      )}
    </div>
  );
};

export default WeatherIllustration;

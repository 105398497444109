import { RootDispatch, GetState } from "..";
import { LocalStorageKeys, localStorageSet } from "../../utils/localStorage";
import { getAuthToken } from "../selectors/auth";
import { tryUpdateUser, UserProfile } from "../../api/authApi";
import {
  UserProfileActionTypes,
  USER_PROFILE_UPDATE,
} from "../types/userProfile";

export function userProfileSet(
  userProfile: Partial<UserProfile>
): UserProfileActionTypes {
  return {
    type: USER_PROFILE_UPDATE,
    userProfile,
  };
}

export function userProfileUpdate(userProfile: Partial<UserProfile>) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!userProfile) return;
    try {
      dispatch({ type: USER_PROFILE_UPDATE, userProfile });

      const token = getAuthToken(getState());
      if (token) {
        dispatch(userProfileSet(userProfile));

        await tryUpdateUser(token, userProfile);
      } else {
        localStorageSet(LocalStorageKeys.userProfile, userProfile);
      }
    } catch (e) {
      console.error("userPhoneUpdate: ", e);
    }
  };
}

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import { UserWeatherAlerts } from "../../../api/authApi";
import { Checkbox, Switch } from "../../../components/Controls";
import { HSplit } from "../../../components/Divider";
import { CardSection, CardSectionTitle } from "../Card/Card";

export enum WeatherConditions {
  heavyRainfall = "Heavy Rainfall",
  thunderstorm = "Thunderstorm",
  highWind = "High Wind",
  heavySnowfall = "Heavy Snowfall",
  iceForecast = "Ice Forecast",
  excessiveHeat = "Excessive Heat",
  veryCold = "Very Cold",
  veryHumid = "Very humid weather",
  denseFog = "Dense Fog",
  highUvRadiation = "Very high risk of ultraviolet radiation",
}

export const allWeatherConditions = Object.keys(WeatherConditions);

type AlertsSignificantChangesProps = {
  alerts: UserWeatherAlerts;
  updateAlerts: (alerts: Partial<UserWeatherAlerts>) => void;
};

const AlertsSignificantChanges: React.FC<AlertsSignificantChangesProps> = ({
  alerts,
  updateAlerts,
}) => {
  const handleSignificantChangesChange = (significantChanges: boolean) => {
    updateAlerts({ significantChanges, desktop: true });
  };
  const handleWeatherConditionsChange = (
    checked: boolean,
    selected: string
  ) => {
    const newChanges = alerts.weatherAlerts
      ? checked
        ? [...alerts.weatherAlerts, selected]
        : alerts.weatherAlerts.filter((x) => x !== selected)
      : [selected];

    updateAlerts({ weatherAlerts: newChanges });
  };

  return (
    <>
      <CardSection className={styles.alertsSection}>
        <Switch
          label="Significant weather changes"
          info="Get notified up to 48 hours in advance"
          checked={!!alerts.significantChanges}
          onChange={handleSignificantChangesChange}
        />
      </CardSection>
      {!!alerts.significantChanges && (
        <CardSection className={styles.sectionColumn}>
          <CardSectionTitle title="Alerts:" />
          <div className={styles.alerts}>
            <Checkbox
              label={WeatherConditions.heavyRainfall}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.heavyRainfall)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.heavyRainfall
                )
              }
            />
            <Checkbox
              label={WeatherConditions.thunderstorm}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.thunderstorm)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.thunderstorm
                )
              }
            />
            <Checkbox
              label={WeatherConditions.highWind}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.highWind)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.highWind
                )
              }
            />
            <Checkbox
              label={WeatherConditions.heavySnowfall}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.heavySnowfall)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.heavySnowfall
                )
              }
            />
            <Checkbox
              label={WeatherConditions.iceForecast}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.iceForecast)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.iceForecast
                )
              }
            />
            <Checkbox
              label={WeatherConditions.excessiveHeat}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.excessiveHeat)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.excessiveHeat
                )
              }
            />
            <Checkbox
              label={WeatherConditions.veryCold}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.veryCold)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.veryCold
                )
              }
            />
            <Checkbox
              label={WeatherConditions.veryHumid}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.veryHumid)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.veryHumid
                )
              }
            />
            <Checkbox
              label={WeatherConditions.denseFog}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.denseFog)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.denseFog
                )
              }
            />
            <Checkbox
              label={WeatherConditions.highUvRadiation}
              checked={
                !alerts.weatherAlerts ||
                alerts.weatherAlerts.includes(WeatherConditions.highUvRadiation)
              }
              onClick={(checked) =>
                handleWeatherConditionsChange(
                  checked,
                  WeatherConditions.highUvRadiation
                )
              }
            />
          </div>
        </CardSection>
      )}
      <HSplit />
    </>
  );
};

export default AlertsSignificantChanges;

import React from "react";
import styles from "./Controls.module.scss";
export type NumberRange = { from: number | undefined; to: number | undefined };

type RangeFieldsProps = {
  from?: number;
  to?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
  onChange: (range: NumberRange) => void;
};

export const RangeFields: React.FC<RangeFieldsProps> = ({
  from,
  to,
  min,
  max,
  disabled,
  className = "",
  onChange,
}) => {
  const handleMinChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value !== undefined ? +e.target.value : undefined;
    if (value && min !== undefined && value < min) value = min;
    onChange({ from: value, to });
  };
  const handleMinBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value ? +e.target.value : undefined;
    if (to !== undefined && to !== null && value !== undefined && value > to) {
      onChange({ from: min ?? 0, to });
    }
  };
  const handleMaxChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value !== undefined ? +e.target.value : undefined;
    if (value && max !== undefined && value > max) value = max;
    onChange({ from, to: value });
  };

  const handleMaxBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value ? +e.target.value : undefined;
    if (
      from !== undefined &&
      from !== null &&
      value !== undefined &&
      value < from
    ) {
      onChange({ from, to: max ?? from + 1 });
    }
  };

  return (
    <div className={`${styles.rangeFields} ${className}`}>
      <label className={styles.rangeFieldWrap}>
        <span className={styles.rangeFieldLabel}>From</span>
        <input
          min={min}
          className={styles.rangeFieldInput}
          type="number"
          placeholder="min"
          value={disabled ? "" : from}
          disabled={disabled}
          onChange={handleMinChange}
          onBlur={handleMinBlur}
        />
      </label>

      <label className={styles.rangeFieldWrap}>
        <span className={styles.rangeFieldLabel}>To</span>
        <input
          max={max}
          className={styles.rangeFieldInput}
          type="number"
          placeholder="max"
          value={disabled ? "" : to}
          disabled={disabled}
          onChange={handleMaxChange}
          onBlur={handleMaxBlur}
        />
      </label>
    </div>
  );
};

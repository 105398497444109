const en = {
  // Page titles
  HomeTitle: "Weatherman: Global, National, & Local Weather Forecasts",
  ContactUsTitle: "Contact us | Weatherman",
  PrivacyPolicyTitle: "Privacy Policy | Weatherman",
  TermsOfUseTitle: "Terms of Use | Weatherman",
  WeatherSelectionTitle: "{name} Weather Forecasts & Temperatures | Weatherman",
  WeatherSelectionPlaceTitle:
    "{name} Weather Forecasts & Temperatures by Place | Weatherman",
  WeatherTitleToday:
    "{name} Hourly Weather Temperatures for Today | Weatherman",
  WeatherTitleTomorrow:
    "{name} Hourly Weather Forecasts for Tomorrow  | Weatherman",
  WeatherTitleDays10: "{name} 10 Day Weather Forecasts | Weatherman",
  WeatherTitleTides: "{name} Tide Chart | Weatherman",
  WeatherTitleRadar: "{name} Weather Radar | Weatherman",

  // Page descriptions
  HomeDesc:
    "Weatherman provides national and local weather forecast for cities and places, as well as weather radar, and tidal information.",
  ContactUsDesc:
    "Contact Weatherman with any questions or concerns you may have regarding our website and services.",
  PrivacyPolicyDesc:
    "Please read our privacy policy. Your privacy is important to us and our community. Please contact us with any questions.",
  TermsOfUseDesc:
    "We hope you enjoy Weatherman.com. Please read our terms of use to understand how we operate and how our services work.",
  WeatherSelectionDesc:
    "Weatherman provides local weather forecast, temperature readings, and radar images for cities within {name}.",
  WeatherSelectionPlaceDesc:
    "Weatherman provides local weather forecast, temperature readings, and radar images for places and destinations within {name}.",
  WeatherDescToday:
    "Hourly temperatures and detailed weather reports for {name}.",
  WeatherDescTomorrow:
    "Tomorrow's hourly weather forecast and detailed weather reports for {name}.",
  WeatherDescDays10:
    "10-day weather forecast and detailed weather reports for {name}.",
  WeatherDescTides: "Tide charts for {name} and surrounding areas.",
  WeatherDescRadar: "Satellite and Doppler radar images for {name}.",

  PageNotFound: "Sorry, page not found",
  Login: "Login",
  Logout: "Log Out",
  AccountSettings: "Account Settings",
  ContactInformation: "Contact Information",
  CurrentLocation: "Current Location",
  DetectAutomatically: "Detect automatically",
  SelectManually: "Select manually",
  HomePage: "Home",
  TodaysWeather: "Today's Weather",
  WorldWeather: "World Weather",
  WeatherWidget: "Weather Widget",
  GpsLocation: "GPS Location",
  SearchPlaceholder: "Search location",
  Units: "Units",
  Language: "Language",
  Navigation: "Navigation",
  Information: "Information",
  JoinUs: "Join Us",
  ContactUs: "Contact Us",
  PrivacyPolicy: "Privacy Policy",
  TermsOfUse: "Terms of Use",
  Copyright: "© {year} WeatherMan. All rights reserved",
  NearbyCitiesAndTowns: "Nearby Cities & Towns",
  NearbyPlaces: "Places of Interest",
  GoToPlacesPage: "Go To Places Page >",
  GoToFullForecast: "Full Forecast >",
  CurrentWeather: "Current Weather",
  TodayWeather: "Today Weather",
  Loading: "Loading...",
  FeelsLikeTemp: "Feels Like {temp}",
  Next12Hours: "Next 12 hours",
  Next24Hours: "Next 24 hours",
  WeatherSelectionHeader: "World Weather",
  WeatherSelectionHeader2:
    "Select the city where you want to see the detailed weather forecast",
  WeatherSelectionPlacesHeader2:
    "Select the place where you want to see the detailed weather forecast",
  WeatherSelectionLocationType: "Select a location type",
  WeatherSelectionContinents: "Continent",
  WeatherSelectionCountries: "Country",
  WeatherSelectionRegions: "Region / State",
  WeatherSelectionCities: "Cities & Towns",
  WeatherSelectionPlaces: "Places of Interest",
  WeatherDescription:
    "{condition} with {windSpeed} winds from the {windDir} and a temperature of {temp}.",
  Now: "Now",
  Sunrise: "Sunrise",
  Sunset: "Sunset",
  Moonrise: "Moonrise",
  Moonset: "Moonset",
  SunAndMoon: "Sun & Moon",
  LengthOfVisibleLight: "Length of Visible Light",
  LengthOfDay: "Length of Day",
  DetailedHourlyForecastTitleToday: "Detailed Hourly Weather - {name}",
  DetailedHourlyForecastTitle: "Detailed Hourly Forecast - {name}",
  ExtendedForecastTitleToday: "Extended Weather - {name}",
  ExtendedForecastTitle: "Extended Forecast - {name}",
  Today: "Today",
  AverageTemperatureTitle: "Average Temperature - {name}",
  MaxTemp: "Max Temp",
  AvgTemp: "Avg Temp",
  MinTemp: "Min Temp",
  AverageTemperatureTempAxis: "Temp, {unit}",
  AverageRainfallTitle: "Average Rainfall - {name}",
  AverageRainfall: "Average rainfall",
  AvgRainfall: "Avg rainfall",
  RainfallAxis: "Rainfall",
  NextXDays: "Next {days, plural, one {# day} other {# days}}",
  SeeDetailedForecast10: "See detailed 10 Day forecast",
  DetailedDailyForecastTitle10: "Detailed 10 Day Forecast - {name}",
  MinutesDuration: "{hours} h {minutes} m",
  PopularCities: "Popular Cities",
  FavoriteCities: "Favorites",

  // Consent
  CookieOk: "Continue",
  CookieText:
    "We use cookies to personalize content and to analyze our traffic, which may also result in profiling.\nYour privacy is important to us. Read more in our ",

  // Login
  LoginWelcome: "Welcome to Weatherman",
  LoginContinue: "Please log in to continue",
  LoginGoogle: "Login With Google",
  LoginFacebook: "Login With Facebook",

  // Share
  Share: "Share",
  ShareCopyLink: "Copy Link",

  // Radar
  RadarMapTitle: "Current Conditions - {name}",
  WeatherMap: "Weather Map:",
  WeatherMaps: "Weather Maps",
  HideAdvancedMaps: "Hide Advanced Maps",
  ShowAdvancedMaps: "Show Advanced Maps",
  RadarTitle: "Doppler Radar - {name}",
  SatelliteTitle: "Satellite Weather Images - {name}",
  RadarPause: "Pause",
  RadarPlay: "Play",

  // Map layers
  MapPrecipitationIntensity: "Precipitation (Intensity)",
  MapCloudiness: "Cloudiness",
  MapHumidity: "Humidity",
  MapWindDir: "Wind Direction (arrows)",
  MapAccPrecipitation: "Accumulated Precipitation",
  MapAccPrecipitationRain: "Accumulated Precipitation - Rain",
  MapAccPrecipitationSnow: "Accumulated Precipitation - Snow",
  MapSnowDepth: "Depth of Snow",
  MapConvectivePrecipitation: "Convective Precipitation",
  MapWindSpeed: "Wind Speed",
  MapPressure: "Atmospheric Pressure",
  MapTemperature: "Air Temperature",
  MapDewPoint: "Dew Point",
  MapSoil0: "Soil Temperature 0-10 cm",
  MapSoil10: "Soil Temperature >10 cm",

  // Tides
  TidePredictions: "Tide Predictions - {name}",
  TidesHourly: "Hourly",
  TideHourlyPredictions: "Hourly Tide Predictions",

  TideHeight: "Tide Height",
  TideHeightAxis: "H, {unit}",

  TimeAt: "at",

  TideTime: "Time",
  TideLevel: "Height",

  TideH: "High",
  TideL: "Low",

  // Alerts
  AlertSummary:
    "{description} in effect{start, select, none {} other { from {start}}}{end, select, none {} other { until {end}}}. Source: {source}.",
  AlertMore: "More",
  AlertWarning: "WARNING!",
  AlertCloseBtn: "Close",

  //Contact Us
  ContactName: "Name",
  ContactEmail: "Email",
  ContactMessage: "Message",
  ContactSubmit: "Submit",
  FieldRequired: "Required",
  ContactBadEmail: "Invalid email address",
  ContactThankyou: "Thank you for getting in touch!",

  // Search
  SearchRecent: "Recently Viewed",
  SearchClear: "Clear",
  SearchNearestByIp: "Nearest cities by IP-address",
  SearchCities: "Cities & Towns",
  SearchPlaces: "Places",
  SearchCountries: "Countries",
  SearchRegions: "Regions / States",
  SearchResults: "Search Results",
  SearchLoading: "Loading...",
  SearchNoMatch: "No Match",

  // Continents
  ContinentAF: "Africa",
  ContinentAN: "Antarctica",
  ContinentAS: "Asia",
  ContinentEU: "Europe",
  ContinentNA: "North America",
  ContinentSA: "South America",
  ContinentOC: "Oceania",
  // Weather Types
  WeatherTypeToday: "Today",
  WeatherTypeTomorrow: "Tomorrow",
  WeatherTypeDays10: "10 Day",
  WeatherTypeTides: "Tides",
  WeatherTypeRadar: "Radar",
  BreadcrumbNameToday: "{name} Weather",
  BreadcrumbNameTomorrow: "{name} Forecast",
  BreadcrumbNameDays10: "{name} 10 Day Forecast",
  BreadcrumbNameTides: "{name} Tides",
  BreadcrumbNameRadar: "{name} Radar",
  // MoonPhases
  MoonPhase0: "New Moon",
  MoonPhase1: "Waxing Crescent",
  MoonPhase2: "First Quarter",
  MoonPhase3: "Waxing Gibbous",
  MoonPhase4: "Full Moon",
  MoonPhase5: "Waning Gibbous",
  MoonPhase6: "Third Quarter",
  MoonPhase7: "Waning Crescent",
  // Directions
  DirShortN: "N",
  DirShortNNE: "NNE",
  DirShortNE: "NE",
  DirShortENE: "ENE",
  DirShortE: "E",
  DirShortESE: "ESE",
  DirShortSE: "SE",
  DirShortSSE: "SSE",
  DirShortS: "S",
  DirShortSSW: "SSW",
  DirShortSW: "SW",
  DirShortWSW: "WSW",
  DirShortW: "W",
  DirShortWNW: "WNW",
  DirShortNW: "NW",
  DirShortNNW: "NNW",
  DirFullN: "North",
  DirFullW: "West",
  DirFullS: "South",
  DirFullE: "East",
  DirFullNW: "North-West",
  DirFullNE: "North-East",
  DirFullSW: "South-West",
  DirFullSE: "South-East",
  DirFullNNW: "North-North-West",
  DirFullNNE: "North-North-East",
  DirFullENE: "East-North-East",
  DirFullESE: "East-South-East",
  DirFullSSW: "South-South-West",
  DirFullSSE: "South-South-East",
  DirFullWSW: "West-South-West",
  DirFullWNW: "West-North-West",
  // Units
  UnitMiles: "{value}{suffix} miles",
  UnitKm: "{value}{suffix} km",
  UnitMilesPerHour: "{value} mph",
  UnitMetersPerSecond: "{value} m/s",
  UnitMm: "{value} mm",
  UnitM: "{value} m",
  UnitFt: "{value} ft",
  UnitInches: '{value} "',
  UnitMmHg: "{value} mmHg",
  UnitILow: "Low",
  UnitILight: "Light",
  UnitIModerate: "Moderate",
  UnitIHigh: "High",
  UnitIHeavy: "Heavy",
  UnitIVeryHigh: "Very High",
  UnitIExtreme: "Extreme",
  UnitIPoor: "Poor",
  UnitIFair: "Fair",
  UnitIGood: "Good",
  UnitIVeryGood: "Very Good",
  UnitIExcellent: "Excellent",
  UnitIndex: "{value} ({level})",

  // Fields
  Wind: "Wind",
  Visibility: "Visibility",
  DewPt: "Dew Pt",
  Humidity: "Humidity",
  UVIndex: "UV Index",
  Pressure: "Pressure",
  FeelsLike: "Feels Like",
  WetBulbTemp: "Wet Bulb Temp",
  WindGust: "Wind Gust",
  CloudCeiling: "Cloud Ceiling",
  CloudCover: "Cloud Cover",
  PrecipitationProbability: "Precipitation Probability",
  SnowProbability: "Snow Probability",
  IceProbability: "Ice Probability",
  TStormProbability: "Thunderstorm Probability",
  TotalLiquid: "Total Liquid",
  Rain: "Rain",
  Snow: "Snow",
  Ice: "Ice",
  Mixed: "Mixed",
  MoonAge: "Moon Age",
  FeelsLikeShade: "FeelsLike in the shade",
  PrecipitationDuration: "Precipitation Duration",
  RainDuration: "Rain Duration",
  SnowDuration: "Snow Duration",
  IceDuration: "Ice Duration",
  PrecipitationType: "Precipitation Type",
  PrecipitationIntensity: "Precipitation Intensity",
  AirQuality: "Air Quality",
  Grass: "Grass",
  Mold: "Mold",
  Ragweed: "Ragweed",
  Tree: "Tree",

  // Weather codes
  WeatherCodeSunny: "Sunny",
  WeatherCodePartlyCloudy: "Partly cloudy",
  WeatherCodeCloudy: "Cloudy",
  WeatherCodeOvercast: "Overcast",
  WeatherCodeMist: "Mist",
  WeatherCodePatchyRainPossible: "Patchy rain possible",
  WeatherCodePatchySnowPossible: "Patchy snow possible",
  WeatherCodePatchySleetPossible: "Patchy sleet possible",
  WeatherCodePatchyFreezingDrizzlePossible: "Patchy freezing drizzle possible",
  WeatherCodeThunderyOutbreaksPossible: "Thundery outbreaks possible",
  WeatherCodeBlowingSnow: "Blowing snow",
  WeatherCodeBlizzard: "Blizzard",
  WeatherCodeFog: "Fog",
  WeatherCodeFreezingFog: "Freezing fog",
  WeatherCodePatchyLightDrizzle: "Patchy light drizzle",
  WeatherCodeLightDrizzle: "Light drizzle",
  WeatherCodeFreezingDrizzle: "Freezing drizzle",
  WeatherCodeHeavyFreezingDrizzle: "Heavy freezing drizzle",
  WeatherCodePatchyLightRain: "Patchy light rain",
  WeatherCodeLightRain: "Light rain",
  WeatherCodeModerateRainAtTimes: "Moderate rain at times",
  WeatherCodeModerateRain: "Moderate rain",
  WeatherCodeHeavyRainAtTimes: "Heavy rain at times",
  WeatherCodeHeavyRain: "Heavy rain",
  WeatherCodeLightFreezingRain: "Light freezing rain",
  WeatherCodeModerateOrHeavyFreezingRain: "Moderate or heavy freezing rain",
  WeatherCodeLightSleet: "Light sleet",
  WeatherCodeModerateOrHeavySleet: "Moderate or heavy sleet",
  WeatherCodePatchyLightSnow: "Patchy light snow",
  WeatherCodeLightSnow: "Light snow",
  WeatherCodePatchyModerateSnow: "Patchy moderate snow",
  WeatherCodeModerateSnow: "Moderate snow",
  WeatherCodePatchyHeavySnow: "Patchy heavy snow",
  WeatherCodeHeavySnow: "Heavy snow",
  WeatherCodeIcePellets: "Ice pellets",
  WeatherCodeLightRainShower: "Light rain shower",
  WeatherCodeModerateOrHeavyRainShower: "Moderate or heavy rain shower",
  WeatherCodeTorrentialRainShower: "Torrential rain shower",
  WeatherCodeLightSleetShower: "Light sleet shower",
  WeatherCodeModerateOrHeavySleetShower: "Moderate or heavy sleet shower",
  WeatherCodeLightSnowShower: "Light snow shower",
  WeatherCodeModerateOrHeavySnowShower: "Moderate or heavy snow shower",
  WeatherCodeLightShowerOfIcePellets: "Light shower of ice pellets",
  WeatherCodeModerateOrHeavyShowerOfIcePellets:
    "Moderate or heavy shower of ice pellets",
  WeatherCodePatchyLightRainInAreaWithThunder:
    "Patchy light rain in area with thunder",
  WeatherCodeModerateOrHeavyRainInAreaWithThunder:
    "Moderate or heavy rain in area with thunder",
  WeatherCodePatchyLightSnowInAreaWithThunder:
    "Patchy light snow in area with thunder",
  WeatherCodeModerateOrHeavySnowInAreaWithThunder:
    "Moderate or heavy snow in area with thunder",
  // AccuWeather codes
  WeatherCodeMostlySunny: "Mostly sunny",
  WeatherCodePartlySunny: "Partly sunny",
  WeatherCodeIntermittentClouds: "Intermittent clouds",
  WeatherCodeHazySunshine: "Hazy sunshine",
  WeatherCodeMostlyCloudy: "Mostly cloudy",
  WeatherCodeShowers: "Showers",
  WeatherCodeMostlyCloudyWithShowers: "Mostly cloudy with showers",
  WeatherCodePartlySunnyWithShowers: "Partly sunny with showers",
  WeatherCodeTStorms: "Thunderstorms",
  WeatherCodeMostlyCloudyWithTStorms: "Mostly cloudy with t-storms",
  WeatherCodePartlySunnyWithTStorms: "Partly sunny with t-storms",
  WeatherCodeRain: "Rain",
  WeatherCodeFlurries: "Flurries",
  WeatherCodeMostlyCloudyWithFlurries: "Mostly cloudy with flurries",
  WeatherCodePartlySunnyWithFlurries: "Partly sunny with flurries",
  WeatherCodeSnow: "Snow",
  WeatherCodeMostlyClodyWithSnow: "Mostly clody with snow",
  WeatherCodeIce: "Ice",
  WeatherCodeSleet: "Sleet",
  WeatherCodeFreezingRain: "Freezing rain",
  WeatherCodeRainAndSnow: "Rain and snow",
  WeatherCodeHot: "Hot",
  WeatherCodeCold: "Cold",
  WeatherCodeWindy: "Windy",
  WeatherCodeClear: "Clear",
  WeatherCodeMostlyClear: "Mostly clear",
  WeatherCodeHazyMoonlight: "Hazy moonlight",
  WeatherCodePartlyCloudyWithShowers: "Partly cloudy with showers",
  WeatherCodePartlyClodyWithTStorms: "Partly clody with t-storms",
  WeatherCodeMostlyCloudyWithSnow: "Mostly cloudy with snow",

  // AccountSettings
  AlertAccountSettingsManualLocation:
    "You can set the current location manually. Keep in mind, when you enter the site, the location will not be set by GPS.",
  Favorites: "Favorites",
  AccSettingsLocationInfoMsg: "Alerts & Notifications",
  AccSettingsAlertsStatistic: "Alerts Statistic",

  //Alerts
  Alert1: "Snowfall Warning",
  Alert10: "Tornado Watch",
  Alert11: "Tornado Warning",
  Alert12: "High Wind Warning",
  Alert13: "Rainfall Warning",
  Alert14: "Severe Wind Warning",
  Alert15: "Flash Freeze Warning",
  Alert16: "Cold Wave Warning",
  Alert17: "Arctic Outflow Warning",
  Alert18: "Extreme Cold Warning",
  Alert19: "Snowsquall Warning",
  Alert2: "Blizzard Warning",
  Alert21: "Wreckhouse Wind Warning",
  Alert22: "Tropical Storm Watch",
  Alert23: "Tropical Storm Warning",
  Alert24: "Hurricane Watch",
  Alert25: "Hurricane Warning",
  Alert26: "Storm Surge Warning",
  Alert27: "Dust Storm Warning",
  Alert28: "Freezing Drizzle Warning",
  Alert29: "Blowing Snow Warning",
  Alert3: "Snow Squall Warning",
  Alert30: "Frontal Squall Warning",
  Alert31: "Frost Warning",
  Alert32: "Heat Warning",
  Alert33: "Les Suetes Wind Warning",
  Alert34: "Snow Squall Watch",
  Alert35: "Snowsquall Watch",
  Alert36: "Frost Advisory",
  Alert37: "Fog Advisory",
  Alert38: "Special Air Quality Statement",
  Alert39: "Special Weather Statement",
  Alert4: "Wind Warning",
  Alert40: "Tropical Cyclone Information Statement",
  Alert5: "Winter Storm Watch",
  Alert6: "Winter Storm Warning",
  Alert7: "Freezing Rain Warning",
  Alert8: "Severe Thunderstorm Warning",
  Alert9: "Severe Thunderstorm Watch",
  AlertAFW: "Ashfall Warning",
  AlertAFY: "Ashfall Advisory",
  AlertASY: "Air Stagnation Advisory",
  AlertAWXAQA: "Air Quality Alert",
  AlertAWXAVA: "Avalanche Watch",
  AlertAWXAVW: "Avalanche Warning",
  AlertAWXAWG: "National Attack Warning",
  AlertAWXCAE: "Child Abduction Emergency",
  AlertAWXCDW: "Civil Danger Warning",
  AlertAWXCEM: "Civil Emergency Message",
  AlertAWXEQW: "Earthquake Warning",
  AlertAWXEVI: "Evacuation Immediate",
  AlertAWXFFS: "Flash Flood Statement",
  AlertAWXFLS: "Flood Statement",
  AlertAWXFRW: "Fire Warning",
  AlertAWXHMW: "Hazardous Materials Warning",
  AlertAWXLAE: "Local Area Emergency",
  AlertAWXLEW: "Law Enforcement Warning",
  AlertAWXNUW: "Nuclear Power Plant Warning",
  AlertAWXRHW: "Radiological Hazard Warning",
  AlertAWXSDS: "Special Dispersion Statement",
  AlertAWXSPS: "Special Weather Statement",
  AlertAWXSPW: "Shelter In Place Warning",
  AlertAWXTOE: "911 Telephone Outage",
  AlertAWXVOW: "Volcano Warning",
  AlertBSY: "Blowing Snow Advisory",
  AlertBWY: "Brisk Wind Advisory",
  AlertBZA: "Blizzard Watch",
  AlertBZW: "Blizzard Warning",
  AlertCFA: "Coastal Flood Watch",
  AlertCFS: "Coastal Flood Statement",
  AlertCFW: "Coastal Flood Warning",
  AlertCFY: "Coastal Flood Advisory",
  AlertDSW: "Dust Storm Warning",
  AlertDUY: "Blowing Dust Advisory",
  AlertECA: "Extreme Cold Watch",
  AlertECW: "Extreme Cold Warning",
  AlertEHA: "Excessive Heat Watch",
  AlertEHW: "Excessive Heat Warning",
  AlertEWW: "Extreme Wind Warning",
  AlertFAA: "Areal Flood Watch",
  AlertFAW: "Areal Flood Warning",
  AlertFAY: "Areal Flood Advisory",
  AlertFFA: "Flash Flood Watch",
  AlertFFW: "Flash Flood Warning",
  AlertFGY: "Dense Fog Advisory",
  AlertFLA: "Flood Watch",
  AlertFLW: "Flood Warning",
  AlertFLY: "Flood Advisory",
  AlertFRY: "Frost Advisory",
  AlertFWA: "Fire Weather Watch",
  AlertFWW: "Red Flag Warning",
  AlertFZA: "Freeze Watch",
  AlertFZW: "Freeze Warning",
  AlertGLA: "Gale Watch",
  AlertGLW: "Gale Warning",
  AlertHFA: "Hurricane Force Wind Watch",
  AlertHFW: "Hurricane Force Wind Warning",
  AlertHIA: "Hurricane Wind Watch",
  AlertHIW: "Hurricane Wind Warning",
  AlertHSW: "Heavy Snow Warning",
  AlertHTY: "Heat Advisory",
  AlertHUA: "Hurricane Watch",
  AlertHUS: "Tropical Cyclone Statement",
  AlertHUW: "Hurricane Warning",
  AlertHWA: "High Wind Watch",
  AlertHWW: "High Wind Warning",
  AlertHZA: "Hard Freeze Watch",
  AlertHZW: "Hard Freeze Warning",
  AlertIPW: "Sleet Warning",
  AlertIPY: "Sleet Advisory",
  AlertISW: "Ice Storm Warning",
  AlertLBY: "Lake Effect Snow/Blowing Snow Advisory",
  AlertLEA: "Lake Effect Snow Watch",
  AlertLEW: "Lake Effect Snow Warning",
  AlertLEY: "Lake Effect Snow Advisory",
  AlertLOY: "Low Water Advisory",
  AlertLSA: "Lakeshore Flood Watch",
  AlertLSS: "Lakeshore Flood Statement",
  AlertLSW: "Lakeshore Flood Warning",
  AlertLSY: "Lakeshore Flood Advisory",
  AlertLWY: "Lake Wind Advisory",
  AlertMAS: "Marine Weather Statement",
  AlertMAW: "Special Marine Warning",
  AlertRBY: "Small Craft Adv (Rough Bar)",
  AlertRPS: "Rip Current Statement",
  AlertSBY: "Snow / Blowing Snow Advisory",
  AlertSCY: "Small Craft Advisory",
  AlertSEA: "Hazardous Seas Watch",
  AlertSEW: "Hazardous Seas Warning",
  AlertSIY: "Small Craft Adv (Winds)",
  AlertSMY: "Dense Smoke Advisory",
  AlertSNY: "Snow Advisory",
  AlertSRA: "Storm Watch",
  AlertSRW: "Storm Warning",
  AlertSUW: "High Surf Warning",
  AlertSUY: "High Surf Advisory",
  AlertSVA: "Severe Thunderstorm Watch",
  AlertSVW: "Severe Thunderstorm Warning",
  AlertSWY: "Small Craft Adv (Hazardous Seas)",
  AlertTIA: "Tropical Storm Wind Watch",
  AlertTIW: "Tropical Storm Wind Warning",
  AlertTOA: "Tornado Watch",
  AlertTOW: "Tornado Warning",
  AlertTRA: "Tropical Storm Watch",
  AlertTRW: "Tropical Storm Warning",
  AlertTSA: "Tsunami Watch",
  AlertTSW: "Tsunami Warning",
  AlertTSY: "Tsunami Advisory",
  AlertTYA: "Typhoon Watch",
  AlertTYW: "Typhoon Warning",
  AlertUPA: "Heavy Freezing Spray Watch",
  AlertUPW: "Heavy Freezing Spray Warning",
  AlertUPY: "Freezing Spray Advisory",
  AlertWCA: "Wind Chill Watch",
  AlertWCW: "Wind Chill Warning",
  AlertWCY: "Wind Chill Advisory",
  AlertWIY: "Wind Advisory",
  AlertWSA: "Winter Storm Watch",
  AlertWSW: "Winter Storm Warning",
  AlertWWY: "Winter Weather Advisory",
  AlertZFY: "Freezing Fog Advisory",
  AlertZRY: "Freezing Rain Advisory",
};

export default en;

import moment from "moment";
import { GetState, RootDispatch } from "..";
import {
  localStorageGet,
  LocalStorageKeys,
  localStorageSet,
} from "../../utils/localStorage";
import { getNotificationsPopupDate } from "../selectors/notifications";
import {
  NotificationsActionTypes,
  NOTIFICATIONS_POPUP_DATE_UPDATE,
  NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE,
} from "../types/notifications";

export const defaultNotificationsPopupStorage = {
  popupDate: "",
  popupLocationId: "",
};

export function notificationsPopupDateSet(
  notificationsPopupDate: number
): NotificationsActionTypes {
  return {
    type: NOTIFICATIONS_POPUP_DATE_UPDATE,
    notificationsPopupDate,
  };
}

export function notificationsPopupLocationIdSet(
  notificationsPopupLocationId: string
): NotificationsActionTypes {
  return {
    type: NOTIFICATIONS_POPUP_LOCATION_ID_UPDATE,
    notificationsPopupLocationId,
  };
}

export function notificationsPopupHide() {
  return async (dispatch: RootDispatch) => {
    const nextDate = +moment().add(1, "d");

    dispatch(notificationsPopupDateSet(nextDate));
    const notifications = localStorageGet(
      LocalStorageKeys.notificationsPopup,
      defaultNotificationsPopupStorage
    );
    try {
      localStorageSet(LocalStorageKeys.notificationsPopup, {
        popupDate: nextDate,
        popupLocationId: notifications.popupLocationId,
      });
    } catch (e) {
      console.error("notificationsPopupHide: ", e);
    }
  };
}

export function notificationsPopupLocationIdUpdate(locationId: string) {
  return async (dispatch: RootDispatch) => {
    if (!locationId) return;
    dispatch(notificationsPopupLocationIdSet(locationId));
    const nextDate = +moment().add(1, "d");
    const notifications = localStorageGet(
      LocalStorageKeys.notificationsPopup,
      defaultNotificationsPopupStorage
    );

    try {
      localStorageSet(LocalStorageKeys.notificationsPopup, {
        popupDate: nextDate,
        popupLocationId: notifications.popupLocationId,
      });
    } catch (e) {
      console.error("notificationsPopupLocationIdUpdate: ", e);
    }
  };
}

export function notificationsPopupRestore() {
  return (dispatch: RootDispatch, getState: GetState) => {
    if (getNotificationsPopupDate(getState())) return;

    const { popupDate, popupLocationId } = localStorageGet(
      LocalStorageKeys.notificationsPopup,
      defaultNotificationsPopupStorage
    );

    if (popupDate) dispatch(notificationsPopupDateSet(parseInt(popupDate)));
    if (popupLocationId)
      dispatch(notificationsPopupLocationIdSet(popupLocationId));
  };
}

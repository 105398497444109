import React from "react";
import { useHistory } from "react-router-dom";
import { getContinents } from "../../../types/continents";
import { getContinentUrl } from "../../../utils/url";
import { useSelector } from "react-redux";
import {
  getSelectedContinent,
  getSelectedLocationType,
} from "../../../store/selectors/locationSelector";
import DropdownAutocomplete, {
  Option,
} from "../../../components/DropdownAutocomplete";
import { Continent } from "../../../api/placesApi";
import WeatherSelectorTitle from "../WeatherSelectorTitle";
import { useLocale } from "../../../i18n";
import { LocaleMessage } from "../../../components/Locale";

const ContinentSelectorMobile: React.FC = () => {
  const locale = useLocale();
  const history = useHistory();
  const continents = getContinents(locale);

  const selected = useSelector(getSelectedContinent);
  const locationType = useSelector(getSelectedLocationType);

  const options = continents.map((x) => ({ value: x, label: x.name }));
  const selectedOption = selected
    ? options.find((x) => x.value === selected)
    : undefined;

  const handleChange = (option: Option<Continent>) => {
    if (!option) return;

    history.push(getContinentUrl(locale, locationType, option.value), {
      softRedirect: true,
    });
  };

  return (
    <div className="section d-lg-none">
      <WeatherSelectorTitle>
        <LocaleMessage id="WeatherSelectionContinents" />
      </WeatherSelectorTitle>

      <DropdownAutocomplete
        options={options}
        value={selectedOption}
        placeholder=""
        onChange={handleChange}
      />
    </div>
  );
};

export default ContinentSelectorMobile;

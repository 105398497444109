import React from "react";
import { StationTides, TideHiLoType } from "../../../api/weatherApi";
import styles from "./TideStationChart.module.scss";
import {
  TooltipProps,
  PresentationAttributes,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  AreaChart,
} from "recharts";
import { useSelector } from "../../../store/hooks";
import { getUnits } from "../../../store/selectors/units";
import { useFormatMessage } from "../../../i18n";
import ResponsiveContainerSsr from "../../../components/ResponsiveContainerSsr";
import {
  UnitKey,
  convertMeter,
  formatMeterConverted,
  getMeterUnit,
  formatMeterConvertedShort,
} from "../../../types/units";
import {
  LocaleMessage,
  formatTimeHHMM,
  LocaleTime,
  LocaleDate,
  LocaleDateOnly,
} from "../../../components/Locale";
import { useIntl } from "react-intl";
import { groupByDate } from "./utils";
import { getStartOfUTCDay } from "../../../types/time";
import Card from "../../../components/Card";

type ChartData = {
  time: number;
  level: number;
  type: TideHiLoType | undefined;
};

const ChartLegend: React.FC = () => {
  return (
    <ul className={styles.legend}>
      <li className={styles.tideHeight}>
        <LocaleMessage id="TideHeight" />
      </li>
    </ul>
  );
};

const ChartTooltip: React.FC<
  TooltipProps & { data: ChartData[]; adjustment: number }
> = ({ data, adjustment, active, label }) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();

  if (!active) return null;
  const bar = data.find((x) => x.time === label);
  if (!bar) return null;

  const time = new Date(bar.time);
  return (
    <div className={styles.tooltip}>
      <h3 className={styles.tooltipTime}>
        <LocaleTime value={time} />
      </h3>
      <div className={styles.tooltipDate}>
        <LocaleDate value={time} />
      </div>
      <ul className={styles.tooltipList}>
        <li className={styles.tideHeight}>
          <div className={styles.label}>
            <LocaleMessage id="TideHeight" />
          </div>
          <div className={styles.value}>
            {formatMeterConverted(formatMessage, bar.level - adjustment, units)}
          </div>
        </li>
      </ul>
    </div>
  );
};

function getChartData(data: StationTides, units: UnitKey) {
  const result = data.hourly
    .filter((x) => x.level !== undefined)
    .map<ChartData>(({ time, level }) => ({
      time: time.getTime(),
      level: convertMeter(level || 0, units),
      type: undefined,
    }));

  if (result.length) return result;

  const hilo = data.hilo
    .filter((x) => x.level !== undefined)
    .map<ChartData>(({ time, level, type }) => ({
      time: time.getTime(),
      level: convertMeter(level || 0, units),
      type,
    }));

  result.push(...hilo);

  result.sort((a, b) => a.time - b.time);

  return result;
}

function adjustChartData(data: ChartData[]): number {
  if (!data.length) return 0;
  const { min, max } = data.reduce(
    (p, x) => {
      if (x.level < p.min) p.min = x.level;
      if (x.level > p.max) p.max = x.level;
      return p;
    },
    { min: data[0].level, max: data[0].level }
  );

  const adjustment = (max - min) * 0.2 + (min < 0 ? -min : 0);

  //console.log("adj", data.length, min, max, adjustment);

  data.forEach((x) => {
    x.level += adjustment;
  });
  return adjustment;
}

type TideStationChartProps = {
  data: StationTides;
};

const ChartInner: React.FC<
  TideStationChartProps & { width: number; height: number }
> = ({ data, width, height }) => {
  const units = useSelector(getUnits);
  const intl = useIntl();
  const formatMessage = useFormatMessage();

  if (width <= 0 || height <= 0) return null;

  const dates = groupByDate(data.hilo)
    .slice(0, 3)
    .map((x) => getStartOfUTCDay(x.date));

  const chartData = getChartData(data, units);

  if (!chartData.length) return null;

  const adjustment = adjustChartData(chartData);

  const tick: Partial<PresentationAttributes<SVGTextElement>> = {
    fontSize: 14,
  };
  const axisLine: Partial<PresentationAttributes<SVGLineElement>> = {
    strokeDasharray: "9 6",
  };
  const tooltipCursor: Partial<PresentationAttributes<SVGElement>> = {
    stroke: "#3D4060",
    strokeOpacity: "0.5",
  };

  const yAxisWidth = 65;
  const gridWidth = width - yAxisWidth;
  const chartStart = chartData[0].time;
  const chartDateRange = chartData[chartData.length - 1].time - chartStart;

  const verticalPoints =
    dates.length === 3
      ? [
          (gridWidth * (dates[1].getTime() - chartStart)) / chartDateRange +
            yAxisWidth,
          (gridWidth * (dates[2].getTime() - chartStart)) / chartDateRange +
            yAxisWidth,
        ]
      : undefined;

  return (
    <>
      <AreaChart width={width} height={height} data={chartData}>
        <CartesianGrid
          stroke="#E8E8E8"
          strokeOpacity="0.6"
          verticalPoints={verticalPoints}
        />
        <XAxis
          dataKey="time"
          type="number"
          domain={["dataMin", "dataMax"]}
          // tickLine={false}
          axisLine={axisLine}
          // tickMargin={20}
          stroke="#C8C8C8"
          height={80}
          tick={tick}
          tickFormatter={(x) => formatTimeHHMM(intl, new Date(x))}
          tickCount={12}
          ticks={
            data.hilo.length
              ? data.hilo.map((x) => x.time.getTime())
              : undefined
          }

          //  padding={{ left: 20, right: 20 }}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLine}
          domain={[0, "auto"]}
          tickMargin={10}
          stroke="#C8C8C8"
          tickFormatter={(x) => formatMeterConvertedShort(x - adjustment)}
          padding={{ top: 80, bottom: 0 }}
          tick={tick}
          width={yAxisWidth}
          label={{
            fill: "#999",
            fontSize: 14,
            value: formatMessage("TideHeightAxis", {
              unit: getMeterUnit(units, formatMessage),
            }),
            position: "insideTopRight",
            dx: -10,
          }}
        />
        <Tooltip
          cursor={tooltipCursor}
          content={<ChartTooltip data={chartData} adjustment={adjustment} />}
        />
        <Legend content={<ChartLegend />} />
        <Area
          isAnimationActive={false}
          type={data.hourly.length ? "natural" : "monotone"}
          dataKey="level"
          stroke="#0066FA"
          fill="#0066FA"
          fillOpacity={0.1}
          strokeWidth={3}
          dot={false}
          activeDot={{ r: 8 }}
        />

        {verticalPoints && (
          <text
            stroke="none"
            height="60"
            width={width - yAxisWidth}
            y={height - 50}
            dy="0.72em"
            fill="#999999"
            fontSize="16"
            textAnchor="middle"
            style={{ textTransform: "uppercase" }}
          >
            <tspan x={(verticalPoints[0] + yAxisWidth) / 2}>
              <LocaleMessage id="Today" />, <LocaleDateOnly value={dates[0]} />
            </tspan>
            <tspan x={(verticalPoints[1] + verticalPoints[0]) / 2}>
              <LocaleDate value={dates[1]} />
            </tspan>
            <tspan x={(width + verticalPoints[1]) / 2}>
              <LocaleDate value={dates[2]} />
            </tspan>
          </text>
        )}
      </AreaChart>
    </>
  );
};

const TideStationChart: React.FC<TideStationChartProps> = ({ data }) => {
  return (
    <Card className="mt-40 d-none d-md-block">
      <ResponsiveContainerSsr
        aspect={2}
        width="100%"
        ssrWidth={1110}
        ssrHeight={555}
      >
        <ChartInner data={data} width={0} height={0} />
      </ResponsiveContainerSsr>
    </Card>
  );
};

export default TideStationChart;

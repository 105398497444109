import React, { useState } from "react";
import styles from "./SidebarMenu.module.scss";

export type Option = { value: string; label: string };

type MenuSelectorProps = {
  title: React.ReactNode;
  img: string;
  options: Option[];
  active: string;
  setActive: (value: string) => void;
};

const MenuSelector: React.FC<MenuSelectorProps> = ({
  title,
  img,
  options,
  active,
  setActive,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${styles.menuSelector} ${open ? styles.open : ""}`}>
      <button className={styles.selectButton} onClick={() => setOpen(!open)}>
        <img className={styles.icon} src={img} alt="" />
        {title}
      </button>
      <ul className={styles.options}>
        {options.map(({ value, label }) => {
          return (
            <li
              key={value}
              className={active === value ? styles.active : ""}
              onClick={() => {
                setActive(value);
                setOpen(false);
              }}
            >
              {label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuSelector;

import React, { useEffect } from "react";
import PlaceList from "../PlaceList";
import { useSelector, useDispatch } from "../../store/hooks";
import {
  getWeatherType,
  getWeatherLocation,
  getWeatherLocationNearbyCityIds,
} from "../../store/selectors/weather";
import { tryLoadDailyShort } from "../../store/actions/dailyShort";
import { getLocationRegion } from "../../store/selectors/locations";
import { getRegionCountry } from "../../store/selectors/regions";
import { getCountryContinent } from "../../store/selectors/countries";
import { Link } from "react-router-dom";
import { getRegionUrl } from "../../utils/url";
import styles from "./NearbyCitiesAndTowns.module.scss";
import { useLocale } from "../../i18n";
import { LocaleMessage } from "../Locale";
import { autoRefreshDailyShort } from "../../utils/cache";

const GoToPlaces: React.FC = () => {
  const location = useSelector(getWeatherLocation);
  const region = useSelector((x) => getLocationRegion(x, location));
  const country = useSelector((x) => getRegionCountry(x, region));
  const continent = useSelector((x) => getCountryContinent(x, country));
  const locale = useLocale();

  if (!continent || !country || !region || !location) return null;

  return (
    <div className="d-md-none">
      <Link
        className={styles.goToPlaces}
        to={getRegionUrl(locale, location.type, continent, country, region)}
      >
        <LocaleMessage id="GoToPlacesPage" />
      </Link>
    </div>
  );
};

const NearbyCitiesAndTowns: React.FC = () => {
  const weatherType = useSelector(getWeatherType);
  const nearbyIds = useSelector(getWeatherLocationNearbyCityIds);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!nearbyIds.length) return;
    //console.log("NearbyCitiesAndTowns: ", nearbyIds.length);
    return autoRefreshDailyShort(() => dispatch(tryLoadDailyShort(nearbyIds)));
  }, [dispatch, nearbyIds]);

  if (!nearbyIds.length) return null;

  return (
    <div className="container section">
      <h3 className="title title-solo">
        <LocaleMessage id="NearbyCitiesAndTowns" />
      </h3>
      <PlaceList locationIds={nearbyIds} weatherType={weatherType} />
      <GoToPlaces />
    </div>
  );
};

export default NearbyCitiesAndTowns;

import React from "react";
import DesktopHeader from "./Desktop";
import MobileHeader from "./Mobile";
import LoginDialog from "./LoginDialog";

const Header: React.FC = () => {
  return (
    <header>
      <DesktopHeader />
      <MobileHeader />
      <LoginDialog />
    </header>
  );
};

export default Header;

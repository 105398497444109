import React from "react";
import { useSelector } from "../../store/hooks";
import {
  getWeatherType,
  getSelectedDailyForecast,
} from "../../store/selectors/weather";
import { useLocale } from "../../i18n";
import {
  formatWeatherType,
  isTodayWeatherType,
  WeatherType,
} from "../../types/weatherType";
import { LocaleDate } from "../Locale";

const PlaceDate: React.FC = () => {
  const weatherType = useSelector(getWeatherType);
  const locale = useLocale();
  const dailyForecast = useSelector(getSelectedDailyForecast);
  return (
    <>
      {formatWeatherType(
        locale,
        isTodayWeatherType(weatherType) ? WeatherType.Today : weatherType
      )}
      {!!dailyForecast && (
        <>
          : <LocaleDate value={dailyForecast.date} />
        </>
      )}
    </>
  );
};

export default PlaceDate;

import React from "react";
import styles from "./AlertsNotifications.module.scss";
import iconCheck from "../../../assets/icons/i-check.svg";
import { formatPrecip, formatPrecipRange, UnitKey } from "../../../types/units";

export enum PrecipitationIntencityTypes {
  Light = "Light",
  Moderate = "Moderate",
  Heavy = "Heavy",
  Violent = "Violent",
}

type PrecipitationIntensitySelectorProps = {
  intensity: (number | undefined | string)[] | undefined;
  onChange: (types: (number | string | undefined)[]) => void;
  units: UnitKey;
  disabled?: boolean;
};

const PrecipitationIntensitySelector: React.FC<PrecipitationIntensitySelectorProps> =
  ({ intensity, onChange, units, disabled }) => {
    const light =
      !!intensity && intensity.includes(PrecipitationIntencityTypes.Light);
    const moderate =
      !!intensity && intensity.includes(PrecipitationIntencityTypes.Moderate);
    const heavy =
      !!intensity && intensity.includes(PrecipitationIntencityTypes.Heavy);
    const violent =
      !!intensity && intensity.includes(PrecipitationIntencityTypes.Violent);

    return (
      <div className={styles.precipitationIntensity}>
        <div
          className={`${styles.precipitationIntensityCard} ${
            light ? styles.precipitationIntensityCardSelected : ""
          }`}
          onClick={() => {
            if (disabled) return;
            const checked = !light;
            const values = intensity ? [...intensity] : [];
            const newValues = checked
              ? [...values, PrecipitationIntencityTypes.Light]
              : values.filter((x) => x !== PrecipitationIntencityTypes.Light);
            onChange(newValues);
          }}
        >
          <h3 className={styles.precipitationIntensityTitle}>
            {PrecipitationIntencityTypes.Light}
            {light && <img src={iconCheck} alt="check icon" />}
          </h3>
          <p className={styles.precipIntensityValue}>
            &#60; {formatPrecip(2.5, units)}
          </p>
          <div className={styles.precipitationIntensityIndicator}></div>
        </div>

        <div
          className={`${styles.precipitationIntensityCard} ${
            moderate ? styles.precipitationIntensityCardSelected : ""
          }`}
          onClick={() => {
            if (disabled) return;
            const checked = !moderate;
            const values = intensity ? [...intensity] : [];
            const newValues = checked
              ? [...values, PrecipitationIntencityTypes.Moderate]
              : values.filter(
                  (x) => x !== PrecipitationIntencityTypes.Moderate
                );
            onChange(newValues);
          }}
        >
          <h3 className={styles.precipitationIntensityTitle}>
            {PrecipitationIntencityTypes.Moderate}
            {moderate && <img src={iconCheck} alt="check icon" />}
          </h3>
          <p className={styles.precipIntensityValue}>
            {formatPrecipRange(2.6, 7.5, units)}
          </p>
          <div className={styles.precipitationIntensityIndicator}></div>
        </div>

        <div
          className={`${styles.precipitationIntensityCard}  ${
            heavy ? styles.precipitationIntensityCardSelected : ""
          }`}
          onClick={() => {
            if (disabled) return;
            const checked = !heavy;
            const values = intensity ? [...intensity] : [];
            const newValues = checked
              ? [...values, PrecipitationIntencityTypes.Heavy]
              : values.filter((x) => x !== PrecipitationIntencityTypes.Heavy);
            onChange(newValues);
          }}
        >
          <h3 className={styles.precipitationIntensityTitle}>
            {PrecipitationIntencityTypes.Heavy}
            {heavy && <img src={iconCheck} alt="check icon" />}
          </h3>
          <p className={styles.precipIntensityValue}>
            {formatPrecipRange(7.6, 50, units)}
          </p>
          <div className={styles.precipitationIntensityIndicator}></div>
        </div>

        <div
          className={`${styles.precipitationIntensityCard}  ${
            violent ? styles.precipitationIntensityCardSelected : ""
          }`}
          onClick={() => {
            if (disabled) return;
            const checked = !violent;
            const values = intensity ? [...intensity] : [];
            const newValues = checked
              ? [...values, PrecipitationIntencityTypes.Violent]
              : values.filter((x) => x !== PrecipitationIntencityTypes.Violent);
            onChange(newValues);
          }}
        >
          <h3 className={styles.precipitationIntensityTitle}>
            {PrecipitationIntencityTypes.Violent}
            {violent && <img src={iconCheck} alt="check icon" />}
          </h3>
          <p className={styles.precipIntensityValue}>
            &#62; {formatPrecip(50, units)}
          </p>
          <div className={styles.precipitationIntensityIndicator}></div>
        </div>
      </div>
    );
  };

export default PrecipitationIntensitySelector;

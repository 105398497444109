import React from "react";
import styles from "./TabSelector.module.scss";
import { MessageKey } from "../../../i18n";
import { LocaleMessage } from "../../Locale";

type Props = {
  options: MessageKey[];
  selected: MessageKey;
  onSelect: (tab: MessageKey) => void;
};

export const TabSelector: React.FC<Props> = ({
  options,
  selected,
  onSelect,
}) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onSelect) return;
    const newSelected = e.currentTarget.value;

    onSelect(newSelected as MessageKey);
  };

  return (
    <div className={styles.selectorWrap}>
      {options.map((x, i) => (
        <button
          className={`${styles.button} ${selected === x && styles.active}`}
          key={x}
          value={x}
          onClick={handleClick}
        >
          <LocaleMessage id={x} />
        </button>
      ))}
    </div>
  );
};

import {
  ErrorsActionTypes,
  ErrorMsg,
  ERRORS_ADD,
  ERRORS_REMOVE
} from "../types/errors";

export type ErrorsState = ErrorMsg[];

const initialState: ErrorsState = [];

export default function reducer(
  state: ErrorsState = initialState,
  action: ErrorsActionTypes
): ErrorsState {
  switch (action.type) {
    case ERRORS_ADD:
      return [...state, action.error];
    case ERRORS_REMOVE:
      return state.filter(x => x !== action.error);

    default:
      return state;
  }
}

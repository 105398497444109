import { LocaleCode, getLocaleMessages } from "../i18n";
import { Location } from "../api/placesApi";

export enum WeatherType {
  Today = 0,
  Tomorrow = 1,
  Days10 = 10,
  Radar = 100,
  Tides = 150,
}

const weatherTypesAll: WeatherType[] = [
  WeatherType.Today,
  WeatherType.Tomorrow,
  WeatherType.Days10,
  WeatherType.Radar,
  WeatherType.Tides,
];

const weatherTypesNoTides = weatherTypesAll.filter(
  (x) => x !== WeatherType.Tides
);

export function getWeatherTypes(location: Location | undefined) {
  if (!location) return weatherTypesNoTides;
  return location.tides ? weatherTypesAll : weatherTypesNoTides;
}

export function formatWeatherType(
  locale: LocaleCode,
  weatherType: WeatherType
) {
  const messages = getLocaleMessages(locale);
  return messages["WeatherType" + WeatherType[weatherType]];
}

export function getWeatherTypeDaysCount(weatherType: WeatherType) {
  switch (weatherType) {
    case WeatherType.Days10:
      return 10;
    default:
      return 1;
  }
}

export function isTodayWeatherType(weatherType: WeatherType) {
  return (
    weatherType === WeatherType.Today ||
    weatherType === WeatherType.Radar ||
    weatherType === WeatherType.Tides
  );
}

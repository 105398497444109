import React from "react";
import styles from "./LocationName.module.scss";
import { useSelector } from "../../store/hooks";
import { getWeatherLocationNameWithShortRegion } from "../../store/selectors/weather";

const LocationName: React.FC = () => {
  const cityName = useSelector(getWeatherLocationNameWithShortRegion);
  return <span className={styles.name}>{cityName}</span>;
};

export default LocationName;

import React from "react";
import Card from "../../../components/Card";
import { timeMinutesDuration } from "../../../types/time";
import CardTitle from "../../../components/Card/CardTitle";
import styles from "./SunAndMoonCard.module.scss";
import sunriseImg from "../../../assets/sunrise.svg";
import sunsetImg from "../../../assets/sunset.svg";
import { formatPercent } from "../../../types/units";
import { getPlaceMoonPhase, getMoonPhaseName } from "../../../types/moon";
import { getMoonPhaseImg } from "./moonPhaseImg";
import { useSelector } from "../../../store/hooks";
import {
  getWeatherLocation,
  getSelectedDailyForecast
} from "../../../store/selectors/weather";
import {
  LocaleMessage,
  LocaleTime,
  formatMinutesDuration
} from "../../../components/Locale";
import PlaceDate from "../../../components/PlaceDate";
import { useFormatMessage } from "../../../i18n";

const SunAndMoonCard: React.FC = () => {
  const location = useSelector(getWeatherLocation);
  const dailyForecast = useSelector(getSelectedDailyForecast);
  const formatMessage = useFormatMessage();

  if (!location || !dailyForecast) return null;

  const {
    sunrise,
    sunset,
    moonrise,
    moonset,
    sunMinutes,
    moonIllumination
  } = dailyForecast;

  const moonPhase = getPlaceMoonPhase(dailyForecast);

  const moonPhaseName = getMoonPhaseName(formatMessage, moonPhase);

  const moonPhaseImg = getMoonPhaseImg(moonPhase, location.lat);

  const visibleDuration =
    sunrise && sunset ? timeMinutesDuration(sunset, sunrise) : sunMinutes;

  return (
    <div className="container section">
      <div className="d-md-none">
        <CardTitle
          title={<LocaleMessage id="SunAndMoon" />}
          subTitle={<PlaceDate />}
        />
      </div>
      <Card>
        <div className="d-none d-md-block">
          <CardTitle
            title={<LocaleMessage id="SunAndMoon" />}
            subTitle={<PlaceDate />}
          />
        </div>
        <div className={styles.data}>
          <div className={styles.sunriseSunset}>
            <div className={styles.sunrise}>
              <img src={sunriseImg} className={styles.sunImg} alt="" />
              <div>
                <div className={styles.sunName}>
                  <LocaleMessage id="Sunrise" />
                </div>
                <div className={styles.sunTime}>
                  {sunrise ? <LocaleTime value={sunrise} /> : "-"}
                </div>
              </div>
            </div>
            <div className={styles.sunset}>
              <img src={sunsetImg} className={styles.sunImg} alt="" />
              <div>
                <div className={styles.sunName}>
                  <LocaleMessage id="Sunset" />
                </div>
                <div className={styles.sunTime}>
                  {sunset ? <LocaleTime value={sunset} /> : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.lengthsMoon}>
            <div className={styles.lengths}>
              <div className={styles.lengthRow}>
                <div className={styles.lengthLabel}>
                  <LocaleMessage id="LengthOfVisibleLight" />
                </div>
                <div className={styles.lengthValue}>
                  {formatMinutesDuration(formatMessage, visibleDuration)}
                </div>
              </div>
              <div className={styles.lengthRow}>
                <div className={styles.lengthLabel}>
                  <LocaleMessage id="LengthOfDay" />
                </div>
                <div className={styles.lengthValue}>
                  {formatMinutesDuration(formatMessage, sunMinutes)}
                </div>
              </div>
            </div>
            <div className={styles.moon}>
              <img
                alt={moonPhaseName}
                src={moonPhaseImg}
                className={styles.moonImg}
              />
              <div>
                <div className={styles.moonName}>
                  {moonPhaseName}
                  {typeof moonIllumination === "number" &&
                    ` (${formatPercent(moonIllumination)})`}
                </div>
                <div className={styles.moonWrap}>
                  <div className={styles.moonLabel}>
                    <LocaleMessage id="Moonrise" /> -{" "}
                    <span className={styles.moonValue}>
                      {moonrise ? <LocaleTime value={moonrise} /> : ""},{" "}
                    </span>
                  </div>
                  <div className={styles.moonLabel}>
                    <LocaleMessage id="Moonset" /> -{" "}
                    <span className={styles.moonValue}>
                      {moonset ? <LocaleTime value={moonset} /> : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SunAndMoonCard;

import { Continent, ContinentKey } from "../api/placesApi";
import { LocaleCode, getLocaleMessages } from "../i18n";
import { toMap } from "../utils/array";

const continentLongCodes: { [key in ContinentKey]: string } = {
  af: "africa",
  an: "antarctica",
  as: "asia",
  eu: "europe",
  na: "north-america",
  sa: "south-america",
  oc: "oceania",
};

type ContinentLocale = {
  continents: Continent[];
  byCode: { [key: string]: Continent };
};

const continentsLocale: { [key in LocaleCode]?: ContinentLocale } = {};

function getContinentLocale(locale: LocaleCode): ContinentLocale {
  let result = continentsLocale[locale];
  if (result) return result;
  const messages = getLocaleMessages(locale);
  const continents = Object.entries(continentLongCodes).map<Continent>(
    ([_id, longCode]) => ({
      _id: _id as ContinentKey,
      longCode,
      name: messages["Continent" + _id.toUpperCase()],
    })
  );
  const byCode = {
    ...toMap(continents, (x) => x._id),
    ...toMap(continents, (x) => x.longCode),
  };
  result = { continents, byCode };
  continentsLocale[locale] = result;
  return result;
}

export function getContinents(locale: LocaleCode): Continent[] {
  return getContinentLocale(locale).continents;
}

export function getContinent(
  locale: LocaleCode,
  code: string
): Continent | undefined {
  const { byCode } = getContinentLocale(locale);
  return byCode[code || ""];
}

import React from "react";
import styles from "./PlaceTime.module.scss";
import { SelectedDayWeather } from "../../scenes/WeatherPage/DayWeatherHead";
import { HourlyForecast, DailyForecast } from "../../api/weatherApi";
import { useSelector } from "../../store/hooks";
import { getWeatherType } from "../../store/selectors/weather";
import { formatWeatherType } from "../../types/weatherType";
import { useLocale } from "../../i18n";
import { LocaleTime, LocaleDate } from "../Locale";

type PlaceTimeProps = {
  weather: SelectedDayWeather | undefined;
  time?: Date;
};

function getPlaceTime(weather: SelectedDayWeather, time?: Date) {
  const weatherTime = time || (weather as HourlyForecast).time;
  const weatherDate = (weather as DailyForecast).date;

  const placeNow = weatherTime || weatherDate;

  const date = <LocaleDate value={placeNow} />;

  if (typeof weatherTime !== "undefined") {
    const time = <LocaleTime value={weatherTime} />;
    return (
      <>
        {time} / {date}
      </>
    );
  }

  return date;
}

const PlaceTime: React.FC<PlaceTimeProps> = ({ weather, time }) => {
  const weatherType = useSelector(getWeatherType);
  const locale = useLocale();

  return (
    <div className={styles.time}>
      {weather
        ? getPlaceTime(weather, time)
        : formatWeatherType(locale, weatherType)}
    </div>
  );
};

export default PlaceTime;

import React, { useEffect } from "react";
import { LocaleMessage } from "../../../components/Locale";
import styles from "./CurrentLocation.module.scss";
import iconAlert from "../../../assets/icons/i-alert.svg";
import iconLocation from "../../../assets/icons/i-location.svg";
import { MessageKey } from "../../../i18n";
import { TabSelector } from "../../../components/Controls/TabSelector";
import { LocationChip } from "../LocationChip";
import { UserProfile } from "../../../api/authApi";
import { useDispatch, useSelector } from "../../../store/hooks";
import { getHomeLocationId } from "../../../store/selectors/locations";
import LocationsSearch from "../Search";
import {
  homeLocationSet,
  tryLoadLocationsByIds,
} from "../../../store/actions/locations";
import { Card, CardSection, CardSectionTitle, CardTitle } from "../Card/Card";
import { isMobile } from "../../../utils/browser";
import { Radio } from "../../../components/Controls";
import { loadGeolocation } from "../../../store/actions/search";

type CurrentLocationProps = {
  userProfile: UserProfile;
  updateUser: (userProfile: Partial<UserProfile>) => void;
};

const locationModeOptions: MessageKey[] = [
  "DetectAutomatically",
  "SelectManually",
];

const CurrentLocation: React.FC<CurrentLocationProps> = ({
  userProfile,
  updateUser,
}) => {
  const dispatch = useDispatch();
  const homeLocationId = useSelector(getHomeLocationId);

  useEffect(() => {
    if (!userProfile.locationId) return;
    dispatch(tryLoadLocationsByIds([userProfile.locationId]));
  }, [dispatch, userProfile.locationId]);

  const handleLocationModeSelect = async (tab: MessageKey) => {
    if (tab === "DetectAutomatically") {
      const location = await dispatch(loadGeolocation());

      if (location) dispatch(homeLocationSet(location._id));
      updateUser({
        locationId: location ? location._id : undefined,
        locationSelector: "auto",
      });
    } else {
      updateUser({ locationSelector: "manual" });
    }
  };

  const handleLinkClick = (locationId: string) => {
    dispatch(homeLocationSet(locationId));
    updateUser({ locationId });
  };

  const handleLocationRadioChange = async (label: string) => {
    if (label.includes("Detect automatically")) {
      const location = await dispatch(loadGeolocation());

      if (location) {
        dispatch(homeLocationSet(location._id));
        updateUser({
          locationId: location ? location._id : undefined,
          locationSelector: "auto",
        });
        return;
      }
    }
    updateUser({ locationSelector: "manual" });
  };

  return (
    <Card>
      <CardTitle title={<LocaleMessage id="CurrentLocation" />} />
      {isMobile() ? (
        <div className={styles.radioLocation}>
          <Radio
            checked={userProfile.locationSelector === "auto"}
            name="airAlerts"
            label="Detect automatically"
            onChange={handleLocationRadioChange}
          />
          <Radio
            checked={userProfile.locationSelector !== "auto"}
            name="airAlerts"
            label="Select manually"
            onChange={handleLocationRadioChange}
          />
        </div>
      ) : (
        <TabSelector
          options={locationModeOptions}
          selected={
            userProfile.locationSelector === "auto"
              ? "DetectAutomatically"
              : "SelectManually"
          }
          onSelect={handleLocationModeSelect}
        />
      )}

      {userProfile.locationSelector !== "auto" && (
        <CardSection className={styles.searchSetion}>
          <LocationsSearch
            onLinkClick={handleLinkClick}
            label="Set manual location"
            disabled={userProfile.locationSelector === "auto"}
            placeholder="Search for a location..."
          />

          <div className={styles.alert}>
            <img
              src={iconAlert}
              alt="alert icon"
              className={styles.alertIcon}
            />
            <p className={styles.alertText}>
              <LocaleMessage id="AlertAccountSettingsManualLocation" />
            </p>
          </div>
        </CardSection>
      )}
      <CardSection className={styles.searchSetion}>
        <div className={styles.currentLocationWrap}>
          <CardSectionTitle
            title={`Current ${
              userProfile.locationSelector === "auto" ? "automatic" : "manual"
            } location`}
          />
          <LocationChip
            locationId={userProfile.locationId || homeLocationId}
            icon={<img src={iconLocation} alt="location icon" />}
          />
        </div>
      </CardSection>
    </Card>
  );
};

export default CurrentLocation;

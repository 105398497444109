import { Region, LocationType } from "../../api/placesApi";

export const REGIONS_BY_COUNTRY_LOADING = "REGIONS_BY_COUNTRY_LOADING";
export const REGIONS_BY_COUNTRY_LOADED = "REGIONS_BY_COUNTRY_LOADED";
export const REGIONS_LOADED = "REGIONS_LOADED";

type RegionsByCountryLoadingAction = {
  type: typeof REGIONS_BY_COUNTRY_LOADING;
  locationType: LocationType;
  countryId: string;
};

type RegionsByCountryLoadedAction = {
  type: typeof REGIONS_BY_COUNTRY_LOADED;
  locationType: LocationType;
  countryId: string;
  regionIds: string[];
};

type RegionsLoadedAction = {
  type: typeof REGIONS_LOADED;
  regions: Region[];
};

export type RegionsActionTypes =
  | RegionsByCountryLoadingAction
  | RegionsByCountryLoadedAction
  | RegionsLoadedAction;

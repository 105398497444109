import React from "react";
import { Link } from "react-router-dom";
import styles from "./PlaceList.module.scss";
import { getLocationWeatherUrl } from "../../utils/url";
import WeatherCondition from "../WeatherCondition";
import { WeatherType } from "../../types/weatherType";
import { formatTempShort } from "../../types/units";
import { useSelector } from "../../store/hooks";
import { getUnits } from "../../store/selectors/units";
import { getDailyForecastShort } from "../../store/selectors/dailyShort";
import {
  getLocationRegion,
  getLocationById
} from "../../store/selectors/locations";
import { getRegionCountry } from "../../store/selectors/regions";
import { getCountryContinent } from "../../store/selectors/countries";
import { useLocale } from "../../i18n";

type WeatherShortCardProps = {
  locationId: string;
  weatherType: WeatherType;
};

const WeatherShortCard: React.FC<WeatherShortCardProps> = ({
  locationId,
  weatherType
}) => {
  const location = useSelector(x => getLocationById(x, locationId));
  const locale = useLocale();
  const region = useSelector(x => getLocationRegion(x, location));
  const country = useSelector(x => getRegionCountry(x, region));
  const continent = useSelector(x => getCountryContinent(x, country));

  const units = useSelector(getUnits);
  const forecast = useSelector(x =>
    getDailyForecastShort(x, locationId, weatherType)
  );
  if (!location) return null;

  const { name } = location;

  let weatherDiv: JSX.Element | null = null;
  if (forecast) {
    const { tempMax, tempMin, weatherCode } = forecast;

    weatherDiv = (
      <div className={styles.weather}>
        <WeatherCondition
          weatherCode={weatherCode}
          day
          weatherDesc=""
          className={styles.condition}
        />
        <div className={styles.temp}>
          {formatTempShort(tempMax, units)}

          <span className={styles.tempMin}>
            {" "}
            / {formatTempShort(tempMin, units)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cardWrap}>
      <Link
        to={getLocationWeatherUrl(locale, continent, country, region, location)}
        className={styles.card}
      >
        <div className={styles.nameRegion}>
          <div className={styles.name}>{name}</div>
          <div className={styles.region}>
            {region ? region.name : "?"}, {country ? country.name : "?"}
          </div>
        </div>

        {weatherDiv}
      </Link>
    </div>
  );
};

export default WeatherShortCard;

import React from "react";
import Helmet from "react-helmet";
import { useFormatMessage, MessageKey } from "../../i18n";
import { useSelector } from "../../store/hooks";
import {
  getWeatherLocationNameWithShortRegion,
  getWeatherType,
} from "../../store/selectors/weather";
import { WeatherType } from "../../types/weatherType";

const WeatherPageTitle: React.FC = () => {
  const formatMessage = useFormatMessage();
  const name = useSelector(getWeatherLocationNameWithShortRegion);
  const weatherType = useSelector(getWeatherType);

  const title = formatMessage(
    ("WeatherTitle" + WeatherType[weatherType]) as MessageKey,
    {
      name,
    }
  );

  const desc = formatMessage(
    ("WeatherDesc" + WeatherType[weatherType]) as MessageKey,
    {
      name,
    }
  );

  return (
    <Helmet title={title}>
      <meta name="description" content={desc} />
    </Helmet>
  );
};

export default WeatherPageTitle;

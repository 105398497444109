import React, { ReactNode, useState } from "react";
import Card from "../../../components/Card";
import CardTitle from "../../../components/Card/CardTitle";
import { HourlyForecast } from "../../../api/weatherApi";
import styles from "./DetailedHourlyForecast.module.scss";
import {
  formatTempShort,
  UnitKey,
  formatWindDirectionShort,
  formatSpeed,
  formatPercent,
  formatDistance,
  formatUvIndex,
  formatPressure,
  formatRainfall,
} from "../../../types/units";
import { getWeatherDescription } from "../../../types/weatherCodes";
import RainChance from "../../../components/RainChance";
import TempConditionChart, {
  TempConditionPoint,
} from "../../../components/TempConditionChart";
import { useSelector } from "../../../store/hooks";
import {
  getWeatherType,
  getSelectedHoursOrNext24By3,
} from "../../../store/selectors/weather";
import { getUnits } from "../../../store/selectors/units";
import { isTodayWeatherType } from "../../../types/weatherType";
import LocationName from "../../../components/LocationName";
import { MessageKey, useFormatMessage } from "../../../i18n";
import { LocaleMessage, LocaleTime } from "../../../components/Locale";
import PlaceDate from "../../../components/PlaceDate";

type DetailedHourlyForecastTableProps = {
  hourly: HourlyForecast[];
  units: UnitKey;
};

const DetailedHourlyForecastTable: React.FC<DetailedHourlyForecastTableProps> = ({
  hourly,
  units,
}) => {
  const formatMessage = useFormatMessage();
  const [selected, setSelected] = useState(-1);
  const renderRow = (
    header: MessageKey | "",
    item: (hour: HourlyForecast, i: number) => ReactNode,
    className: string = styles.hourItem
  ) => {
    return (
      <div className={styles.row}>
        <div className={`${styles.col} ${header ? styles.headerItem : ""}`}>
          {header ? <LocaleMessage id={header as MessageKey} /> : ""}
        </div>

        {hourly.map((x, i) => {
          return (
            <div
              key={x.time.getTime()}
              className={`${styles.col} ${className} ${
                selected === i ? styles.selected : ""
              }`}
              onMouseEnter={() => setSelected(i)}
              onMouseLeave={() => setSelected(-1)}
            >
              {item(x, i)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderRow("", () => null, styles.tempConditionDummy)}
      {renderRow(
        "",
        ({ time }) => (
          <LocaleTime value={time} />
        ),
        styles.time
      )}
      {renderRow("", () => null, styles.splitter)}
      {renderRow(
        "",
        ({ rainChance }) => (
          <RainChance className={styles.rain} rainChance={rainChance} />
        ),
        styles.rain
      )}
      {renderRow(
        "",
        ({ weatherCode, weatherDesc }) =>
          getWeatherDescription(formatMessage, weatherCode, weatherDesc),
        styles.condition
      )}
      {renderRow("FeelsLike", ({ tempFeels }) =>
        formatTempShort(tempFeels, units)
      )}
      {renderRow("Wind", ({ windDir, windSpeed }) => (
        <>
          {formatWindDirectionShort(formatMessage, windDir)}{" "}
          {formatSpeed(formatMessage, windSpeed, units)}
        </>
      ))}
      {renderRow("Humidity", ({ humidity }) => formatPercent(humidity))}
      {renderRow("Visibility", ({ visibility }) =>
        formatDistance(formatMessage, visibility, units)
      )}
      {renderRow("UVIndex", ({ uvIndex }) =>
        formatUvIndex(formatMessage, uvIndex)
      )}
      {renderRow("DewPt", ({ dewPoint }) => formatTempShort(dewPoint, units))}
      {renderRow("Pressure", ({ pressure }) =>
        formatPressure(formatMessage, pressure)
      )}
      {/* New fields */}
      {renderRow("WetBulbTemp", ({ wetBulbTemp }) =>
        formatTempShort(wetBulbTemp, units)
      )}
      {renderRow("WindGust", ({ windGustSpeed }) =>
        formatSpeed(formatMessage, windGustSpeed, units)
      )}
      {renderRow("CloudCeiling", ({ ceiling }) =>
        formatDistance(formatMessage, ceiling, units)
      )}
      {renderRow("CloudCover", ({ cloudCover }) => formatPercent(cloudCover))}
      {renderRow("PrecipitationProbability", ({ precipChance: percipChance }) =>
        formatPercent(percipChance)
      )}
      {renderRow("SnowProbability", ({ snowChance }) =>
        formatPercent(snowChance)
      )}
      {renderRow("IceProbability", ({ iceChance }) => formatPercent(iceChance))}
      {renderRow("TotalLiquid", ({ totalLiquid }) =>
        formatRainfall(formatMessage, totalLiquid, units)
      )}
      {renderRow("Rain", ({ rain }) =>
        formatRainfall(formatMessage, rain, units)
      )}
      {renderRow("Snow", ({ snow }) =>
        formatRainfall(formatMessage, snow, units)
      )}
      {renderRow("Ice", ({ ice }) => formatRainfall(formatMessage, ice, units))}
    </>
  );
};

const DetailedHourlyForecast: React.FC = () => {
  const units = useSelector(getUnits);
  const weatherType = useSelector(getWeatherType);

  const hourly = useSelector(getSelectedHoursOrNext24By3);
  if (!hourly.length) return null;

  //console.log("Render", hourly);

  const chartData = hourly.map<TempConditionPoint>(
    ({ temp, weatherCode, weatherDesc, day }) => ({
      temp,
      weatherCode,
      weatherDesc,
      day,
    })
  );

  return (
    <div className="container section d-none d-md-block">
      <Card>
        <CardTitle
          title={
            <LocaleMessage
              id={
                isTodayWeatherType(weatherType)
                  ? "DetailedHourlyForecastTitleToday"
                  : "DetailedHourlyForecastTitle"
              }
              values={{ name: <LocationName /> }}
            />
          }
          subTitle={
            isTodayWeatherType(weatherType) ? (
              <LocaleMessage id="Next24Hours" />
            ) : (
              <PlaceDate />
            )
          }
        />
        <div className={styles.grid}>
          <div className={styles.chartWrap}>
            <TempConditionChart
              baseId="detailedHourly_tempCond"
              data={chartData}
              ssrWidth={986}
              height={Number.parseFloat(styles.chartHeight)}
              stroke="#0066FA"
              count={8}
              dot={{ fill: "#fff" }}
              renderTemp={({ point: { temp } }) => (
                <tspan className={styles.temp}>
                  {formatTempShort(temp, units)}
                </tspan>
              )}
            />
          </div>
          <DetailedHourlyForecastTable hourly={hourly} units={units} />
        </div>
      </Card>
    </div>
  );
};

export default DetailedHourlyForecast;

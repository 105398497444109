import React from "react";
import { getWeatherTypeDaysCount } from "../../../types/weatherType";
import { useSelector } from "react-redux";
import {
  getWeatherType,
  getSelectedDailyForecasts
} from "../../../store/selectors/weather";
import DailyForecastHorizontal from "../DailyForecastHorizontal";

type DaysForecastHorizontalProps = {
  offset: number;
};

const DaysForecastHorizontal: React.FC<DaysForecastHorizontalProps> = ({
  offset
}) => {
  const daily = useSelector(getSelectedDailyForecasts);
  const weatherType = useSelector(getWeatherType);
  const days = daily.slice(
    offset,
    offset + getWeatherTypeDaysCount(weatherType)
  );
  const today = daily[0];

  return (
    <div className="mt-30">
      <DailyForecastHorizontal days={days} today={today} />
    </div>
  );
};

export default DaysForecastHorizontal;

import {
  COUNTRIES_BY_CONTINENT_LOADING,
  COUNTRIES_BY_CONTINENT_LOADED,
  COUNTRIES_LOADED,
  CountriesActionTypes,
} from "../types/countries";
import {
  fetchCountriesByContinent,
  Country,
  LocationType,
} from "../../api/placesApi";
import { errorsAdd } from "./errors";
import { areCountriesByContinentLoaded } from "../selectors/countries";
import { RootDispatch, GetState } from "..";
import { getLocale } from "../selectors/locale";

export function countriesLoaded(countries: Country[]): CountriesActionTypes {
  return { type: COUNTRIES_LOADED, countries };
}

function loadCountriesByContinent(
  locationType: LocationType,
  continentId: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!continentId) return;
    dispatch({
      type: COUNTRIES_BY_CONTINENT_LOADING,
      locationType,
      continentId,
    });

    try {
      const lang = getLocale(getState());
      const countries = await fetchCountriesByContinent(
        locationType,
        continentId,
        lang
      );

      dispatch({ type: COUNTRIES_LOADED, countries });
      dispatch({
        type: COUNTRIES_BY_CONTINENT_LOADED,
        locationType,
        continentId,
        countryIds: countries.map((x) => x._id),
      });
    } catch (e) {
      console.error(e);

      dispatch(errorsAdd(e.message));
    }
  };
}

export function tryLoadCountriesByContinent(
  locationType: LocationType,
  continentId: string
) {
  return async (dispatch: RootDispatch, getState: GetState) => {
    if (!continentId) return;
    const state = getState();
    if (areCountriesByContinentLoaded(state, locationType, continentId)) return;
    await dispatch(loadCountriesByContinent(locationType, continentId));
  };
}

import React, { useState, useEffect } from "react";
import { Image } from "../../../api/weatherApi";
import styles from "./WeatherImages.module.scss";

import Card from "../../../components/Card";
import { LocaleMessage, LocaleTime } from "../../../components/Locale";

type WeatherImagesCardProps = {
  title: React.ReactNode;
  images: Image[];
};

const WeatherImagesCard: React.FC<WeatherImagesCardProps> = ({
  title,
  images,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [playing, setPlaying] = useState(true);

  useEffect(() => {
    if (!playing) return;

    let timeoutId = setTimeout(() => {
      setSelectedIndex((selectedIndex + 1) % images.length);
    }, 1000);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [playing, selectedIndex, images]);

  return (
    <Card>
      <div className={styles.head}>
        <h3 className="title mr-20 mb-20">{title}</h3>
        <div className={`${styles.controls} mb-20`}>
          <button
            className={playing ? styles.pauseBtn : styles.playBtn}
            onClick={() => setPlaying(!playing)}
          >
            <LocaleMessage id={playing ? "RadarPause" : "RadarPlay"} />
          </button>
          <div className={styles.indicators}>
            {images.map((x, i) => (
              <div
                key={x.date.getTime()}
                className={`${styles.indicator} ${
                  i === selectedIndex ? styles.active : ""
                }`}
                onClick={() => {
                  setSelectedIndex(i);
                  setPlaying(false);
                }}
              >
                <LocaleTime value={x.date} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.images}>
        {images.map((x, i) => {
          const selected = selectedIndex === i;
          return (
            <img
              key={x.date.getTime()}
              src={x.url}
              alt=""
              className={styles.image}
              style={{
                position: selected ? "relative" : "absolute",
                zIndex: selected ? 1 : 0,
                opacity: selected ? 1 : 0,
              }}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default WeatherImagesCard;

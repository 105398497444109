import { UNITS_SET, UnitActionTypes } from "../types/units";
import { UnitKey } from "../../types/units";

export type UnitsState = {
  user: boolean;
  units: UnitKey;
};

const initialState: UnitsState = {
  user: false,
  units: "F_mph",
};

export default function reducer(
  state: UnitsState = initialState,
  action: UnitActionTypes
): UnitsState {
  switch (action.type) {
    case UNITS_SET:
      if (state.user && !action.user) return state;
      if (state.user === action.user && state.units === action.units)
        return state;
      return {
        ...state,
        units: action.units,
        user: action.user,
      };
    default:
      return state;
  }
}

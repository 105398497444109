import { RootState } from "..";

export const getNotifications = (state: RootState) => state.notifications;
export const getNotificationsPopupDate = (state: RootState) =>
  state.notifications.notificationsPopupDate;

export const getNotificationsPopupShow = (
  state: RootState,
  weatherLocationId: string
) => {
  const { notificationsPopupDate, notificationsPopupLocationId: locationId } =
    getNotifications(state);
  const now = Date.now();
  if (locationId && locationId === weatherLocationId) return false;
  if (notificationsPopupDate && notificationsPopupDate > now) return false;

  return true;
};

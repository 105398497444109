import { RootState } from ".";
import {
  normalizeDailyForecastsShort,
  normalizeDailyForecasts,
  normalizeCurrentWeather,
  normalizeHourlyForecasts,
  normalizeImagery,
  normalizeMonthlyStats,
  normalizeAlerts,
  normalizeTides,
} from "../api/weatherApi";

export function normalizeState(state: Partial<RootState>) {
  if (state.dailyShort) normalizeDailyForecastsShort(state.dailyShort);

  const weather = state.weather;
  if (weather) {
    if (weather.current && weather.current.data)
      normalizeCurrentWeather(weather.current.data);
    if (weather.daily && weather.daily.data)
      normalizeDailyForecasts(weather.daily.data);
    if (weather.hourly && weather.hourly.data)
      normalizeHourlyForecasts(weather.hourly.data);
    if (weather.imagery && weather.imagery.data)
      normalizeImagery(weather.imagery.data);
    if (weather.stats && weather.stats.data)
      normalizeMonthlyStats(weather.stats.data);
    if (weather.alerts && weather.alerts.data)
      normalizeAlerts(weather.alerts.data);
    if (weather.tides && weather.tides.data) normalizeTides(weather.tides.data);
  }

  return state;
}

import React from "react";
import { getCountryUrl } from "../../../utils/url";
import { useSelector } from "react-redux";
import {
  getSelectedContinent,
  getSelectedContinentCountries,
  getSelectedCountry,
  getSelectedLocationType,
} from "../../../store/selectors/locationSelector";
import DropdownAutocomplete, {
  Option,
} from "../../../components/DropdownAutocomplete";
import { useHistory } from "react-router-dom";
import { Country } from "../../../api/placesApi";
import WeatherSelectorTitle from "../WeatherSelectorTitle";
import { useLocale } from "../../../i18n";
import { LocaleMessage } from "../../../components/Locale";

const CountrySelector: React.FC = () => {
  const locale = useLocale();
  const history = useHistory();
  const locationType = useSelector(getSelectedLocationType);
  const continent = useSelector(getSelectedContinent);
  const countries = useSelector(getSelectedContinentCountries);
  const selected = useSelector(getSelectedCountry);

  if (!continent) return null;

  const options = countries.map((x) => ({ value: x, label: x.name }));
  const selectedOption = selected
    ? options.find((x) => x.value === selected)
    : undefined;

  const handleChange = (option: Option<Country>) => {
    if (!option) return;

    history.push(getCountryUrl(locale, locationType, continent, option.value), {
      softRedirect: true,
    });
  };

  return (
    <div className="section">
      <WeatherSelectorTitle>
        <LocaleMessage id="WeatherSelectionCountries" />
      </WeatherSelectorTitle>

      <DropdownAutocomplete
        options={options}
        value={selectedOption}
        placeholder=""
        onChange={handleChange}
      />
    </div>
  );
};

export default CountrySelector;

const oneMinute = 1000 * 60;
const oneHour = oneMinute * 60;

export function convertTimeToMS(time: string): number {
  const [hours, minutes] = time.split(":");
  return +hours * oneHour + +minutes * oneMinute;
}

export function convertMsToTimeArr(ms: number): number[] {
  const hours = Math.floor(ms / oneHour);
  const minutes = (ms % oneHour) / oneMinute;
  return [hours, minutes];
}

import React from "react";
import { WeatherAlert } from "../../api/weatherApi";
import { RemoveScroll } from "react-remove-scroll";

import styles from "./WeatherAlertPopup.module.scss";
import { LocaleMessage } from "../Locale";
import { formatAlertSummary } from "../../types/alerts";
import { useFormatMessage } from "../../i18n";

type WeatherAlertPopupProps = {
  alert: WeatherAlert;
  close: () => any;
};

const WeatherAlertPopup: React.FC<WeatherAlertPopupProps> = ({
  alert,
  close,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <RemoveScroll enabled noIsolation>
      <div className={styles.overlay} onClick={close} />

      <div className={styles.wrap} onClick={close}>
        <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
          <div className={styles.popupHead}>
            <button
              aria-label="Close"
              className={styles.crossBtn}
              onClick={close}
            />
            <div className={styles.warning}>
              <LocaleMessage id="AlertWarning" />
            </div>
          </div>
          <div className={styles.popupBody}>
            <div className={styles.summary}>
              {formatAlertSummary(formatMessage, alert)}
            </div>
            <div className={styles.area}>{alert.area}</div>
            {!!alert.text && <div className={styles.text}>{alert.text}</div>}
            {!!alert.disclaimer && (
              <div className={styles.disclaimer}>{alert.disclaimer}</div>
            )}
            <button className={styles.closeBtn} onClick={close}>
              <LocaleMessage id="AlertCloseBtn" />
            </button>
          </div>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default WeatherAlertPopup;

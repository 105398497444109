import { UserWeatherAlerts } from "../../api/authApi";
import {
  UserWeatherAlertsActionTypes,
  USER_WEATHER_ALERTS_RESET,
  USER_WEATHER_ALERTS_UPDATE,
} from "../types/userWeatherAlerts";

type userAlertsState = UserWeatherAlerts;

const initialState: userAlertsState = {
  _id: "",
  desktop: false,
  locationId: "",
  daily: false,
  dailyDays: [],
  government: false,
  nextHourPrecipitation: false,
  significantChanges: false,
  weatherAlerts: [],
  wind: false,
  windRange: [],
  precipitation: false,
  precipitationType: [],
  precipitationIntensity: [],
  visibility: false,
  air: false,
  airTempCelcius: [],
  uv: false,
  uvIndex: [],
};

export default function userAlertsReducer(
  state: userAlertsState = initialState,
  action: UserWeatherAlertsActionTypes
): userAlertsState {
  switch (action.type) {
    case USER_WEATHER_ALERTS_RESET:
      return initialState;

    case USER_WEATHER_ALERTS_UPDATE:
      const { userWeatherAlerts: userAlerts } = action;
      return { ...state, ...userAlerts };

    default:
      return state;
  }
}

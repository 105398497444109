import React from "react";
import styles from "./DayForecastShort.module.scss";
import { DailyForecast } from "../../../api/weatherApi";
import { formatTempShort } from "../../../types/units";
import { getWeatherDescription } from "../../../types/weatherCodes";
import WeatherCondition from "../../../components/WeatherCondition";
import RainChance from "../../../components/RainChance";
import { useSelector } from "react-redux";
import { getUnits } from "../../../store/selectors/units";
import { LocaleDayShort, LocaleDateShort } from "../../../components/Locale";
import { useFormatMessage } from "../../../i18n";

type DayForecastShortProps = {
  day: DailyForecast;
  title?: string;
  selected?: boolean;
  className?: string;
  small?: boolean;
};

const DayForecastShort: React.FC<DayForecastShortProps> = ({
  day,
  title,
  selected,
  className = "",
  small
}) => {
  const units = useSelector(getUnits);
  const formatMessage = useFormatMessage();

  const {
    date: dateStr,
    weatherCode,
    weatherDesc,
    rainChance,
    tempMin,
    tempMax
  } = day;
  const date = new Date(dateStr);
  const weatherDescription = getWeatherDescription(
    formatMessage,
    weatherCode,
    weatherDesc
  );

  const combinedClassName = `${styles.card} ${small ? styles.small : ""} ${
    selected ? styles.selected : ""
  } ${className}`;

  return (
    <div className={combinedClassName}>
      <div className={styles.day}>
        {title || <LocaleDayShort value={date} />}
      </div>
      <div className={styles.date}>
        <LocaleDateShort value={date} />
      </div>
      <div className={styles.imgContainer}>
        <WeatherCondition
          weatherCode={weatherCode}
          weatherDesc={weatherDescription}
          day
        />
      </div>
      <div>
        <span className={styles.tempMax}>
          {formatTempShort(tempMax, units)}
        </span>
        <span className={styles.tempMin}>
          {" "}
          / {formatTempShort(tempMin, units)}
        </span>
      </div>
      <RainChance
        rainChance={rainChance}
        className={styles.rain}
        light={selected}
      />
      <div className={styles.weatherDesc}>{weatherDescription}</div>
    </div>
  );
};

export default DayForecastShort;
